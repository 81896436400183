export enum SpacingUnit {
  unit0_5 = 'unit-0_5',
  unit1 = 'unit-1',
  unit2 = 'unit-2',
  unit3 = 'unit-3',
  unit4 = 'unit-4',
  unit5 = 'unit-5',
  unit6 = 'unit-6',
  unit7 = 'unit-7',
  unit8 = 'unit-8',
  unit9 = 'unit-9',
  unit10 = 'unit-10',
  unit11 = 'unit-11',
  unit12 = 'unit-12',
  unit13 = 'unit-13',
  unit14 = 'unit-14',
  unit15 = 'unit-15',
}

export const SpacingUnitMap: Record<SpacingUnit, number> = {
  [SpacingUnit.unit0_5]: 2,
  [SpacingUnit.unit1]: 4,
  [SpacingUnit.unit2]: 8,
  [SpacingUnit.unit3]: 12,
  [SpacingUnit.unit4]: 16,
  [SpacingUnit.unit5]: 20,
  [SpacingUnit.unit6]: 24,
  [SpacingUnit.unit7]: 28,
  [SpacingUnit.unit8]: 32,
  [SpacingUnit.unit9]: 36,
  [SpacingUnit.unit10]: 40,
  [SpacingUnit.unit11]: 44,
  [SpacingUnit.unit12]: 48,
  [SpacingUnit.unit13]: 52,
  [SpacingUnit.unit14]: 56,
  [SpacingUnit.unit15]: 60,
};
