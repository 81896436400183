const prefix = window.location.href.match(/localhost:3000/)
  ? 'https://dev1.tvmetrica.ru'
  : '';

export const API_HOST = prefix + '';

export const ADMIN_HOST = prefix + '';

export const VIDEO_API_HOST = prefix + '/wallapi/api/v2';

export const VIDEO_HOST = prefix + '/wallapi';
