import axios from 'axios';
import { toast } from 'react-toastify';

import { VideoRequest } from '../models';

// export const API_HOST = 'https://dev.tvmetrica.ru/wallapi/api/v2';
// export const VIDEO_HOST = 'https://dev.tvmetrica.ru/wallapi';

import { VIDEO_API_HOST } from './Env';

export const apiVideoInstance = axios.create({
  baseURL: VIDEO_API_HOST,
});

const getStringParams = (params: any): string => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

  return searchParams.toString();
};

export const VideoApi = {
  getUrl(url: string) {
    return apiVideoInstance
      .get(url)
      .then((res) => res.data)
      .catch((error) => toast.error(error));
  },
  getChannels(date: string) {
    return apiVideoInstance
      .get(`/Video/GetChannels?date=${date}`)
      .then((response) => response.data)
      .catch(() => {
        toast.error('Сервис не доступен.');
      });
  },
  getDatesWithVideo() {
    return apiVideoInstance
      .get('/Video/GetDatesWithVideo')
      .then((response) => response.data)
      .catch(() => {
        toast.error('Сервис не доступен.');
      });
  },
  getMetrics(request: VideoRequest) {
    return apiVideoInstance
      .get(`/Video/GetMetrics?${getStringParams(request)}`)
      .then((response) => response.data)
      .catch(() => {
        toast.error('Сервис не доступен.');
      });
  },
};
