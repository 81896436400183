import { ReactComponent as Add } from './images/add.svg';
import { ReactComponent as AddGroup } from './images/add-group.svg';
import { ReactComponent as Edit } from './images/edit.svg';
import { ReactComponent as Delete } from './images/delete.svg';

import { ReactComponent as Yes } from './images/yes.svg';
import { ReactComponent as No } from './images/no.svg';

function AddIcon(props) {
  return (
    <div {...props} className="icon-button">
      <Add></Add>
    </div>
  );
}

function AddGroupIcon(props) {
  return (
    <div {...props} className="icon-button">
      <AddGroup></AddGroup>
    </div>
  );
}

function EditIcon(props) {
  return (
    <div {...props} className="icon-button">
      <Edit></Edit>
    </div>
  );
}

function DeleteIcon(props) {
  return (
    <div {...props} className="icon-button">
      <Delete></Delete>
    </div>
  );
}

function YesIcon(props) {
  return (
    <div {...props} className="icon-button">
      <Yes></Yes>
    </div>
  );
}

function NoIcon(props) {
  return (
    <div {...props} className="icon-button">
      <No></No>
    </div>
  );
}



export { AddIcon, AddGroupIcon, EditIcon, DeleteIcon, YesIcon, NoIcon };