import { Collection } from '@models/index';

/**
 * Функция рекурсивно ищет коллекцию со значением paramValue по ключу param
 */
export const findCollectionByValue = (
  data: Collection[],
  param: 'value' | 'id',
  paramValue: any,
): Collection | null => {
  let result: Collection | null = null;

  if (Array.isArray(data)) {
    data?.forEach((el) => {
      if (el[param] === paramValue) {
        result = el;
      }

      if (el.children) {
        const element = findCollectionByValue(el.children, param, paramValue);

        if (element) {
          result = element;
        }
      }
    });
  }

  return result;
};
