/* eslint-disable */
import cn from 'classnames';

function Placeholder({ placeholder, oneLine }) {
  return (
    <div
      className={cn(
        'multiselect-placeholder',
        oneLine && 'multiselect-placeholder_oneline',
      )}
    >
      {placeholder}
    </div>
  );
}

export default Placeholder;
