import React, { useState, useEffect } from 'react'
import API from '@utils/AdminApi';
import DateRangeFilter from '@components/DateRangeFilter/DateRangeFilter'
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedDateRange, fetchDemographics } from '@store/panel';
import { useParams } from 'react-router-dom'
import { useTable } from 'react-table'
import { Button } from '@chakra-ui/react'
import { Link } from "react-router-dom";


function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row)
        return (<tr>
          {row.cells.map(cell => {
            return <td {...cell.getCellProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{cell.render('Cell')}</td>
          })}
        </tr>)
      })}
      </tbody>
    </table>
  )
}


function CityDetails(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.households.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.households.columns;

  let city = '';
  let region = '';
  if (data && data.length !== 0) {
    city = props.data['city']['name'];

    region = <Link  to={{pathname: `/panel/region/${props.data['region']['id']}`}}>
       <Button>{props.data['region']['name']} регион</Button>
    </Link>

    for (const row of data) {
      let household_id = row['household']
      row['household'] = <Link  to={{pathname: `/panel/household/${household_id}`}}>
        <Button>{household_id}</Button>
      </Link>
    }

  }

  return (
    <div><br/>
      <div><b>{city}</b></div>
      <br/>
      <div>
        {region}
      </div>
      <div>
        <br/>
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
}


function PanelCity() {
  const city_id = useParams()['id']

  const datesRange = useSelector((state) => state.panel.datesRange);
  const demographics = useSelector((state) => state.panel.demographics);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const loadData = (city_id, datesRange) => {
    API.postCityInfo({ city_id, datesRange })
      .then(response => {
        if (response) {
          setData(response.data);
        }
      });
  };


  useEffect(() => {
    if (Object.keys(demographics).length === 0) {
      dispatch(fetchDemographics());
    }
    dispatch(changeSelectedDateRange(datesRange));
    loadData(city_id, datesRange);
  }, [datesRange]);

  useEffect(() => {
    if (!data || data.length === 0) return;
  }, [data]);


  return (
    <div>
      <div>
        <DateRangeFilter />
      </div>
      <CityDetails city_id={city_id} data={data}/>
    </div>
  );
}



export default PanelCity
