import { Collection } from '@models/';

type Statistics = {
  name: string;
  type: string;
  wall: boolean;
};

type CreateBroadcastRequestPayload = {
  audience: Collection;
  channels: (number | Collection)[];
  date: string;
  timeband: Collection | null;
};

export type BroadcastRequest = {
  debug: boolean;
  target_base: Collection;
  // Массив Id каналов или объектов наборов каналов
  channels: (number | Collection)[];
  // Массив видов запрашиваемой статистики (tvr, share)
  statistics: Statistics[];
  // Дата в формате "YYYY-MM-DD"
  date: string;
  timeband: Collection | null;
  // INFO: event_end => next event_start, join issued programs to remove gap in between
  join_events: boolean;
};

export const createBroadcastRequestPayload = ({
  audience,
  channels,
  date,
  timeband,
}: CreateBroadcastRequestPayload): BroadcastRequest => {
  return {
    debug: false,
    join_events: true,
    target_base: audience,
    channels: channels,
    statistics: [
      {
        name: 'tvr',
        type: 'tvr',
        wall: false,
      },
      {
        name: 'share',
        type: 'share',
        wall: false,
      },
    ],
    date,
    timeband,
  };
};
