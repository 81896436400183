import React, { useContext, useReducer } from 'react';

export const DataContext = React.createContext();

export const useDataContext = () => {
  return useContext(DataContext);
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'showTree':
      return { ...state, isTree: true }

    case 'showSearch':
      return { ...state, isSearch: true }

    case 'hideAll':
      return { ...state, isTree: false, isSearch: false }

    default:
      return state;
  }
};

export const DataProvider = ({ children, single, keyField, labelField, labelFormat, selectFilter, getIcon }) => {
  const [state, dispatch] = useReducer(reducer, {
    single,
    keyField,
    labelField,
    isTree: false,
    isSearch: false,
    selectFilter,
    getIcon,
  });

  const clear = () => {
    dispatch({ type: 'clear', payload: null });
  };

  const showTree = () => {
    dispatch({ type: 'showTree' });
  };

  const showSearch = () => {
    dispatch({ type: 'showSearch' });
  };

  const hideAll = () => {
    dispatch({ type: 'hideAll' });
  };

  const formatLabel = (entry) => {
    if (labelFormat) {
      return labelFormat(entry);
    }

    return entry[labelField];
  }

  return (
    <DataContext.Provider
      value={{
        isTree: state.isTree,
        isSearch: state.isSearch,
        single: state.single,
        keyField: state.keyField,
        labelField: state.labelField,
        selectFilter: state.selectFilter,
        getIcon: state.getIcon,
        clear,
        showTree,
        showSearch,
        hideAll,
        formatLabel,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};