import { ChakraProvider } from '@chakra-ui/react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Content from './components/Content/Content';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { Login } from './pages/login/Login';
import { store } from './store';
import { AuthProvider } from './store/AuthProvider';
import theme from './theme';

// todo KTS -> woff2
import './fonts/Roboto/Roboto-Medium.ttf';

import './App.scss';

const toastOptions = {
  autoClose: 3000,
  draggable: false,
  hideProgressBar: false,
  closeOnClick: true,
  rtl: false,
  pauseOnHover: true,
};

declare global {
  type RootState = ReturnType<typeof store.getState>;
  type AppDispatch = typeof store.dispatch;
  interface Window {
    _env_: any;
  }
}

declare module 'react-redux' {
  type DefaultRootState = RootState;
}

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ConfigProvider locale={ru_RU}>
        <AuthProvider>
          <Login>
            <div className="wrapper">
              <Router>
                <Header />
                <Sidebar />
                <Content />
              </Router>
            </div>
          </Login>
        </AuthProvider>
        <ToastContainer {...toastOptions} />
      </ConfigProvider>
    </ChakraProvider>
  );
};

export default App;
