import React from 'react';
import { toast } from 'react-toastify';

// @ts-ignore
import { ReactComponent as Logo } from '../../components/Header/assets/Logo_text_black.svg';
import LoginForm from '../../components/LoginForm/LoginForm';
import { useAuthContext } from '../../store/AuthProvider';
import { PDAPI } from '../../utils/PDApi';

import './Login.scss';

export interface LoginProps {
  children: any;
}

export function Login({ children }: LoginProps) {
  const { isLoggedIn, login: authLogin, authLoad } = useAuthContext();

  const authRequest = (login: string, password: string) => {
    PDAPI.login(login, password)
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error('Неверный логин или пароль!');
        } else {
          toast.error('Системная ошибка.');
        }
      })
      .then((result) => {
        if (result?.data?.token) {
          authLogin(result.data.token, result.data.refresh_token);
          authLoad(result.data.username ?? '');
        }
      });
  };

  return isLoggedIn ? (
    children
  ) : (
    <div className="login-wrapper">
      <div className="login-box">
        <div className="login-box-header">
          <Logo />
        </div>
        <div className="login-box-content">
          <h1>Авторизация</h1>
          <h3>Введите Логин и Пароль</h3>

          <div className="login-box-form">
            <LoginForm handleSubmit={authRequest} />
          </div>
        </div>
      </div>
    </div>
  );
}
