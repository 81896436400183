import { useMemo, useState, useEffect, useCallback } from 'react';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { ADVERT_FIELDS as FIELDS } from './utils';
import FieldSelect from './UI/FieldSelect';
import ConditionSelect from './UI/ConditionSelect';
import MultiSelect from '@components/MultiSelect';
import TreeSelect from '@components/MultiSelect/TreeSelect/TreeSelect';
import { DeleteIcon } from './UI/Buttons';
import { Input } from 'antd';

const contentOptions = [ 
  { value: 0, label: 'N/A' },
  { value: 1, label: 'Коммерческий' },
  { value: 2, label: 'Спонсорский' },
  { value: 3, label: 'Анннос' },
  { value: 4, label: 'Политический' },
];

const distributionOptions = [
  { value: 'network', label: 'Сетевой'},
  { value: 'orbital', label: 'Орбитальный'},
  { value: 'local', label: 'Локальный'},
];

const initValue = (field) => {
  const fieldData = FIELDS.filter(({ id }) => id === field)?.pop();

  if (fieldData && fieldData.type) {
    switch (fieldData.type) {
      case 'checkboxGroup':
      case 'treeview':
      case 'multiselect':
      case 'search':

        return [];
    }
  }

  return '';
}

function AdvertCondition(props) {
  const brands = useSelector((state) => state.collections.advertsBrands);

  const articles = useSelector((state) => state.collections.advertsArticles);

  const channels = useSelector((state) => state.collections.channels);

  const [ field, setField ] = useState();
  const [ condition, setCondition ] = useState();
  const [ value, setValue ] = useState();

  const [ options, setOptions ] = useState([]);

  const valueType = useMemo(() => {
    const find = FIELDS.filter(({ id }) => id === field);

    return find.length ? find[0] : null;
  }, [ field ]);

  const onChangeField = (field) => {
    const initedValue = initValue(field);

    const find = FIELDS.filter(({ id }) => id === field).pop();

    const _condition = condition && find.conditions.indexOf(condition) !== -1 ? props.condition : find.conditions[0];

    if (props.onChangeHandle) {
      props.onChangeHandle(field, _condition, initedValue);
    } else {
      setField(field);
      setValue(_condition);
      setValue(value ? value : initedValue);
    }
  }

  const onChangeCondition = (condition) => {
    const initedValue = initValue(field);

    if (props.onChangeHandle) {
      props.onChangeHandle(field, condition, value ? value : initedValue);
    } else {
      setCondition(condition);
    }
  }

  const onChangeValue = (value) => {
    if (field && condition && props.onChangeHandle) {
      props.onChangeHandle(field, condition, value);
    } else {
      setValue(value);
    }
  }  

  const onChangeCheckbox = (v) => {
    onChangeValue(
      v.filter(id => value.indexOf(id) == -1)
    );
  }

  useEffect(() => {
    if (!valueType) {
      return;
    }

    switch (valueType.id) {
      case 'brand_id':
      case 'subbrand_id':
        setOptions(
          JSON.parse(
            JSON.stringify(brands)
          )
        );

        break;

      case 'article_id':
        setOptions(
          JSON.parse(
            JSON.stringify(articles)
          )
        );

        break;

      case 'channels':
        setOptions(channels.map(channel => {
          return {
            value: channel.id,
            label: channel.name,
          }
        }));

        break;

      case 'content':
        setOptions(contentOptions);

        break;

      case 'distribution':
        setOptions(distributionOptions);

        break;
    }    
  }, [ valueType ]);

  const conditions = useMemo(() => {
    return valueType ? valueType.conditions.map(value => ({ value, label: value })) : []
  }, [ valueType ])

  // инициализация постого блока
  useEffect(() => {
    const _field = props.field ? props.field : FIELDS[0].id;

    const find = FIELDS.filter(({ id }) => id === _field);

    const _condition = props.condition ? props.condition : find[0].conditions[0];

    const _value = props.value ? props.value : initValue(_field);

    if (props.new && props.onChangeHandle) {
      props.onChangeHandle(_field, _condition, _value);
    } else {
      setField(_field);
      setCondition(_condition);
      setValue(_value);
    }
  }, [ props ]);

  const onDelete = () => {
    if (props.onDeleteHandle) {
      props.onDeleteHandle();
    }
  }

  if (!field && valueType === null) {
    return <></>
  }

  return  (
    <div className="condition">
      <div className="condition-wrapper">
        <div className="field">
          <FieldSelect
            value={field}
            placeholder="Поле"
            onChange={event => onChangeField(event?.value)}
            options={FIELDS.map(({ id, name }) => ({ value: id, label: name }))}
          />
        </div>
        <div className="field">
          <ConditionSelect
            value={condition}
            onChange={event => onChangeCondition(event?.value)}
            options={conditions}
          />
        </div>
        { valueType.type === 'checkboxGroup' ? (
          <div className="field full-width">
            <Checkbox.Group key={valueType.id} options={valueType.options} value={value} onChange={(value) => onChangeCheckbox(value)} />
          </div>
        ) : (
          valueType.type === 'search' ? (
            <div className="field full-width">
              <TreeSelect
                key={valueType.id}
                searchOnly={true}
                single={true}
                selected={value}
                onChange={value => onChangeValue(value)}
                line={false}
                {...valueType.params}
              />
            </div>
        ) : (
          valueType.type === 'treeView' ? (
            <div className="field full-width">
              <TreeSelect
                key={valueType.id}
                selected={value}
                onChange={value => onChangeValue(value)}
                options={options}
                single={false}
                {...valueType.params}
              />
            </div>
        ) : (
          valueType.type === 'multiselect' ? (
            <div className="field full-width">
              <MultiSelect
                key={valueType.id}
                options={options}
                onChange={(value) => onChangeValue(value)}
                selected={value}
                search={true}
                dropDown={true}
              />
            </div>
          ) : (
            valueType.type === 'text' ? (
              <div className="field full-width">
                <Input
                  key={valueType.id}
                  onChange={(event) => onChangeValue(event.target.value)}
                  value={value}
                />
              </div>
          ) : <></> )))) }
      </div>
      <div className="condition-actions">
        <DeleteIcon
          onClick={onDelete}
        />
      </div>
    </div>
  )
}

export default AdvertCondition;