import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import LoaderTop from '@components/LoaderTop/LoaderTop';
import API from '@utils/AdminApi';
import Type from './UI/Type';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { changeName, addGroup, deleteItem, spliceElement, appendElement } from './Utils';

import { ConfirmProvider } from '@components/Confirm/ConfirmProvider';
import Confirm from '@components/Confirm/Confirm';

import { openType, openGroup, setEditGroup, cleanCollections } from '@store/collections';

import './CollectionList.scss';

/**
 * 
 * @param {
 *  onAddHandle(id),
 *  onEditHandle(id),
 *  onCreateHandle(),
 * } props 
 * @returns 
 */
function CollectionList(props) {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  /**
   * переход на редактирование
   * @param {*} id 
   */
   const createHandle = (parentId, type) => {
    if (props.onCreateHandle) {
      props.onCreateHandle(parentId, type);
    }
  }

  /**
   * переход на редактирование
   * @param {*} parentId, id 
   */
  const editHandle = (parentId, id) => {
    if (props.onEditHandle) {
      props.onEditHandle(parentId, id);
    }
  }

  const createGroupHandle = (parent, type) => {
    const group = {
      parent: parent,
      name: 'Группа',
      type: type,
      is_group: true,
      children: [],
    };

    API.saveGroup(group).then(id => {
      if (id) {
        group.id = id;

        dispatch(setEditGroup(id));

        const newData = [...data];

        newData.forEach(typeData => {
          if (typeData.type === type) {
            if (parent === null) {
              typeData.children.push(group);
              dispatch(openType(type));
            } else {
              addGroup(typeData.children, group);
              dispatch(openGroup(parent));
            }
          }
        });
    
        setData(newData);
        dispatch(cleanCollections());
      }
    });
  }

  const editGroupHandle = (id, parent, name, type) => {
    API
      .updateGroup({
        id,
        parent,
        name,
        type
      }).then(() => {
        dispatch(cleanCollections());
      });



    const newData = [...data];

    newData.forEach(typeData => {
      if (typeData.type === type) {
        changeName(typeData.children, id, name);
      }
    });

    setData(newData);
  };

  const moveCollection = (id, parent, type) => {
    API
      .changeGroup({
        id,
        parent,
      }).then(() => {
        dispatch(cleanCollections());
      });

    const newData = [...data];

    newData.forEach(typeData => {
      if (typeData.type === type && typeData.children?.length) {
        const element = spliceElement(typeData.children, id);
        if (element) {
          element.parent = parent;

          if (parent && parent != null) {
            appendElement(typeData.children, parent, element);
          } else {
            typeData.children.push(element);
          }
        }
      }
    });

    setData(newData);
  }

  /**
   * загрука данных для таблицы
   */
  const loadData = () => {
    setIsLoading(true);

    API.getCollections(false)
      .then(response => {
        if (response.data) {
          setData(response.data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * удаление набора
   * @param {*} item 
   */
  const deleteHandle = (item, isGroup = false) => {
    API
      .deleteCollection(item.id, isGroup)
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast.error('Ошибка при удалении');
        } else {
          toast.error('Системная ошибка.');
        }
      })
      .then(result => {
        if (result?.status === 200) {
          toast.success('Успешно');

          const newData = [...data];
          newData.forEach(value => deleteItem(value, item.id));
          setData(newData);

          dispatch(cleanCollections());
        }
      });
  }

  const actions = {
    createHandle,
    createGroupHandle,
    editHandle,
    editGroupHandle,
    deleteHandle,
  }

  /**
   * инициализация таблицы и запуск загруки данных
   */
  useEffect(() => {
    loadData();
  }, [ ]);

  return isLoading ? ( <LoaderTop /> ) : (
    <ConfirmProvider>
      <div>
        <Confirm />
        { data.map(type => (
          <div key={'type_' + type.type}>
            <DndProvider backend={HTML5Backend}>
              <Type {...type} actions={actions} moveCollection={moveCollection}></Type>
            </DndProvider>
          </div>
        )) }
      </div>
    </ConfirmProvider>
  );
}

export default CollectionList;