import React, { useState, useEffect } from 'react';
import API from '@utils/AdminApi';
import DateRangeFilter from '@components/DateRangeFilter/DateRangeFilter';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedDateRange } from '@store/panel';

import { useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { fetchDemographics } from '@store/panel';


function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (<tr>
          {row.cells.map(cell => {
            return <td {...cell.getCellProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{cell.render('Cell')}</td>;
          })}
        </tr>);
      })}
      </tbody>
    </table>
  );
}


function RegionDetails(props) {

  let name = Object.keys(props.data).length === 0 ? [] : props.data.name;
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;

  if (data && data.length !== 0) {
    for (const row of data) {
      row['city'] = <Link to={{ pathname: `/panel/city/${row['city_id']}` }}>
         <Button>{row['city']}</Button>
       </Link>;
     }
   }

  return (
    <div>
      <div><b> {name} регион</b></div>
      <br/>
      <Table columns={columns} data={data} />
    </div>
  );
}

function PanelRegion() {
  const region_id = useParams()['id'];

  const datesRange = useSelector((state) => state.panel.datesRange);
  const demographics = useSelector((state) => state.panel.demographics);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const loadData = (region_id, datesRange) => {
    API.postRegionInfo({ region_id, datesRange })
      .then(response => {
        if (response) {
          setData(response.data);
        }
      });
  };


  useEffect(() => {
    if (Object.keys(demographics).length === 0) {
      dispatch(fetchDemographics());
    }
    dispatch(changeSelectedDateRange(datesRange));
    loadData(region_id, datesRange);
  }, [datesRange]);

  useEffect(() => {
    if (!data || data.length === 0) return;
  }, [data]);


  return (
    <div>
      <div>
        <DateRangeFilter />
      </div>
      <RegionDetails region_id={region_id} data={data}/>
    </div>
  );
}


export default PanelRegion;
