import { createSlice } from '@reduxjs/toolkit'

export const audiencePanel = createSlice({
  isLoading: false,
  name: 'audiencePanel',
  initialState: {
    barData: {
      xAxis: [],
      series: [],
    },
    queryData: {
      fromDate: new Date(),
      fromTime: '05:00',
      toDate: new Date(),
      toTime: '28:59',
      channels: [],
    },
    pie: {
      channels: {},
      categories: {},
      programs: {},
    },
    channelId: 0,
    path: '',
    payload: {},
    filters: {},
    demographicsData: [],
    pieCharts: [],
  },
  reducers: {
    setBarData: (state, action) => {
      state.barData = action.payload
    },
    setQueryData: (state, action) => {
      state.queryData = action.payload
    },
    setPie: (state, action) => {
      state.pie[action.payload.data_type] = action.payload.value;
    },
    setChannelId: (state, action) => {
      state.channelId = action.payload
    },
    setPath: (state, action) => {
      state.path = action.payload
    },
    setPayload: (state, action) => {
      state.payload = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setDemographicsData: (state, action) => {
      state.demographicsData = action.payload
    },
    setPieCharts: (state, action) => {
      state.pieCharts = action.payload
    },
  },
})

export const { setBarData, setQueryData, setPie, setChannelId, setPath, setPayload, setFilters, setDemographicsData, setPieCharts } = audiencePanel.actions

export default audiencePanel.reducer
