/* eslint-disable */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import MultiSelect from '@components/MultiSelect';

const months = [
  {
    value: 1,
    label: 'Январь',
    days: 31,
  },
  {
    value: 2,
    label: 'Февраль',
    days: 29,
  },
  {
    value: 3,
    label: 'Март',
    days: 31,
  },
  {
    value: 4,
    label: 'Апрель',
    days: 30,
  },
  {
    value: 5,
    label: 'Май',
    days: 31,
  },
  {
    value: 6,
    label: 'Июнь',
    days: 30,
  },
  {
    value: 7,
    label: 'Июль',
    days: 31,
  },
  {
    value: 8,
    label: 'Август',
    days: 31,
  },
  {
    value: 9,
    label: 'Сентябрь',
    days: 30,
  },
  {
    value: 10,
    label: 'Октябрь',
    days: 31,
  },
  {
    value: 11,
    label: 'Ноябрь',
    days: 30,
  },
  {
    value: 12,
    label: 'Декабрь',
    days: 31,
  },
];

function MonthPicker({ value, onChange }) {
  const _value = useRef('');

  const [month, setMonth] = useState();
  const [day, setDay] = useState();

  const days = useMemo(() => {
    const monthData = months.filter((m) => m.value === month);

    if (!monthData.length) {
      return [];
    }

    const result = [];

    for (let i = 1; i <= monthData[0].days; i++) {
      result.push({
        value: i,
        label: `0${i}`.slice(-2),
      });
    }

    return result;
  }, [month]);

  useEffect(() => {
    if (value && value !== _value.current) {
      const [month, day] = value ? value.split('-') : [1, 1];

      _value.current = value;

      setMonth(parseInt(month));
      setDay(parseInt(day));
    }
  }, [value]);

  useEffect(() => {
    const value = `0${month ?? 0}`.slice(-2) + '-' + `0${day ?? 0}`.slice(-2);

    if (_value.current !== value) {
      _value.current = value;

      if (onChange) {
        onChange(value);
      }
    }
  }, [month, day]);

  return (
    <div style={{ display: 'flex' }}>
      <MultiSelect
        options={months}
        placeholder="Месяц"
        onChange={(value) => setMonth(value[0])}
        selected={[month]}
        dropDown={true}
        single={true}
      />
      <span>&nbsp;</span>
      <MultiSelect
        options={days}
        placeholder="день"
        onChange={(value) => setDay(value[0])}
        selected={[day]}
        dropDown={true}
        single={true}
      />
    </div>
  );
}

export default MonthPicker;
