/* eslint-disable */
import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ReactComponent as Circle } from '@UI/assets/circle.svg';
import { ReactComponent as Delete } from '@pages/collections/crud/UI/images/delete.svg';
import { ReactComponent as Edit } from '@pages/collections/crud/UI/images/edit.svg';
import { ReactComponent as Yes } from '@pages/collections/crud/UI/images/yes.svg';
import { ReactComponent as No } from '@pages/collections/crud/UI/images/no.svg';

import './Event.scss';

function Event({ editId, index, entry, onDelete, onMove, onEdit }) {
  const [confirm, setConfirm] = React.useState(false);

  const showConfirm = () => {
    setConfirm(true);

    setTimeout(() => setConfirm(false), 5000);
  };

  const moveRow = (dragId, hoverId, position) => {
    if (onMove) {
      onMove(dragId, hoverId, position);
    }
  };

  const dropRow = useCallback((itemId, hoverId, last) => {

  }, [ onMove ]);

  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'collection',
    item: () => {
      return { id: entry.id };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'collection',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropRow(item.id, null);
    },
    hover(item, monitor) {
      const dragId = item.id;
      const hoverId = entry.id;

      if (dragId === hoverId) {
          return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY= (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      moveRow(dragId, hoverId, hoverClientY > hoverMiddleY ? 1 : 0);
    },
  });

  drag(drop(ref));

  const opacity = {opacity: editId === entry.id ? 0.5 : 1};

  return (
    <div
      className="event-wrapper"
      data-handler-id={handlerId}
      ref={ref}
      style={opacity}
    >
      <div className="sort">
        <div><Circle /><Circle /><Circle /></div>
      </div>
      <div className="info">
        {!!entry.channel && (
          <div>
            <span>Канал</span>{entry.channel.map((value, index) => (<span key={index}>{value}</span>))}
          </div>
        )}
        {!!entry.category && (
          <div>
            <span>Категория</span>{entry.category.map((value, index) => (<span key={index}>{value}</span>))}
          </div>
        )}
        {!!entry.timeband && (
          <div>
            <span>Таймбэнд</span>{entry.timeband.map((value, index) => (<span key={index}>{value}</span>))}
          </div>
        )}
        {!!entry.program && (
          <div>
            <span>Программа</span>{entry.program.map((value, index) => (<span key={index}>{value}</span>))}
          </div>
        )}
      </div>
      <div className="group">
        <div>
          <div>
            {!!entry.aggregated && <span>Группировать</span>}
            {!entry.aggregated && <span>Отдельно</span>}
          </div>
        </div>
      </div>
      <div className="buttons">
        <div>
          {confirm ? (
            <>
              <div className="button"><Yes onClick={() => { setConfirm(false); onDelete(index); }} /></div>
              <div className="button"><No onClick={() => setConfirm(false)} /></div>
            </>
          ) : (
            <>
              <div className="button"><Edit onClick={() => onEdit(entry.id)} /></div>
              <div className="button"><Delete onClick={() => showConfirm()} /></div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Event;
