// todo KTS add props typings
/* eslint react/prop-types: 0 */
import {
  FormControl,
  FormLabel,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState, useMemo, useRef, useCallback } from 'react';
import 'moment/locale/ru';
import { useSelector } from 'react-redux';

import { Calendar } from '@components/DateRange';
import * as locales from '@components/DateRange/locale';
import { ReactComponent as CalendarIcon } from '@components/UI/assets/calendar.svg';
import { dateColorCallback } from '@utils/helpers';

import s from './DatePicker.module.scss';

// todo KTS refactor
function DatePicker(props) {
  const dates = useSelector((state) => state.status.statusObject);

  const calendarWrapperRef = useRef();

  const [showCalendar, setShowCalendar] = useState(false);

  const hideCalendar = useCallback(() => setShowCalendar(false), []);

  useOutsideClick({
    ref: calendarWrapperRef,
    handler: hideCalendar,
  });

  const onChange = (dateRange) => {
    if (props.onChange) {
      props.onChange(dateRange);
    }
  };

  const onRangeChange = (range) => {
    onChange(range);
    setTimeout(hideCalendar, 200);
  };

  const minDate = useMemo(
    () =>
      moment()
        .set('year', props.start ?? 2018)
        .set('month', 0)
        .set('day', 1)
        .toDate(),
    [props.start],
  );

  const maxDate = useMemo(
    () => props.maxDate ?? moment().toDate(),
    [props.maxDate],
  );

  const dateColor = useMemo(() => {
    return dateColorCallback(dates);
  }, [dates]);

  const rangeColors = ['#F15B5B'];

  return (
    <FormControl
      isRequired={props.isRequired ?? false}
      className={props.className ?? 'form-control'}
    >
      {!!props.label && <FormLabel htmlFor="range">{props.label}</FormLabel>}
      <div className="date-range-text" onClick={() => setShowCalendar(true)}>
        <Text ml={1} mr={1} className={s['date-range-text__dates']}>
          {props.value.length
            ? props.value
                .map((date) => Intl.DateTimeFormat('ru-RU').format(date))
                .join(', ')
            : props.placeholder}
        </Text>
        <div className="date-range-text-icon">
          <CalendarIcon />
        </div>
      </div>

      <div
        className={`date-range--calendar-wrapper ${showCalendar ? 'show' : ''}`}
        ref={calendarWrapperRef}
      >
        <Calendar
          showDateDisplay={false}
          showSelectionPreview={false}
          editableDateInputs={false}
          onChange={onRangeChange}
          moveRangeOnFirstSelection={false}
          locale={locales.ru}
          minDate={minDate}
          maxDate={maxDate}
          rangeColors={rangeColors}
          dayContentRenderer={dateColor}
          color="#F15B5B"
          weekdayDisplayFormat="EEEEEE"
          disabledDay={props.disabledDay}
        />
      </div>
    </FormControl>
  );
}

export default DatePicker;
