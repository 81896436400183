/* eslint-disable */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import Targets from '../../components/Sources/groups/Targets';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchChannels,
  fetchStatistics,
  fetchCollections,
} from '@store/collections';
import Timebands from '../../components/Sources/groups/Timebands';
import DateRange from '@components/Sources/groups/DateRange';
import API from '@utils/AdminApi';
import { Radio } from 'antd';
import Statistics from '@components/Sources/groups/Statistics';
import { Button } from '../../components/UI/Buttons';
import { setData, setResults, setChartStat, setStatistics } from '@store/forms/AverageForm';
import Collection from './Collection';
import { collectionToArray } from '@utils/helpers.js';
import { useAuthContext } from '../../store/AuthProvider';
import { useTable } from 'react-table';
import { TableLayout } from '../tables/layouts';
import Csv from '@utils/Csv.js';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { toast } from "react-toastify";
import moment from 'moment';

import { ReactComponent as Copy } from '@UI/assets/copy.svg';


import './Average.scss';

function prepareData(csv_data) {
  // const rows = [];
  //
  // csv_data.forEach(data => {
  //   rows.push([data.name]);
  //
  //   rows.push(data.data.columns.map(entry => entry.Header));
  //
  //   data.data.data.forEach(entry => {
  //     rows.push(Object.values(entry));
  //   });
  // });

  return csv_data;
}

function Average() {
  const dispatch = useDispatch();

  const inited = useRef(false);

  const { population: population100k } = useAuthContext();

  const data = useSelector((state) => state.averageForm.data);
  const averageResult = useSelector((state) => state.averageForm.results);
  const chartStat = useSelector((state) => state.averageForm.chartStat);
  const chartStats = useSelector((state) => state.averageForm.statistics);

  const maxDate = useSelector((state) => state.status.maxDate);
  const formData = useSelector((state) => state.averageForm.data);

  const [loading, setLoading] = useState(false);

  const highchartsRef = useRef();

  const onchange = useCallback(
    (value, target) => {
      const newData = { ...data };

      if (target === 'datebands') {
        value = value ? value[0] : null;
      }

      newData[target] = value;

      dispatch(setData(newData));
    },
    [data],
  );

  const targetValue = useMemo(
    () =>
      data.target_base && data.target_base.id ? [data.target_base.id] : [],
    [data.target_base],
  );
  const datebandsValue = useMemo(
    () => (data.datebands && data.datebands.id ? [data.datebands.id] : []),
    [data.datebands],
  );
  const statisticsValue = useMemo(
    () => (data.statistics ? data.statistics.map(({ type }) => type) : []),
    [data.statistics],
  );
  const chartStatValue = useMemo(
    () => chartStat && chartStat[0] ? [chartStat[0].type] : [],
    [chartStat],
  );

  const eventsValue = useMemo(
    () => (data.events ? data.events : null),
    [data.events],
  );

  useEffect(() => {
    dispatch(fetchChannels());

    dispatch(fetchStatistics('average'));
    dispatch(fetchCollections());
  }, []);

  useEffect(() => {
    if (!inited.current) {
      inited.current = true;

      formData && setData({ ...data, ...formData });
    }
  }, [formData]);

  const premierOptions = [
    {
      label: 'Все',
      value: null,
    },
    {
      label: 'Премьеры',
      value: true,
    },
    {
      label: 'Повторы',
      value: false,
    },
  ];

  const groupOptions = [
    { label: 'Год', value: 'year' },
    { label: 'Месяц', value: 'month' },
    { label: 'Неделя', value: 'week' },
    { label: 'День', value: 'day' },
  ];

  const getChannelCollection = async (value) => {
    if (Number.isInteger(value)) {
      return value
    }
    const response = await API.getCollection(value.id);
    return response && response.data
      ? response.data
      : null;
  };

  const getCollection = async (id) => {
    const response = await API.getCollection(id);

    return response && response.data
      ? response.data
      : null;
  };

  const calculate = async () => {
    setLoading(true);

    dispatch(setResults(null));

    const payload = { ...data };

    payload.dates_range = {
      start: moment(payload.dates_range.startDate).format('yyyy-MM-DDT05:00:00'),
      end: moment(payload.dates_range.endDate).format('yyyy-MM-DDT05:00:00'),
    }
    /*
          for (let i = 0; i < channels.length; i++) {
        if (Number.isInteger(channels[i])) {
          data.push(channels[i]);
        } else {
          const response = await API.getCollection(channels[i].id);

          if (response && response.data) {
            data.push(response.data);
          }
        }
      }
     */
    payload.events = await Promise.all(

      payload.events.map(async (entry) => {
        switch (entry.block) {
          case 0:
            return {
              channels: entry.channel ? await Promise.all(
                  entry.channel.map(
                    async (v) => await getChannelCollection(v),
                  ),
                )
                : null,
              timeband: entry.timeband
                ? await Promise.all(
                    entry.timeband.map(
                      async (timeband) => await getCollection(timeband.id),
                    ),
                  )
                : entry.timeband,
              aggregated: entry.aggregated
            };

          case 1:
            return {
              programs: entry.program,
              timeband: entry.timeband
                ? await Promise.all(
                    entry.timeband.map(
                      async (timeband) => await getCollection(timeband.id),
                    ),
                  )
                : entry.timeband,
              aggregated: entry.aggregated,
            };

          case 2:
            return {
              channels: entry.channel ? await Promise.all(
                  entry.channel.map(
                    async (v) => await getChannelCollection(v),
                  ),
                )
                : null,
              categories: entry.category,
              timeband: entry.timeband
                ? await Promise.all(
                    entry.timeband.map(
                      async (timeband) => await getCollection(timeband.id),
                    ),
                  )
                : entry.timeband,
              aggregated: entry.aggregated,
            };
        }

        return null;
      }),
    );

    payload.target_base = payload.target_base
      ? await getCollection(data.target_base.id)
      : payload.target_base;

    payload.datebands = payload.datebands
      ? await getCollection(data.datebands.id)
      : payload.datebands;

    payload.population_100 = population100k;
    API.postAverage(payload)
      .then((result) => {
        dispatch(setResults(result.data));
        dispatch(setStatistics(data.statistics.map(entry => entry.type)));
        dispatch(setChartStat([data.statistics[0]]));
      })
      .finally(() => setLoading(false));
  };

  const isValid = useMemo(() => {

    if (!data.target_base || !data.dates_range) {
      return false;
    }

    if (!data.events || data.events.length === 0) {
      return false;
    }


    return true;
  }, [ data ]);

  const copyToClipboard = useCallback(() => {
    if (averageResult && averageResult.csv) {
      // Csv(prepareData(averageResult.csv));
      Csv(averageResult.csv, );
      toast.success(`Данные скопированы в буфер обмена`);
    }
  }, [ averageResult ]);

  const charts = useMemo(() => {
    const series = [];

    if (chartStat && averageResult && averageResult.chart) {
      averageResult.chart.series.forEach(s => {
        series.push({
          name: s.name,
          data: s.data[chartStatValue[0]] ?? [],
        });
      });
    }

    return {
      chart: {
        type: 'column',
        height: 450,
      },
      title: null,
      xAxis: {
        categories: averageResult ? averageResult.chart.xAxis.categories : [],
        crosshair: false,
      },
      yAxis: {
        min: 0,
        title: {
          text: chartStat && chartStat[0] ? chartStat[0].name : 'Статистика',
        },
      },
      tooltip: {
        headerFormat: '<div style="font-size:10px">{point.key}</div>',
        pointFormat: '<div style="font-size:10px;"><span style="color:{series.color}; font-size:10px;">{series.name}: </span> &nbsp; <b>{point.y:.2f}</b></div>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          groupPadding: 0.05,
        },
      },
      series,
    }
  }, [ averageResult, chartStat ]);

  return (
    <div>
      <div className="card-wrapper">
        <div className="card-title">
          <div className="title">Подсчет средних значений</div>
        </div>
        <div className="card-content no-radius">
          <div className="card-row">
            <div className="card-col">
              <Targets
                name="targetBase"
                value={targetValue}
                isRequired={true}
                onChange={(v) => onchange(v, 'target_base')}
                label="Целевая аудитория"
                className="form-control"
                placeholder="Целевая аудитория"
              />
            </div>
            <div className="card-col">
              <DateRange
                onChange={(v) => onchange(v, 'dates_range')}
                label="Дата"
                isRequired={true}
                placeholder="Выберите диапазон дат"
                value={data.dates_range}
                maxDate={maxDate}
              />
            </div>
            <div className="card-col">
              <Timebands
                label="Таймбэнд по дням"
                placeholder="Таймбэнд по дням"
                className="form-control"
                onChange={(value) => onchange(value, 'datebands')}
                value={datebandsValue}
                single={true}
              />
            </div>
          </div>

          <div className="card-row">
            <div className="card-col">
              <label>Только</label>
              <div className="premier-wrapper">
                <Radio.Group
                  options={premierOptions}
                  onChange={({ target: { value } }) => onchange(value, 'premier')}
                  value={data.premier}
                />
              </div>
            </div>
            <div className="card-col">
              <label>Группировать усреднение</label>
              <div className="premier-wrapper">
                <Radio.Group
                  options={groupOptions}
                  onChange={({ target: { value } }) => onchange(value, 'aggregateBy')}
                  value={data.aggregateBy}
                />
              </div>
            </div>
          </div>

          <div className="card-row">
            <div className="card-col">
              <Statistics
                name="statistics"
                label="Статистика(и)"
                placeholder="Выбери статистику"
                className="form-control"
                isRequired={true}
                single={false}
                value={statisticsValue}
                onChange={(value) => onchange(value, 'statistics')}
                source="average"
              />
            </div>
          </div>
        </div>
      </div>

      <Collection
        defaultValue={eventsValue}
        onChange={(value) => onchange(value, 'events')}
      />

      <div className="results-button">
        <Button onClick={calculate} label="Рассчитать" disabled={!isValid}></Button>
        {/*<Button onClick={calculate} label="Рассчитать" disabled={loading || !isValid}></Button>*/}
      </div>

      <div className="card-wrapper">
        <div className="card-title">
          <div className="title">Результаты</div>
          { averageResult && averageResult.table ? <div className="actions"><Copy onClick={() => copyToClipboard()} /></div> : <></> }
        </div>
        <div className="card-content">
        { averageResult && averageResult.chart  ? <>
          <div className="statistics">
            <Statistics
              label="Статистика"
              placeholder="Выбери статистику"
              className="form-control"
              single={true}
              value={chartStatValue}
              onChange={(value) => dispatch(setChartStat(value))}
              source="average"
              filter={chartStats}
            />
          </div>

          <HighchartsReact
            highcharts={Highcharts}
            options={charts}
            ref={highchartsRef}
            allowChartUpdate={true}
            showLoading={true}
          />
        </> : <div className="card-row"><div className="card-col"><span>Пусто</span></div></div> }
        </div>
      </div>
    </div>
  );
}

export default Average;
