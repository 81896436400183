/* eslint-disable */
import { Navigate, Route, Routes } from 'react-router-dom';
import Collections from '@pages/collections/Collections';
import Tables from '@pages/tables/Tables';
import { connect } from 'react-redux';
import Panel from '@pages/panel/Panel';

import Programs from '@pages/programs/Programs';

import ProgramsCategoriesByChannelAndType from '@pages/programs/ProgramsCategoriesByChannelAndType';
import ChannelCategoryPrograms from '@pages/programs/ProgramsByChannelAndCategory';
import Program from '@pages/programs/Program';
import ProgramIssued from '@pages/programs/ProgramIssued';
import ProgramsTypesByChannel from '@pages/programs/ProgramsTypesByChannel';
import CompareAudience from '@pages/programs/CompareAudience';

import ChannelsPage from '@pages/channels/ChannelsPage';
import ChannelPage from '@pages/channels/Channel';

import './Content.scss';
import PanelMember from '@pages/panel/PanelMember';
import Audience from '@pages/panel/Audience';
import PanelHousehold from '@pages/panel/PanelHousehold';
import PanelCity from '@pages/panel/PanelCity';
import PanelRegion from '@pages/panel/PanelRegion';
import { Wall } from '@pages/wall/Wall';
import Average from '@pages/average/Average';
import Adverts from '@pages/adverts/Adverts';
import { Schedule } from '@pages/schedule';

import Test from '@pages/test';

// todo KTS move Content from @components to App/ because of circular dependencies
const Content = (props) => {
  return (
    <div className={`content ${props.minimizeSidebar ? 'mini' : ''}`}>
      <Routes>
        <Route exact path="/" element={<Navigate to="/channels" />}></Route>

        <Route exact path="/channels" element={<ChannelsPage />}></Route>
        <Route path="/channels/:channel_id" element={<ChannelPage />}></Route>
        <Route path="/collections" element={<Collections />}></Route>
        <Route path="/tables" element={<Tables />}></Route>

        <Route exact path="/panel" element={<Panel />}></Route>
        <Route path="/panel/member/:id" element={<PanelMember />}></Route>
        <Route path="/panel/household/:id" element={<PanelHousehold />}></Route>
        <Route path="/panel/city/:id" element={<PanelCity />}></Route>
        <Route path="/panel/region/:id" element={<PanelRegion />}></Route>
        <Route path="/panel/audience" element={<Audience />}></Route>

        <Route path="/audience_compare" element={<CompareAudience />}></Route>

        <Route exact path="/programs" element={<Programs />}></Route>
        <Route
          path="/programs/channel/:channel_id/types"
          element={<ProgramsTypesByChannel />}
        ></Route>
        <Route
          path="/programs/channel/:channel_id/type/:type_id/categories"
          element={<ProgramsCategoriesByChannelAndType />}
        ></Route>
        <Route
          path="/programs/channel/:channel_id/category/:category_id"
          element={<ChannelCategoryPrograms />}
        ></Route>

        <Route
          exact
          path="/programs/:program_id/channel/:channel_id"
          element={<Program />}
        ></Route>
        <Route
          exact
          path="/programs/:program_id/channel/:channel_id/issued/:issued_id"
          element={<ProgramIssued />}
        ></Route>

        <Route
          path="/programs/:program_id/channel/:channel_id/:issued_id?"
          element={<Program />}
        ></Route>

        <Route path="/wall" element={<Wall />}></Route>

        <Route path="/average" element={<Average />}></Route>

        <Route path="/schedule" element={<Schedule />}></Route>

        <Route path="/adverts" element={<Adverts />}></Route>

        <Route path="/test" element={<Test />}></Route>
        
      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => ({
  minimizeSidebar: state.sidebar.minimizeSidebar,
});

export default connect(mapStateToProps)(Content);
