import React, { useState, useEffect } from 'react'
import API from '@utils/AdminApi';
import { Button } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import Table from '@components/Table/table'
import { useParams } from 'react-router-dom'


function Programs(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;
  let channel_id = Object.keys(props.data).length === 0 ? '' : props.data.channel_id;
  let channel_name = Object.keys(props.data).length === 0 ? '' : props.data.channel_name;
  let type_name = Object.keys(props.data).length === 0 ? '' : props.data.type_name;
  let type_id = Object.keys(props.data).length === 0 ? '' : props.data.type_id;
  let category_name = Object.keys(props.data).length === 0 ? '' : props.data.category_name;
  let category_id = Object.keys(props.data).length === 0 ? '' : props.data.category_id;


  if (data && data.length !== 0) {
    for (const row of data) {
      row['name'] = <Link  to={{pathname: `/programs/${row['program_id']}/channel/${channel_id}`}}> <Button>{row['name']}</Button></Link>
      delete row['program_id'];
    }
    delete columns[0];
  }

  return (
    <div>
      <div>Канал: {channel_name}</div>
      <div>Тип: <Link  to={{pathname: `/programs/channel/${channel_id}/types`}}> <Button>{type_name}</Button></Link></div>
      <div>Категория: <Link  to={{pathname: `/programs/channel/${channel_id}/type/${type_id}/categories`}}> <Button>{category_name}</Button></Link></div>
      <br/>
      <Table columns={columns} data={data} rowStyle={{"borderWidth":"2px"}} tableStyle={{"borderWidth":"2px"}} showHeader={false}/>
    </div>
  );
}


function ProgramsByChannelAndCategory() {
  const channel_id = useParams()['channel_id']
  const category_id = useParams()['category_id']
  const [data, setData] = useState([]);

  const loadData = () => {
    API.getProgramsByChannelAndCategory(channel_id, category_id)
      .then(response => {
        if (response) {
          setData(response);
        }
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Programs data={data}/>
    </div>
  )
}



export default ProgramsByChannelAndCategory

