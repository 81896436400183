/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    target_base: '',
    dates_range: {
        startDate: new Date(new Date()  - 21 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
        key: "selection"
    },
    premier: null,
    statistics: null,
    aggregateBy: 'month',
    datebands: null,
    events: [],
    debug: false
  },
  results: null,
  chartStat: null,
  statistics: [],
};

export const AverageForm = createSlice({
  name: 'AverageForm',
  initialState,
  reducers: {
    setResults: (state, action) => {
      state.results = action.payload ? { ...action.payload } : null;
    },
    setData: (state, action) => {
      state.data = { ...action.payload };
    },
    setChartStat: (state, action) => {
      state.chartStat = [ ...action.payload ];
    },
    setStatistics: (state, action) => {
      state.statistics = [ ...action.payload ];
    },
  },
});

export const { setResults, setData, setChartStat, setStatistics } = AverageForm.actions;

export default AverageForm.reducer;
