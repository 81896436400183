import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

import { Programms } from '../../models';
import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useStatsContext } from '../../store/StatsProvider';
import { useVideosContext } from '../../store/VideosProvider';
import { getAllIntervals, stringToTime, timeToString } from '../../utils';
import AdminApi from '../../utils/AdminApi';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

ChartJS.register(annotationPlugin);

export interface StatsProps {
  type: string;
}

export type Options = {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
};

export type ProgrammsCache = {
  [key: string]: {
    [key: number]: {
      channelId: number;
      data: Programms[] | null;
    };
  };
};

export function Chart({ type }: StatsProps) {
  const navigate = useNavigate();

  const chartRef = useRef(null);

  const { channels, statistics } = useDictionaryContext();

  const { stats, programs } = useStatsContext();

  const {
    channels: selectedChannels,
    date,
    interval,
    statistics: selectedStats,
    duration,
    position,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setPosition,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    seek,
  } = useVideosContext();

  const getIntervals = useCallback(() => {
    return interval ? getAllIntervals(interval, duration) : [];
  }, [interval, duration]);

  const series = useMemo(() => {
    const results: Options[] = [];

    selectedChannels?.forEach((channel) => {
      const current = channels?.filter(({ id }) => channel === id);

      const label = current && current.length ? current[0].title : '';

      const backgroundColor =
        current && current.length ? current[0].hex_color : '';

      const channelId = current && current.length ? current[0].channel_id : 0;

      const channelStats =
        channel && stats && stats[channelId] ? stats[channelId] : null;

      const intervals = getIntervals();

      if (channelStats) {
        const data = intervals.map((i) =>
          channelStats[i] ? channelStats[i][type] : 0,
        );

        results.push({
          label,
          data,
          borderColor: backgroundColor,
          backgroundColor,
        });
      }
    });

    return results;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channels,
    selectedChannels,
    selectedStats,
    interval,
    getIntervals,
    stats,
    type,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const title = useMemo(() => {
    const statistic = statistics
      ? statistics.filter((rec) => type === rec.type)
      : null;

    return statistic && statistic.length ? statistic[0].name : '';
  }, [statistics, type]);

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  const updateSize = () =>
    setSize({ x: window.innerWidth, y: window.innerHeight });

  useEffect(() => (window.onresize = updateSize), []);

  const isTablet = useMemo<boolean>(
    () => (size && size.x < 1024 ? true : false),
    [size],
  );

  const options = useMemo(
    () => ({
      responsive: true,
      elements: {
        line: {
          tension: 0.25,
          borderWidth: isTablet ? 1 : 2,
        },
        point: {
          radius: isTablet ? 1.5 : 3,
          hoverRadius: isTablet ? 3 : 6,
        },
      },
      scales: {
        yAxes: {
          afterFit: (scale: any) => {
            scale.width = isTablet ? '63' : '78';
          },
          ticks: {
            font: {
              size: isTablet ? 9 : 11,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: isTablet ? 9 : 11,
            },
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom' as const,
          labels: {
            font: {
              size: isTablet ? 10 : 12,
            },
          },
        },
        annotation: {
          annotations: [
            {
              type: 'line',
              mode: 'vertical',
              scaleID: 'x',
              borderColor: '#42A5F5',
              value: position * 60,
              borderWidth: isTablet ? 0.5 : 0.7,
            },
          ],
        },
        title: {
          display: false,
        },
      },
    }),
    [position, isTablet],
  );

  const data = useMemo(
    () => ({
      labels: getIntervals(),
      datasets: series,
    }),
    [series, getIntervals],
  );

  const onClick = useCallback(
    (event: any) => {
      if (selectedChannels && channels && interval && chartRef.current) {
        const elements = getElementAtEvent(chartRef.current, event);

        if (elements.length) {
          const index = elements[0].index;

          const channel = channels.filter(({ id }) => selectedChannels[elements[0].datasetIndex] == id);
          const channelId = channel[0].channel_id;

          const value = timeToString(stringToTime(interval) + index);

          if (programs[channelId] && programs[channelId][value]) {
            navigate(
              `/programs/${programs[channelId][value].programId}/channel/${channelId}/issued/${programs[channelId][value].pissId}`, 
            );
          }
        }
      }
    },
    [selectedChannels, interval, channels, programs],
  );

  useEffect(() => {
    if (channels && selectedChannels && date) {
      const _channels = channels
        .filter(({ id }) => selectedChannels.indexOf(id) !== -1)
        .map(({ id, channel_id }) => ({
          id,
          channelId: channel_id,
        }));
    }
  }, [channels, selectedChannels, date]);

  return (
    <div className="chart">
      {!!series && (
        <Line
          ref={chartRef}
          /* @ts-expect-error */
          options={options}
          data={data}
          onClick={onClick}
        />
      )}
    </div>
  );
}
