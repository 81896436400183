import cn from 'classnames';
import * as React from 'react';

import { ColorRangeConfigEntity } from '../models/colorRangeConfigEntity';

import s from './Legend.module.scss';
import { LegendItem } from './LegendItem';

type LegendProps = {
  items: ColorRangeConfigEntity[];
  className?: string;
};

const Legend: React.FC<LegendProps> = ({ items, className }) => {
  return (
    <ul className={cn(s['legend'], className)}>
      {items.map((item) => (
        <LegendItem key={item.title} item={item} />
      ))}
    </ul>
  );
};

export default React.memo(Legend);
