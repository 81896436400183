import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { GlobalStoreEntity } from '@models/globalStoreEntity';
import API from '@utils/AdminApi';

export const fetchCollectionTypes = createAsyncThunk(
  'collections/types',
  async (_, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (!state.collections.types || state.collections.types.length === 0) {
      const data = await API.getCollectiontypes();

      return data ?? [];
    }

    return [];
  },
);

export const fetchCollections = createAsyncThunk(
  'collections/list',
  async () => {
    const response = await API.getCollections();

    return response?.data ?? [];
  },
);

export const fetchChannelsCollections = createAsyncThunk(
  'collections/channels/list',
  async () => {
    const response = await API.getChannelsCollections();

    return response?.data ?? [];
  },
);

export const fetchGroups = createAsyncThunk('collections/groups', async () => {
  const filterGroups = (data) => {
    return data
      .filter((item) => item.is_group || item.type)
      .map((item) => {
        if (item.children) {
          item.children = filterGroups(item.children);
        }

        return item;
      });
  };

  const response = await API.getCollections();
  return filterGroups(response?.data ?? []);
});

export const fetchDemographics = createAsyncThunk(
  'collections/demographics',
  async (_, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (
      !state.collections.demographics ||
      state.collections.demographics.length === 0
    ) {
      const data = await API.getDemographics();

      return data ?? [];
    }

    return state.collections.demographics ?? [];
  },
);

export const fetchDemographicsBreakdown = createAsyncThunk(
  'collections/demographicsBreakdown',
  async (_, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (
      !state.collections.demographicsBreakdown ||
      state.collections.demographicsBreakdown.length === 0
    ) {
      // @ts-ignore
      const data = await API.getDemographics('breakdown');

      return data ?? [];
    }

    return state.collections.demographicsBreakdown ?? [];
  },
);

export const fetchChannels = createAsyncThunk(
  'collections/channels',
  async (_, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (
      !state.collections.channels ||
      state.collections.channels.length === 0
    ) {
      const data = await API.getChannels();

      return data ?? [];
    }

    return state.collections.channels ?? [];
  },
);

export const fetchStatistics = createAsyncThunk(
  'collections/statistics',
  async (arg, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    const source = typeof arg === 'undefined' ? null : (arg as string);

    const key = source ?? 'default';

    if (
      !state.collections.statistics ||
      !state.collections.statistics[key] ||
      state.collections.statistics[key].length === 0
    ) {
      const data = await API.getStatistics(source);

      const result = { ...state.collections.statistics };

      result[key] = data ?? [];

      return result;
    }

    return state.collections.statistics ?? {};
  },
);

export const fetchConditionOptions = createAsyncThunk(
  'collections/conditionOptions',
  async (arg, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    return state.collections.conditionOptions ?? [];
  }
);

export const fetchAdvertsColumns = createAsyncThunk(
  'collections/advertsColumns',
  async (arg, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (!state.collections.advertsColumns || Object.keys(state.collections.advertsColumns).length === 0) {
      const data = await API.getAdvertsColumns();

      return data ?? {};
    }

    return state.collections.advertsColumns ?? {};
  }
);

export const fetchAdvertsBrands = createAsyncThunk(
  'adverts/brands',
  async (arg, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (!state.collections.advertsBrands || Object.keys(state.collections.advertsBrands).length === 0) {
      const data = await API.getAdvertsBrands();

      return data ?? [];
    }

    return state.collections.advertsBrands ?? [];
  }
);

export const fetchAdvertsArticles = createAsyncThunk(
  'adverts/articles',
  async (arg, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (!state.collections.advertsArticles || Object.keys(state.collections.advertsArticles).length === 0) {
      const data = await API.getAdvertsArticles();

      return data ?? [];
    }

    return state.collections.advertsArticles ?? [];
  }
);

/**
 * Приватный экшн, который запрашивает или возвращает из кэша существующий
 * объект коллекции (тип Collection в поле data)
 */
export const _fetchCondition = createAsyncThunk(
  'collections/_fetchCondition',
  async (collectionId: number, { getState }) => {
    const state = getState() as GlobalStoreEntity;

    if (state.collections.conditions?.[collectionId]) {
      return {
        id: collectionId,
        data: state.collections.conditions[collectionId],
      };
    }

    const response = await API.getCollection(collectionId);

    return response?.data ? { id: collectionId, data: response.data } : null;
  },
);

export const fetchProgramCategories = createAsyncThunk(
  'program/categories',
  async (_, { getState }) => {
    const state = getState();

    if (
      // @ts-ignore todo kts fix typings
      !state.collections.programCategories ||
      // @ts-ignore todo kts fix typings
      state.collections.programCategories.length === 0
    ) {
      const data = await API.getProgramCategories();

      const added = new Set();

      return data && data.data
        ? data.data
            .map((entry) => {
              added.add(entry.category_id);

              return { value: entry.category_id, label: entry.category_name };
            })
            .filter((entry) => {
              if (added.has(entry.value)) {
                added.delete(entry.value);

                return true;
              }

              return false;
            })
        : [];
    }

    // @ts-ignore todo kts fix typings
    return state.collections.programCategories ?? [];
  },
);

export const loadConditions =
  (collectionIds: number[]) => async (dispatch, getState) => {
    await Promise.all(
      collectionIds.map((collectionId) =>
        dispatch(_fetchCondition(collectionId)),
      ),
    );

    return getState().collections.conditions;
  };

// todo KTS fix typings
export const collections = createSlice({
  name: 'collections',
  initialState: {
    types: [],
    collections: [],
    groups: [],
    demographics: [],
    demographicsBreakdown: [],
    channels: [],
    statistics: {},
    openTypes: [] as any[],
    openGroups: [] as any[],
    editGroup: 0,
    channelsCollections: [],
    conditions: {},
    programCategories: [],
    conditionOptions: [] as any[],
    advertsColumns: {},
    advertsBrands: [] as any[],
    advertsArticles: [] as any[],
  },
  reducers: {
    openType: (state, action) => {
      state.openTypes.push(action.payload);
    },
    closeType: (state, action) => {
      state.openTypes = state.openTypes.filter((id) => action.payload !== id);
    },
    openGroup: (state, action) => {
      state.openGroups.push(action.payload);
    },
    closeGroup: (state, action) => {
      state.openGroups = state.openGroups.filter((id) => action.payload !== id);
    },
    setEditGroup: (state, action) => {
      state.editGroup = action.payload;
    },
    cleanCollections: (state) => {
      state.groups = [];
    },
    resetCache: (state) => {
      state.types = [];
      state.collections = [];
      state.demographics = [];
      state.demographicsBreakdown = [];
      state.channels = [];
      state.statistics = [];
      state.groups = [];
      state.channelsCollections = [];

      
      state.programCategories = [];
      state.conditionOptions = [];
      state.advertsColumns = [];
      state.advertsBrands = [];
      state.advertsArticles = [];
    },
    cleanCollectionTypes: (state) => {
      state.types = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollectionTypes.fulfilled, (state, action) => {
      state.types = action.payload;
    });

    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      state.collections = action.payload;
    });

    builder.addCase(fetchDemographics.fulfilled, (state, action) => {
      state.demographics = action.payload;
    });

    builder.addCase(fetchDemographicsBreakdown.fulfilled, (state, action) => {
      state.demographicsBreakdown = action.payload;
    });

    builder.addCase(fetchChannels.fulfilled, (state, action) => {
      state.channels = action.payload;
    });

    builder.addCase(fetchStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload;
    });

    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });

    builder.addCase(fetchChannelsCollections.fulfilled, (state, action) => {
      state.channelsCollections = action.payload;
    });

    builder.addCase(_fetchCondition.fulfilled, (state, action) => {
      if (action.payload) {
        const { id, data } = action.payload;

        state.conditions[id] = data;
      }
    });

    builder.addCase(fetchProgramCategories.fulfilled, (state, action) => {
      state.programCategories = action.payload;
    });

    builder.addCase(fetchConditionOptions.fulfilled, (state, action) => {
      state.conditionOptions = action.payload;
    });

    builder.addCase(fetchAdvertsColumns.fulfilled, (state, action) => {
      state.advertsColumns = action.payload;
    });

    builder.addCase(fetchAdvertsBrands.fulfilled, (state, action) => {
      state.advertsBrands = action.payload;
    });

    builder.addCase(fetchAdvertsArticles.fulfilled, (state, action) => {
      state.advertsArticles = action.payload;
    });
  },
});

export const {
  openType,
  closeType,
  openGroup,
  closeGroup,
  setEditGroup,
  cleanCollections,
  resetCache,
  cleanCollectionTypes,
} = collections.actions;

export default collections.reducer;
