import cn from 'classnames';
import * as React from 'react';

import s from './RadioButton.module.scss';
import { RadioButtonBaseColor, RadioButtonProps } from './RadioButton.types';

function RadioButton<T extends string | number>({
  onChange,
  value,
  className,
  checked,
  baseColor = RadioButtonBaseColor.none,
  ...rest
}: RadioButtonProps<T>) {
  const onChangeCallback = React.useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <input
      checked={checked}
      value={value.value}
      onChange={onChangeCallback}
      type="radio"
      className={cn(
        s['radio-button'],
        s[`radio-button_color_${baseColor}`],
        className,
      )}
      {...rest}
    />
  );
}

export default React.memo(RadioButton) as typeof RadioButton;
