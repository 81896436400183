import React, { useState, useEffect, useRef } from 'react';
import { useAuthContext } from '@store/AuthProvider';
import Logout from './Logout';
import Login from './Login';
import Profile from './Profile';

import './UserBlock.scss';

function UserBlock () {
  const { isLoggedIn, currentUser } = useAuthContext();

  const [showMenu, setShowMenu] = useState(false);

  const wrapperRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  useEffect(() => {
    const hasModal = (target) => {
      let node = target;
      while (node !== document.body) {
        const className = node && typeof node.className === 'string' ? node.className : '';

        if (className?.search('ant-modal-root') !== -1) {
          return true;
        }

        if (node === null) {
          return false
        }
        node = node.parentNode;
      }

      return false;
    }

    const handleClickOutside = (event) => {
      if (wrapperRef && !wrapperRef.current?.contains(event.target) && !hasModal(event.target)) {
        if (showMenu) {
          setShowMenu(false);
        }
      }
    }
  
    document.addEventListener('mousedown', event => handleClickOutside(event));

    return () => {
      document.removeEventListener('mousedown', event => this.handleClickOutside(event));
    }
  }, [wrapperRef, showMenu]);

  if (isLoggedIn) {
    return (
      <div
        ref={wrapperRef}
        className="userblock logged-in"
      >
        <div
          className="userblock-subblock"
          onClick={() => toggleMenu()}
        >
          <div className="user-image"></div>
          <div className="user-name">
            {currentUser}
          </div>
        </div>
        { !!showMenu && (
          <div className='user-menu'>
            <div className="user-menu-item">
              <Profile />
            </div>
            <div className="user-menu-item">
              <Logout />
            </div>
          </div>
        ) }
      </div>
    );
  }

  return (
    <div className="userblock">
      <div className="user-login">
        <Login />
      </div>
    </div>
  );
}

export default UserBlock;