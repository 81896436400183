import React, { useState, useEffect, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official';
import API from '@utils/AdminApi';
import DateRangeFilter from '@components/DateRangeFilter/DateRangeFilter'
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedDateRange, fetchDemographics } from '@store/panel';
import _ from "lodash-es"
import { useParams } from 'react-router-dom'
import { useTable } from 'react-table'
import { Button } from '@chakra-ui/react'
import Common from '@utils/common';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Highcharts from 'highcharts';



function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data,
  })

  let headers = '';
  if (headerGroups && headerGroups[0].headers[0].Header) {
    headers = <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{column.render('Header')}</th>
        ))}
      </tr>
    ))}
    </thead>;
  }


  let show_headers = true;
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      {headers}
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row)
        return (<tr>
          {row.cells.map(cell => {
            return <td {...cell.getCellProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{cell.render('Cell')}</td>
          })}
        </tr>)
      })}
      </tbody>
    </table>
  )
}


function ViewHistory(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;

  for (let row of data) {
    row['channel'] = <Link  to={{pathname: `/programs/channel/${row['channel_id']}/types`}}> <Button>{row['channel']}</Button> </Link>
    row['program_name'] = _.map(row['program'], function(v) {
          return <Link  to={{pathname: `/programs/${v['program_id']}/channel/${row['channel_id']}`}}> <Button>{v['program_name']}</Button> </Link>
      })
    row['piss_id'] =_.map(row['piss_id'], function(v) {
      return <Link  to={{pathname: `/programs/${row['program_id']}/issued/${v}`}}> <Button>{v}</Button> </Link>
    })

  }

  columns = [
    {Header: 'Начало просмотра', accessor: 'startTime'},
    {Header: 'Канал', accessor: 'channel'},
    {Header: 'Длительность', accessor: 'watched_minutes'},
    {Header: 'Выход программы', accessor: 'piss_id'},
    {Header: 'Программа', accessor: 'program_name'}
  ]

  return (<div>
      <div><b>История смотрения</b></div>
      {columns.length > 0? <Table columns={columns} data={data} />: <div></div>}
    </div>
  );
}


function CoreHours(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;
  let chart = Object.keys(props.data).length === 0 ? [] : props.data.chart;
  const highchartsRef = useRef();

  const change_range = (e) => {
    // INFO: change associated with range value, what will trigger loadFavChannels data
    props.change_range(e.target.value);
  };

  function barFormatter(){
    return Common.toDDHHMM(this.y);
  }

  function labelFormatter(){
    return Common.toDDHHMM(this.value);
  }

  const options = {
    chart: {
      type: 'column'
    },
    title: '',
    xAxis: chart.xAxis,
    yAxis: {
      min: chart.yAxis ? chart.yAxis.min : 0,
      max: chart.yAxis ? chart.yAxis.max : 0,
      title: chart.yAxis ? chart.yAxis.title : '',
      labels: {
        formatter: labelFormatter
      }
    }
    ,
    tooltip: {
      formatter: barFormatter
  },
    plotOptions: {
      column: {
        pointPadding: 0.2,
          borderWidth: 0
      }
    },
    series: chart.series
  }

  return (<div>
      <div><b>Часы активности</b></div>
      <Tabs>
        <TabList>
          <Tab onClick={change_range} value={0}> {props.dates.startDate.toLocaleDateString('ru-RU')} - {props.dates.endDate.toLocaleDateString('ru-RU')}</Tab>
          <Tab onClick={change_range} value={1}>За все время</Tab>
          <Tab onClick={change_range} value={2}>За все время по дням</Tab>
        </TabList>
        <TabPanel> {columns.length > 0? <HighchartsReact highcharts={Highcharts} options={options} ref={highchartsRef}/>: <div></div>} </TabPanel>
        <TabPanel> {columns.length > 0? <HighchartsReact highcharts={Highcharts} options={options} ref={highchartsRef}/>: <div></div>} </TabPanel>
        <TabPanel> {columns.length > 0? <HighchartsReact highcharts={Highcharts} options={options} ref={highchartsRef}/>: <div></div>} </TabPanel>
      </Tabs>
    </div>
  );
}


function FavouritePrograms(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;

  for (let row of data) {
    row['channel'] = <Link  to={{pathname: `/programs/channel/${row['channel_id']}/types`}}> <Button>{row['channel']}</Button> </Link>
    row['program'] = <Link  to={{pathname: `/programs/${row['program_id']}/channel/${row['channel_id']}`}}> <Button>{row['program']}</Button> </Link>
  }
  delete columns[1]
  delete columns[0]

  const change_range = (e) => {
    // INFO: change associated with range value, what will trigger loadFavChannels data
    props.change_range(e.target.value);
  };
  return (<div>
  <div><b>Топ 10 предпочитаемых программ</b></div>
  <Tabs>
    <TabList>
        <Tab onClick={change_range} value={0}> {props.dates.startDate.toLocaleDateString('ru-RU')} - {props.dates.endDate.toLocaleDateString('ru-RU')}</Tab>
      <Tab onClick={change_range} value={30}>30 дней</Tab>
      <Tab onClick={change_range} value={60}>60 дней</Tab>
      <Tab onClick={change_range} value={90}>90 дней</Tab>
    </TabList>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
  </Tabs>
    </div>
    );
}


function FavouriteChannels(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;

  const change_range = (e) => {
    // INFO: change associated with range value, what will trigger loadFavChannels data
    props.change_range(e.target.value);
  };

  if (columns && columns.length !== 0) {

    for (let record of data) {
      // record['channel'] = <Link  to={{pathname: `#${record['channel']['id']}`}}> <Button>{record['channel']['name']}</Button> </Link>
      if (record['channel']){
        record['channel'] = <Link  to={{pathname: `/programs/channel/${record['channel_id']}/types`}}> <Button>{record['channel']['name']}</Button> </Link>;
      }
    }
    delete columns['2']
  }

  return (<div>
  <div><b>Топ 10 предпочитаемых каналов</b></div>
  <Tabs>
    <TabList>
      <Tab onClick={change_range} value={30}>30 дней</Tab>
      <Tab onClick={change_range} value={60}>60 дней</Tab>
      <Tab onClick={change_range} value={90}>90 дней</Tab>
      <Tab onClick={change_range} value={0}>За все время</Tab>
    </TabList>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
    <TabPanel>{columns.length > 0? <Table columns={columns} data={data} />: <div></div>}</TabPanel>
  </Tabs>
    </div>
    );
}


function MemberDetails(props) {
  let member_info = Object.keys(props.data).length === 0 ? {} : props.data.member_info;
  let household_info = Object.keys(props.data).length === 0 ? {} : props.data.household_info;

  let member_columns = [{Header: '', accessor: 'name', id: 'h1', }, {Header: '', accessor: 'value', id: 'h2', },];
  let member_data = [];

  let household_columns = [{Header: '', accessor: 'name', id: 'h1', }, {Header: '', accessor: 'value', id: 'h2', },];
  let household_data = [];

  if (_.keys(member_info).length > 0) {
    for (let key of _.keys(member_info)) {
      let value = member_info[key]['value']
      let name = member_info[key]['name']
      switch(key) {
        case 'household':
          member_data.push({name: name, value:
              <Link  to={{pathname: `/panel/household/${value}`}}>
                <Button>{value}</Button>
              </Link>})
          break
        case 'weights':
          let min_weight = Common.round(Math.min(...value), 2)
          let max_weight =  Common.round(Math.max(...value) , 2)
          let avg_weight =  Common.round(Common.avg(value), 2)
          let med_weight =  Common.round(Common.median(value), 2)
          member_data.push({name: name, value: `min:${min_weight} avg: ${avg_weight}  med:${med_weight} max:${max_weight}`})
          break
        default:
          member_data.push({name: name, value: value})
      }
    }
  }

  if (_.keys(household_info).length > 0) {
    for (let key of _.keys(household_info)) {
      let data = household_info[key]
      let name = household_info[key]['name']
      switch(key) {
        case 'members':
          let members = [];
          for (let member_id of data.value) {
            members.push(<Link  to={{pathname: `/panel/member/${member_id}`}}> <Button>{member_id}</Button> </Link>)
          }
          household_data.push({name: name, value: members})
          break
        case 'city100_plus':
          household_data.push({name: name, value:<Link  to={{pathname: `/panel/city/${data['value_id']}`}}> <Button>{data.value}</Button> </Link>})
          break
        case 'region_federation':
          household_data.push({name: name, value:<Link  to={{pathname: `/panel/region/${data['value_id']}`}}> <Button>{data.value}</Button> </Link>})
          break
        default:
          household_data.push({name: name, value: data.value})
      }
    }
  }

  return (
    <div><br/>
      <div> Респондент: {props.data['member_id']}
        <Table columns={member_columns} data={member_data} />
      </div>
      <br/>
      <div>
        Домовладение: {_.keys(member_info).length > 0 ? member_info.household.value : '' }
        <Table columns={household_columns} data={household_data} />
      </div>
      <br/>
    </div>
  );
}


function PanelMember() {
  const member_id = useParams()['id']
  const datesRange = useSelector((state) => state.panel.datesRange);

  const demographics = useSelector((state) => state.panel.demographics);
  const [data, setData] = useState({});

  const [favchannel_data, setFavchannel_data] = useState([])
  const [favchannel_data_range, setFavchannel_data_range] = useState(30)

  const [favprogs_data, setFavprogs_data] = useState([])
  const [favprogs_data_range, setFavprogs_data_range] = useState(0)

  const [corehours_data, setCorehours_data] = useState([])
  const [corehours_data_tab, setCorehours_data_tab] = useState(0)

  const [view_history_data, setView_history_data] = useState([])

  const dispatch = useDispatch();

  const loadData = (member_id, datesRange) => {
    API.postMemberInfo({ member_id, datesRange })
      .then(response => {
        if (response) {
          setData(response.data);
        }
      });
  };

  const loadFavoriteChannelsData = (member_id, range, datesRange) => {
    API.postMemberFavouriteChannels({ member_id, range, datesRange })
      .then(response => {
        if (response) {
          setFavchannel_data(response.data);
        }
      });
  }

  const loadFavoriteProgramsData = (member_id, range, datesRange) => {
    API.postMemberFavouritePrograms({ member_id, range, datesRange })
      .then(response => {
        if (response) {
          setFavprogs_data(response.data);
        }
      });
  }

  const loadCoreHoursData = (member_id, tab_no, datesRange) => {
    API.postMemberCoreHours({ member_id, tab_no, datesRange })
      .then(response => {
        if (response) {
          setCorehours_data(response.data);
        }
      });
  }

  const loadViewHistoryData = (member_id, datesRange) => {
    API.postViewHistory({ member_id, datesRange })
      .then(response => {
        if (response) {
          setView_history_data(response.data);
        }
      });
  }

  // TODO: I probably getting data twice or more, need to find out where and why
  useEffect(() => {
    if (Object.keys(demographics).length === 0) {
      dispatch(fetchDemographics());
    }
    loadData(member_id, datesRange)
    dispatch(changeSelectedDateRange(datesRange));
    setFavchannel_data([]);
    loadFavoriteChannelsData(member_id, favchannel_data_range, datesRange);
    setFavprogs_data([]);
    loadFavoriteProgramsData(member_id, favprogs_data_range, datesRange);
    setCorehours_data([]);
    loadCoreHoursData(member_id, corehours_data_tab, datesRange);
    loadViewHistoryData(member_id, datesRange);

  }, [datesRange, member_id]);

  useEffect(() => {
    if (!data || data.length === 0) return;
  }, [data]);

  useEffect(() => {
    setFavchannel_data([]);
    loadFavoriteChannelsData(member_id, favchannel_data_range, datesRange);
  }, [favchannel_data_range, ])

  useEffect(() => {
    setFavprogs_data([]);
    loadFavoriteProgramsData(member_id, favprogs_data_range, datesRange);
  }, [favprogs_data_range, ])

  useEffect(() => {
    setCorehours_data([]);
    loadCoreHoursData(member_id, corehours_data_tab, datesRange);
  }, [corehours_data_tab, ])

  useEffect(() => {
    if (view_history_data.length == 0){
      loadViewHistoryData(member_id, datesRange);
    }
  }, [view_history_data, ])


  return (
    <div>
      <div>
        <DateRangeFilter/>
      </div>
      <MemberDetails member_id={member_id} data={data}/>
      <br/>
      <FavouriteChannels member_id={member_id} data={favchannel_data} change_range={setFavchannel_data_range}/>
      <br/>
      <FavouritePrograms member_id={member_id} data={favprogs_data} change_range={setFavprogs_data_range} dates={datesRange}/>
      <br/>
      <ViewHistory member_id={member_id} data={view_history_data}/>
      <br/>
      <CoreHours member_id={member_id} data={corehours_data} change_range={setCorehours_data_tab} dates={datesRange}/>
    </div>
  )
}


export default PanelMember
