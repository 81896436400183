/* eslint-disable */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchChannels,
  fetchCollections,
  fetchChannelsCollections,
  fetchProgramCategories,
} from '@store/collections';
import Timebands from '../../components/Sources/groups/Timebands';
import Channels from '@components/Sources/groups/Channels';
import MultiSelect from '../../components/MultiSelect';
import Common from '@utils/common';
import API from '@utils/AdminApi';
import { findElement } from '@components/Sources/groups/Utils';
import { Switch } from 'antd';
import { Tabs } from 'antd';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { ReactComponent as Add } from '@UI/assets/add_box.svg';

import Event from './Event';

import './Collection.scss';

const emptyData = [
  {
    channel: null,
    timeband: null,
    aggregated: false,
  },
  {
    program: null,
    timeband: null,
    aggregated: false,
  },
  {
    channel: null,
    category: null,
    timeband: null,
    aggregated: false,
  },
];

const blocks = [ 'channels', 'program', 'program-channels' ];

const validateData = (data, block) => {
  switch (block) {
    case 0:
      if (data.channel && data.channel.length) {
        return true;
      }

      break;

    case 1:
      if (data.program && data.program.length) {
        return true;
      }

      break;

    case 2:
      if (data.channel && data.channel.length && data.category && data.category.length) {
        return true;
      }

      break;
  }

  return false;
};

function Collection({ defaultValue, onChange }) {
  const dispatch = useDispatch();

  const lastId = useRef(1);

  const [currentBlock, setCurrentBlock] = useState(1);

  const [editId, setEditId] = useState(0);
  const editing = useRef(false);

  const channelsCollection = useSelector(
    (state) => state.collections.channelsCollections,
  );
  const channels = useSelector((state) => state.collections.channels);
  const programCategories = useSelector(
    (state) => state.collections.programCategories,
  );

  const timebands = useSelector((state) => {
    const collections = state.collections.collections.filter(
      (entry) => entry.type === 3,
    );

    return collections.length && collections[0].children
      ? collections[0].children
      : [];
  });

  const inited = useRef(false);

  const [listData, setListData] = useState([]);

  const listInfo = useMemo(() => {
    const data = [];

    listData.forEach(row => {
      let { id, block, channel, program, category, timeband, aggregated } = row;

      if (channel && channel.length) {
        channel = channel
          .map((row) => {
            if (Number.isInteger(row)) {
              const result = channels.filter(
                (entry) => entry.id === parseInt(row),
              );
              return result ? result[0].name : channel[0];
            } else {
              const result = findElement(channelsCollection, parseInt(row.id));
              return result ? result.name : parseInt(row.id);
            }
          });
      }

      if (timeband) {
        timeband = timeband
          .map((row) => {
            const result = findElement(timebands, parseInt(row.id));
            return result ? result.name : parseInt(row.id);
          });
      }

      if (category && category.length) {
        category = category
          .map((id) => {
            const result = programCategories.filter(
              (entry) => entry.value === id,
            );
            return result.length ? result[0].label : id;
          });
      }

      if (program && program.length) {
        program = program
          .map((row) => row.path.join(' / ') + ' / ' + row.label);
      }

      data.push({ id, block, channel, timeband, program, category, aggregated });
    });

    return data;
  }, [listData, programCategories]);

  const [data, setData] = useState(emptyData);

  const onchange = useCallback(
    (value, target, block) => {
      const newData =
        block !== currentBlock
          ? [...emptyData.map((row) => ({ ...row }))]
          : [...data.map((row) => ({ ...row }))];

      newData[block][target] = value;

      setData(newData);
    },
    [data, currentBlock],
  );

  const onAdd = () => {
    if (validateData(data[currentBlock], currentBlock)) {
      let newListData = [...listData];

      const current = data.filter((row, index) => index === currentBlock);

      if (editId) {
        newListData = newListData.map(entry => entry.id === editId ? { ...current[0], block: currentBlock, id: editId } : entry);

        onCancel();
      } else {
        lastId.current++;


        newListData.push({ ...current[0], block: currentBlock, id: lastId.current });

      }

      setData(emptyData);

      setListData(newListData);

      onChange && onChange(newListData);
    }
  };

  const onMove = (id, dropId, position) => {
    const item = listData.filter(entry => entry.id === id);

    let newListData = [...listData].filter(entry => entry.id !== id);

    let index = 0

    newListData.forEach((entry, i) => {
      if (entry.id === dropId) {
        index = i;
      }
    });

    newListData = [ ...newListData.slice(0, index + position), item[0], ...newListData.slice(index + position) ];

    setListData(newListData);

    onChange && onChange(newListData);
  }

  const onEdit = (id) => {
    editing.current = true;
    setEditId(id);
  }

  const onCancel = () => {
    setEditId(0);
    setData(emptyData);
  }

  useEffect(() => {
    if (editing.current) {
      editing.current = false;

      const data = listData.filter(entry => entry.id === editId);


      setCurrentBlock(data[0].block);

      const newData = [ ...emptyData ];
      newData[data[0].block] = data[0]

      setData(newData);
    }
  }, [editId]);

  const isValid = useMemo(() => validateData(data[currentBlock], currentBlock), [data, currentBlock]);

  const deleteEvent = (index) => {
    const newListData = [...listData].filter((val, i) => i !== index && val !== null);

    setListData(newListData);

    onChange && onChange(newListData);
  }

  const timebandValue = useMemo(
    () =>
      data[0].timeband ? data[0].timeband.map((timeband) => timeband.id) : [],
    [data[0].timeband],
  );
  const channelValue = useMemo(
    () =>
      data[0].channel
        ? data[0].channel.map((ch) => (ch.content ? ch.id : 'ch' + ch))
        : [],
    [data[0].channel],
  );
  const programTimebandValue = useMemo(
    () =>
      data[1].timeband ? data[1].timeband.map((timeband) => timeband.id) : [],
    [data[1].timeband],
  );
  const categoryChannelValue = useMemo(
    () =>
      data[2].channel
        ? data[2].channel.map((ch) => (ch.content ? ch.id : 'ch' + ch))
        : [],
    [data[2].channel],
  );
  const categoryTimebandValue = useMemo(
    () =>
      data[2].timeband ? data[2].timeband.map((timeband) => timeband.id) : [],
    [data[2].timeband],
  );
  const programCategoriesValue = useMemo(
    () => (data[2].category ? data[2].category : []),
    [data[2].category],
  );
  const programCategoriesOptions = useMemo(() => {
    return [...programCategories.map((entry) => ({ ...entry }))];
  }, [programCategories]);

  useEffect(() => {
    if (!inited.current && defaultValue && defaultValue.length) {
      inited.current = true;

      setListData(defaultValue);

      let id = 1;

      defaultValue.forEach(entry => {
        if (entry.id > id) {
          id = entry.id;
        }
      });

      lastId.current = id;
    }
  }, [defaultValue]);

  useEffect(() => {
    dispatch(fetchChannels());

    dispatch(fetchCollections());

    dispatch(fetchChannelsCollections());

    dispatch(fetchProgramCategories());
  }, []);

  const programsLoader = (id) => {
    return new Promise((resolve, reject) => {
      API.getProgramsOptions(id).then(
        (data) => {
          if (!id) {
            resolve(data.map((option) => ({ ...option, show: true })));
          } else {
            resolve(data);
          }
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const items = [{
    label: 'Программы',
    key: 'program',
    children: (
      <>
        <div className="crow">
          <div className="ccol">
            <div className="form-control">
              <MultiSelect
                search={Common.searchProgram}
                loader={programsLoader}
                lazily={true}
                selected={data[1].program}
                single={false}
                line={true}
                placeholder="Выберите программу"
                onChange={(value) => onchange(value, 'program', 1)}
              />
            </div>
          </div>
          <div className="ccol">
            <div>
              <Timebands
                placeholder="Таймбэнд"
                className="form-control"
                onChange={(value) => onchange(value, 'timeband', 1)}
                value={programTimebandValue}
                single={false}
              />
            </div>
          </div>
        </div>

        <div className="crow">
          <div className="ccol switch">
            <Switch
              checked={data[1].aggregated}
              onChange={(value) => onchange(value, 'aggregated', 1)}
            />
            <span>Группировать</span>
          </div>
          <div className="ccol add-button">
            { !!editId && <div onClick={onCancel} className="highlight">
              <span>Отменить</span>
            </div> }

            <div onClick={onAdd} className={ isValid ? "highlight" : "" }>
              <span>{ editId ? "Обновить событие" : "Добавить в события" }</span>
              <Add />
            </div>
          </div>

        </div>
      </>
    )
  }, {
    label: 'Каналы',
    key: 'channels',
    children: (
      <>
        <div className="crow">
          <div className="ccol">
            <Channels
              isRequired={true}
              placeholder="Выберите канал"
              className="form-control"
              value={channelValue}
              onChange={(value) => onchange(value, 'channel', 0)}
              single={false}
            />
          </div>
          <div className="ccol">
            <Timebands
              placeholder="Таймбэнд"
              className="form-control"
              onChange={(value) => onchange(value, 'timeband', 0)}
              value={timebandValue}
              single={false}
            />
          </div>
        </div>

        <div className="crow">
          <div className="ccol switch">
            <Switch
              checked={data[0].aggregated}
              onChange={(value) => onchange(value, 'aggregated', 0)}
            />
            <span>Группировать</span>
          </div>
          <div className="ccol add-button">
            { !!editId && <div onClick={onCancel} className="highlight">
                <span>Отменить</span>
              </div> }

            <div onClick={onAdd} className={ isValid ? "highlight" : "" }>
              <span>{ editId ? "Обновить событие" : "Добавить в события" }</span>
              <Add />
            </div>
          </div>
        </div>
      </>
    )
  }, {
    label: 'Каналы и категории программ',
    key: 'program-channels',
    children: (
      <>
        <div className="crow">
          <div className="ccol">
            <Channels
              name="channels"
              isRequired={true}
              placeholder="Выберите канал"
              className="form-control"
              value={categoryChannelValue}
              onChange={(value) => onchange(value, 'channel', 2)}
              single={false}
            />
          </div>
          <div className="ccol">
            <div className="form-control">
              <MultiSelect
                options={programCategoriesOptions}
                onChange={(value) => onchange(value, 'category', 2)}
                search={true}
                dropDown={true}
                selected={programCategoriesValue}
                placeholder="Выберите категорию"
                single={false}
                line={true}
              />
            </div>
          </div>
          <div className="ccol">
            <Timebands
              placeholder="Таймбэнд"
              className="form-control"
              onChange={(value) => onchange(value, 'timeband', 2)}
              value={categoryTimebandValue}
              single={false}
            />
          </div>
        </div>

        <div className="crow">
          <div className="ccol switch">
            <Switch
              checked={data[2].aggregated}
              onChange={(value) => onchange(value, 'aggregated', 2)}
            />
            <span>Группировать</span>
          </div>
          <div className="ccol add-button">
            { !!editId && <div onClick={onCancel} className="highlight">
                <span>Отменить</span>
              </div> }

            <div onClick={onAdd} className={ isValid ? "highlight" : "" }>
              <span>{ editId ? "Обновить событие" : "Добавить в события" }</span>
              <Add />
            </div>
          </div>
        </div>
      </>
    )
  }];

  const activeTab = useMemo(() => blocks[currentBlock], [currentBlock])

  return (
    <>
      <div className="average-controls-wrapper">
        <Tabs
          className="top-tabs"
          items={items}
          activeKey={activeTab}
          onTabClick={(key) => setCurrentBlock(blocks.indexOf(key))}
        ></Tabs>
      </div>

      {!!listInfo && listInfo.length > 0 && <div className="average-collection-actions">
        <div className="title-block">
          <div className="title">
            События
          </div>
        </div>
      </div> }

      <DndProvider backend={HTML5Backend}>
        {!!listInfo && listInfo.length > 0 && listInfo.map((entry, index) => (
          <Event
            lastId={lastId.current}
            editId={editId}
            key={index}
            entry={entry}
            index={index}
            onDelete={deleteEvent}
            onMove={onMove}
            onEdit={onEdit}
          />
        ))}
      </DndProvider>
    </>
  );
}

export default Collection;
