import { createSelector } from '@reduxjs/toolkit';

import { AudienceCompareGroup } from '@store/forms/FormStorage.types';

export const getEventsAudienceXSelector = (state) =>
  state.formStorage.eventsAudience.payloadX;

export const getEventsAudienceYSelector = (state) =>
  state.formStorage.eventsAudience.payloadY;

export const getAllEventsAudienceSelector = createSelector(
  getEventsAudienceXSelector,
  getEventsAudienceYSelector,
  (audienceX, audienceY) => {
    const result = {};

    audienceX?.issued?.forEach(
      (id) => (result[id] = AudienceCompareGroup.audienceX),
    );
    audienceY?.issued?.forEach(
      (id) => (result[id] = AudienceCompareGroup.audienceY),
    );

    return result;
  },
);

export const getChosenCellOptionsFromScheduleSelector = (state) =>
  state.formStorage.scheduleCellsToCompare;
