import React, { useState, useMemo, useRef, useEffect } from 'react';
import API from '@utils/AdminApi';
import Common from '@utils/common';
import DemographicsFilters from './DemographicsFilters';
import { Button } from '@components/UI/Buttons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPie, setChannelId, setPath, setPayload, setFilters } from '@store/audiencePanel';

import { ReactComponent as Arrow } from '../../../assets/arrow.svg';

function Demographics({ members }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);

  const { queryData, pie, payload, path, channelId, filters } = useSelector((state) => state.audiencePanel);

  const highchartsRef = useRef();

  const charts = useMemo(() => ({
    chart: {
      height: 500,
    },
    title: {
      text: null,
    },
    xAxis: [
      {
        categories: ['Channels'],
        labels: {
          enabled: true,
        },
      },
    ],
    plotOptions: {
      pie: {
        events: {
          click: (e) => {
            switch (e.point.custom.data_type) {
              case 'categories':
                showCategories(e.point.custom);
                break
              case 'programs':
                showPrograms(e.point.custom);
                break
              case 'program':
                goToProgram(e.point.custom);
                break
              default:
                break
            }
          }
        },
        center: ["50%", "50%"],
        innerSize: 0,
        cursor: 'pointer',
      }
    },
    series: [ path === 'channels' ? pie.channels : (path === 'categories' ? pie.categories : pie.programs) ],
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      // pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>'
    },
    yAxis: {
      visible: false,
    },
  }), [ path, pie ]);

  const submitForm = async () => {
    setIsLoading(true);

    const from = Common.pickers2TVDatetime(payload.period.startDate, queryData.fromTime);
    const to = Common.pickers2TVDatetime(payload.period.endDate, queryData.toTime);

    let filters = {
      from,
      to,
      payload: {
        members,
        timeband: null,
        premierOption: payload.premier,
      }
    };

    if (payload.timeband) {
      try {
        const timeband = await API.getCollection(payload.timeband.id);
        filters.payload.timeband = timeband.data;
      } catch {

      }
    }

    dispatch(setFilters(filters));

    showChannels(filters);
  }

  const showChannels = (filters) => {
    const payload = Common.copy(filters.payload);
    payload['dates_range'] = {'start': filters.from, 'end': filters.to}

    API.postAudienceWatchedChannels(payload).then(data => {
      dispatch(setPath('channels'));
      dispatch(setPie({data_type: 'channels',value: { ...data, type: 'pie',}}));
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const showCategories = (data) => {
    setIsLoading(true);
    // API.postAudienceWatchedCategories(filters.from, filters.to, filters.payload, channelId).then((result) => {
    dispatch(setPath('categories'));
    dispatch(setChannelId(data.channel_id));
    dispatch(setPie({ data_type: 'categories', value :{ ...data, type: 'pie' } }));
    // }).finally(() => {
    //   setIsLoading(false);
    // });
    setIsLoading(false);
  }

  const showPrograms = (data) => {
    setIsLoading(true);
    // API.postAudienceWatchedPrograms(filters.from, filters.to, filters.payload, channelId, typeId).then((result) => {
      dispatch(setPath('programs'));
      dispatch(setPie({ data_type: 'programs', value: { ...data, type: 'pie' } }));
    // }).finally(() => {
      setIsLoading(false);
    // });
  }

  const goToProgram = (program) => {

    navigate(`/programs/${program.program_id}/channel/${program.channel_id}`, {
      state: location.state
    });
  }

  const onChange = (payload) => {
    dispatch(setPayload(payload));
  }

  const goBack = () => {
    let newPath = path === 'programs' ? 'types' : (path === 'categories' ? 'channels' : '');
    dispatch(setPath(newPath));
  }

  const channelSelected = useMemo(() => path === 'channels' ? 'current' : '', [ path ]);
  const categorySelected = useMemo(() => path === 'categories' ? 'current' : '', [ path ]);
  const programSelected = useMemo(() => path === 'programs' ? 'current' : '', [ path ]);

  return (
    <div className="audience-wrapper">

      <div className="audience-title">
        <div className="title">
          <div>Смотрение за интервал</div>
        </div>
        <div className="title-button">
          <Button
            onClick={() => submitForm()}
            disabled={isLoading}
            type="primary"
            label="Показать"
          />
        </div>
      </div>

      <div className="audience-content">
        <DemographicsFilters onChange={payload => onChange(payload)} defaultValue={payload} queryData={queryData} />
        { !!path && <div className="path">
          <span className={channelSelected} onClick={() => dispatch(setPath('channels'))}>Каналы</span>
          { path !== 'channels' && <><span className="arrow"><Arrow /></span><span className={categorySelected} onClick={() => dispatch(setPath('categories'))}>Жанры</span></> }
          { path === 'programs' && <><span className="arrow"><Arrow /></span><span className={programSelected} onClick={() => dispatch(setPath('programs'))}>Программы</span></> }
        </div> }
        <div className="bars pipes">
          <HighchartsReact highcharts={Highcharts} options={charts} ref={highchartsRef} allowChartUpdate={true} showLoading={true}/>
          {/* { path && path !== 'channels' && <div className="back-button" onClick={goBack}>Назад</div>  */}
          { isLoading && <div className="is-loading">Loading...</div> }
        </div>
      </div>
    </div>
  );
}

export default Demographics;
