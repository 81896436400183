import React, { useState } from 'react'
import LoginForm from '../LoginForm/LoginForm';
import ForgotPassword from './ForgotPassword';
import { useAuthContext } from '@store/AuthProvider';
import { PDAPI } from '@utils/PDApi';
import { toast } from "react-toastify";
import { Modal } from 'antd';
import { Button } from '@UI/Buttons';
import { ReactComponent as ProfileIcon } from '@UI/assets/profile.svg';

function Login() {
  const { login: authLogin, authLoad } = useAuthContext();

  const [ isOpen, setIsOpen ] = useState(false);

  const authRequest = (login, password) => {
    PDAPI
      .login(login, password)
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast.error('Неверный логин или пароль!');
        } else {
          toast.error('Системная ошибка.');
        }
      })
      .then(result => {
        if (result?.data?.token) {
          authLogin(result.data.token)

          authLoad(result.data.username ?? '')

          setIsOpen(false);
        }
      });
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)} label='Войти' type='danger'><ProfileIcon /></Button>

      <Modal
        title="Авторизация"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <LoginForm handleSubmit={authRequest} />
        <div className="forgot-block">
          <ForgotPassword />
        </div>
      </Modal>
    </>
  )
}

export default Login
