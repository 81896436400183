import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect from '@components/MultiSelect';

import {
  FormControl, FormLabel
} from "@chakra-ui/react"

import { getTreeOptions, getTreeIds } from './Utils';

/**
 * @param {
 *  label: string,
 *  single: boolean,
 *  placeholder: string,
 *  setValue: any | Array<any>
 *  onChange: (value) => {}
 *  className: string,
 *  isRequired: boolean,
 *  dropDown: boolean
 * } props
 * @returns
 */
function Adverts(props) {
  const types = useSelector((state) => state.collections.collections);

  const collections = useMemo(() => {
    const data = types.filter(entry => entry.type === 6);

    return data.length ? data[0].children : [];
  }, [types]);
  
  const [value, setValue] = useState();

  const options = useMemo(() => {
    return getTreeOptions(collections);
  }, [ collections ]);

  const onChange = (value) => {
    setValue(value);

    if (props.onChange) {
      const keys = getTreeIds(value ?? [], collections);
      props.onChange(keys);
    }

    if (props.onChangeBase) {
      props.onChangeBase(value);
    }
  }

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  return (
    <div>
      <FormControl
        className={props.className ?? ''}
        isRequired={props.isRequired ?? false}
      >
        { !!props.label && <FormLabel>{ props.label }</FormLabel> }

        <MultiSelect
          options={options}
          onChange={onChange}
          search={props.search ?? true}
          dropDown={props.dropDown ?? true}
          selected={value}
          placeholder={props.placeholder ?? ''}
          single={props.single ?? false}
          line={true}
        />
      </FormControl>
    </div>
  )
}

export default Adverts;
