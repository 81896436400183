/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuthContext } from '@store/AuthProvider';

import { ReactComponent as ArrowIcon } from '@UI/assets/arrow_drop_up.svg';
import { ReactComponent as Icon1 } from '@UI/assets/apartment.svg';
import { ReactComponent as Icon2 } from '@UI/assets/location_city.svg';
import { ReactComponent as Icon3 } from '@UI/assets/globe_asia.svg';

import './Population.scss';

const populationOptions = [
  {
    id: true,
    icon: <Icon1 />,
    label: 'Россия 100 +',
  },
  {
    id: false,
    icon: <Icon2 />,
    label: 'Россия 100 -',
  },
  {
    id: null,
    icon: <Icon3 />,
    label: 'Россия 0+',
  },
];

function Population() {
  const { population: population100k, setPopulation } = useAuthContext();

  const [showMenu, setShowMenu] = useState(false);

  const wrapperRef = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const info = useMemo(() => {
    const result = populationOptions.filter(({ id }) => id === population100k);

    return result.length ? result[0] : null;
  }, [population100k]);

  useEffect(() => {
    const hasModal = (target) => {
      var node = target;
      while (node !== document.body) {
        const className =
          node && typeof node.className === 'string' ? node.className : '';

        if (className?.search('ant-modal-root') !== -1) {
          return true;
        }

        if (node === null) {
          return false
        }

        node = node.parentNode;
      }

      return false;
    };

    const handleClickOutside = (event) => {
      if (
        wrapperRef &&
        !wrapperRef.current?.contains(event.target) &&
        !hasModal(event.target)
      ) {
        if (showMenu) {
          setShowMenu(false);
        }
      }
    };

    document.addEventListener('mousedown', (event) =>
      handleClickOutside(event),
    );

    return () => {
      document.removeEventListener('mousedown', (event) =>
        this.handleClickOutside(event),
      );
    };
  }, [showMenu]);

  const arrowClass = useMemo(
    () => 'arrow' + (showMenu ? '' : ' top'),
    [showMenu],
  );

  return (
    <div ref={wrapperRef} className="population-wrapper">
      <div className="population-title" onClick={() => toggleMenu()}>
        <div className="icon">{info && info.icon}</div>
        <div className="title">{info && info.label}</div>
        <div className={arrowClass}>
          <ArrowIcon />
        </div>
      </div>
      {!!showMenu && (
        <div className="population-menu">
          {populationOptions.map((row, index) => (
            <div
              className="population-menu-item"
              key={index}
              onClick={() => {
                setPopulation(row.id);
                toggleMenu();
              }}
            >
              <div className="icon">{row.icon}</div>
              <div className="title">{row.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Population;
