import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import {
  fetchChannels,
  fetchChannelsCollections,
  fetchCollections,
  fetchDemographics,
  fetchStatistics,
} from '@store/collections';

export const useLoadFilterCollections = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // todo KTS combine actions to make them easier to understand
    dispatch(fetchCollections() as unknown as AnyAction);

    dispatch(fetchDemographics() as unknown as AnyAction);

    dispatch(fetchChannels() as unknown as AnyAction);

    dispatch(fetchStatistics() as unknown as AnyAction);

    dispatch(fetchChannelsCollections() as unknown as AnyAction);
  }, [dispatch]);
};
