import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersProvider } from './FiltersProvider';
import { fetchCollections, fetchDemographics, fetchChannels, fetchStatistics, fetchChannelsCollections } from '@store/collections';
import DateRange from '@components/Sources/groups/DateRange';
import { setChannelsPage } from '../../store/forms/FormStorage';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAuthContext } from '../../store/AuthProvider';
import { useNavigate } from "react-router-dom";

import FormTemplates from '@components/FormTemplates/FormTemplates';
import { FormTemplatesProvider } from '@components/FormTemplates/FormTemplatesProvider';
import ChannelsFilters from './ChannelsFilters';

import { ReactComponent as Right } from '../collections/crud/UI/images/right.svg';
import { ReactComponent as Down } from '../collections/crud/UI/images/down.svg';

import { ConfirmProvider } from '@components/Confirm/ConfirmProvider';
import Confirm from '@components/Confirm/Confirm';

import './Channels.scss';

export default function ChannelsPage() {

  const maxDate = useSelector((state) => state.status.maxDate);

  const { isLoggedIn } = useAuthContext();

  const [ response, setResponse ] = useState({target: null, data: {columns: [], data: []}});
  const [ filters, setFilters ] = useState({});

  const [ isOpen, setIsOpen ] = useState(true);

  const [ period, setPeriod ] = useState(null);

  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen(value => !value);
  }

  const inited = useRef(false);

  const highchartsRef = useRef();

  const dispatch = useDispatch();

  const channelsCollection = useSelector((state) => state.collections.channels);

  let channelsPage = useSelector((state) => state.formStorage.channelsPage);

  const onChangePeriod = (period) => {
    setPeriod(period);
    dispatch(setChannelsPage({ ...channelsPage, period }));
  }

  const onChangeResponse = (response) => {
    setResponse(response);
    dispatch(setChannelsPage({ ...channelsPage, response }));
  }

  useEffect(() => {
    dispatch(
      fetchCollections()
    );

    dispatch(
      fetchDemographics()
    );

    dispatch(
      fetchChannels()
    );

    dispatch(
      fetchStatistics('channels')
    );

    dispatch(
      fetchChannelsCollections()
    );
  }, [ dispatch, isLoggedIn ]);

  const chart = useMemo(() => {

    if (!response || !response.data || response.data.length === 0) {
      return {
        xAxis: {},
        yAxis: {},
        series: [],
      }
    }

    return { ...response.data.graph }
  }, [ response ]);

  const options = useMemo(() => ({
    chart: {
      height: "350px",
    },
    'title': {
      'text': chart.title ? chart.title.text : '',
      'align': 'left',
      'margin': 50
    },
    subtitle: {
      'text': chart.subtitle? chart.subtitle.text : '',
      'align': 'left',
      'margin': 50
    },
    xAxis: chart.xAxis,
    yAxis: {
      min: chart.yAxis ? chart.yAxis.min : 0,
      max: chart.yAxis ? chart.yAxis.max : 0,
      title: chart.yAxis ? chart.yAxis.title : '',
    },
    series: chart.series,
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: (e) => {
              let channel = channelsCollection.filter(ch => ch.name === e.point.series.name);

              navigate(`/channels/${channel.length ? channel[0].id : ''}`, {
                state: {
                  target: response.target,
                  date: e.point.category
                }
              });
            }
          }
        }
      }
    }
  }), [ chart, response ]);

  useEffect(() => {
    if (channelsPage.period) {
      setPeriod(channelsPage.period);
      inited.current = true;
    }

    if (channelsPage.response) {
      setResponse(channelsPage.response);
    }

  }, []);

  useEffect(() => {
    if (!inited.current && maxDate !== null) {
      let minDate = new Date(maxDate);
      minDate.setDate(minDate.getDate() - 3);

      setPeriod({
        startDate: minDate,
        endDate: maxDate,
        key: 'selection',
      });
    }
  }, [ maxDate ]);

  return (
    <FiltersProvider defaultValue={channelsPage.filters}>
      <FormTemplatesProvider>
        <div className="pdcard-wrapper">
          <div className="pdcard-title">
            <div className="list" onClick={() => toggle()}>{ isOpen ? <Down /> : <Right /> }</div>
            <div className="title" onClick={() => toggle()}>Каналы</div>
            <div className="template">
              <ConfirmProvider>
                <Confirm />
                <FormTemplates type={5} filters={filters} storage="channels" />
              </ConfirmProvider>
            </div>
            <div>
              { !!maxDate && <DateRange
                onChange={onChangePeriod}
                label="Период"
                placeholder="Выберите диапазон дат"
                value={period}
                maxDate={maxDate}
              /> }
            </div>
          </div>
          <div className={isOpen ? "" : "hidden"}>
            <ChannelsFilters onSubmit={onChangeResponse} onChangeFilters={(filters) => setFilters(filters)} period={period}></ChannelsFilters>
          </div>
        </div>
        <div className="channels-chart">
          <HighchartsReact highcharts={Highcharts} options={options} ref={highchartsRef}/>
        </div>
      </FormTemplatesProvider>
    </FiltersProvider>
  );
}
