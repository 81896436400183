/* eslint-disable */
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from '@store/sidebar';

import { ReactComponent as Profile } from './assets/profile.svg';
import { ReactComponent as Collections } from './assets/collections.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as MiniMenu } from './assets/mini-menu.svg';
import { ReactComponent as Temperature } from './assets/temperature.svg';

import { Status } from './Status';
import {
  MdAreaChart,
  MdOutlineVideoLibrary,
  MdCalendarViewWeek,
  MdDashboard,
  MdCompare,
  MdAdsClick,
} from 'react-icons/md';

import './Sidebar.scss';

function Sidebar() {
  const dispatch = useDispatch();
  const minimizeSidebar = useSelector((state) => state.sidebar.minimizeSidebar);

  return (
    <aside className={`sidebar ${minimizeSidebar ? 'mini' : ''}`}>
      <div className="roller" title="Свернуть">
        <div onClick={() => dispatch(toggle())}>
          {minimizeSidebar ? <MiniMenu /> : <Menu />}
        </div>
      </div>
      {/** todo: fix layout — plain HMTL -> BEM */}
      <ul className="sidebar-nav">
        <li>
          {/** todo: move routes to config */}
          <NavLink to="/channels">
            <MdDashboard />
            <span>Каналы</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/collections">
            <Collections />
            <span>Управление наборами</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/tables">
            <Profile />
            <span>Мои отчеты</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/programs">
            <MdAreaChart />
            <span>Программы</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/audience_compare">
            <MdCompare />
            <span>Сравнение аудиторий</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/wall">
            <MdOutlineVideoLibrary />
            <span>Стена</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/average">
            <Temperature />
            <span>Среднее</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/schedule">
            <MdCalendarViewWeek />
            <span>Сетка</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/adverts">
            <MdAdsClick />
            <span>Реклама β</span>
          </NavLink>
        </li>
      </ul>
      <Status />
    </aside>
  );
}

export default Sidebar;
