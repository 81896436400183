import * as React from 'react';

import { ColorRangeConfigEntity } from '@pages/schedule/models/colorRangeConfigEntity';

import s from './LegendItem.module.scss';

type LegendItemProps = {
  item: ColorRangeConfigEntity;
};

const LegendItem: React.FC<LegendItemProps> = ({ item: { title, color } }) => {
  return (
    <div className={s['legend-item']}>
      <div
        className={s['legend-item__marker']}
        style={{ backgroundColor: color }}
      />
      <span className={s['legend-item__title']}>{title}</span>
    </div>
  );
};

export default React.memo(LegendItem);
