import { useTable } from 'react-table'

function Table({ columns, data, showHeader, showBorder, tableStyle = {}, rowStyle = {}}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  const tableStyleVal = showBorder ? { borderWidth: "2px" } : tableStyle
  const rowStyleVal = showBorder ? { borderWidth: "2px" } : rowStyle

  let header = '';
  if (showHeader !== false) {
    header = <thead>
    {headerGroups.map((headerGroup, index) => (
      <tr {...headerGroup.getHeaderGroupProps()} key={index}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps()} style={rowStyleVal}>{column.render('Header')}</th>
        ))}
      </tr>
    ))}
    </thead>;
  }
  // Render the UI for your table
  return (
    <table {...getTableProps()} style={tableStyleVal}>
      {header ? header : null}
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row)
        return (<tr key={i}>
          {row.cells.map(cell => {
            return <td {...cell.getCellProps()} style={rowStyleVal}>{cell.render('Cell')}</td>
          })}
        </tr>)
      })}
      </tbody>
    </table>
  )
}

export default Table
