import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function PieCharts() {

  const { pieCharts } = useSelector((state) => state.audiencePanel);

  const charts = useMemo(() => {
    return pieCharts.map(chart => ({
      chart: {
        height: 240,
        width: 320,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: chart.title,
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          center: ["50%", "50%"],
          innerSize: 100,
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: chart.series,
      legend: {
        enabled: false,
      },
    })).slice(0, 4)
  }, [ pieCharts ]);

  return (
    <>
      { charts.map(chart => (
        <div>
          <HighchartsReact highcharts={Highcharts} options={chart} allowChartUpdate={true} />
        </div>
      )) }
    </>
  )
}

export default PieCharts;