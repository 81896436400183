import cn from 'classnames';
import * as React from 'react';

import { TabBar } from '@UI/TabBar';
import { Meta } from '@models/meta';

import { Legend } from '../Legend';
import { colorRangeConfig } from '../config/tableRangeColors';
import { useCurrentTableTabData, useTabBarOptions } from '../hooks';
import {
  ScheduleTableColumnsContextProvider,
  useCreateColumnsContext,
} from '../stores';

import s from './ScheduleTable.module.scss';
import { ScheduleTableLayout } from './ScheduleTableLayout';

export type ScheduleTableProps = {
  className?: string;
};

/**
 * Таблица на странице ТВ-программы
 */
const ScheduleTable: React.FC<ScheduleTableProps> = ({
  className,
}: ScheduleTableProps) => {
  const {
    meta,
    data: { fixedColumnIds, scrolledColumnIds, cellEntities },
  } = useCurrentTableTabData();

  const { audienceTabBarParams, datesTabBarParams } = useTabBarOptions();

  const columnsContextValue = useCreateColumnsContext(
    scrolledColumnIds,
    cellEntities,
  );

  return (
    <div className={cn(s['schedule-table'], className)}>
      <div className={s['schedule-table__tabs']}>
        <TabBar {...audienceTabBarParams} />
        <TabBar {...datesTabBarParams} />
      </div>
      <ScheduleTableColumnsContextProvider value={columnsContextValue}>
        <ScheduleTableLayout
          className={s['schedule-table__content']}
          isLoading={meta === Meta.loading}
          fixedColumnIds={fixedColumnIds}
          scrolledColumnIds={scrolledColumnIds}
          cellEntities={cellEntities}
        />
      </ScheduleTableColumnsContextProvider>
      <Legend
        items={colorRangeConfig}
        className={s['schedule-table__legend']}
      />
    </div>
  );
};

export default ScheduleTable;
