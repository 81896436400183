import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useVideosContext } from '../../store/VideosProvider';
import MultiSelect from '../MultiSelect';

export type СhannelsModalProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  handleSelect: (value: number) => void;
  selected?: number;
};

export function ChannelsModal({
  isModalVisible,
  selected,
  handleCancel,
  handleSelect,
}: СhannelsModalProps) {
  const { channels } = useDictionaryContext();

  const { channels: selectedChannels } = useVideosContext();

  const onChange = (value: number[]) => {
    handleSelect(value[0]);
  };

  const options = useMemo(
    () =>
      channels
        ? channels
            .filter(
              (channel) =>
                !selectedChannels ||
                (selectedChannels as Array<number>).indexOf(channel.id) === -1,
            )
            .map(({ title: label, id: value }) => ({
              label,
              value,
            }))
        : [],
    [channels, selectedChannels],
  );

  const selectedValues = useMemo(
    () => (selected ? [selected] : []),
    [selected],
  );

  return (
    <Modal
      title="Выбор канала"
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleCancel}
      footer={null}
    >
      <div className="form-control" style={{ marginBottom: '18px' }}>
        <MultiSelect
          single={true}
          options={options}
          onChange={onChange}
          search={true}
          selected={selectedValues}
        />
      </div>
    </Modal>
  );
}
