/**
 * Параметры шрифта ячейки программы
 */
type FontParamsItem = {
  fontSize: number;
  lineHeight: number;
  pixelsPerChar: number;
  /* ширина текста рейтингов программы */
  occupiedWidth: number;
};

const FONT_PARAMS: Array<FontParamsItem> = [
  {
    fontSize: 16,
    lineHeight: 18,
    pixelsPerChar: 11,
    occupiedWidth: 190,
  },
  {
    fontSize: 14,
    lineHeight: 16,
    pixelsPerChar: 9.5,
    occupiedWidth: 170,
  },
  {
    fontSize: 12,
    lineHeight: 12,
    pixelsPerChar: 7.5,
    occupiedWidth: 150,
  },
];

type CellParams = {
  /* ширина ячейки */
  containerWidth: number;
  /* высота ячейки */
  containerHeight: number;
  /* количество символов в названии программы */
  charsCount: number;
};

const FONT_FALLBACK_PARAMS = FONT_PARAMS[2];

const doesConfigMatch = (
  { lineHeight, occupiedWidth, pixelsPerChar }: FontParamsItem,
  { containerWidth, containerHeight, charsCount }: CellParams,
): boolean => {
  const charsCapacity =
    ((containerWidth - occupiedWidth) / pixelsPerChar) *
    Math.floor(containerHeight / lineHeight);

  return containerHeight >= lineHeight && charsCount <= charsCapacity;
};

/**
 * Функция выбора параметров шрифта ячейки программы
 */
export const getProgramCellFontParams = (
  cellParams: CellParams,
): FontParamsItem =>
  FONT_PARAMS.find((fontParams) => doesConfigMatch(fontParams, cellParams)) ||
  FONT_FALLBACK_PARAMS;
