import cn from 'classnames';
import * as React from 'react';

import { MINUTE_TO_PX_SCALE } from '@pages/schedule/models/constants';
import { ScheduleCellEntity } from '@pages/schedule/models/scheduleCellEntity';
import { ScheduleCellKind } from '@pages/schedule/models/scheduleCellKind';

import { CellContentTVEvent } from './CellContentTVEvent';
import { CellContentTimeSlot } from './CellContentTimeSlot';
import s from './ScheduleTableCell.module.scss';

export type ScheduleTableCellProps = {
  cellEntity: ScheduleCellEntity;
  className?: string;
};

/**
 * Компонент ячейки события на календаре
 */
const ScheduleTableCell: React.FC<ScheduleTableCellProps> = ({
  cellEntity,
  className,
}: ScheduleTableCellProps) => {
  const tvEventCellHeight = React.useMemo(() => {
    return (
      (cellEntity.tableTVTimeInterval.yEnd -
        cellEntity.tableTVTimeInterval.yStart) *
      MINUTE_TO_PX_SCALE
    );
  }, [cellEntity]);

  const cssProps: React.CSSProperties = React.useMemo(() => {
    return {
      height: tvEventCellHeight,
      top: cellEntity.tableTVTimeInterval.yStart * MINUTE_TO_PX_SCALE,
    };
  }, [tvEventCellHeight, cellEntity]);

  return (
    <div style={cssProps} className={cn(s['schedule-table-cell'], className)}>
      {cellEntity.cellKind === ScheduleCellKind.timeSlot && (
        <CellContentTimeSlot cellEntity={cellEntity} />
      )}
      {cellEntity.cellKind === ScheduleCellKind.tvEvent && (
        <CellContentTVEvent
          cellEntity={cellEntity}
          cellHeight={tvEventCellHeight}
        />
      )}
    </div>
  );
};

export default ScheduleTableCell;
