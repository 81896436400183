import React, { useState } from 'react'
import { ReactComponent as CalendarIcon } from '@components/DateFilter/assets/date-range.svg';
import moment from 'moment';
import 'moment/locale/ru';
import { Flex, Select, Text, useOutsideClick } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import * as locales from '@components/DateRange/locale';
import { DateRange } from '@components/DateRange';
import _ from "lodash-es"

import * as store_channels from '@store/channels'
import * as store_panel from '@store/panel'


function isEndDateFocused(focusNumber) {
  return focusNumber === 1;
}

function DateRangeFilter(callback) {
  const range = callback.state;
  let changeSelectedDateRange = {};
  switch (callback.store) {
    case 'channels':
      changeSelectedDateRange = store_channels.changeSelectedDateRange;
      break
    default:
      changeSelectedDateRange = store_panel.changeSelectedDateRange;
  }
  const dispatch = useDispatch();
  const calendarWrapperRef = React.useRef()
  const [showCalendar, setShowCalendar] = useState(false);
  useOutsideClick({
    ref: calendarWrapperRef,
    handler: () => setShowCalendar(false)
  })

  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: range.startDate,
    endDate: range.endDate,
    key: "selection"
  });

  const handleSelectDate = (ranges) => {
    const { startDate, endDate } = ranges?.selection || {};

    const newValue = {
      startDate,
      endDate,
      key: "selection"
    };

    setSelectionRange(newValue);

    if (isEndDateFocused(focusedRange[1])) {
      setShowCalendar(false);
      dispatch(changeSelectedDateRange(newValue));
    }
  };

  return (
    <div className="row dateFilter">
      <div className="col mb-3">
        <Flex
          alignItems="center"
          cursor="pointer"
          onClick={() => setShowCalendar(true)}
        >
          <CalendarIcon />
          <Text ml={1} mr={1}>
            {moment(range.startDate).format('DD MMMM')}
          </Text>
          {`-`}
          <Text ml={1}>
            {moment(range.endDate).format('DD MMMM')}
          </Text>
        </Flex>

        <div
          className={`dateFilter--calendar-wrapper ${showCalendar ? 'show' : ''}`}
          ref={calendarWrapperRef}
        >
          <DateRange
            showDateDisplay={false}
            showSelectionPreview={false}
            editableDateInputs={false}
            onChange={handleSelectDate}
            onRangeFocusChange={setFocusedRange}
            moveRangeOnFirstSelection={false}
            ranges={[selectionRange]}
            locale={locales['ru']}
            minDate={moment()
              .set('year', 2020)
              .set('month', 1)
              .set('day', 1)
              .toDate()}
            maxDate={moment()
              .set('year', 2024)
              .set('month', 12)
              .set('day', 31)
              .toDate()}
          />
        </div>
      </div>
    </div>
  );
}

export default DateRangeFilter;
