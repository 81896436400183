import { DEFAULT_INTERVAL_SPLIT_STEP } from '../models/constants';
import { ScheduleCellPayloadTimeSlot } from '../models/scheduleCellPayloadTimeSlot';
import { tvTimeIntervalEntityToTVTimeString } from '../models/timeIntervalValueKind';

/**
 * Для тайм-интервала формируются тайм-байты с шагом DEFAULT_INTERVAL_SPLIT_STEP
 */
export const createTimeBites = (
  minutesStart: number,
  minutesEnd: number,
): ScheduleCellPayloadTimeSlot => {
  const minutesStartRemainder = minutesStart % DEFAULT_INTERVAL_SPLIT_STEP;

  const minutesEndRemainder = minutesEnd % DEFAULT_INTERVAL_SPLIT_STEP;

  const minutesStartEven = minutesStartRemainder
    ? minutesStart - minutesStartRemainder
    : minutesStart;

  const minutesEndEven = minutesEndRemainder
    ? minutesEnd + (DEFAULT_INTERVAL_SPLIT_STEP - minutesEndRemainder)
    : minutesEnd;

  const timeBites = {};

  const timeBiteIds: string[] = [];

  for (
    let i = minutesStartEven;
    i < minutesEndEven;
    i += DEFAULT_INTERVAL_SPLIT_STEP
  ) {
    const timeBite = {
      minutesStart: i,
      minutesEnd: Math.min(i + DEFAULT_INTERVAL_SPLIT_STEP, minutesEndEven),
    };

    const timeBiteId = tvTimeIntervalEntityToTVTimeString(timeBite);

    timeBiteIds.push(timeBiteId);

    timeBites[timeBiteId] = {
      ...timeBite,
      hidden: false,
    };
  }

  return {
    timeBites,
    timeBiteIds,
  };
};
