import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '@config/routes';
import { ScheduleCellTVEvent } from '@pages/schedule/models/scheduleCellPayloadTVEvent';
import { useScheduleTableColumnsContext } from '@pages/schedule/stores/ScheduleTableColumnsContext';
import { getProgramCellFontParams } from '@pages/schedule/utils';
import { SpacingUnit, SpacingUnitMap } from '@styles/utils';
import formatPercent from '@utils/formatPercent';

import s from './CellContentStats.module.scss';

type CellContentStatsProps = {
  cellEntity: ScheduleCellTVEvent;
  areRatingsVisible: boolean;
  cellHeight: number;
};

/** Вертикальный padding ячейки события */
const VERTICAL_PADDING = SpacingUnitMap[SpacingUnit.unit0_5];

const CellContentStats: React.FC<CellContentStatsProps> = ({
  cellEntity,
  areRatingsVisible,
  cellHeight,
}) => {
  const { subscribeOnColumnResize } = useScheduleTableColumnsContext();

  const cellContentRef = React.useRef<HTMLDivElement | null>(null);

  const programRef = React.useRef<HTMLSpanElement | null>(null);

  const contentCellHeight = cellHeight - VERTICAL_PADDING * 2;

  React.useEffect(() => {
    const unsubscribe = subscribeOnColumnResize((columnWidth) => {
      if (!cellContentRef.current || !programRef.current) {
        return;
      }

      const { fontSize, lineHeight } = getProgramCellFontParams({
        containerWidth: columnWidth,
        containerHeight: contentCellHeight,
        charsCount: cellEntity.payload.program.length,
      });

      programRef.current.style.webkitLineClamp = String(
        Math.floor(contentCellHeight / lineHeight) || 1,
      );
      cellContentRef.current.style.fontSize = `${fontSize}px`;
      cellContentRef.current.style.lineHeight = `${lineHeight}px`;
    });

    return () => {
      unsubscribe();
    };
  }, [
    cellEntity.payload.program.length,
    contentCellHeight,
    subscribeOnColumnResize,
  ]);

  const { programId, channelId, pissId, program, tvr, share } =
    cellEntity.payload;

  return (
    <div ref={cellContentRef} className={s['schedule-table-cell-stats']}>
      <Link
        to={ROUTES.programs.detail.channel.detail.issued.detail.createRoute(
          programId,
          channelId,
          pissId,
        )}
        target="_blank"
        className={cn(
          s['schedule-table-cell-stats__item'],
          s['schedule-table-cell-stats__item_title'],
        )}
      >
        <span
          ref={programRef}
          className={cn(
            s['schedule-table-cell-stats__text'],
            s['schedule-table-cell-stats__text_title'],
          )}
        >
          {program}
        </span>
      </Link>
      <div
        className={cn(
          s['schedule-table-cell-stats__item'],
          s['schedule-table-cell-stats__item_ratings'],
          areRatingsVisible &&
            s['schedule-table-cell-stats__item_ratings-opaque'],
        )}
      >
        <span
          className={cn(
            s['schedule-table-cell-stats__item'],
            s['schedule-table-cell-stats__text'],
          )}
        >
          TVR {formatPercent(tvr)}%
        </span>
        <span
          className={cn(
            s['schedule-table-cell-stats__item'],
            s['schedule-table-cell-stats__text'],
          )}
        >
          SHR {formatPercent(share)}%
        </span>
      </div>
    </div>
  );
};

export default React.memo(CellContentStats);
