export const MINUTES_IN_HOUR = 60;

export const TV_TIME_SHIFT_HOURS = 5;

// Расстояние между схлопнутыми таймбендами в минутах
export const Y_SPACE_BETWEEN_INTERVALS = 5;

// Цена деления временной шкалы внутри интервала на таблице
export const IN_TIME_INTEVAL_STEP = 15;

export const TIME_SLOTS_COLUMN_ID = 'TIME_SLOTS_COLUMN';

// Масштаб 1 минута = 10px по вертикали
export const MINUTE_TO_PX_SCALE = 10;

/** Минимальная длительность события (в минутах), при котором
 * будет показано содержимое события в ячейке, а не в тултипе
 */
export const MIN_INLINE_CONTENT_EVENT_DURATION = 2;

// Интервал деления временного промежутка - таймй-байт, для ячеек таблицы (в минутах)
export const DEFAULT_INTERVAL_SPLIT_STEP = 5;

// Величина заглушки вместо схлопнутых тайм-байтов (в минутах)
export const TIME_BITE_STUB_HEIGHT = 3;

// Значение пресета каналов в массиве опций каналов
export const CHANNELS_PRESET_VALUE = 'c';

// Префикс значения канала в массиве опций каналов
export const CHANNEL_PREFIX = 'ch';

// Разделитель каналов в строке пресета каналов вида "1868,1902,1832"
export const CHANNEL_IDS_IN_PRESET_DIVIDER = ',';
