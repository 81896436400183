import * as React from 'react';

export type ScheduleTableColumnsContextValue = {
  columnsContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  subscribeOnColumnResize: (
    handler: (columnWidth: number) => void,
  ) => VoidFunction;
  subscribeOnColumnsContainerResize: (
    handler: (containerWidth: number) => void,
  ) => VoidFunction;
};

const ScheduleTableColumnsContext =
  React.createContext<ScheduleTableColumnsContextValue | null>(null);

export const useScheduleTableColumnsContext =
  (): ScheduleTableColumnsContextValue => {
    const contextValue = React.useContext(ScheduleTableColumnsContext);

    if (!contextValue) {
      throw 'useScheduleTableContext must be used with the ScheduleTableContextProvider';
    }

    return contextValue;
  };

type ScheduleTableColumnsContextProviderProps = React.PropsWithChildren<{
  value: ScheduleTableColumnsContextValue;
}>;

export const ScheduleTableColumnsContextProvider: React.FC<
  ScheduleTableColumnsContextProviderProps
> = ({ value, children }) => {
  return (
    <ScheduleTableColumnsContext.Provider value={value}>
      {children}
    </ScheduleTableColumnsContext.Provider>
  );
};
