import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AxisCard from './axis-card';
import update from 'immutability-helper';

import {
  FormControl
} from "@chakra-ui/react"

function Targets(props) {
  const lastId = useRef(-1);

  const [options, setOptions] = useState([
    { value: 'x', label: 'Колонки' },
    { value: 'y', label: 'Строки' },
  ]);

  const [data, setData] = useState([]);

  const getAxises = (options) => {
    const values = options.map(option => option.value);

    const y = values.indexOf('y');

    return {
      x: options.slice(1, y),
      y: options.slice(y + 1),
    }
  }

  const geDataAxises = (data) => {
    const values = data.map(el => el.option.value);

    const y = values.indexOf('y');

    return {
      x: data.slice(1, y),
      y: data.slice(y + 1),
    }
  }

  const axes = useMemo(() => {
    return geDataAxises(data);
  }, [ data ]);

  const onChange = (axisX, axisY) => {
    if (props.onChange) {
      props.onChange({
        axisX,
        axisY,
      });
    }
  }

  useEffect(() => {
    if (props.options) {
      const exists = options.map(option => option.value);
      const newOptions = ['x', 'y', ...props.options.map(option => option.value)];

      const _options = [
        ...options.filter(option => newOptions.indexOf(option.value) !== -1),
        ...props.options.filter(option => exists.indexOf(option.value) === -1)
      ];

      setOptions(_options);

      const {x, y} = getAxises(_options);
      onChange(x, y);
    }
  }, [ props.options ]);

  useEffect(() => {
    setData(options.map((option, index) => ({
      option,
      dropId: index,
    })));
  }, [ options ]);

  const moveCard = useCallback((hoverId) => {
    if (hoverId !== -1 && lastId.current !== hoverId) {
      lastId.current = hoverId;

      const list = [...options].map((option, index) => ({
        option,
        dropId: index,
      }));

      const newData = update(list, {
        $splice: [
          [hoverId, 0, {option: { value: -1, label: '' }, dropId: -1 }],
        ],
      });

      setData(newData);
    }
  }, [ options ]);

  const dropCard = useCallback((dragId) => {
    if (lastId.current !== -1) {
      const dragOption = options[dragId];

      const dropId = lastId.current - (dragId < lastId.current ? 1 : 0);

      const newOptions = update(options, {
          $splice: [
              [dragId, 1],
              [dropId, 0, dragOption],
          ],
      });

      lastId.current = -1;

      setOptions(newOptions);

      const {x, y} = getAxises(newOptions);
      onChange(x, y);

    }
  }, [ options ]);

  const dropToAxis = useCallback((dragId, axis) => {
    const values = data.map(({ option }) => option.value);
    const dropId = values.indexOf('y') + ( axis === 'y' ? 1  : 0);

    const yIndex = values.indexOf('y');

    const add = !((axis === 'x' && dragId < yIndex) || (axis === 'y' && dragId >= yIndex))

    if (lastId.current !== -1) {
      const curentAxis = lastId.current < yIndex ? 'x' : 'y';

      if (curentAxis === axis) {
        return;
      }

      const dragOption = options[dragId];

      const newOptions = add ? update(options, {
        $splice: [
          [dragId, 1],
          [dropId, 0, dragOption],
        ],
      }) : options;

      lastId.current = -1;

      setOptions(newOptions);

      const {x, y} = getAxises(newOptions);
      onChange(x, y);

    }
  }, [ options ]);

  const addToAxis = useCallback((dragId, axis) => {
    const values = data.map(({ option }) => option.value);
    const hoverId = values.indexOf('y') + ( axis === 'y' ? 1  : 0);

    const yIndex = values.indexOf('y');
    const dragAxis = dragId < yIndex ? 'x' : 'y';

    const add = axis !== dragAxis;

    if (hoverId !== -1 && lastId.current !== hoverId) {
      const curentAxis = lastId.current < yIndex ? 'x' : 'y';

      if (curentAxis === axis) {
        return;
      }

      lastId.current = hoverId;

      const list = [...options].map((option, index) => ({
        option,
        dropId: index,
      }));

      const newData = add ? update(list, {
        $splice: [
          [hoverId, 0, {option: { value: -1, label: '' }, dropId: -1 }],
        ],
      }) : list;

      setData(newData);
    }
  }, [ data ]);

  return (
    <div>
      <FormControl isRequired={true} className='axes'>
				<DndProvider backend={HTML5Backend}>
          <AxisCard
            axes={axes}
            dropToAxis={dropToAxis}
            addToAxis={addToAxis}
            moveCard={moveCard}
            dropCard={dropCard}
          />
				</DndProvider>
      </FormControl>
    </div>
  )
}

export default Targets;
