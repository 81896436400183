import React, { useContext, useReducer } from 'react';

import { Statistic, Channel, Audience } from '../models';
import { PDAPI } from '../utils/PDApi';
import { VideoApi } from '../utils/VideoApi';

export interface DictionaryState {
  statistics: Statistic[] | null;
  channels: Channel[] | null;
  datesWithVideo: string[] | null;
  audiences: Audience[] | null;
}

export interface DictionaryActions {
  loadStatistics: () => void;
  loadChannels: (date: string) => void;
  loadAudiences: () => void;
  loadDatesWithVideo: () => void;
}

export type DictionaryContext = DictionaryActions & DictionaryState;

export enum DictionaryActionKind {
  STATISTICS = 'statistics',
  CHANNELS = 'channels',
  AUDIENCES = 'audiences',
  DATES_WITH_VIDEO = 'datesWithVideo',
}

interface StorageAction {
  type: DictionaryActionKind;
  payload: any;
  date?: string;
}

// eslint-disable-next-line import/no-named-as-default-member
export const AtdlContext = React.createContext<DictionaryContext>({
  statistics: null,
  channels: null,
  datesWithVideo: null,
  audiences: null,
  loadStatistics: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadChannels: (date: string) => {},
  loadAudiences: () => {},
  loadDatesWithVideo: () => {},
});

export const useDictionaryContext = () => {
  return useContext(AtdlContext);
};

const reducer = (
  state: DictionaryState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { type, payload, date }: StorageAction,
) => {
  switch (type) {
    case DictionaryActionKind.STATISTICS:
      return { ...state, statistics: payload };

    case DictionaryActionKind.AUDIENCES:
      return { ...state, audiences: payload };

    case DictionaryActionKind.CHANNELS:
      return { ...state, channels: payload };

    case DictionaryActionKind.DATES_WITH_VIDEO:
      return { ...state, datesWithVideo: payload };

    default:
      return state;
  }
};

export interface StorageContextProps {
  count?: number;
  children: any;
}

export const DictionaryProvider = ({
  count,
  children,
}: StorageContextProps) => {
  const videosCount = count ? count : 4;

  const videos: string[] = [];

  const stats: number[] = [];

  for (let i = 0; i < videosCount; i++) {
    videos.push('');
    stats.push(0);
  }

  const [state, dispatch] = useReducer(reducer, {
    statistics: null,
    channels: null,
    datesWithVideo: null,
    audiences: null,
  });

  const loadStatistics = () => {
    if (state.statistics === null) {
      PDAPI.getStatistics().then((payload) => {
        dispatch({
          type: DictionaryActionKind.STATISTICS,
          payload: payload ? payload : null,
        });
      });
    }
  };

  const loadChannels = (date: string) => {
    VideoApi.getChannels(date).then((payload) => {
      if (payload) {
        dispatch({
          type: DictionaryActionKind.CHANNELS,
          payload: payload ? payload.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1) : null,
        });
      }
    });
  };

  const loadDatesWithVideo = () => {
    if (state.datesWithVideo === null) {
      VideoApi.getDatesWithVideo().then((payload) => {
        if (payload) {
          dispatch({
            type: DictionaryActionKind.DATES_WITH_VIDEO,
            payload: payload ? payload : null,
          });
        }
      });
    }
  };

  const loadAudiences = () => {
    PDAPI.getTargets()
      .then((payload) => {
        dispatch({ type: DictionaryActionKind.AUDIENCES, payload });
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error) => {
        PDAPI.getAudiences().then((payload) => {
          dispatch({ type: DictionaryActionKind.AUDIENCES, payload });
        });
      });
  };

  return (
    <AtdlContext.Provider
      value={{
        statistics: state.statistics,
        channels: state.channels,
        datesWithVideo: state.datesWithVideo,
        audiences: state.audiences,
        loadStatistics,
        loadChannels,
        loadDatesWithVideo,
        loadAudiences,
      }}
    >
      {children}
    </AtdlContext.Provider>
  );
};
