import React from 'react';

// @ts-ignore
import { ReactComponent as Down } from '../../assets/down.svg';
// @ts-ignore
import { ReactComponent as Up } from '../../assets/up.svg';
import { Metric } from '../../models';

export interface MetricProps {
  name: string;
  type: string;
  metric: Metric;
}

export function Metrics({ name, type, metric }: MetricProps) {
  return (
    <div key={type} className="stat-info">
      <div>{metric && metric.current < metric.prev ? <Down /> : <Up />}</div>
      <div>{name}</div>
      <div>{metric ? metric.current : 0}</div>
    </div>
  );
}
