import * as React from 'react';

import { RadioButtonGroup } from '@UI/RadioButtonGroup';
import { RadioButtonOption } from '@UI/RadioButtonGroup/RadioButton';
import { ScheduleCellTVEvent } from '@pages/schedule/models/scheduleCellPayloadTVEvent';
import { AudienceCompareGroup } from '@store/forms/FormStorage.types';

import { CellContentStats } from '../CellContentStats';

import s from './CellContentInline.module.scss';

export type CellContentInlineProps = {
  cellEntity: ScheduleCellTVEvent;
  cellHeight: number;
  options: RadioButtonOption<AudienceCompareGroup>[];
  value: RadioButtonOption<AudienceCompareGroup>;
  onChange: (value: RadioButtonOption<AudienceCompareGroup>) => void;
  areControlsVisible: boolean;
};

/**
 * Внутренний контент ячейки события
 */
const CellContentInline: React.FC<CellContentInlineProps> = ({
  cellEntity,
  cellHeight,
  options,
  value,
  onChange,
  areControlsVisible,
}: CellContentInlineProps) => {
  return (
    <div className={s['cell-content-inline']}>
      <CellContentStats
        cellEntity={cellEntity}
        areRatingsVisible={!areControlsVisible}
        cellHeight={cellHeight}
      />
      {areControlsVisible && (
        <span className={s['cell-content-inline__radio-buttons']}>
          <RadioButtonGroup
            onChange={onChange}
            value={value}
            options={options}
          />
        </span>
      )}
    </div>
  );
};

export default React.memo(CellContentInline);
