import React, { useEffect, useState } from 'react';
import { useDispatch, } from'react-redux';
import { useAuthContext } from '../../store/AuthProvider';

import { fetchCollectionTypes, fetchDemographics, fetchChannels, fetchConditionOptions, fetchAdvertsBrands, fetchAdvertsArticles } from '@store/collections';

import CollectionList from './crud/CollectionList';
import CollectionEdit from './crud/CollectionEdit';

/**
 * менеджер наборов
 * @returns 
 */
function Collections() {
  const [action, setAction] = useState('list');
  const [editId, setEditId] = useState(0);
  const [create, setCreate] = useState({});

  const { isLoggedIn } = useAuthContext();

  const dipatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dipatch(fetchCollectionTypes());
      dipatch(fetchDemographics());
      dipatch(fetchChannels());
      dipatch(fetchConditionOptions());
      dipatch(fetchAdvertsBrands());
      dipatch(fetchAdvertsArticles());
    }
  }, [dipatch, isLoggedIn]);

  const onEditHandle = (id) => {
    setEditId(id);
    setAction('edit');
  }

  const onCreateHandle = (parent, type) => {
    setCreate({
      parent,
      type
    });

    setAction('create');
  }

  const onListHandle = () => {
    setEditId(0);
    setAction('list');
  }

  return (
    <div className='mt-4'>
      { action === 'list' ? ( <CollectionList onEditHandle={onEditHandle} onCreateHandle={onCreateHandle} /> ) : '' }
      { action === 'edit' ? ( <CollectionEdit onListHandle={onListHandle} id={editId} /> ) : '' }
      { action === 'create' ? ( <CollectionEdit onListHandle={onListHandle} {...create} /> ) : '' }
    </div>
  );
}

export default Collections;