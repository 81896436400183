import React, { useEffect, useMemo } from "react";
import { useData } from "../DataProvider";
import MultiSelect from "../../MultiSelect";
import Common from '@utils/common';
import API from '@utils/AdminApi';

function Program() {

  const { program, setValue } = useData();

  const programsLoader = (id) => {
    return new Promise((resolve, reject) => {
      API.getProgramsOptions(id).then((data) => {
        if (!id) {
          resolve(data.map(option => ({ ...option, show: true })));
        } else {
          resolve(data);
        }
      }, (error) => {
        reject(error);
      })
    });
  }

  const programValue = useMemo(() => program ? program : [], [ program ]);

  return (
    <div className="form-control">
      <MultiSelect
        search={Common.searchProgram}
        loader={programsLoader}
        lazily={true}
        selected={programValue}
        single={false}
        line={true}
        placeholder="Выберите программу"
        onChange={(value) => setValue('program', value ? value : null)}
      />
    </div>
  );
}

export default Program;
