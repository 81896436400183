/**
 * Получить дату, которая была daysBack дней назад
 */
export const getDateBeforeToday = (daysBack: number): Date => {
  const date = new Date();

  date.setDate(date.getDate() - daysBack);

  return date;
};
