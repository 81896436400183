import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

// @ts-ignore
import { ReactComponent as Add } from '../../assets/add.svg';
import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useStatsContext } from '../../store/StatsProvider';
import { useVideosContext } from '../../store/VideosProvider';
import { VIDEO_HOST } from '../../utils/Env';
import { ChannelsModal } from '../VideoUI/ChannelsModal';

import { Stats } from './Stats';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

export interface VideoProps {
  index: number;
  stream?: string;
}

export function Video({ index }: VideoProps) {
  const player = useRef<ReactPlayer | null>(null);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { channels: dictChannels } = useDictionaryContext();

  const {
    videos,
    channels,
    setChannel,
    setVideo,
    setReady,
    stop,
    play,
    sound,
    setDuration: setMaxDuration,
    setPosition,
    seekTo,
    position,
    nextPeriod,
    speed,
  } = useVideosContext();

  const { videoData } = useStatsContext();

  const [duration, setDuration] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);

  const [loadSeek, setLoadSeekg] = useState<boolean>(false);

  const selectChannel = (value: number) => {
    setIsModalVisible(false);
    setChannel(value, index);
  };

  useEffect(() => {
    const _index = channels ? channels[index] : -1;

    if (videoData[_index] && videoData[_index] != null) {
      stop();

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setVideo(VIDEO_HOST + videoData[_index]!.url, index);
    } else {
      setVideo('', index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels, videoData]);

  useEffect(() => {
    if (player.current && seekTo >= 0) {
      player.current.seekTo(duration * seekTo);
    }
  }, [seekTo, duration]);

  useEffect(() => {
    if (player.current && loadSeek && position && duration) {
      player.current.seekTo(duration * position);
      setLoadSeekg(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSeek, duration]);

  const onReady = () => {
    setLoading(false);
    setReady(index);

    if (player.current) {
      setDuration(player.current.getDuration());
      setMaxDuration(player.current.getDuration(), index);
    }
  };

  const maxIndex = useMemo<number>(
    () =>
      videos.reduce(
        (value, current, index) => (current && index > value ? index : value),
        0,
      ),
    [videos],
  );

  const onProgress = (data: ReactPlayerProps) => {
    if (index === maxIndex && play) {
      setPosition(data.played);
    }
  };

  const onBuffer = () => {
    setLoading(true);
  };

  const onBufferEnd = () => {
    setLoading(false);
  };

  const onEnded = () => {
    stop();

    if (index === maxIndex) {
      nextPeriod();
    }
  };

  if (!channels[index]) {
    return (
      <div
        className="noise"
        style={{ backgroundImage: 'url(/img/white-noise.svg)' }}
      >
        {!!dictChannels && !!dictChannels.length && (
          <>
            <div onClick={() => setIsModalVisible(true)}>
              <Add />
            </div>
            <ChannelsModal
              isModalVisible={isModalVisible}
              handleCancel={() => setIsModalVisible(false)}
              handleSelect={selectChannel}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className="player"
      style={
        !videos[index] ? { backgroundImage: 'url(/img/white-noise.svg)' } : {}
      }
    >
      {!!videos[index] && (
        <ReactPlayer
          ref={player}
          playing={play}
          url={videos[index]}
          width="100%"
          height="100%"
          muted={sound !== index}
          onReady={onReady}
          progressInterval={1000}
          onProgress={onProgress}
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          onEnded={onEnded}
          config={{ file: { attributes: { playsInline: true } } }}
          playbackRate={speed}
        />
      )}
      {!!videos[index] && !!loading && (
        <div className="spinner">
          <Spin indicator={antIcon} />
        </div>
      )}
      <Stats index={index} />
    </div>
  );
}
