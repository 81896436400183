import locale from 'antd/es/date-picker/locale/ru_RU';
import moment, { Moment } from 'moment';
import React, { useMemo, useCallback } from 'react';

import DatePicker from '@components/DatePicker/DatePicker';
import { getTreeOptions } from '@models/utils';
import { useDictionaryContext } from '@store/DictionaryProvider';
import { useVideosContext } from '@store/VideosProvider';
import { getIntervals } from '@utils/index';

import MultiSelect from '../MultiSelect';

import 'moment/locale/ru';

export function Filters() {
  const { statistics, datesWithVideo, audiences } = useDictionaryContext();

  const {
    statistics: selected,
    audience,
    selectStatistics,
    selectAudience,
    date,
    interval,
    selectDate,
    selectInterval,
  } = useVideosContext();

  const options = useMemo<any[]>(
    () =>
      statistics === null
        ? []
        : statistics.map((stats) => ({
            label: stats.name,
            value: stats.type,
          })),
    [statistics],
  );

  const audienceOptions = useMemo(
    () => (audiences === null ? [] : getTreeOptions(audiences)),
    [audiences],
  );

  const selectedAudience = useMemo(
    () => (audience ? [audience] : []),
    [audience],
  );

  const dateSelected = useMemo(
    () => (date ? moment(date, 'YYYY-MM-DD').toDate() : null),
    [date],
  );

  const intervals = getIntervals();

  const selectedInterval = useMemo<string[]>(
    () => (interval ? [interval] : []),
    [interval],
  );

  const onDateChange = (value: Date | null) => {
    selectDate(value ? moment(value).format('YYYY-MM-DD') : null);
  };

  const onTimeChange = (value: string[]) => {
    selectInterval(value && value.length ? value[0] : null);
  };

  const disabledDate = useCallback(
    (currentDate: Moment) => {
      if (datesWithVideo !== null) {
        const date = currentDate.format('YYYY-MM-DDT00:00:00');

        return datesWithVideo.indexOf(date) === -1;
      }

      return false;
    },
    [datesWithVideo],
  );

  return (
    <div className="filters">
      <div className="filters-statistics">
        <div className="form-control">
          <label>Статистики</label>
          <MultiSelect
            options={options}
            selected={selected}
            onChange={selectStatistics}
            line={true}
          />
        </div>
      </div>
      <div className="filters-audience">
        <div className="form-control">
          <label>Аудитория</label>
          <MultiSelect
            options={audienceOptions}
            selected={selectedAudience}
            onChange={(value: any) =>
              selectAudience(value && value.length ? value[0] : null)
            }
            single={true}
          />
        </div>
      </div>
      <div className="filters-date">
        <DatePicker
          locale={locale}
          onChange={onDateChange}
          placeholder=""
          disabledDate={disabledDate}
          value={dateSelected}
          label="Дата"
        />
      </div>
      <div className="filters-interval">
        <div className="form-control">
          <label>Интервал</label>
          <MultiSelect
            onChange={onTimeChange}
            options={intervals}
            selected={selectedInterval}
            placeholder=""
            single={true}
          />
        </div>
      </div>
    </div>
  );
}
