import React, { useState, useEffect, useMemo} from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import API from '@utils/AdminApi';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { fetchChannels, fetchStatistics } from '@store/collections';
import DatePicker from '@components/DatePicker/DatePicker';
import Statistics from '@components/Sources/groups/Statistics';
import Channels from '@components/Sources/groups/Channels';
import Targets from '@components/Sources/groups/Targets';

import moment from 'moment';
import {useAuthContext} from "@store/AuthProvider";

function ChannelPage() {
  const [series, setSeries] = useState([]);
  const dispatch = useDispatch()

  const navigate = useNavigate();
  const location = useLocation();

  const [ date, setDate ] = useState(location.state.date? new Date(location.state.date) : new Date(new Date() - 4*24*60*60*1000));
  const [ statistics, setStatistics ] = useState();

  const [ target, setTarget ] = useState(location.state.target ?? null);

  const [ loading, setLoading ] = useState(false);

  const [ channel, setChannel ] = useState([location.pathname.split('/').slice(-1).pop()]);
  const { population: population_100 } = useAuthContext();

  const onClick = (e, target, statistics) => {
    navigate(e.point.id, {
      state: {
        target, statistics
      }
    });
  }

  const channelValue = useMemo(
    () => channel ? ['ch' + channel] : [],
    [channel],
  );

  const statisticsValue = useMemo(
    () => statistics ? statistics.map(({ type }) => type) : [],
    [statistics],
  );

  useEffect(() => {
    dispatch(
      fetchChannels()
    );

    dispatch(
      fetchStatistics('channels')
    );
  }, [ ]);

  const loadData = async () => {
    if (target && channel && channel.length && statistics && statistics.length) {
      setLoading(true);

      const base = await API.getCollection(target.id);

      const payload = {
        target_base: base.data,
        channels: channel,
        statistics: statistics,
        date: moment(date).format('YYYY-MM-DD 05:00:00'),
        timeband: null,
        population_100: population_100
      }

      setLoading(true);

      API.getBroadcastData(payload)
        .then((response) => {
          if (response) {
            let data = [];

            response.series[0].data.forEach(record => {
              const start = record.event_start.split(' ')[1];
              const end = record.event_end.split(' ')[1];
              const time = `${start} - ${end}`;
              const value = record['audience'] || record['tvr'] || record['share'];

              data.push({
                name: `${time} ${record.program}`,
                y: Math.round(value * 100) / 100,
                events: {
                  click: (e) => onClick(e, target, statistics)
                },
                id: `/programs/${record.program_id}/channel/${channel[0]}/issued/${record.piss_id}`});
            });

            setSeries(data);
          }

          setLoading(false);
        })
        .catch(() => {
          setSeries([]);
          setLoading(false);
        });
    }
  }

  useEffect( () => {
    loadData()
  }, [channelValue, date, statisticsValue, target])

  let options = useMemo(
    () => ({
      chart: {
        type: 'bar',
        height: 40 + (series ? series.length * 20 : 0),
      },
      title: {
        text: null,
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        },
        visible: false,
      },
      plotOptions: {
        bar: {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            color: '#716F6F',
            format: '{point.y}',
            align: 'left',
            style: {
              fontSize: '12px',
              fontFamily: 'Roboto',
              fontWeight: 600,
            },
          },
          colorByPoint: true,
          colors: ['#EBEFF6', '#D9E0ED'],
        },
        series: {
          pointPadding: 0,
          groupPadding: 0,
        },
      },
      series: [
        {
          data: series,
        },
      ],
    }),
    [ series ],
  );

  const targeValue = useMemo(
    () => (target ? [target.id] : []),
    [target],
  );

  return (
    <div>
      <div className="card-wrapper">
        <div className="card-title">
          <div className="title">Эфир</div>
        </div>
        <div className="card-content">
          <div className="filters-white-wrapper">
            <Targets
              name="target"
              value={targeValue}
              onChange={(value) => setTarget(value)}
              label="Сохраненный набор"
            />
          </div>

          <br />

          <div className="filters-white-wrapper">
            <Channels
              name="channels"
              isRequired={true}
              placeholder="Выберите канал"
              className="form-control"
              value={channelValue}
              onChange={setChannel}
              label="Канал"
              single={true}
              channelsOnly={true}
            />

            <DatePicker
              onChange={(value) => setDate(value ?? null)}
              placeholder="Выберите дату"
              value={date}
              format="DD.MM.YYYY"
              label="Дата"
            />

            <Statistics
              name="statistics"
              className="form-control"
              onChange={setStatistics}
              placeholder={'Выберите статистику'}
              label="Статистика"
              value={statisticsValue}
              single={true}
              source="channels"
              default={true}
            />
          </div>
        </div>
        <div className="chart-wrapper">
          {(loading || !!series) && (
            <div className="chart-wrapper">
              <br />
              <HighchartsReact highcharts={Highcharts} options={options} />
              {loading && (
                <div className="is-loading">
                  <div>Загрузка...</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChannelPage
