import React, { useContext, useReducer } from "react"

const AudienceSampleContext = React.createContext();

export const useAudienceSample = () => {
  return useContext(AudienceSampleContext);
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'context':
      return { ...state, context: action.context }

    default:
      return state;
  }
}

export const AudienceSampleProvider = ({ children, available }) => {
  const [state, dispatch] = useReducer(reducer, {
    available,
    context: {},
    setContext: (context) => {},
  });

  const setContext = (context) => dispatch({ type: 'context', context });

  return (
    <AudienceSampleContext.Provider value={{
      available: state.available,
      context: state.context,
      setContext,
    }}>
      { children }
    </AudienceSampleContext.Provider>
  )
}