import * as React from 'react';

import { BaseIconProps } from '../commonProps';

export type IconCrossProps = BaseIconProps;

/**
 * Иконка закрытия (крестик)
 */
const IconCross: React.FC<IconCrossProps> = (props: IconCrossProps) => {
  return (
    <svg
      {...props}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L5 3.93934L8.71967 0.21967C9.01256 -0.0732233 9.48744 -0.0732233 9.78033 0.21967C10.0732 0.512563 10.0732 0.987437 9.78033 1.28033L6.06066 5L9.78033 8.71967C10.0732 9.01256 10.0732 9.48744 9.78033 9.78033C9.48744 10.0732 9.01256 10.0732 8.71967 9.78033L5 6.06066L1.28033 9.78033C0.987437 10.0732 0.512563 10.0732 0.21967 9.78033C-0.0732233 9.48744 -0.0732233 9.01256 0.21967 8.71967L3.93934 5L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(IconCross);
