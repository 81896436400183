import React, { useEffect, useState } from 'react';
import { ReactComponent as OkStatus } from './assets/ok-status.svg';
import { ReactComponent as AttentionStatus } from './assets/attention-status.svg';
import { useDispatch } from 'react-redux';
import { setGlobalStatus } from '@store/status';

import API from '@utils/AdminApi';
import './Sidebar.scss';

export function Status() {
  const [status, setStatus] = useState(undefined);
  const [data, setData] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    const getStatus = async () => {
      const response = await API.getStatus();
      setData(response?.data);
      dispatch(setGlobalStatus(response?.data));
    };

    getStatus();
  }, []);

  useEffect(() => {
    const today = new Date();

    if (data) {
      const minDate = data.sort(
        (a, b) => new Date(a.date) - new Date(b.date),
      )[0].date;

      if (today - new Date(minDate) >= 4 * 24 * 3600000) {
        setStatus(false);
      } else {
        setStatus(true);
      }
    }
  }, [data]);
  let mapped_names = {
    'FastPrefact': 'Быстрый',
    'Prefact': 'Префакт',
    'Fact': 'Факт',
    'Mediascope': 'Медиаскоп',
    'Programs': 'Передачи',
    'TotalTVA': 'TotalTVA'
  }
  const text = status === undefined
      ? 'загружается'
      : data?.map((item) => (
          <span key={item.table}>
            {mapped_names[item.table]} - {item.date}
          </span>
        ));

  const showComment = () => {
    document.querySelector('.status-comment').classList.remove('hidden');
  };

  const hideComment = () => {
    document.querySelector('.status-comment').classList.add('hidden');
  };

  return (
    <div className="status-container">
      <div className={`status-comment ${status !== undefined && 'hidden'}`}>
        <div className="status-text">{text}</div>
        <div className="status-rectangle"></div>
      </div>

      <div
        className="sidebar-status"
        onMouseOver={showComment}
        onMouseOut={hideComment}
      >
        {status !== undefined && (status ? <OkStatus /> : <AttentionStatus />)}
      </div>
    </div>
  );
}
