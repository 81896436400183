import { useMemo } from 'react';

import { TabFilled, TabTransparent, TabView } from '@UI/TabBar';
import { TabBarProps } from '@UI/TabBar/TabBar';
import { OptionEntity } from '@models/optionEntity';

import { useScheduleTableContext } from '../stores';
import { getAudienceOptionEntity, getDateOptionEntity } from '../utils';

type TabBarOptions = {
  audienceTabBarParams: TabBarProps<OptionEntity>;
  datesTabBarParams: TabBarProps<OptionEntity>;
};

/**
 * Конвертирует и мемоизирует опции таббара из выбранных опций фильтров таблицы.
 * Также подготавливает набор пропсов для TabBar
 */
export const useTabBarOptions = (): TabBarOptions => {
  const {
    value: { filters },
    actions,
  } = useScheduleTableContext();

  const {
    setActiveTargetAudience,
    removeTargetAudience,
    setActiveDate,
    removeDate,
  } = actions;

  const audiencesTabBarOptions = useMemo(
    () => filters.targetAudiences.map(getAudienceOptionEntity),
    [filters.targetAudiences],
  );

  const activeAudienceTabBarOption = useMemo(
    () =>
      filters.activeTabOption.targetAudience
        ? getAudienceOptionEntity(filters.activeTabOption.targetAudience)
        : null,
    [filters.activeTabOption.targetAudience],
  );

  const datesTabBarOptions = useMemo(
    () => filters.dates.map(getDateOptionEntity),
    [filters.dates],
  );

  const activeDateTabBarOption = useMemo(
    () =>
      filters.activeTabOption.date
        ? getDateOptionEntity(filters.activeTabOption.date)
        : null,
    [filters.activeTabOption.date],
  );

  const audienceTabBarParams = useMemo(() => {
    return {
      tabView: TabView.filled,
      options: audiencesTabBarOptions,
      value: activeAudienceTabBarOption,
      onClose: removeTargetAudience,
      onChange: setActiveTargetAudience,
      TabComponent: TabTransparent,
    };
  }, [
    audiencesTabBarOptions,
    activeAudienceTabBarOption,
    removeTargetAudience,
    setActiveTargetAudience,
  ]);

  const datesTabBarParams = useMemo(() => {
    return {
      tabView: TabView.transparent,
      options: datesTabBarOptions,
      value: activeDateTabBarOption,
      onClose: removeDate,
      onChange: setActiveDate,
      TabComponent: TabFilled,
    };
  }, [datesTabBarOptions, activeDateTabBarOption, removeDate, setActiveDate]);

  return {
    audienceTabBarParams,
    datesTabBarParams,
  };
};
