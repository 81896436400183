import cn from 'classnames';
import * as React from 'react';

import {
  DEFAULT_INTERVAL_SPLIT_STEP,
  MINUTE_TO_PX_SCALE,
  TIME_BITE_STUB_HEIGHT as STUB_HEIGHT,
} from '@pages/schedule/models/constants';
import { ScheduleCellTimeSlot } from '@pages/schedule/models/scheduleCellPayloadTimeSlot';

import s from './CellContentTimeSlot.module.scss';

export type CellContentTimeSlotProps = {
  cellEntity: ScheduleCellTimeSlot;
  className?: string;
};

const TIME_BITE_CELL_HEIGHT = DEFAULT_INTERVAL_SPLIT_STEP * MINUTE_TO_PX_SCALE;

const TIME_BITE_STUB_HEIGHT = STUB_HEIGHT * MINUTE_TO_PX_SCALE;

/**
 * Контент ячейки для отображения временного слота
 */
const CellContentTimeSlot: React.FC<CellContentTimeSlotProps> = ({
  cellEntity,
  className,
}: CellContentTimeSlotProps) => {
  const { timeBites, timeBiteIds } = cellEntity.payload;

  // флаг цепочки рендера тайм-стабов
  let isRenderedStubInSeries = true;

  return (
    <>
      {timeBiteIds.map((timeBiteId, index) => {
        const currentTimeBite = timeBites[timeBiteId];

        if (!currentTimeBite.hidden) {
          isRenderedStubInSeries = false;

          const isNextStub = timeBites[timeBiteIds[index + 1]]?.hidden;

          return (
            <div
              className={cn(
                s['schedule-table-cell-timeslot'],
                isNextStub && s['schedule-table-cell-timeslot_before-stub'],
                className,
              )}
              style={{ height: `${TIME_BITE_CELL_HEIGHT}px` }}
              key={timeBiteId}
            >
              {timeBiteId}
            </div>
          );
        }

        if (!isRenderedStubInSeries) {
          isRenderedStubInSeries = true;

          return (
            <div
              className={cn(s['schedule-table-cell-timestub'], className)}
              style={{ height: `${TIME_BITE_STUB_HEIGHT}px` }}
              key={timeBiteId}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default CellContentTimeSlot;
