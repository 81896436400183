import { useRef, useEffect } from 'react';

function Input({ value, path, onEdit, onDone }) {

  const wrapperRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onDone && onDone();
      }
    }

    const handleKeys = (event) => {
      if (event.keyCode === 27) {
        onDone && onDone();
      }

      if (event.keyCode === 13) {
        onDone && onDone();
      }
    };

    document.addEventListener('mousedown', (event) => handleClickOutside(event));
    document.addEventListener('keydown', (event) => handleKeys(event));

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event));
      document.removeEventListener('keydown', (event) => handleKeys(event));
    }
  }, []);

  return (
    <>
      {path}
      <input
        className="label-edit"
        autoFocus
        ref={wrapperRef}
        value={value}
        onChange={event => onEdit && onEdit(event.target.value)}
      />
    </>
  );
}

export default Input;