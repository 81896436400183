/* eslint-disable */
import React, { Component } from 'react';

import './Header.scss';

import UserBlock from '../UserBlock/UserBlock';
import Population from '../Population/Population';
import { ReactComponent as Logo } from './assets/Logo_text_black.svg';

class Header extends Component {
  render() {
    return (
      <header className="header">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col small-col">
              <div className="header-logo">
                <Logo />
              </div>
            </div>
            <div className="col">
              <div>
                <Population />
              </div>
            </div>
            <div className="col text-right">
              <UserBlock />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
