import React from 'react';
import { useVideosContext } from '../../store/VideosProvider';

// @ts-ignore
import { ReactComponent as Button } from '../../assets/circle.svg';

export function SpeedButton() {

  const {
    speed,
    speedUp,
  } = useVideosContext();

  return (
    <div className="speed-button">
      <span onClick={() => speedUp()}>{ speed }x</span>
      <Button onClick={() => speedUp()} />
    </div>
  );
}
