import React from 'react';

import { ReactComponent as CircleCancel } from '../../CompositionUI/UI/images/circle-cancel.svg';
import { ReactComponent as CircleOk } from '../../CompositionUI/UI/images/circle-ok.svg';
import { ReactComponent as Delete } from '../../CompositionUI/UI/images/delete.svg';

import '../../CompositionUI/UI/Buttons.scss';

export function OkIcon(props) {
  return (
    <div className="icon-button" disabled={props.disabled}>
      <CircleOk {...props}></CircleOk>
    </div>
  );
}

export function CancelIcon(props) {
  return (
    <div className="icon-button" disabled={props.disabled}>
      <CircleCancel {...props}></CircleCancel>
    </div>
  );
}

export function ClearIcon(props) {
  return (
    <div className="icon-button" disabled={props.disabled}>
      <Delete {...props}></Delete>
    </div>
  );
}