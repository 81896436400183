
export const changeName = (data, id, name) => {
  data?.forEach(value => {
    if (value.id === id) {
      value.name = name;
      return;
    }

    changeName(value.children, id, name);
  });
}

export const addGroup = (data, group) => {
  data?.forEach(value => {
    if (value.id === group.parent) {
      value.children.push(group);
      return;
    }

    if (value.children) {
      addGroup(value.children, group);
    }
  });
}

export const deleteItem = (data, id) => {
  data?.children?.forEach((value, index) => {
    if (value.id === id) {
      data.children.splice(index, 1);
      return;
    }
    

    deleteItem(value, id);
  });
}

export const spliceElement = (data, id) => {
  let result;

  if (Array.isArray(data)) {
    data?.forEach((el, index) => {
      if (el.id === id) {
        data.splice(index, 1);
        result = {...el};
      }

      if (el.children) {
        const element = spliceElement(el.children, id);

        if (element) {
          result = element;
        }
      }
    });
  }

  return result;
}

export const appendElement = (data, parent, element) => {
  if (Array.isArray(data)) {
    data?.forEach(el => {
      if (el.id === parent && el.is_group) {
        if (!el.children) {
          el.children = [];
        }

        el.children.push(element);

        return;
      }

      if (el.children) {
        appendElement(el.children, parent, element);
      }
    });
  }
}

export const setFlag = (data, id) => {
  if (Array.isArray(data)) {
    data?.forEach(el => {
      el.flag = el.id === id;

      if (el.children?.length) {
        setFlag(el.children, id);
      }
    });
  }
}