import { ReactComponent as Share } from './assets/share.svg';
import { ReactComponent as Shared } from './assets/shared.svg';

function ShareIcon(props) {
  return (
    <div {...props} className="icon-button">
      <Share></Share>
    </div>
  );
}

function SharedIcon(props) {
  return (
    <div {...props} className="icon-button">
      <Shared></Shared>
    </div>
  );
}

export { ShareIcon, SharedIcon };