import cn from 'classnames';
import * as React from 'react';

import { RadioButton } from './RadioButton';
import s from './RadioButtonGroup.module.scss';
import { RadioButtonGroupProps } from './RadioButtonGroup.types';

function RadioButtonGroup<Value extends string | number>({
  options,
  onChange,
  value,
  className,
}: RadioButtonGroupProps<Value>) {
  return (
    <div className={cn(s['radio-button-group'], className)}>
      {options.map((option) => (
        <RadioButton<Value>
          key={option.value}
          value={option}
          onChange={onChange}
          checked={value.value === option.value}
          baseColor={option.baseColor}
          className={s['radio-button-group__button']}
        />
      ))}
    </div>
  );
}

export default React.memo(RadioButtonGroup) as typeof RadioButtonGroup;
