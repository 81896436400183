import { LoadingOutlined } from '@ant-design/icons';
import cn from 'classnames';
import * as React from 'react';

import { ScheduleCellEntity } from '@pages/schedule/models/scheduleCellEntity';

import { useScheduleTableColumnsContext } from '../../stores/ScheduleTableColumnsContext';

import { ScheduleTableColumn } from './ScheduleTableColumn';
import { ScheduleTableHeader } from './ScheduleTableHeader';
import { ScheduleTableHeaderCell } from './ScheduleTableHeader/ScheduleTableHeaderCell';
import s from './ScheduleTableLayout.module.scss';

type ScrollHandler = (scrollPosition: number) => void;

export type ScheduleTableLayoutProps = {
  isLoading: boolean;
  fixedColumnIds: string[];
  scrolledColumnIds: string[];
  cellEntities: Record<string, ScheduleCellEntity>;
  className?: string;
};

/**
 * Разметка расписания
 */
const ScheduleTableLayout: React.FC<ScheduleTableLayoutProps> = ({
  scrolledColumnIds,
  fixedColumnIds,
  cellEntities,
  isLoading,
  className,
}: ScheduleTableLayoutProps) => {
  const [scrollXHandler, setScrollXHandler] =
    React.useState<ScrollHandler | null>(null);

  const { columnsContainerRef } = useScheduleTableColumnsContext();

  const handleScrollXColumns: React.UIEventHandler<HTMLDivElement> =
    React.useCallback(
      (event) => {
        if (!event.target || !scrollXHandler) {
          return;
        }

        scrollXHandler((event.target as HTMLDivElement).scrollLeft);
      },
      [scrollXHandler],
    );

  if (
    !isLoading &&
    (!scrolledColumnIds.length ||
      !fixedColumnIds.length ||
      !Object.keys(cellEntities).length)
  ) {
    return (
      <div
        className={cn(
          s['layout-container'],
          s['layout-container_no-data'],
          s['layout-container_full-height'],
          className,
        )}
      >
        Нет данных
      </div>
    );
  }

  return (
    <div
      className={cn(
        s['layout-container'],
        isLoading && s['layout-container_full-height'],
        className,
      )}
    >
      {isLoading && (
        <div className={s['loader']}>
          <LoadingOutlined className={cn(s['loader__icon'])} spin />
        </div>
      )}
      <ScheduleTableHeader setScrollHandler={setScrollXHandler}>
        {(scrolledColumnsRef) => (
          <div
            className={cn(
              s['schedule-table-layout'],
              s['schedule-table-layout_header'],
            )}
          >
            <div
              className={cn(
                s['schedule-table-layout__columns'],
                s['schedule-table-layout__columns_fixed'],
              )}
            >
              {fixedColumnIds.map((columnId: string) => (
                <ScheduleTableHeaderCell key={columnId} columnId={columnId} />
              ))}
            </div>
            <div className={s['schedule-table-layout__header-columns-wrapper']}>
              <div
                className={cn(
                  s['schedule-table-layout__columns'],
                  s['schedule-table-layout__columns_content'],
                )}
                ref={scrolledColumnsRef}
              >
                {scrolledColumnIds.map((columnId: string) => (
                  <ScheduleTableHeaderCell key={columnId} columnId={columnId} />
                ))}
              </div>
            </div>
          </div>
        )}
      </ScheduleTableHeader>
      <div ref={columnsContainerRef} className={cn(s['schedule-table-layout'])}>
        <div
          className={cn(
            s['schedule-table-layout__columns'],
            s['schedule-table-layout__columns_fixed'],
          )}
        >
          {fixedColumnIds.map((columnId: string) => (
            <ScheduleTableColumn key={columnId} columnId={columnId} />
          ))}
        </div>
        <div
          className={cn(
            s['schedule-table-layout__columns'],
            s['schedule-table-layout__columns_content'],
            s['schedule-table-layout__columns_content-scrolled'],
          )}
          onScroll={handleScrollXColumns}
        >
          {scrolledColumnIds.map((columnId: string) => (
            <ScheduleTableColumn key={columnId} columnId={columnId} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleTableLayout;
