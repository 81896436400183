import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tabs } from 'antd';
import Timebands from '@components/Sources/groups/Timebands'
import DateRange from '@components/Sources/groups/DateRange';
// import { Radio } from 'antd';

function DemographicsFilters({ onChange, defaultValue, queryData }) {

  const [ data, setData ] = useState({});

  const inited = useRef(false);

  const setValue = (field, value) => {
    const timeband = timebandValue.length ? timebandValue[0] : null;

    let newData = {
      timeband,
      premier,
      period
    };

    newData[field] = value;

    setData(newData);

    onChange && onChange(newData);
  }

  const timebandValue = useMemo(() => data.timeband && data.timeband.id ? [ data.timeband.id ] : [], [ data ]);

  const premier = useMemo(() => data.premier ?? "all", [ data ]);

  const period = useMemo(() => data.period ?? "", [ data ]);
/*
  const options = [{
    label: 'Все',
    value: 'all',
  }, {
    label: 'Премьеры',
    value: 'premier',
  }, {
    label: 'Повторы',
    value: 'repeat',
  }];
*/
  useEffect(() => {
    if (!inited.current && defaultValue) {
      inited.current = true;

      setData(defaultValue);
    }
  }, [ defaultValue ]);

  useEffect(() => {
    setValue('period', {
      endDate: queryData.toDate,
      key: 'selection',
      startDate: queryData.fromDate,
    });
  }, [ queryData ]);

  return (
    <div className="demographics-filters-wrapper">
      <Tabs>
        <Tabs.TabPane tab="Диапазон дат" key="period">
          <div className="two-columns">
            <div>
              <DateRange
                onChange={(value) => setValue('period', value)}
                placeholder="Выберите диапазон дат"
                value={period}
              />
            </div>

            <div>
              <Timebands
                placeholder="Таймбэнд"
                className="form-control"
                onChange={(value) => setValue('timeband', value ? value[0] : null)}
                value={timebandValue}
                single={true}
              />
            </div>
          </div>
{/*
          <div className="premier-wrapper">
            <Radio.Group options={options} onChange={({ target: { value }}) => setValue('premier', value)} value={premier} />
          </div>
  */}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default DemographicsFilters;