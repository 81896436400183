import { Meta } from '@models/meta';
import common from '@utils/common';

import { createInitialScheduleTableData } from '../models/scheduleTableData';
import { ScheduleTableTabData } from '../models/scheduleTableTabsStructure';
import { useScheduleTableContext } from '../stores';

const createScheduleTableTabErrorData = (): ScheduleTableTabData => {
  return {
    meta: Meta.error,
    data: createInitialScheduleTableData(),
  };
};

/**
 * Извлекает данные о колонках таблицы для выбранной даты и аудитории
 */
export const useCurrentTableTabData = (): ScheduleTableTabData => {
  const {
    value: { tableData, filters },
  } = useScheduleTableContext();

  const {
    activeTabOption: { targetAudience, date },
  } = filters;

  if (!date) {
    return createScheduleTableTabErrorData();
  }

  const currentTabData =
    tableData?.[common.toTVDate(date, true)]?.[targetAudience?.name || ''];

  return currentTabData || createScheduleTableTabErrorData();
};
