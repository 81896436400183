import { Collection } from '@models/index';
import { findCollectionByValue } from '@models/utils';

import { CHANNEL_PREFIX } from '../constants';

const isChannel = (value: any): value is string =>
  typeof value === 'string' && value.startsWith(CHANNEL_PREFIX);

const isChannelPreset = (value: any): value is number =>
  typeof value === 'number';

const getChannelId = (value: string): number =>
  parseInt(value.substring(CHANNEL_PREFIX.length));

/**
 * Функция формирует список id каналов и наборов каналов
 * из смешанного списка id каналов и наборов (selectedValues имеет вид ['ch1859', 'ch1952', 15])
 * 'ch1859' -> 1859 (канал)
 * 15 -> { id: 15, name: '', is_group: false, parent: null, type: 2, content: {...} } (набор)
 */
export const prepareSelectedChannelValues = (
  selectedValues: (string | number)[],
  collections: Collection[],
): (number | Collection)[] => {
  const channels: (number | Collection)[] = [];

  selectedValues.forEach((value) => {
    if (isChannel(value)) {
      channels.push(getChannelId(value));

      return;
    }

    if (isChannelPreset(value)) {
      const collection = findCollectionByValue(collections, 'id', value);

      if (collection) {
        channels.push(collection);
      }
    }
  });

  return channels;
};
