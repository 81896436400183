import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { RadioButtonGroup } from '@UI/RadioButtonGroup';
import { RadioButtonOption } from '@UI/RadioButtonGroup/RadioButton';
import { ROUTES } from '@config/routes';
import { ScheduleCellTVEvent } from '@pages/schedule/models/scheduleCellPayloadTVEvent';
import { AudienceCompareGroup } from '@store/forms/FormStorage.types';
import formatPercent from '@utils/formatPercent';

import s from './CellContentTooltip.module.scss';

export type CellContentTooltipProps = {
  cellEntity: ScheduleCellTVEvent;
  options: RadioButtonOption<AudienceCompareGroup>[];
  value: RadioButtonOption<AudienceCompareGroup>;
  onChange: (value: RadioButtonOption<AudienceCompareGroup>) => void;
};

/**
 * Контент тултипа ячейки события
 */
const CellContentTooltip: React.FC<CellContentTooltipProps> = ({
  cellEntity,
  options,
  value,
  onChange,
}: CellContentTooltipProps) => {
  const { programId, channelId, pissId, program, tvr, share } =
    cellEntity.payload;

  return (
    <div className={s['cell-content-tooltip']}>
      <div className={s['cell-content-tooltip__item']}>
        <Link
          to={ROUTES.programs.detail.channel.detail.issued.detail.createRoute(
            programId,
            channelId,
            pissId,
          )}
          target="_blank"
          className={s['cell-content-tooltip__link']}
        >
          {program}
        </Link>
      </div>
      <div className={s['cell-content-tooltip__item']}>
        TVR {formatPercent(tvr)}% SHR {formatPercent(share)}%
      </div>
      <RadioButtonGroup
        onChange={onChange}
        value={value}
        options={options}
        className={cn(
          s['cell-content-tooltip__item'],
          s['cell-content-tooltip__radio-buttons'],
        )}
      />
    </div>
  );
};

export default React.memo(CellContentTooltip);
