import { colorRangeConfig, FALLBACK_COLOR } from '../config/tableRangeColors';
import { Range } from '../models/range';

export const getColorByRange = (itemValue?: Range): string => {
  if (!itemValue) {
    return FALLBACK_COLOR;
  }

  const rangeItemIndex = colorRangeConfig.findIndex((range) => {
    return range.value.min <= itemValue.min && range.value.max >= itemValue.max;
  });

  return colorRangeConfig[rangeItemIndex]?.color || FALLBACK_COLOR;
};
