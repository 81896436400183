import cn from 'classnames';
import * as React from 'react';

import { OptionEntity } from '@models/optionEntity';

import { Tab, TabProps } from '../Tab';

import s from './TabTransparent.module.scss';

type TabTransparentProps = TabProps<OptionEntity>;

const TabTransparent: React.FC<TabTransparentProps> = (props) => {
  return (
    <Tab
      {...props}
      className={cn(
        s['tab-transparent'],
        props.chosen && s['tab-transparent_chosen'],
      )}
      value={props.value}
    />
  );
};

export default TabTransparent;
