import { useMemo } from 'react';
import { getId } from './utils';

function SearchLabel({ path, keyField, labelField, addItem }) {

  const sep = '»';

  const parts = useMemo(() => {
    if (path && path.length) {
      const _path = [];

      return path.map(entry => {
        _path.push(entry);

        return {
          id: getId(_path, keyField),
          name: entry.name,
          path: [..._path]
        }
      });
    }

    return [];
  }, [ path ]);

  return (
    <div className="multiselect-options-option">
      { parts.map((part, index) => <>
        { index !==  0 ? <div key={`sep-${part.id}`} style={{paddingLeft: 0, paddingRight: 0}}>{ sep }</div> : <></> }
        <div key={part.id} onClick={() => addItem(part.path)}>{ part[labelField] }</div>
      </> )}
    </div>
  );
}

export default SearchLabel;