/* eslint-disable */
import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchCollections,
  fetchChannels,
  fetchStatistics,
} from '@store/collections';
import { useAuthContext } from '@store/AuthProvider';

import Channels from '@components/Sources/groups/Channels';
import Statistics from '@components/Sources/groups/Statistics';
import Targets from '@components/Sources/groups/Targets';

/**
 * условие для каналов
 * @param {*} props
 * @returns
 */
function Channel(props) {
  const { isLoggedIn } = useAuthContext();

  const onChange = useCallback(
    (val, field) => {
      const newValue = { ...(props.value ?? {}), [field]: val };

      if (props.onChangeHandle) {
        props.onChangeHandle(props.field, '', newValue);
      }
    },
    [props.value, props.field],
  );

  const targetBaseValue = useMemo(
    () =>
      props.value && props.value.targetBase ? [props.value.targetBase.id] : [],
    [props.value],
  );

  const channelsValue = useMemo(
    () =>
      props.value && props.value.channels
        ? props.value.channels.map((channel) =>
            channel.content ? channel.id : 'ch' + channel,
          )
        : [],
    [props.value],
  );

  const statisticsValue = useMemo(
    () =>
      props.value && props.value.statistics
        ? props.value.statistics.map(({ type }) => type)
        : [],
    [props.value],
  );


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCollections());

    dispatch(fetchChannels());

    dispatch(fetchStatistics());
  }, [dispatch, isLoggedIn]);

  return (
    <div>
      <div className="pdcard-filters">
        <Targets
          name="targetBase"
          value={targetBaseValue}
          onChange={(value) => onChange(value, 'targetBase')}
        />

        <div className="two-columns">
          <Channels
            name="channels"
            isRequired={true}
            placeholder="Выберите канал"
            className="form-control"
            value={channelsValue}
            onChange={(value) => onChange(value, 'channels')}
          />

          <Statistics
            name="statistics"
            placeholder="Выберите статистику"
            className="form-control"
            single={true}
            value={statisticsValue}
            onChange={(value) => onChange(value, 'statistics')}
          />
        </div>
      </div>
    </div>
  );
}

export default Channel;
