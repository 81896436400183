import cn from 'classnames';
import * as React from 'react';

import s from './ContentLayout.module.scss';

export type ContentLayoutProps = {
  title: string;
  children: React.ReactNode | React.ReactNode[];
};

function ContentLayout({ children, title }: ContentLayoutProps) {
  return (
    <section className={s['content-layout']}>
      <h4 className={s['content-layout__title']}>{title}</h4>
      {React.Children.map(children, (child, index) => (
        <div
          key={index}
          className={cn(
            s['content-layout__child'],
            index === 0 && s['content-layout__child_no-grow'],
          )}
        >
          {child}
        </div>
      ))}
    </section>
  );
}

export default ContentLayout;
