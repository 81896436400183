import cn from 'classnames';
import * as React from 'react';

import { BaseButton } from '@UI/BaseButton';
import { IconCross } from '@UI/icon/IconCross';
import { OptionEntity } from '@models/optionEntity';

import s from './Tab.module.scss';

export type TabProps<Value extends OptionEntity> = {
  value: Value;
  chosen: boolean;
  className?: string;
  onClick: (option: Value) => void;
  onClose: (option: Value) => void;
  onMouseEnter?: (option: Value) => void;
  onMouseLeave?: (option: Value) => void;
};

const Tab: React.FC<TabProps<OptionEntity>> = ({
  value,
  chosen,
  className,
  onClick,
  onClose,
  onMouseEnter,
  onMouseLeave,
}) => {
  const handleClose = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClose(value);
    },
    [onClose, value],
  );

  return (
    <BaseButton
      className={cn(className, s['tab'])}
      onClick={() => onClick(value)}
      onMouseEnter={onMouseEnter && (() => onMouseEnter(value))}
      onMouseLeave={onMouseLeave && (() => onMouseLeave(value))}
    >
      <span className={cn(s['tab__inner'], chosen && s['tab__inner_chosen'])}>
        {value.children}
      </span>
      <div className={s['tab__close-btn']} onClick={handleClose}>
        <IconCross className={s['tab__close-icon']} />
      </div>
    </BaseButton>
  );
};

export default Tab;
