import React from "react";
import { Tabs } from 'antd';
import { useData } from "./DataProvider";
import Program from "./tabs/Program";
import Channel from "./tabs/Channel";
import Issued from "./tabs/Issued";
import Date from "./tabs/Date";
import Period from "./tabs/Period";

function Component({ programOnly = false }) {

  const { filter, source, setSource, setFilter } = useData();

  return (
    <div>
      { !programOnly && <Tabs className="top-tabs" onChange={setSource} activeKey={source}>
        <Tabs.TabPane tab="Программа" key="program">
          <Program />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Канал" key="channel">
          <Channel />
        </Tabs.TabPane>
      </Tabs> }

      { !programOnly && <hr className="split" /> }

      <Tabs onChange={setFilter} activeKey={filter}>
        { source == 'program' &&
          <Tabs.TabPane tab="Передача" key="issued">
            <Issued />
          </Tabs.TabPane>
        }

        <Tabs.TabPane tab="Дата выхода" key="date">
          <Date />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Диапазон дат" key="period">
          <Period />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Component;