import { Collection, Condition } from '@models/index';

import { ApiTVTimeIntervalEntity } from '../models/apiScheduleEntities';

const FALLBACK_TIME_INTERVAL: ApiTVTimeIntervalEntity[] = [['05:00', '28:59']];

/**
 * @param timebandCondition объект коллекции, описывающий таймбенд
 * @returns массив тайм-интервалов [['05:00', '28:59'], ['05:00', '28:59'], ...]
 */
export const getTimebandIntervals = (
  timebandCondition: Collection | null,
): ApiTVTimeIntervalEntity[] => {
  const timeIntervalCondition = (
    timebandCondition?.content?.data as Condition[]
  )?.[0].value;

  if (!timebandCondition || !timeIntervalCondition) {
    return FALLBACK_TIME_INTERVAL;
  }

  return [timeIntervalCondition as ApiTVTimeIntervalEntity];
};
