/* eslint-disable */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useData } from '../DataProvider';
import API from '@utils/AdminApi';
import Timebands from '../../Sources/groups/Timebands';
import DatePicker from '@components/DatePicker/DatePicker';
import Premier from './Premier';
// todo KTS replace moment with date-fns. Moment.js is deprecated and high weight
// todo KTS analyze JS bundle size
import moment from 'moment';

moment.locale('ru');

function Date() {
  const { source, date, timeband, program, setValue } = useData();

  const [availableDates, setAvailableDates] = useState(null);

  const timebandValue = useMemo(
    () => (timeband ? [timeband.id] : []),
    [timeband],
  );

  useEffect(() => {
    if (program && program.length) {
      let update = true;

      const promises = [];

      program.forEach(({value}) => {
        promises.push(API.getProgramIssuedDates(value));
      });

      Promise.all(promises).then(
        data => {
          if (update) {
            const dates = program.reduce((all, row, index) => {
              return [...all, ...data[index]];
            }, []);

            setAvailableDates(dates);
          }
        },
        () => update && setAvailableDates(null)
      );

      return () => (update = false);
    }
  }, [program]);

  const disabledDates = useCallback(
    (currentDate) => {
      if (source !== 'channel' && availableDates !== null) {
        const date = moment(currentDate).format('YYYY-MM-DD');
        return availableDates.indexOf(date) === -1;
      }

      return false;
    },
    [availableDates, source],
  );

  const selecteDate = useMemo(() => (date ? date.toDate() : null), [date]);

  return (
    <>
      <DatePicker
        onChange={(value) => setValue('date', value ? moment(value) : null)}
        disabledDay={disabledDates}
        value={selecteDate}
      />

      <Timebands
        placeholder="Таймбэнд"
        className="form-control"
        onChange={(value) => setValue('timeband', value ? value[0] : null)}
        value={timebandValue}
        single={true}
      />

      <Premier />
    </>
  );
}

export default Date;
