// todo KTS add props typings
/* eslint react/prop-types: 0 */
import { FormControl, FormLabel } from '@chakra-ui/react';
import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import MultiSelect from '@components/MultiSelect';
import { getTreeIds } from '@components/Sources/groups/Utils';
import { getTreeOptions } from '@models/utils';

import { useFilterSelectedValues } from '../../hooks';

function Targets({
  value,
  onChange,
  label,
  className,
  single,
  oneLine,
  isRequired,
}) {
  const { selectedValues, setSelectedValues } = useFilterSelectedValues(value);

  const types = useSelector((state) => state.collections.collections);

  const collections = useMemo(() => {
    const data = types.filter((entry) => entry.type === 1);

    return data.length ? data[0].children : [];
  }, [types]);

  const options = useMemo(() => getTreeOptions(collections), [collections]);

  const handleBlur = useCallback(
    (selectedValues) => {
      if (onChange) {
        const targetAudiences = getTreeIds(selectedValues, collections);

        onChange(targetAudiences.length ? targetAudiences : []);
      }
    },
    [onChange, collections],
  );

  return (
    <FormControl
      isRequired={isRequired ?? false}
      className={className ?? 'form-control'}
    >
      {!!label && <FormLabel>{label}</FormLabel>}

      <MultiSelect
        selected={selectedValues}
        placeholder="Выберите сохраненный набор"
        onChange={setSelectedValues}
        onBlur={handleBlur}
        options={options}
        path={true}
        single={single ?? true}
        line={oneLine ?? false}
      />
    </FormControl>
  );
}

export default Targets;
