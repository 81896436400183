import { useState } from 'react';

import Sources from '@components/Sources/Sources';

import DataTable from './DataTable';

import './Tables.scss';

/**
 * Фильтры и таблица результатов на странице "Мои отчеты"
 */
function Tables() {
  // todo KTS typings for filters and related components
  const [payload, setPayload] = useState();

  return (
    <div className="tables">
      <Sources onFilter={(payload) => setPayload(payload)} />
      <br />
      <DataTable payload={payload} />
    </div>
  );
}

export default Tables;
