import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  minimizeSidebar: localStorage.getItem('minimizeSidebar') === 'true',
};

export const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggle: (state) => {
      state.minimizeSidebar = !state.minimizeSidebar;
    },
  },
});

export const { toggle } = sidebar.actions;

export default sidebar.reducer;
