import React from 'react';

import { ReactComponent as Add } from './images/library-add.svg';
import { ReactComponent as AddCondition } from './images/circle-add.svg';
import { ReactComponent as Delete } from './images/delete.svg';
import { ReactComponent as CircleDelete } from './images/circle-delete.svg';
import { ReactComponent as Back } from './images/back.svg';

import './Buttons.scss';

function AddBlockButton(props) {

  return (
    <button
      type="button"
      className="composition-button add-block"
      onClick={ (event) => props.onClick && props.onClick(event) }
    >
      <Add></Add>
      <span>Добавить группу</span>
    </button>
  );
}

function AddConditionButton(props) {

  return (
    <button
      type="button"
      className="composition-button add-condition"
      onClick={ (event) => props.onClick && props.onClick(event) }
    >
      <AddCondition></AddCondition>
      <span>Добавить правило</span>
    </button>
  );
}

function DeleteBlockButton(props) {

  return (
    <button
      type="button"
      className="composition-button delete-block"
      onClick={ (event) => props.onClick && props.onClick(event) }
    >
      <Delete></Delete>
      <span>Удалить группу</span>
    </button>
  );
}
  
function DeleteIcon(props) {

  return (
    <div className="icon-button">
      <CircleDelete {...props}></CircleDelete>
    </div>
  );
}

function BackButton(props) {
  return (
    <button
      type="button"
      className="form-button back"
      {...props}
    >
      <Back></Back>
      <span>Назад</span>
    </button>
  );
}

function SaveButton(props) {
  return (
    <button
      type="submit"
      className="form-button save"
      {...props}
    >
      <span>Сохранить</span>
    </button>
  );
}

export { AddBlockButton, AddConditionButton, DeleteBlockButton, DeleteIcon, BackButton, SaveButton };