import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';

import Group from './Group';
import Collection from './Collection';

import { AddIcon, AddGroupIcon } from './Buttons';

import { ReactComponent as Right } from './images/right.svg';
import { ReactComponent as Down } from './images/down.svg';

import { openType, closeType, openGroup, closeGroup } from '@store/collections';

import { setFlag } from '../Utils';

import { cloneDeep } from 'lodash';

function Type(props) {
  const dispatch = useDispatch();

  const [children, setChildren] = useState([]);

  const lastId = useRef(0);

  const openTypes = useSelector((state) => state.collections.openTypes);
  const openGroups = useSelector((state) => state.collections.openGroups);

  const isOpen = useMemo(() => {
    return openTypes.indexOf(props.type) !== -1;
  }, [openTypes]);

  const toggle = () => {
    if (props.children?.length) {
      isOpen ? dispatch(closeType(props.type)) : dispatch(openType(props.type));
    }
  }

  const moveRow = useCallback((dragId, hoverId, length) => {
    if (length && openGroups.indexOf(hoverId) === -1) {
      dispatch(
        openGroup(hoverId)
      );
    }

    if (lastId.current !== hoverId) {
      lastId.current = hoverId;

      const newChildren = cloneDeep(children);

      setFlag(newChildren, hoverId);

      setChildren(newChildren);
    }
  }, [props, children]);

  const dropRow = useCallback((itemId, hoverId, last) => {
    if (openGroups.indexOf(hoverId) === -1) {
      dispatch(
        openGroup(hoverId)
      );
    }

    if (last !== 0) {
      dispatch(
        closeGroup(last)
      );      
    }

    if (props.moveCollection) {
      props.moveCollection(itemId, hoverId, props.type);
    }
  }, [props]);

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'collection',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropRow(item.id, null, item.last);
    },
    hover(item) {
      const dragId = item.id;
      const hoverId = props.id;

      if (dragId === hoverId) {
          return;
      }

      moveRow(dragId, 0, 0);
    },
  });

  drop(ref);

  useEffect(() => {
    setChildren(props.children);
  }, [props]);

  return (
    <div className='collection-type'>
      <div
        className='wrapper'
        onClick={() => toggle()}
        ref={ref}
        data-handler-id={handlerId}
      >
        <div className='list' onClick={() => toggle()}>{ isOpen ? <Down /> : <Right className={props.children && props.children.length ? '' : 'disabled'} /> }</div>
        <div className='name'>{ props.name ?? '' }</div>
        <div className='actions'>
          <div>
            <AddIcon onClick={() => props.actions.createHandle(null, props.type)}/>
            <AddGroupIcon onClick={() => props.actions.createGroupHandle(null, props.type)}/>
          </div>
        </div>
      </div>
      { children?.length && isOpen ? (
        <div className="children">
          <div className='type-bottom-line'></div>
          { children?.filter(element => element.is_group === true).map(element => (
              <Group
                {...element}
                type={props.type}
                actions={props.actions}
                level={1}
                id={element.id}
                key={'g_' + element.id}
                moveRow={moveRow}
                dropRow={dropRow}
              ></Group>
            )
          )}
          { children?.filter(element => element.is_group !== true).map(element => (
              <Collection
                {...element}
                type={props.type}
                actions={props.actions}
                level={1}
                id={element.id}
                key={'c_' + element.id}
                moveRow={moveRow}
                dropRow={dropRow}
                last={0}
              ></Collection>
            )
          )}
        </div>
      ) : '' }
    </div>
  );
}

export default Type;