import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect from '@components/MultiSelect';

import {
  FormControl, FormLabel
} from "@chakra-ui/react"

import { getTreeOptions, getTreeIds } from './Utils';

/**
 * @param {
 *  label: string,
 *  single: boolean,
 *  placeholder: string,
 *  setValue: any | Array<any>
 *  onChange: (value) => {}
 *  className: string,
 *  isRequired: boolean,
 *  dropDown: boolean
 * } props
 * @returns
 */
function Timebands(props) {

  const types = useSelector((state) => state.collections.collections);

  const collections = useMemo(() => {
    const data = types.filter(entry => entry.type === 3);

    return data.length ? data[0].children : [];
  }, [types]);

  const [timebands, setTimebands] = useState();

  const options = useMemo(() => {
    return getTreeOptions(collections);
  }, [ collections ]);

  const onChange = (values) => {
    setTimebands(values);

    if (props.onChange) {
      const timebands = getTreeIds(values ?? [], collections);

      props.onChange(timebands);
    }
  }

  useEffect(() => {
    if (props.value) {
      setTimebands(props.value);
    }
  }, [ props.value ]);

  return (
    <div>
      <FormControl className={props.className ?? ''}>
        { !!props.label && <FormLabel>{ props.label }</FormLabel> }

        <MultiSelect
          options={options}
          onChange={onChange}
          search={props.search ?? true}
          dropDown={props.dropDown ?? true}
          selected={timebands}
          placeholder={props.placeholder ?? ''}
          single={props.single ?? false}
          line={true}
        />
      </FormControl>
    </div>
  )
}

export default Timebands;
