import { ReactComponent as Dropdown } from './images/dropdown.svg';

import Select, { components } from 'react-select';

const selectStyles = {
  control: (base, state) => ({
    ...base,
    background: '#EBEFF6',
    borderRadius: 4,
    border: null,
    borderWidth: 0,
    boxShadow: null,
    height: '28px',
    minHeight: '28px',
    padding: '0',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    color: '#585771',
    boxSizing: 'border-box',
  }),
  option: (base, state) => ({
    ...base,
    padding: '2px 8px',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    color: '#585771',
    backgroundColor: state.isSelected ? '#EBEFF6' : '#FFFFFF',
    cursor: 'pointer',
  }),
  placeholder: (base, state) => ({
    ...base,
    color: '#585771',
    margin: 0,
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '0 0 0 8px',
  }),
  input: (base, state) => ({
    ...base,
    padding: 0,
    margin: 0,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    color: '#585771',
  }),
  singleValue: (base, state) => ({
    ...base,
    fontFamily: "'Roboto', sans-serif",
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    color: '#585771',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '28px',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#FFFFFF',
    borderRadius: 4,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    whiteSpace: 'nowrap',
    width: 'max-content',
    minWidth: '100%',
  }),
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Dropdown />
    </components.DropdownIndicator>
  );
}

function ConditionSelect(props) {
  const label = props?.options?.filter(option => option.value === props?.value);

  const value = {
    value: props?.value,
    label: label?.length ? label[0].label : ''
  }

  return (
    <Select
      {...props}
      value={value}
      styles={selectStyles}
      components={{ DropdownIndicator }}
      placeholder="Выбрать"
      noOptionsMessage={() => 'Поле не найдено'}
    ></Select>
  );
}


export default ConditionSelect;