import { OptionEntity } from '@models/optionEntity';

import { getFormattedDateTab } from './getFormattedDateTab';

export const getDateOptionEntity = (date: Date): OptionEntity => {
  return {
    key: date.valueOf(),
    children: getFormattedDateTab(date),
  };
};
