import moment from 'moment';
import React, { useContext, useReducer, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { stringToTime, timeToString } from '../utils';

import { WallFormInterface, setfilters } from './forms/WallForm';

export interface VideosState {
  channels: number[];
  videos: string[];
  stats: number[];
  statistics: string[];
  audience: number | null;
  date: string | null;
  interval: string | null;
  moreChannels: number[];
  canPlayVideo: boolean;
  loaded: number[];
  play: boolean;
  sound: number;
  duration: number;
  position: number;
  seekTo: number;
  time: string;
  durations: number[];
  speed: number;
}

export interface VideosActions {
  setChannel: (payload: number, index: number) => void;
  setVideo: (payload: string, index: number) => void;
  setStat: (payload: number, index: number) => void;
  selectStatistics: (payload: number[]) => void;
  selectAudience: (payload: number) => void;
  selectDate: (payload: string | null) => void;
  selectInterval: (payload: string | null) => void;
  selectMoreChannel: (payload: number, index: number) => void;
  deleteMoreChannel: (payload: number) => void;
  setReady: (payload: number) => void;
  startPlay: () => void;
  setSound: (payload: number) => void;
  setDuration: (payload: number, index: number) => void;
  setPosition: (payload: number) => void;
  stop: () => void;
  seek: (payload: number) => void;
  nextPeriod: () => void;
  prevPeriod: () => void;
  speedUp: () => void;
}

export type VideosContext = VideosActions & VideosState;

export enum VideosActionKind {
  CHANNEL = 'channel',
  VIDEO = 'video',
  STATS = 'stats',
  SELECT_STATISTICS = 'select_statistics',
  SELECT_AUDIENCE = 'audience',
  SELECT_DATE = 'select_date',
  SELECT_INTERVAL = 'select_interval',
  PLAY = 'play',
  ADD_LOADED = 'loading',
  SOUND = 'sound',
  DURATION = 'duration',
  POSITION = 'position',
  SEEK = 'seek',
  NEXT = 'next',
  PREV = 'prev',
  ADD_MORE_CHANNEL = 'addMoreChannel',
  DELETE_MORE_CHANNEL = 'deleteMoreChannel',
  INIT = 'init',
  SPEED = 'speed',
}

interface StorageAction {
  type: VideosActionKind;
  payload: any;
  index?: number;
  reduxDispatch?: AppDispatch;
}

// eslint-disable-next-line import/no-named-as-default-member
export const AtdlContext = React.createContext<VideosContext>({
  channels: [],
  videos: [],
  stats: [],
  statistics: [],
  audience: null,
  date: null,
  interval: null,
  moreChannels: [],
  canPlayVideo: false,
  loaded: [],
  play: false,
  sound: -1,
  duration: 0,
  position: 0,
  seekTo: -1,
  time: '',
  durations: [],
  speed: 1,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setChannel: (payload: number, index: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setVideo: (payload: string, index: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStat: (payload: number, index: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectStatistics: (payload: number[]) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectAudience: (payload: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectDate: (payload: string | null) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectInterval: (payload: string | null) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectMoreChannel: (payload: number, index: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteMoreChannel: (payload: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setReady: (payload: number) => {},
  startPlay: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSound: (payload: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDuration: (payload: number, index: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPosition: (payload: number) => {},
  stop: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  seek: (payload: number) => {},
  nextPeriod: () => {},
  prevPeriod: () => {},
  speedUp: () => {},
});

export const useVideosContext = () => {
  return useContext(AtdlContext);
};

const getTime = (
  duration: number | null,
  position: number,
  interval: string | null,
) => {
  if (duration && position && interval) {
    const time =
      stringToTime(interval) + Math.floor((duration * position) / 60);

    return timeToString(time);
  } else if (interval) {
    return interval;
  }

  return '';
};

const reducer = (
  state: VideosState,
  { type, payload, index, reduxDispatch }: StorageAction,
) => {
  switch (type) {
    case VideosActionKind.CHANNEL:
      // eslint-disable-next-line no-case-declarations
      const channels = [...state.channels];

      if (typeof index !== 'undefined') {
        channels[index] = payload;
      }

      reduxDispatch && reduxDispatch(setfilters({ channels }));

      return { ...state, channels, play: false };

    case VideosActionKind.VIDEO:
      // eslint-disable-next-line no-case-declarations
      const videos = [...state.videos];

      if (typeof index !== 'undefined') {
        videos[index] = payload;
      }

      reduxDispatch && reduxDispatch(setfilters({ videos }));

      return { ...state, videos };

    case VideosActionKind.SELECT_STATISTICS:
      reduxDispatch && reduxDispatch(setfilters({ statistics: payload }));

      return {
        ...state,
        statistics: payload,
        play: false,
        canPlayVideo: payload && payload.length && state.date && state.interval,
      };

    case VideosActionKind.SELECT_AUDIENCE:
      reduxDispatch && reduxDispatch(setfilters({ audience: payload }));

      return { ...state, audience: payload, play: false };

    case VideosActionKind.SELECT_DATE:
      reduxDispatch && reduxDispatch(setfilters({ date: payload }));

      return {
        ...state,
        date: payload,
        play: false,
        canPlayVideo:
          state.statistics &&
          state.statistics.length &&
          payload &&
          state.interval,
      };

    case VideosActionKind.SELECT_INTERVAL:
      reduxDispatch && reduxDispatch(setfilters({ interval: payload }));

      return {
        ...state,
        interval: payload,
        play: false,
        time: getTime(state.duration, state.position, payload),
        canPlayVideo:
          state.statistics && state.statistics.length && state.date && payload,
      };

    case VideosActionKind.PLAY:
      return { ...state, play: payload, loading: [] };

    case VideosActionKind.ADD_LOADED:
      // eslint-disable-next-line no-case-declarations
      const loaded = [...state.loaded];

      if (loaded.indexOf(payload) === -1) {
        loaded.push(payload);
      }

      return { ...state, loaded };

    case VideosActionKind.SOUND:
      reduxDispatch && reduxDispatch(setfilters({ audience: payload }));

      return { ...state, sound: payload };

    case VideosActionKind.DURATION:
      if (typeof index !== 'undefined') {
        state.durations[index] = payload;

        return { ...state, duration: Math.round(Math.max(...state.durations)) };
      }

      return { ...state };

    case VideosActionKind.POSITION:
      return {
        ...state,
        position: payload,
        time: getTime(state.duration, payload, state.interval),
      };

    case VideosActionKind.SEEK:
      return { ...state, seekTo: payload };

    case VideosActionKind.NEXT:
      if (state.interval && state.date) {
        let next = stringToTime(state.interval) + 60;

        const date = moment(state.date);

        if (next > 1680) {
          next = 300;
          date.add(1, 'day');
        }

        return {
          ...state,
          interval: timeToString(next),
          date: date.format('YYYY-MM-DD'),
          position: 0,
        };
      }

      return { ...state };

    case VideosActionKind.PREV:
      if (state.interval && state.date) {
        let prev = stringToTime(state.interval) - 60;

        const date = moment(state.date);

        if (prev < 300) {
          prev = 1680;
          date.add(-1, 'day');
        }

        return {
          ...state,
          interval: timeToString(prev),
          date: date.format('YYYY-MM-DD'),
          position: 0,
        };
      }

      return { ...state };

    case VideosActionKind.ADD_MORE_CHANNEL:
      // eslint-disable-next-line no-case-declarations
      const moreChannels = [...state.moreChannels];

      if (index && index > 0) {
        const _index = moreChannels.indexOf(index);

        if (_index !== -1) {
          moreChannels[_index] = payload;
        }
      } else {
        moreChannels.push(payload);
      }

      reduxDispatch && reduxDispatch(setfilters({ moreChannels }));

      return { ...state, moreChannels };

    case VideosActionKind.DELETE_MORE_CHANNEL:
      // eslint-disable-next-line no-case-declarations
      const _channels = [...state.moreChannels];

      reduxDispatch &&
        reduxDispatch(
          setfilters({
            moreChannels: _channels.filter((id) => id !== payload),
          }),
        );

      return {
        ...state,
        moreChannels: _channels.filter((id) => id !== payload),
      };

    case VideosActionKind.INIT:
      state.channels = [...payload.channels];
      state.videos = [...payload.videos];
      state.stats = [...payload.stats];
      state.statistics = [...payload.statistics];
      state.audience = payload.audience;
      state.date = payload.date;
      state.interval = payload.interval;
      state.moreChannels = [...payload.moreChannels];
      state.sound = payload.sound;

      return {
        ...state,
        play: false,
        canPlayVideo:
          state.statistics &&
          state.statistics.length &&
          state.date &&
          state.interval,
        time: getTime(state.duration, state.position, state.interval),
      };

    case VideosActionKind.SPEED:
      let speed = state.speed;

      switch (speed) {
        case 1:
          speed = 1.5;
          break;

        case 1.5:
          speed = 2;
          break;

        default:
          speed = speed * 2;
      }

      return {
        ...state,
        speed: speed > 16 ? 1 : speed,
      };

    default:
      return state;
  }
};

export interface StorageContextProps {
  count?: number;
  children: any;
  defaultValues?: WallFormInterface;
}

export const VideosProvider = ({
  count,
  children,
  defaultValues,
}: StorageContextProps) => {
  const videosCount = count ? count : 4;

  const channels: number[] = [];

  const videos: string[] = [];

  const stats: number[] = [];

  const durations: number[] = [];

  for (let i = 0; i < videosCount; i++) {
    channels.push(0);
    videos.push('');
    stats.push(0);
    durations.push(0);
  }

  const [state, dispatch] = useReducer(reducer, {
    channels,
    videos,
    stats,
    statistics: [],
    audience: null,
    date: null,
    interval: null,
    moreChannels: [],
    canPlayVideo: false,
    loaded: [],
    play: false,
    sound: -1,
    duration: 0,
    position: 0,
    seekTo: -1,
    time: '',
    durations,
    speed: 1,
  });

  const reduxDispatch = useDispatch();

  const setChannel = (payload: number, index: number) =>
    dispatch({ type: VideosActionKind.CHANNEL, payload, index, reduxDispatch });

  const setVideo = (payload: string, index: number) =>
    dispatch({ type: VideosActionKind.VIDEO, payload, index, reduxDispatch });

  const setStat = (payload: number, index: number) =>
    dispatch({ type: VideosActionKind.STATS, payload, index, reduxDispatch });

  const selectStatistics = (payload: number[]) =>
    dispatch({
      type: VideosActionKind.SELECT_STATISTICS,
      payload,
      reduxDispatch,
    });

  const selectAudience = (payload: number) =>
    dispatch({
      type: VideosActionKind.SELECT_AUDIENCE,
      payload,
      reduxDispatch,
    });

  const selectDate = (payload: string | null) =>
    dispatch({ type: VideosActionKind.SELECT_DATE, payload, reduxDispatch });

  const selectInterval = (payload: string | null) =>
    dispatch({
      type: VideosActionKind.SELECT_INTERVAL,
      payload,
      reduxDispatch,
    });

  const startPlay = () =>
    dispatch({ type: VideosActionKind.PLAY, payload: true });

  const setReady = (payload: number) =>
    dispatch({ type: VideosActionKind.ADD_LOADED, payload });

  const setSound = (payload: number) =>
    dispatch({ type: VideosActionKind.SOUND, payload, reduxDispatch });

  const setDuration = (payload: number, index: number) =>
    dispatch({ type: VideosActionKind.DURATION, payload, index });

  const setPosition = (payload: number) =>
    dispatch({ type: VideosActionKind.POSITION, payload });

  const stop = () => dispatch({ type: VideosActionKind.PLAY, payload: false });

  const seek = (payload: number) =>
    dispatch({ type: VideosActionKind.SEEK, payload });

  const nextPeriod = () =>
    dispatch({ type: VideosActionKind.NEXT, payload: {} });

  const prevPeriod = () =>
    dispatch({ type: VideosActionKind.PREV, payload: {} });

  const selectMoreChannel = (payload: number, index: number) =>
    dispatch({
      type: VideosActionKind.ADD_MORE_CHANNEL,
      payload,
      index,
      reduxDispatch,
    });

  const deleteMoreChannel = (payload: number) =>
    dispatch({
      type: VideosActionKind.DELETE_MORE_CHANNEL,
      payload,
      reduxDispatch,
    });

  const speedUp = () =>
    dispatch({ type: VideosActionKind.SPEED, payload: {} });

  const prevValue = useRef<WallFormInterface>();

  useEffect(() => {
    if (defaultValues && !prevValue.current) {
      dispatch({ type: VideosActionKind.INIT, payload: defaultValues.filters });
    }

    prevValue.current = defaultValues;
  }, [defaultValues]);

  return (
    <AtdlContext.Provider
      value={{
        channels: state.channels,
        videos: state.videos,
        stats: state.stats,
        statistics: state.statistics,
        audience: state.audience,
        date: state.date,
        interval: state.interval,
        moreChannels: state.moreChannels,
        canPlayVideo: state.canPlayVideo,
        loaded: state.loaded,
        play: state.play,
        sound: state.sound,
        duration: state.duration,
        position: state.position,
        seekTo: state.seekTo,
        time: state.time,
        durations: state.durations,
        speed: state.speed,
        setChannel,
        setVideo,
        setStat,
        selectStatistics,
        selectAudience,
        selectDate,
        selectInterval,
        startPlay,
        setReady,
        setSound,
        setDuration,
        setPosition,
        stop,
        seek,
        nextPeriod,
        prevPeriod,
        selectMoreChannel,
        deleteMoreChannel,
        speedUp,
      }}
    >
      {children}
    </AtdlContext.Provider>
  );
};
