import React, { useCallback } from "react";
import Component from "./Component";
import { DataProvider } from "./DataProvider";

import './EventsAudience.scss';

function EventsAudience(props) {
  const preparePayload = useCallback((payload) => {
    const data = {
      source: payload.source,
      filter: payload.filter,
    };

    switch (payload.source) {
      case 'program':
        data.program = payload.program;
        break;

      case 'channel':
        data.channel = payload.channel;
        break;

        default:
          break;
    }

    switch (payload.filter) {
      case 'issued':
        data.issued = payload.issued;
        break;

      case 'date':
        data.date = payload.date;
        data.timeband = payload.timeband;
        data.premier = payload.premier;
        break;

      case 'period':
        data.dates_range = payload.dateRange ? {start: payload.dateRange.startDate, end: payload.dateRange.endDate} : null;
        data.timeband = payload.timeband;
        data.premier = payload.premier;
        break;

      default:
        break;
    }

    if (props.onChange) {
      props.onChange(data);
    }
  }, [ props ]);
  return (
    <div className="events-audience-wrapper">
      <DataProvider onChange={preparePayload} defaultValue={props.defaultValue}>
        <Component />
      </DataProvider>
    </div>
  )
}

export default EventsAudience;
