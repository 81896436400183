import React, { useMemo } from 'react';

import './AndOr.scss';

function AndOr(props) {

  const value = useMemo(() => {
    return props.value ?? 'and';
  }, [props.value]);

  return (
    <div className='and-or'>
        <div
          className={ value === 'and' ? 'selected' : '' }
          onClick={ () => props?.onChange('and') }
        >
          AND
        </div>
        <div
          className={ value === 'or' ? 'selected' : '' }
          onClick={ () => props?.onChange('or') }
        >
          OR
        </div>
    </div>
  );
}

export default AndOr;