import React, { useMemo } from "react";
import { useData } from "../DataProvider";
import Channels from "../../Sources/groups/Channels";

function Channel() {

  const { channel, setValue } = useData();

  const channelValue = useMemo(() => channel ? channel.map(ch => ch.content ? ch.id : ('ch' + ch)) : [], [ channel ]);

  return (
    <Channels
      isRequired={true}
      search={true}
      placeholder="Выберите канал"
      className="form-control"
      value={channelValue}
      onChange={(value) => setValue('channel', value)}
    />
  );
}

export default Channel;
