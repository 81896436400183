/* eslint-disable */
export const dateColorCallback = (dates) => {
  const formatDate = (date) => {
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return `${date.getFullYear()}-${month}-${day}`;
  };

  return function (date) {
    const day = `0${date.getDate()}`.slice(-2);
    const textDate = formatDate(date);

    let className = '';
    if (textDate === dates.fastprefact) {
      className = 'fastprefact';
    } else if (textDate >= dates.prefact && textDate < dates.fastprefact) {
      className = 'prefact';
    } else if (textDate < dates.prefact) {
      className = 'default';
    }

    return <span className={className}>{day}</span>;
  };
};

export const collectionToArray = (collection) => {
  return [
    collection.union,
    ...collection.data.map((entry) => {
      if (entry.type === 'condition') {
        return [entry.field, entry.condition, entry.value];
      } else {
        return collectionToArray(entry);
      }
    }),
  ];
};
