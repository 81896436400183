import React, { useEffect, useState } from 'react';
import Condition from './Condition';
import Timeband from './Timeband';
import Channel from './Channel';

import AndOr from './UI/AndOr';
import { AddBlockButton, AddConditionButton, DeleteBlockButton } from './UI/Buttons';
import AdvertCondition from './AdvertCondition';
import AdvertSearch from './AdvertSearch';
import { ADVERT_FIELDS } from './utils';
import AudienceSample from '@components/AudienceSample/AudienceSample';

/**
 * блок с условием
 * @param { type, union, data, root, onChangeHandle } props
 * @returns
 */
function CompositionBlock(props) {
  const defaultUnion = 'and';

  const [union, setUnion] = useState(defaultUnion);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);

  const onChange = (value) => {
    setUnion(value);

    if (props.onChangeHandle) {
      props.onChangeHandle(value, newData);
    }
  }

  const addBlock = () => {
    const result = [
      ...newData,
    ];

    result.push({
      type: 'block',
      union: 'and',
      data: [ ],
    });

    if (props.onChangeHandle) {
      props.onChangeHandle(union, result, true);
    }
  }

  const addCondition = () => {
    const result = [
      ...newData,
    ];

    result.push({
      type: 'condition',
      field: '',
      condition: '',
      value: '',
      new: true,
    });

    if (props.onChangeHandle) {
      props.onChangeHandle(union, result, true);
    }
  }

  const deleteBlock = () => {
    if (props.onDeleteHandle) {
      props.onDeleteHandle();
    }
  }

  const onDeleteCondition = (num, type) => {
    const result = newData.filter((entry, index) => index !== num);

    if (props.onChangeHandle) {
      props.onChangeHandle(union, result, true);
    }
  }

  const onChangeCondition = (num, type, condition) => {
    let result = [];

    switch (props.type) {
      case 5:
        result = condition.value;
        break;


      default:
        result = newData.map((entry, index) => {
          if (index === num) {
            return {
              type,
              ...condition
            }
          }
    
          return entry;
        });
    
    }

    setNewData(result);

    if (props.onChangeHandle) {
      props.onChangeHandle(union, result, true);
    }
  }

  const onSelectAdvert = (row) => {
    if (!row.type) {
      return;
    }

    const entry = { ...row };
    entry.type = `${entry.type}_id`

    let found = false;

    const result = [
      ...newData,
    ].map(row => {
      if (row.type === 'condition' && row.field === entry.type) {
        row.value = [...row.value, entry];
        found = true;
      }

      return row;
    });

    if (!found) {
      const fieldData = ADVERT_FIELDS.filter(({ id }) => id === entry.type);

      if (fieldData.length) {

        result.push({
          type: 'condition',
          field: entry.type,
          condition: fieldData[0].conditions[0],
          value: [entry],
          new: true,
        });
      }
    }

    if (props.onChangeHandle) {
      props.onChangeHandle(union, result, true);
    }
  }

  useEffect(() => {
    setData(props.data ?? []);
    setUnion(props.union ?? defaultUnion);
    setNewData(props.data ?? []);
  }, [ props ]);

  return (
    <div className="composition">
      { props.type !== 5 && <div className="composition-select">
        <div>
          { props.root ? <AudienceSample /> : (
            <AndOr
                onChange={onChange}
                value={union}
            >
            </AndOr>
          ) }
        </div>
        { props.type === 6 ? (
          <div>
            <AdvertSearch onSelect={onSelectAdvert} />
          </div>
        ) : <></> }
        <div className="composition-buttons">
          <div className="wrapper">
            { props.root ? '' : (
              <DeleteBlockButton
                onClick={deleteBlock}
              />
            ) }
          </div>
          <div className="wrapper">
            <AddBlockButton
              onClick={addBlock}
            />
          </div>
          <div className="wrapper">
            <AddConditionButton
              onClick={addCondition}
            />
          </div>
        </div>
      </div> }
      <div className={'composition-block' + (props?.root ? ' root-block' : '')}>
        { props.type === 5 ? (
          <Channel
            field="channels"
            value={data ? data : {}}
            onChangeHandle={(field, condition, value) => {
              onChangeCondition(0, props.type, {field, condition, value});
            }}
          />
        ) : (
          data.map((entry, index) => (
            <div className="item" key={index}>
              { entry.type === 'block' ? (
                <CompositionBlock
                  key={`${props.prefix}-${index}`}
                  prefix={`${props.prefix}-${index}`}
                  type={props.type}
                  union={entry.union}
                  data={entry.data}
                  onChangeHandle={(newUnion, newData) => {
                    onChangeCondition(index, entry.type, {union: newUnion, data: newData});
                  }}
                  onDeleteHandle={() => {
                    onDeleteCondition(index, entry.type);
                  }}
                />
              ) : props.type === 3 ? (
                <Timeband
                  key={`${props.prefix}-${index}`}
                  prefix={`${props.prefix}-${index}`}
                  type={props.type}
                  field={entry.field}
                  condition={entry.condition}
                  value={entry.value}
                  new={entry.new ?? false}
                  onChangeHandle={(field, condition, value) => {
                    onChangeCondition(index, entry.type, {field, condition, value});
                  }}
                  onDeleteHandle={() => {
                    onDeleteCondition(index, entry.type);
                  }}
                />
              ) : (props.type === 6 ? (
                  <AdvertCondition
                    key={`${props.prefix}-${index}`}
                    prefix={`${props.prefix}-${index}`}
                    type={props.type}
                    field={entry.field}
                    condition={entry.condition}
                    value={entry.value}
                    new={entry.new ?? false}
                    onChangeHandle={(field, condition, value) => {
                      onChangeCondition(index, entry.type, {field, condition, value});
                    }}
                    onDeleteHandle={() => {
                      onDeleteCondition(index, entry.type);
                    }}
                  />
              ) : (
                <Condition
                  key={`${props.prefix}-${index}`}
                  prefix={`${props.prefix}-${index}`}
                  type={props.type}
                  field={entry.field}
                  condition={entry.condition}
                  value={entry.value}
                  new={entry.new ?? false}
                  onChangeHandle={(field, condition, value) => {
                    onChangeCondition(index, entry.type, {field, condition, value});
                  }}
                  onDeleteHandle={() => {
                    onDeleteCondition(index, entry.type);
                  }}
                />
              )) }
            </div>
          ))
        ) }
      </div>
    </div>
  );
}

export default CompositionBlock;
