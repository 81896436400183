type FormattedDateEntity = {
  // строка вида 'пт'
  weekDayLabel: string;
  // строка вида '19 мая'
  ddMonthLabel: string;
};

export const formatDate = (date: Date): FormattedDateEntity => {
  const formatOptions = {
    weekday: 'short' as const,
    month: 'long' as const,
    day: 'numeric' as const,
  };

  const formattedDateString = date.toLocaleDateString('ru-RU', formatOptions);

  const [weekDayLabel, ddMonthLabel] = formattedDateString.split(',');

  return {
    weekDayLabel,
    ddMonthLabel,
  };
};
