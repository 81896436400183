import cn from 'classnames';
import * as React from 'react';

import { useCurrentTableTabData } from '@pages/schedule/hooks';
import { ScheduleColumnEntity } from '@pages/schedule/models/scheduleColumnEntity';
import formatPercent from '@utils/formatPercent';

import s from './ScheduleTableHeaderCell.module.scss';

export type ScheduleTableHeaderCellProps = {
  columnId: string;
  className?: string;
};

/**
 * Компонент ячейки шапки таблицы
 */
const ScheduleTableHeaderCell: React.FC<ScheduleTableHeaderCellProps> = ({
  columnId,
  className,
}: ScheduleTableHeaderCellProps) => {
  const {
    data: { columnEntities },
  } = useCurrentTableTabData();

  const columnEntity: ScheduleColumnEntity | null =
    columnEntities[columnId] || null;

  const innerLayout = React.useMemo(
    () =>
      columnEntity ? (
        <div className={s['schedule-table-header-cell-inner']}>
          <h6 className={s['schedule-table-header-cell-inner__text']}>
            {columnEntity.header.title}
          </h6>
          {columnEntity.header.stats && (
            <div className={s['schedule-table-header-cell-inner__stats']}>
              <span className={s['schedule-table-header-cell-inner__text']}>
                TVR {formatPercent(columnEntity.header.stats.tvr)}%
              </span>
              <span
                className={cn(
                  s['schedule-table-header-cell-inner__text'],
                  s['schedule-table-header-cell-inner__text_shifted'],
                )}
              >
                SHR {formatPercent(columnEntity.header.stats.share)}%
              </span>
            </div>
          )}
        </div>
      ) : null,
    [columnEntity],
  );

  return (
    <div className={cn(s['schedule-table-header-cell'], className)}>
      {innerLayout}
    </div>
  );
};

export default ScheduleTableHeaderCell;
