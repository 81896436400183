/* eslint-disable */
import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../store/AuthProvider';
import { collectionToArray } from '../../models/utils';

import {
  fetchCollections,
  fetchDemographics,
  fetchChannels,
  fetchStatistics,
  fetchChannelsCollections,
} from '@store/collections';

import { Button } from '@components/UI/Buttons';

import DateRange from './groups/DateRange';
import Interval from './groups/Interval';
import Targets from './groups/Targets';
import Channels from './groups/Channels';
import Demographics from './groups/Demographics';
import Statistics from './groups/Statistics';
import Timebands from './groups/Timebands';
import Axis from './groups/Axis';
import Premier from './groups/Premier';

import API from '@utils/AdminApi';

import { countChannels } from './groups/Utils';

import './Sources.scss';

// todo KTS typings
function Sources(props) {
  const dipatch = useDispatch();
  const { isLoggedIn, population: population100k } = useAuthContext();

  const [isPreparing, setIsPreparing] = useState(false);

  // todo KTS typings
  const [targetBase, setTargetBase] = useState();
  const [channels, setChannels] = useState();
  const [demographics, setDemographics] = useState();
  const [statistics, setStatistics] = useState();
  const [interval, setInterval] = useState();
  const [timebands, setTimebands] = useState();
  const [datesband, setDatesband] = useState();
  const [premier, setPremier] = useState('all');
  const [dateRange, setDateRange] = useState({
    startDate: moment().toDate(), // todo KTS new Date()
    endDate: moment().toDate(),
    key: 'selection',
  });
  const [axises, setAxises] = useState();

  /**
   * Инициализируем список опций для d'n'd списка в коллекции
   */
  const axisesOptions = useMemo(() => {
    const options = [];

    if (demographics && demographics.length) {
      options.push({
        value: 'target_groups',
        label: 'Статистики целевых групп',
      });
    }

    if (statistics && statistics.length) {
      options.push({
        value: 'statistics',
        label: 'Основные статистики',
      });
    }

    if (interval) {
      options.push({
        value: 'intervals',
        label: 'Интервалы',
      });
    }

    if (timebands && timebands.length) {
      options.push({
        value: 'timebands',
        label: 'Таймбенды',
      });
    }

    if (channels && channels.length) {
      const total = channels.reduce((total, current) => {
        if (Number.isInteger(current)) {
          return ++total;
        } else {
          return (
            total +
            (current.content?.data ? countChannels(current.content.data) : 0)
          );
        }
      }, 0);

      if (total > 1) {
        options.push({
          value: 'channels',
          label: 'Каналы',
        });
      }
    }

    options.push({
      value: 'date',
      label: 'Дата',
    });

    return options;
  }, [demographics, statistics, interval, timebands, channels]);

  // todo KTS useEffect срабатывает на изменение любого пропса, поскольку props меняется по ссылке каждый раз.
  //   Точно ли это ожидаемое поведение?
  /**
   * Запрашиваем данные для коллекций
   */
  useEffect(() => {
    // todo KTS combine actions to make them easier to understand
    dipatch(fetchCollections());

    dipatch(fetchDemographics());

    dipatch(fetchChannels());

    dipatch(fetchStatistics());

    dipatch(fetchChannelsCollections());
  }, [dipatch, isLoggedIn, props]);

  /**
   * Устанавливаем новые значения контролов
   */
  // todo KTS нужна ли эта прослойка в виде маппинга, если ниже мы искусственно его и создаем
  const onChange = (value, field) => {
    switch (field) {
      case 'targetBase':
        setTargetBase(value);
        break;

      case 'channels':
        setChannels(value);
        break;

      case 'demographics':
        setDemographics(value);
        break;

      case 'statistics':
        setStatistics(value);
        break;

      case 'interval':
        setInterval(value);
        break;

      case 'timebands':
        setTimebands(value);
        break;

      case 'dateRange':
        setDateRange(value);
        break;

      case 'axises':
        setAxises(value);
        break;

      case 'datesband':
        setDatesband(value);
        break;

      case 'premier':
        setPremier(value);
        break;

      default:
    }
  };

  // todo KTS move to redux action
  const getDemographics = async () => {
    const data = [];

    if (demographics) {
      for (let i = 0; i < demographics.length; i++) {
        const response = await API.getCollection(demographics[i].id);

        if (response && response.data) {
          data.push(response.data);
        }
      }
    }

    return data.length ? data : null;
  };

  // todo KTS move to redux action
  const getChannels = async () => {
    const data = [];

    if (channels) {
      for (let i = 0; i < channels.length; i++) {
        if (Number.isInteger(channels[i])) {
          data.push(channels[i]);
        } else {
          const response = await API.getCollection(channels[i].id);

          if (response && response.data) {
            data.push(response.data);
          }
        }
      }
    }

    return data.length ? data : null;
  };

  const clearFilters = () => {
    setTargetBase();
    setTargetGroup();
    setChannels();
    setDemographics();
    setStatistics();
    setInterval();
    setTimebands();
    setDatesband();
    setPremier('all');
    setDateRange();
    setAxises();
  };

  const prepareFilters = async (excel = false) => {
    setIsPreparing(true);

    if (!axises || !axises.axisX || !axises.axisX.length) {
      setIsPreparing(false);
      toast.error('Колонки не выбраны');
      return;
    }

    if (!axises || !axises.axisY || !axises.axisY.length) {
      setIsPreparing(false);
      toast.error('Строки не выбраны');
      return;
    }

    if (!targetBase) {
      setIsPreparing(false);
      toast.error('Целевая аудитория не выбрана');
      return;
    }

    if (!channels || !channels.length) {
      setIsPreparing(false);
      toast.error('Каналы не выбраны');
      return;
    }

    if (!statistics) {
      setIsPreparing(false);
      toast.error('Статистики не выбраны');
      return;
    }

    const base = await API.getCollection(targetBase.id);

    const eventsData = {
      split_by: null,
      aggregated: false,
      timeband: null,
      channels: await getChannels(),
      premier: premier == 'all' ? null : (premier == 'premier' ? true : false)
    };

    const datesRange = {};

    if (interval) {
      eventsData.split_by = interval;
    }

    if (timebands && timebands.length) {
      const promises = [];

      const response = await API.getCollection(timebands[0].id);

      if (response && response.data) {
        eventsData.timeband = response.data
      }
    }

    let datesbandData = null

    if (datesband && datesband.length) {
      const response = await API.getCollection(datesband[0].id);

      if (response && response.data) {
        datesbandData = response.data
      }
    }

    if (dateRange) {
      datesRange['start'] = moment(dateRange.startDate).format('YYYY-MM-DD 05:00:00',);
      datesRange['end'] = moment(dateRange.endDate).format('YYYY-MM-DD 05:00:00',);
    }

    const payload = {
      debug: true,
      target_base: base.data ? base.data : null,
      target_groups: await getDemographics(),
      statistics: statistics,
      events: eventsData ?? null,
      xAxis: axises.axisX.map(({ value }) => value),
      yAxis: axises.axisY.map(({ value }) => value),
      population_100: population100k,
      dates_range: datesRange,
      datesband: datesbandData,
    };


    if (props.onFilter) {
      props.onFilter(payload);
    }

    setIsPreparing(false);
  };

  return (
    <div className="sources-form">
      <div className="sources-form-row sources-form-row-title">
        <div>
          <h3>Фильтры выборки</h3>
        </div>
      </div>
      <div
        className="sources-form-row"
        style={{ paddingTop: '14px', paddingBottom: '14px' }}
      >
        <div className="sources-form-row-field" style={{ flex: '1 40%' }}>
          <Targets
            label="Базовая аудитория"
            isRequired={true}
            single={true}
            onChange={(value) => onChange(value, 'targetBase')}
            value={targetBase ? [targetBase.id] : []}
          />
        </div>
        <div className="sources-form-row-field" style={{ flex: '1 40%' }}>
          <DateRange
            label="Период"
            isRequired={false}
            onChange={(value) => onChange(value, 'dateRange')}
            value={dateRange}
          />
        </div>
        <div className="sources-form-row-field" style={{ flex: '1 20%' }}>
          <Interval
            label="Интервал"
            isRequired={false}
            onChange={(value) => onChange(value, 'interval')}
          />
        </div>
      </div>
      <div className="sources-form-filters">
        <div className="sources-form-filter">
          <Demographics
            placeholder="Целевая аудитория"
            onChange={(value) => onChange(value, 'demographics')}
            value={demographics ? demographics.map((el) => el.id) : []}
          />
        </div>
        <div className="sources-form-filter">
          <Channels
            label=""
            placeholder="Выберите каналы"
            onChange={(value) => onChange(value, 'channels')}
            value={
              channels
                ? channels.map((el) =>
                    typeof el === 'object' ? el.id : 'ch' + el,
                  )
                : []
            }
          />
        </div>
        <div className="sources-form-filter">
          <Statistics
            label=""
            placeholder="Выберите статистики"
            onChange={(value) => onChange(value, 'statistics')}
            value={statistics ? statistics.map((el) => el.type) : []}
          />
        </div>
        <div className="sources-form-filter">
          <Timebands
            label=""
            placeholder="Выберите временной диапазон"
            onChange={(value) => onChange(value, 'timebands')}
            value={timebands ? timebands.map((el) => el.id) : []}
            single={true}
          />
        </div>
        <div className="sources-form-filter">
          <Timebands
            label=""
            placeholder="Выберите диапазон дат"
            onChange={(value) => onChange(value, 'datesband')}
            value={datesband ? datesband.map((el) => el.id) : []}
            single={true}
          />
        </div>
        <div className="sources-form-filter">
          <Premier
            onChange={(value) => onChange(value, 'premier')}
            value={premier}
            single={true}
          />
        </div>
      </div>
      <div className="sources-form-filters" style={{ paddingTop: 0 }}>
        <Axis
          options={axisesOptions}
          onChange={(value) => onChange(value, 'axises')}
        />
      </div>

      <div className="sources-form-buttons">
        <div>
          <Button
            onClick={() => prepareFilters()}
            type="primary"
            label="Показать"
          />
        </div>
        <div>
          <Button
            onClick={() => clearFilters()}
            isLoading={isPreparing}
            type="base"
            label="Удалить настройки"
          />
        </div>
      </div>
    </div>
  );
}

export default Sources;
