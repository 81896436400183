import React from 'react';
import { Form, Input } from 'antd';
import { Button } from '@components/UI/Buttons';

import { ReactComponent as ProfileIcon } from '@components/UI/assets/profile.svg';

import '@components/UI/AntD.scss';

function LoginForm(props) {
  const onFinish = values => {
      props.handleSubmit(values.login, values.password);
  }

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      className='ant-custom'
    >
      <Form.Item
        label="Логин"
        name="login"
        rules={[
          { required: true, message: 'Введите логин!' }
        ]}
      >
          <Input placeholder="Логин" />
      </Form.Item>
      <Form.Item
        label="Пароль"
        name="password"
        rules={[
            { required: true, message: 'Введите пароль!' }
        ]}
      >
          <Input.Password placeholder="Пароль" />
      </Form.Item>
      <div>
        <Button type="primary" htmlType="submit" label='Войти' style={{ margin: '0 auto' }}><ProfileIcon /></Button>
      </div>
    </Form>
  )
}

export default LoginForm;