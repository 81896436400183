import {
  ApiScheduleEventEntity,
  ApiTVTimeIntervalEntity,
} from './apiScheduleEntities';
import { MINUTES_IN_HOUR, TV_TIME_SHIFT_HOURS } from './constants';

/**
 * Временные координаты в минутах,
 *  где 0 — это 05:00
 */
export type TVTimeIntervalEntity = {
  minutesStart: number;
  minutesEnd: number;
};

/**
 * Тайм-байты (ака пятиминутки), на которые разбит тайм-интервал
 */
export type TimeBite = {
  // Нужно ли схлопнуть данный тайм байт в тайм-интервале
  hidden: boolean;
} & TVTimeIntervalEntity;

/**
 *
 */
export type TVTimeIntervalEntityWithIntersection = {
  intersectsIntervalStart: boolean;
  intersectsIntervalEnd: boolean;
} & TVTimeIntervalEntity;

/**
 * Добавляются координаты интервалов на вертикальной оси после склеивания и сортировки интервалов
 */
export type TableTVTimeIntervalEntity = TVTimeIntervalEntity & {
  /** Координата старта ячейки по вертикали */
  yStart: number;

  /** Координата окончания ячейки по вертикали */
  yEnd: number;
};

/**
 * intervalMinutesToTVTimeString(75) === '06:15'
 */
export const intervalMinutesToTVTimeString = (
  intervalMinutes: number,
): string => {
  const hours =
    Math.floor(intervalMinutes / MINUTES_IN_HOUR) + TV_TIME_SHIFT_HOURS;

  const minutes = intervalMinutes % MINUTES_IN_HOUR;

  const formatChunk = (chunk: number) => String(chunk).padStart(2, '0');

  return `${formatChunk(hours)}:${formatChunk(minutes)}`;
};

/**
 * tvTimeIntervalEntityToTVTimeString({ minutesStart: 75, minutesEnd: 103 }) === '06:15 — 06:43'
 */
export const tvTimeIntervalEntityToTVTimeString = ({
  minutesStart,
  minutesEnd,
}: TVTimeIntervalEntity): string => {
  return `${intervalMinutesToTVTimeString(
    minutesStart,
  )} — ${intervalMinutesToTVTimeString(minutesEnd)}`;
};

/**
 * tvDateTimeStringToTVMinutes('2023-04-11 05:00') === 0
 * tvDateTimeStringToTVMinutes('05:00') === 0
 */
export const tvDateTimeStringToTVMinutes = (tvTimeString: string): number => {
  try {
    const TEMPLATE_REGEXP = /.*(\d\d):(\d\d)/;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, hours, minutes] = tvTimeString.match(TEMPLATE_REGEXP) || [
      0,
      TV_TIME_SHIFT_HOURS,
      0,
    ];

    return (
      (Number(hours) - TV_TIME_SHIFT_HOURS) * MINUTES_IN_HOUR + Number(minutes)
    );
  } catch {
    return 0;
  }
};

export const apiTVTimeIntervalEntityToTVTimeIntervalEntity = ([
  start,
  end,
]: ApiTVTimeIntervalEntity): TVTimeIntervalEntity => ({
  minutesStart: start ? tvDateTimeStringToTVMinutes(start) : 0,
  minutesEnd: end ? tvDateTimeStringToTVMinutes(end) : 0,
});

export const apiScheduleEventToTVTimeIntervalEntity = (
  from: ApiScheduleEventEntity,
): TVTimeIntervalEntity => ({
  minutesStart: tvDateTimeStringToTVMinutes(from.event_start),
  minutesEnd: tvDateTimeStringToTVMinutes(from.event_end),
});

/**
 * Можно ли объединть интервалы
 * - Объединить нельзя только если между интервалами есть пространство
 * — Объединить можно во всех остальных случаях. Если:
 *   - интервалы состыкованы
 *   - интервалы пересекаются
 *
 * - Ожидаем, что внутри одного интервала .minutesStart <= .minutesEnd всегда
 *
 * {}-[] = false
 * []-{} = false
 *
 * []{} = true
 * {}[] = true
 * {[}] = true
 * [{]} = true
 * {[]} = true
 * [{}] = true
 */
export const canTVTimeIntervalsBeJoined = (
  a: TVTimeIntervalEntity,
  b: TVTimeIntervalEntity,
): boolean => {
  if (a.minutesEnd < b.minutesStart || b.minutesEnd < a.minutesStart) {
    return false;
  }

  return true;
};

/**
 * Объединяются два интервала в один
 */
export const joinTVTimeIntervals = (
  a: TVTimeIntervalEntity,
  b: TVTimeIntervalEntity,
): TVTimeIntervalEntity => ({
  minutesStart: Math.min(a.minutesStart, b.minutesStart),
  minutesEnd: Math.max(a.minutesEnd, b.minutesEnd),
});

/**
 * Пересекаем интервалы вдоль оси y
 * --------------> y
 * a:  [------)
 * b:      [----)
 * =>      |  |
 * result: [--)
 *
 * @param {TVTimeIntervalEntity} a - Интервал события
 * @param {TVTimeIntervalEntity} b - Интервал таймбенда, к которому относится событие
 */
export const getTVTimeIntervalsIntersection = (
  a: TVTimeIntervalEntity,
  b: TVTimeIntervalEntity,
): null | TVTimeIntervalEntity => {
  const min = a.minutesStart < b.minutesStart ? a : b;

  const max = a === min ? b : a;

  if (min.minutesEnd <= max.minutesStart) {
    return null;
  }

  return {
    minutesStart: max.minutesStart,
    minutesEnd: Math.min(min.minutesEnd, max.minutesEnd),
  };
};
