import _ from 'lodash-es';

// todo KTS fix circular dependencies
// eslint-disable-next-line import/no-cycle
import API from '@utils/AdminApi';

const Common = {
  dates(datesRange) {
    const dates: Date[] = [];

    // @ts-ignore
    for (const shift_day of _.range(
      // @ts-ignore
      _.round(
        (datesRange.endDate - datesRange.startDate) / 1000 / 60 / 60 / 24,
      ) + 1,
    )) {
      const d = datesRange.startDate;

      d.setHours(0, 0, 0, 0);
      dates.push(new Date(d.getTime() + shift_day * 24 * 60 * 60 * 1000));
    }

    return dates;
  },

  median(values) {
    if (values.length === 0) {
      throw new Error('No inputs');
    }

    values.sort((a, b) => {
      return a - b;
    });

    const half = Math.floor(values.length / 2);

    if (values.length % 2) {
      return values[half];
    }

    return (values[half - 1] + values[half]) / 2.0;
  },

  avg(values) {
    if (values.length === 0) {
      throw new Error('No inputs');
    }

    const sum = values.reduce((a, b) => a + b, 0);

    return sum / values.length || 0;
  },

  round(value, signs) {
    const s = Math.pow(10, signs);

    return Math.round((value + Number.EPSILON) * s) / s;
  },

  toDDHHMM(minutes) {
    // eslint-disable-next-line no-param-reassign
    minutes = Number(minutes);
    // 60*24
    const d = Math.floor(minutes / 1440);

    const h = Math.floor((minutes - d * 1440) / 60);

    const m = Math.round(minutes % 60);

    if (d > 0) {
      return d + 'd ' + h + 'h ' + m + 'm';
    } else if (h > 0) {
      return h + 'h ' + m + 'm';
    } else {
      return m + 'm';
    }
  },

  toTVDatetime(value) {
    // INFO: round time to event
    if (value.getSeconds() >= 30) {
      // eslint-disable-next-line no-param-reassign
      value = new Date(value.getTime() + 60000);
      value.setSeconds(0);
      value.setMilliseconds(0);
    } else {
      value.setSeconds(0);
      value.setMilliseconds(0);
    }

    // INFO: Hour and day to TV
    let hour = value.getHours();

    if (value.getHours() < 5) {
      // INFO: increase hour value
      hour = `${value.getHours() + 24}`;
      // INFO: Shift day to -1
      // eslint-disable-next-line no-param-reassign
      value = new Date(value.getTime() - 24 * 60 * 60 * 1000);
    } else {
      hour = `${hour}`;
    }

    let day = `${value.getDate()}`;

    let month = `${value.getMonth() + 1}`;

    let minute = `${value.getMinutes()}`;

    day = day.length === 1 ? `0${day}` : day;
    month = month.length === 1 ? `0${month}` : month;
    minute = minute.length === 1 ? `0${minute}` : minute;
    hour = hour.length === 1 ? `0${hour}` : `${hour}`;

    return `${value.getFullYear()}-${month}-${day} ${hour}:${minute}:00`;
  },

  toTVDate(value, already_tv = false) {
    if (already_tv) {
      let day = `${value.getDate()}`;

      let month = `${value.getMonth() + 1}`;

      day = day.length === 1 ? `0${day}` : day;
      month = month.length === 1 ? `0${month}` : month;

      return `${value.getFullYear()}-${month}-${day}`;
    }

    const dt = this.toTVDatetime(value);

    // @ts-ignore
    return _.split(dt, ' ')[0];
  },

  searchProgram(text) {
    return new Promise((resolve, reject) => {
      API.searchProgramsOptions(text).then(
        (data) => {
          resolve(data.map((row) => ({ ...row, show: 1 })));
        },
        (error) => {
          reject(error);
        },
      );
    });
  },

  pickers2TVDatetime(date, time) {
    // INFO: DatePicker, TimePicker to TVDatetime
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0',
    )}-${String(date.getDate()).padStart(2, '0')} ${time}`;
  },


  merge(obj1, obj2) {
    // @ts-ignore
    return JSON.parse(JSON.stringify(_.assignIn(obj1, obj2)));
  },

  deepMerge(target, source) {
    // @ts-ignore
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (source[key] instanceof Object && !Array.isArray(source[key])) {
          if (!target[key]) {
            target[key] = {};
          }
          Common.deepMerge(target[key], source[key]);
        } else {
          if (Array.isArray(source[key])) {
            for (const rec of source[key]) {

            }
          } else {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  },

  deepMergeArrays(target, source) {
  for (let i = 0; i < source.length; i++) {
    if (Array.isArray(source[i])) {
      if (!target[i]) {
        target[i] = [];
      }
      target[i] = Common.deepMergeArrays(target[i], source[i]);
    } else if (typeof source[i] === 'object' && source[i] !== null) {
      if (!target[i]) {
        target[i] = {};
      }
      target[i] = Common.deepMergeObjects(target[i], source[i]);
    } else {
      target[i] = source[i];
    }
  }
  return target;
},

deepMergeObjects(target, source) {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (Array.isArray(source[key])) {
        if (!target[key]) {
          target[key] = [];
        }
        target[key] = Common.deepMergeArrays(target[key], source[key]);
      } else if (typeof source[key] === 'object' && source[key] !== null) {
        if (!target[key]) {
          target[key] = {};
        }
        target[key] = Common.deepMergeObjects(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
},
copy(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
};

export default Common;
