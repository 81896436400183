/* eslint-disable */
import { useEffect, useState } from 'react';
import API from '@utils/AdminApi';
import TreeSelect from '@components/MultiSelect/TreeSelect/TreeSelect';

function Test() {

  const [options, setOptions] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    API.getAdvertsArticles().then(result => setOptions(result));
    API.getAdvertsBrands().then(result => setBrands(result));
  }, [])

  const searchAdverts = (text) => {
    return new Promise(resolve => {
      API.searchAdverts(text).then(
        result =>
          resolve(result.map(({ advert_id, name }) => ({path: [{pk_id: advert_id, name}]})))
      );
    });
  }

  return (
    <>
      <div className="form-control">
        <TreeSelect options={options} single={false} loader={API.getAdvertsModel} search={API.searchAdvertsArticles} />
      </div>
      <br /><br />
      <div className="form-control">
        <TreeSelect options={brands} single={false} loader={API.getAdvertsModel} search={API.searchAdvertsBrands} />
      </div>

      <div className="form-control">
        <TreeSelect searchOnly={true} single={true} search={searchAdverts} placeholder={""} />
      </div>

    </>
  )
}

export default Test