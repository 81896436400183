import * as React from 'react';
import { useTable } from 'react-table';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { Button } from '@components/UI/Buttons';
import { toast } from 'react-toastify';
import Csv from '@utils/Csv.js';

import API from '@utils/AdminApi';

import { TableLayout } from './layouts';

import './Tables.scss';

// todo KTS make predictable component directories structur

// todo KTS fix typings
type DataTableProps = {
  /**
   * Данные, с которыми будет выполнен запрос за отчетом
   */
  payload: any;
};

// todo KTS Виртуализация таблицы
/**
 * Компонент отображает таблицу на странице «Мои отчеты»
 */
function DataTable(props: DataTableProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  // todo KTS add table typings
  const [response, setResponse] = React.useState({ columns: [], data: [], csv: [] });

  // todo KTS remove useMemo
  const data = React.useMemo(() => {
    return response.data ?? [];
  }, [response]);

  const columns = React.useMemo(() => {
    return response.columns ?? [];
  }, [response]);

  /**
   * расчет количества объединения строк для оси Y
   */
  const rowGroups = React.useMemo(() => {
    // todo KTS add typings
    if (!props.payload || !props.payload.yAxis) {
      return [];
    }

    /**
     * Список колонок в таблице, по котором делался group by
     */
    const groups = [...props.payload.yAxis];

    groups.pop();

    // todo KTS add comments & typings
    return groups.map((group) => {
      /**
       * Если в таблице нет строк
       */
      if (!response.data || response.data.length === 0) {
        return {};
      }

      /**
       * Список аксессоров к колонкам таблицы получаем из ключей строки
       * todo KTS переделать на получение аксессоров из .columns для большей консистентности
       */
      const keys = Object.keys(response.data[0]);

      // todo KTS process if groupKey doesn't exist
      /**
       * Аксесср с текущей рассматриваемой колонке
       */
      const groupKey = keys.filter((key) => key.match(group))?.pop() || '';

      // todo KTS переделать формирование уникальных значений на объект с ключем,
      //  чтобы уменьшить алгоритмическую сложность от квадрата до линейной
      /**
       * Уникальные значения в текущей колонке
       */
      const unique = groupKey
        ? response.data
            /** Вытаскиваем из всех строк значения рассматриваемых колонок */
            .map((entry) => entry[groupKey])
            /** Фильтруем уникальные значени */
            .filter((value, index, self) => self.indexOf(value) === index)
        : [];

      return {
        /**
         * Аксессор группы
         */
        name: group,
        /**
         * Уникальные значения в колонке
         */
        data: unique.map((value) => {
          return {
            // Само уникальное значение
            value,
            // todo KTS переделать повторный подсчет уникальных значений, использовать даные из подсчета выше
            // Колв-то таких значений
            count:
              response.data.filter((entry) => entry[groupKey] === value)
                ?.length || 0,
          };
        }),
      };
    });
  }, [props, response]);

  
  const chartToExcelHandler = React.useCallback(() => {
    if (response && response.csv) {
      Csv(response.csv, );
      toast.success(`Данные скопированы в буфер обмена`);
    }
  }, [ response ]);


  const tableInstance = useTable<Record<string, any>>({
    columns,
    data,
    // @ts-ignore todo KTS remove ts-ignore.
    //   autoResetExpanded doesn't work in react-table v7, but passed here. Check if it really works
    autoResetExpanded: false,
  });

  /** загрузка данных */
  React.useEffect(() => {
    if (props.payload) {
      setIsLoading(true);
      API.getDataset(props.payload)
        .then((response) => {
          setResponse(response);
        })
        .finally(() => setIsLoading(false));
    }
  }, [props.payload]);

  return (
    <div className="data-table">
      {isLoading ? (
        <div className="data-table-info">Загрузка таблицы ...</div>
      ) : data && data.length ? (
        <>
          <div className="data-table-title-wrapper">
            <div className="data-table-info">Результат</div>
            <div className="data-table-buttons">
              <Button
                onClick={() => chartToExcelHandler()}
                type="base"
                label="CSV"
                disabled={false}
                style={{}}
              >
                <AiOutlineFileExcel />
              </Button>
            </div>
          </div>
          <TableLayout {...tableInstance} rowGroups={rowGroups} />
        </>
      ) : (
        <div className="data-table-info">Нет данных</div>
      )}
    </div>
  );
}

export default DataTable;
