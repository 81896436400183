/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import API from '@utils/AdminApi';
import { useParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch } from 'react-redux';
import HighchartsSankey from 'highcharts/modules/sankey';
import Statistics from '@components/Sources/groups/Statistics';
import { fetchStatistics, fetchCollections } from '@store/collections';
import Targets from '@components/Sources/groups/Targets';
import MultiSelect from '@components/MultiSelect';
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as Right } from '../collections/crud/UI/images/right.svg';
import { ReactComponent as Down } from '../collections/crud/UI/images/down.svg';

import './Program.scss';
import Common from "@utils/common";
import {useAuthContext} from "@store/AuthProvider";

HighchartsSankey(Highcharts);

const INTERVALS = [
  {
    value: 1,
    label: '1 минута',
  },
  {
    value: 5,
    label: '5 минут',
  },
  {
    value: 10,
    label: '10 минут',
  },
  {
    value: 15,
    label: '15 минут',
  },
  {
    value: 30,
    label: '30 минут',
  },
];

function ProgramIssued() {
  const navigate = useNavigate();
  const location = useLocation();

  const issuedId = useRef(null);
  const { program_id, issued_id, channel_id } = useParams();

  const dispatch = useDispatch();

  const [programInfo, setProgramInfo] = useState({ columns: [], data: [] });
  const [programIssuedInfo, setProgramIssuedInfo] = useState({
    columns: [],
    data: [],
  });

  const [selectedPosition, setSelectedPosition] = useState(null);
  const [statistics, setStatistics] = useState(location.state && location.state.statistics ? location.state.statistics : []);
  const [targetBase, setTargetBase] = useState(location.state && location.state.target ? location.state.target : []);

  const targetBaseValue = useMemo(
    () => (targetBase ? [targetBase.id] : []),
    [targetBase],
  );

  const statisticsValue = useMemo(
    () => (statistics ? statistics.map(entry => entry.type) : []),
    [statistics],
  )

  const [data, setData] = useState(null);
  const [audienceMove, setAudienceMove] = useState(null);
  const [interval, setInterval] = useState(10);

  const [ isOpen, setIsOpen ] = useState(false);
  const { population: population_100 } = useAuthContext();

  const toggle = () => {
    setIsOpen(value => !value);
  }

  const loadStatisticsData = async (targetBase, statistics, issued_id) => {
    const base = await API.getCollection(targetBase.id);

    const response = await API.postProgramIssuedStatistics(
      issued_id,
      statistics,
      base.data,
      [],
    );

    setData(response);
  }

  useEffect(() => {
    if (targetBase && statistics.length > 0) {
      loadStatisticsData(targetBase, statistics, issued_id);
    } else {
      setData(null);
      setAudienceMove(null);
    }
  }, [statistics, targetBase, issued_id]);

  useEffect(() => {
    if (issuedId.current !== issued_id) {
      dispatch(fetchStatistics("issued_program"));

      dispatch(fetchCollections());

      API.getProgramInfo(channel_id, program_id).then((response) => {
        setProgramInfo(response);
      });

      API.getProgramIssuedInfo(issued_id).then((response) => {
        setProgramIssuedInfo(response);
      });
    }
  }, [ issued_id ]);

  const audienceChart = useMemo(
    () =>
      audienceMove
        ? {
            chart: {
              type: 'sankey',
            },
            tooltip: {
              headerFormat: '',
              formatter: function () {
                let message = [];

                if (this.point) {
                  message.push(`${this.point.weight} тыс.`);
                }

                if (this.point && this.point.broadcast && this.point.broadcast.length > 0) {
                  const broadcast = this.point.broadcast;

                  broadcast.forEach((entry) => {
                    message.push(`${entry.event_start} - ${entry.event_end}: ${entry.name}`);
                  });
                }

                return message.join("<br />");
              },
              shared: true,
            },
            title: {
              text: '',
            },
            series: audienceMove.series,
            plotOptions: {
              series: {
                cursor: 'pointer',
                point: {
                  events: {
                    click: (e) => {
                      if (e.point && e.point.broadcast && e.point.broadcast.length) {
                        const broadcast = e.point.broadcast[e.point.broadcast.length - 1];

                        navigate(`/programs/${broadcast.program_id}/channel/${broadcast.channel_id}/issued/${broadcast.piss_id}`);
                        setAudienceMove(null);
                      }
                    },
                  },
                },
              },
            },
          }
        : {},
    [audienceMove],
  );

  const chartData = useMemo(() =>
    Common.deepMergeObjects(
         {
          title: '',
          xAxis: [{
            labels: {
              rotation: -90,
              align: 'right'
            }
          }],
          yAxis: [],
          series: [],
          legend: {
            enabled: false,
          },
          tooltip: {
            headerFormat: '{point.x:.1f}<br>',
            pointFormat: '{point.y}',
            shared: true,
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: (e) => {
                    setSelectedPosition(e.point.category);
                  },
                },
              },
            },
          },
        }, data ? data : {})
    , [data]
  );

  const loadChannelMoves = async (targetBase, channel_id, programIssuedInfo, selectedPosition, interval) => {
    const base = await API.getCollection(targetBase.id);

    const response = await API.getChannelMoves(
      channel_id,
      programIssuedInfo.data[0]['event_start'],
      selectedPosition,
      base.data,
      interval,
    );

    setAudienceMove(response);
  }

  useEffect(() => {
    if (selectedPosition) {
      loadChannelMoves(targetBase, channel_id, programIssuedInfo, selectedPosition, interval);
    }
  }, [selectedPosition, channel_id, interval, targetBase, programIssuedInfo]);

  return (
    <div>
      <div className="card-wrapper">
        <div className="card-title">
          <div className="list" onClick={() => toggle()}>{ isOpen ? <Down /> : <Right /> }</div>
          <div className="title">Карточка передачи</div>
        </div>
        <div className="card-content">
          <div className="channels-info">
            <div className="title">{programIssuedInfo.data.length ? programIssuedInfo.data[0]['name']+':' : ''} {programIssuedInfo.data.length ? programIssuedInfo.data[0]['event_start']: ''}</div>
            <div className="wrapper">
              {!!programInfo && !!programInfo.data ? (
                programInfo.data.map((entry) => (
                  <div className={isOpen || ['Канал', 'Категория'].indexOf(entry.name) !== -1 ? "box" : "hidden"} key={entry.name}>
                    <div>{entry.name}</div>
                    <div>{entry.value}</div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="card-wrapper">
        <div className="card-title">
          <div className="title">Поминутная динамика</div>
        </div>
        <div className="card-content">
          <div className="issued-filters program-issued">
            <div className="filters-wrapper">
              <div className="two-columns">
                <div>
                  <Targets
                    name="targetBase"
                    value={targetBaseValue}
                    onChange={setTargetBase}
                  />
                </div>
                <div>
                  <Statistics
                    name="statistics"
                    onChange={setStatistics}
                    placeholder={'Выберите статистику'}
                    value={statisticsValue}
                    single={true}
                    className="form-control"
                    source="issued_program"
                  />
                </div>
              </div>
            </div>
          </div>

          {!!data && (
            <div className="issued-filters">
              <br />
              <HighchartsReact highcharts={Highcharts} options={chartData} />
            </div>
          )}
        </div>
      </div>
      <br />

      {!!audienceMove && (
        <div className="card-wrapper">
          <div className="card-title">
            <div className="title">График переходов аудитории на {selectedPosition? selectedPosition.split(' - ')[0]: ''} </div>
            <div className="controls">
              <div className="form-control">
                <label>Переходы за</label>
                <MultiSelect
                  options={INTERVALS}
                  onChange={(interval) => setInterval(interval[0])}
                  selected={[interval]}
                  dropDown={true}
                  single={true}
                />
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="issued-filters">
              <HighchartsReact
                highcharts={Highcharts}
                options={audienceChart}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgramIssued;
