import { useState, useEffect, useCallback } from 'react';
import { ReactComponent as Right } from '../images/right.svg';
import { ReactComponent as Down } from '../images/down.svg';
import { useOptions } from './OptionsProvider';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { useDataContext } from './DataProvider';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

function TreeView({ parent, path, addItem }) {
  const [ options, setOptions ] = useState(null);

  const { groups, setGroups, getOptions, optons: globalOptions } = useOptions();

  const { keyField, labelField, selectFilter } = useDataContext();

  const openGroup = (id) => {
    setGroups([...groups, id]);
  }

  const closeGroup = (id) => {
    setGroups(groups.filter(value => value !== id));
  }

  const isOpen = useCallback((id) => {
    return groups.indexOf(id) !== -1;
  }, [ groups ]);

  useEffect(() => {
    if (options) {
      return;
    }

    const getResult = result => {
      setOptions(result);
    }

    let _options = getOptions(parent, getResult);

    if (_options !== null) {
      setOptions(_options);
    }
  }, [ globalOptions ]);
  
  const isDisabled = (option) => {
    return !selectFilter || selectFilter(option) ? {} : {
      className: 'disabled'
    }
  }

  return (
    <>
      { options === null ? (
        <div className="multiselect-options-option">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        !!options && options.length ? options.map(option => 
          !!option.children
            ? (
              <div key={`option-${option[keyField]}`}>
                <div
                  className="multiselect-options-option"
                >
                  <div className="__icon" style={{opacity: option.children !== null ? 1 : 0.3}}>
                    { isOpen(option[keyField], options) ? (
                      <Down onClick={() => closeGroup(option[keyField])} />
                    ) : (
                      <Right onClick={() => option.children !== null ? openGroup(option[keyField]) : false} />
                    ) }
                  </div>
                  <div onClick={() => addItem([...path, option])} {...isDisabled(option)}>{ option[labelField] }</div>
                </div>
                { isOpen(option[keyField], options) && (
                  <div
                    className="multiselect-options-group"
                  >
                    <TreeView parent={option[keyField]} path={[...path, option]} addItem={addItem} />
                  </div>
                ) }
              </div>
            ) : (
              <div
                key={`option-${option[keyField]}`}
                className="multiselect-options-option"
              >
                <div onClick={() => addItem([...path, option])} {...isDisabled(option)}>{ option[labelField] }</div>
              </div>
            )
        ) : (
          <div className="multiselect-options-option">
            <div className="small">пусто</div>
          </div>
        )
      ) }
    </>
  )
}

export default TreeView;