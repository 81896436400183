import React, { useMemo } from "react";
import { useData } from "../DataProvider";
import Timebands from "../../Sources/groups/Timebands";
import DateRange from '../../Sources/groups/DateRange';
import Premier from "./Premier";

function Period() {
  const { dateRange, timeband, setValue } = useData();

  const timebandValue = useMemo(() => timeband ? [ timeband.id ] : [], [ timeband ]);

  return (
    <>
      <DateRange
          onChange={(value) => setValue('dateRange', value)}
          placeholder="Выберите диапазон дат"
          value={dateRange}
      />

      <Timebands
        placeholder="Таймбэнд"
        className="form-control"
        onChange={(value) => setValue('timeband', value ? value[0] : null)}
        value={timebandValue}
        single={true}
      />

      <Premier />
    </>
  );
}

export default Period;