import * as React from 'react';

import { ScheduleCellEntity } from '../models/scheduleCellEntity';

import { ScheduleTableColumnsContextValue } from './ScheduleTableColumnsContext';

type ResizeHandler = (nodeWidth: number) => void;

// Минимальная ширина колонки, рх
const MINIMUM_COLUMN_WIDTH = 320;

export const useCreateColumnsContext = (
  scrolledColumnIds: string[],
  cellEntities: Record<string, ScheduleCellEntity>,
): ScheduleTableColumnsContextValue => {
  const columnsCount = scrolledColumnIds.length;

  /**
   * Число ячеек служит триггером для запуска
   * useEffect с ResizeObserver. Он обновляет
   * координаты шапки таблицы.
   */
  const cellsCount = Object.keys(cellEntities).length;

  const columnsResizeHandlers = React.useRef<Set<ResizeHandler>>(new Set());

  const columnsContainerResizeHandlers = React.useRef<Set<ResizeHandler>>(
    new Set(),
  );

  const subscribeOnColumnResize = React.useCallback(
    (handler: ResizeHandler) => {
      columnsResizeHandlers.current.add(handler);

      return () => columnsResizeHandlers.current.delete(handler);
    },
    [],
  );

  const subscribeOnColumnsContainerResize = React.useCallback(
    (handler: ResizeHandler) => {
      columnsContainerResizeHandlers.current.add(handler);

      return () => columnsContainerResizeHandlers.current.delete(handler);
    },
    [],
  );

  const columnsContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!columnsContainerRef.current) {
      return;
    }

    let callId;

    const handleResize = () => {
      callId = requestAnimationFrame(() => {
        if (!columnsContainerRef.current) {
          return;
        }

        const containerWidth =
          columnsContainerRef.current.getBoundingClientRect().width;

        const columnWidth = Math.max(
          containerWidth / columnsCount,
          MINIMUM_COLUMN_WIDTH,
        );

        columnsResizeHandlers.current.forEach((handler: ResizeHandler) => {
          handler(columnWidth);
        });

        columnsContainerResizeHandlers.current.forEach(
          (handler: ResizeHandler) => {
            handler(containerWidth);
          },
        );
      });
    };

    const observer = new ResizeObserver(handleResize);

    observer.observe(columnsContainerRef.current);

    return () => {
      observer.disconnect();
      cancelAnimationFrame(callId);
    };
  }, [columnsCount, cellsCount]);

  return React.useMemo(
    () => ({
      columnsContainerRef,
      subscribeOnColumnResize,
      subscribeOnColumnsContainerResize,
    }),
    [
      columnsContainerRef,
      subscribeOnColumnResize,
      subscribeOnColumnsContainerResize,
    ],
  );
};
