import {
  ApiScheduleEventEntity,
  ApiTVTimeIntervalEntity,
} from '../models/apiScheduleEntities';
import { ApiScheduleTableContextParams } from '../models/apiScheduleTableContextParams';
import {
  TIME_SLOTS_COLUMN_ID,
  Y_SPACE_BETWEEN_INTERVALS,
} from '../models/constants';
import { getScheduleEventPosition } from '../models/getScheduleEventPosition';
import { ScheduleCellKind } from '../models/scheduleCellKind';
import { ScheduleCellTVEvent } from '../models/scheduleCellPayloadTVEvent';
import { ScheduleCellTimeSlot } from '../models/scheduleCellPayloadTimeSlot';
import { ScheduleColumnEntity } from '../models/scheduleColumnEntity';
import {
  ScheduleTableData,
  createInitialScheduleTableData,
} from '../models/scheduleTableData';
import {
  apiScheduleEventToTVTimeIntervalEntity,
  apiTVTimeIntervalEntityToTVTimeIntervalEntity,
  canTVTimeIntervalsBeJoined,
  joinTVTimeIntervals,
  TableTVTimeIntervalEntity,
  TVTimeIntervalEntity,
  tvTimeIntervalEntityToTVTimeString,
} from '../models/timeIntervalValueKind';
import { createTimeBites } from '../utils/createTimeBites';

import { collapseCellsHeight } from './collapseCellsHeight';

const createTimeIntervalCells = (
  apiTVTimeIntervals: ApiTVTimeIntervalEntity[],
): ScheduleCellTimeSlot[] => {
  return (
    apiTVTimeIntervals
      .map(apiTVTimeIntervalEntityToTVTimeIntervalEntity)
      // Сортируем интервалы по возрастанию: сначала время начала, затем время окончания
      .sort((l, r) => {
        if (l.minutesStart !== r.minutesStart) {
          return l.minutesStart - r.minutesStart;
        }

        return l.minutesEnd - r.minutesEnd;
      })
      // Склеиваем пересекающиеся интервалы
      .reduce<TVTimeIntervalEntity[]>((joined, currentItem) => {
        const last = joined.pop();

        if (!last) {
          joined.push(currentItem);

          return joined;
        }

        if (canTVTimeIntervalsBeJoined(last, currentItem)) {
          joined.push(joinTVTimeIntervals(last, currentItem));

          return joined;
        }

        joined.push(last, currentItem);

        return joined;
      }, [])
      // Преобразовываем временные слоты в сущности ячеек на таблице
      .reduce<ScheduleCellTimeSlot[]>(
        (
          cells: ScheduleCellTimeSlot[],
          timeInterval: TVTimeIntervalEntity,
          currentIndex: number,
        ) => {
          const newInterval: ScheduleCellTimeSlot = {
            id: tvTimeIntervalEntityToTVTimeString(timeInterval),
            tableTVTimeInterval: {
              ...timeInterval,

              yStart: 0,
              yEnd: timeInterval.minutesEnd - timeInterval.minutesStart,
            },
            cellKind: ScheduleCellKind.timeSlot,
            payload: createTimeBites(
              timeInterval.minutesStart,
              timeInterval.minutesEnd,
            ),
          };

          if (cells.length > 0) {
            const yShift =
              cells[currentIndex - 1].tableTVTimeInterval.yEnd +
              Y_SPACE_BETWEEN_INTERVALS;

            newInterval.tableTVTimeInterval.yStart += yShift;
            newInterval.tableTVTimeInterval.yEnd += yShift;
          }

          cells.push(newInterval);

          return cells;
        },
        [],
      )
  );
};

const getTableSize = (
  timeIntervalCells: ScheduleCellTimeSlot[],
  tableData: ScheduleTableData,
) => {
  return timeIntervalCells.reduce(
    (actualSize, currentItem): TableTVTimeIntervalEntity => {
      return {
        minutesStart: Math.min(
          actualSize.minutesStart,
          currentItem.tableTVTimeInterval.minutesStart,
        ),
        minutesEnd: Math.max(
          actualSize.minutesEnd,
          currentItem.tableTVTimeInterval.minutesEnd,
        ),
        yStart: Math.min(
          actualSize.yStart,
          currentItem.tableTVTimeInterval.yStart,
        ),
        yEnd: Math.max(actualSize.yEnd, currentItem.tableTVTimeInterval.yEnd),
      };
    },
    tableData.tableSize,
  );
};

const setTableColumnCells = (
  tableData: ScheduleTableData,
  columnId: string,
  cells: ScheduleCellTimeSlot[],
) => {
  cells.forEach((cell) => (tableData.cellEntities[cell.id] = cell));
  tableData.columnEntities[columnId].innerCellIds = cells.map(
    (cell) => cell.id,
  );
};

const createTVEventCells = (
  apiTVEvents: ApiScheduleEventEntity[],
  allTimeIntervals: TableTVTimeIntervalEntity[],
  channelId: string,
): ScheduleCellTVEvent[] => {
  return apiTVEvents
    .map((apiTVEvent: ApiScheduleEventEntity): ScheduleCellTVEvent | null => {
      const currentEventInterval =
        apiScheduleEventToTVTimeIntervalEntity(apiTVEvent);

      const intersection = getScheduleEventPosition(
        currentEventInterval,
        allTimeIntervals,
      );

      if (!intersection) {
        return null;
      }

      return {
        id: `${apiTVEvent.program_id}-${apiTVEvent.piss_id}`,
        tableTVTimeInterval: {
          // Добавляем реальное время начала и окончания события, даже если оно выходит за границы таймбенда
          ...currentEventInterval,

          yStart: intersection.minutesStart,
          yEnd: intersection.minutesEnd,
        },
        cellKind: ScheduleCellKind.tvEvent,
        payload: {
          program: apiTVEvent.program,
          category: apiTVEvent.category,

          programId: apiTVEvent.program_id,
          pissId: apiTVEvent.piss_id,
          channelId,

          path: apiTVEvent.path,
          channel: apiTVEvent.channel ?? "",

          share: apiTVEvent.share,
          tvr: apiTVEvent.tvr,
          intersectsIntervalStart: intersection.intersectsIntervalStart,
          intersectsIntervalEnd: intersection.intersectsIntervalEnd,
        },
      };
    })
    .filter((el): el is ScheduleCellTVEvent => {
      if (!el) {
        return false;
      }

      return (
        el.tableTVTimeInterval.minutesEnd > el.tableTVTimeInterval.minutesStart
      );
    })
    .sort((l, r) => {
      return (
        l.tableTVTimeInterval.minutesStart - r.tableTVTimeInterval.minutesStart
      );
    });
};

const normalizeTVEvents = (
  tableData: ScheduleTableData,
  from: ApiScheduleTableContextParams,
  timeIntervalCells: ScheduleCellTimeSlot[],
) => {
  const allTimeIntervals = timeIntervalCells.map(
    (event) => event.tableTVTimeInterval,
  );

  from.apiChannelsBroadcast.series.forEach(
    ({ data: apiTVEvents, total }, columnIndex) => {
      const apiColumnHeader = from.apiChannelsBroadcast.columns[columnIndex];

      const channelId = String(apiColumnHeader.id);

      const columnCells = createTVEventCells(
        apiTVEvents,
        allTimeIntervals,
        channelId,
      );

      const columnEntity: ScheduleColumnEntity = {
        id: channelId,
        header: {
          title: apiColumnHeader.Header,
          stats: total,
        },
        innerCellIds: columnCells.map((cell) => cell.id),
      };

      columnCells.forEach((cell) => (tableData.cellEntities[cell.id] = cell));
      tableData.columnEntities[columnEntity.id] = columnEntity;
      tableData.scrolledColumnIds.push(columnEntity.id);
    },
  );
};

export const normalizeScheduleTableData = (
  from: ApiScheduleTableContextParams,
): ScheduleTableData => {
  const tableData: ScheduleTableData = createInitialScheduleTableData();

  // sorted & joined intersections
  const timeIntervalCells = createTimeIntervalCells(from.apiTVTimeIntervals);

  setTableColumnCells(tableData, TIME_SLOTS_COLUMN_ID, timeIntervalCells);

  tableData.tableSize = getTableSize(timeIntervalCells, tableData);

  normalizeTVEvents(tableData, from, timeIntervalCells);

  collapseCellsHeight(tableData);

  return tableData;
};
