export const getFullPath = (id, options, all = false) => {
  const search = (id, options) => {
    if (Array.isArray(options)) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];

        if (id === option.value) {
          return [option];
        }

        if (option.children) {
          const result = search(id, option.children);

          if (result) {
            return [option, ...result];
          }
        }
      }
    }

    return null;
  };

  const results = search(id, options);

  if (results) {
    const option = results.pop();

    option.path = results
      .filter(({ show }) => !!show || all)
      .map(({ label }) => label);

    return option;
  }

  return null;
};

export const intPaths = (options, path, all = false) => {
  return options.map((option) => {
    option.path = path;

    if (option.children) {
      const _path = all || option.show ? [...path, option.label] : path;

      option.children = intPaths(option.children, _path, all);
    }

    return option;
  });
};
