/* eslint-disable */
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import SharedModal from '@components/ShareModal/SharedModal';

import { EditIcon, DeleteIcon, YesIcon, NoIcon } from './Buttons';
import { ReactComponent as IsDefault } from './images/circle-ok.svg';

function Collection(props) {
  const [confirm, setConfirm] = useState(false);

  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'collection',
    item: () => {
      return { id: props.id, last: props.last };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'collection',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {},
    hover(item) {
      if (props.moveRow) {
        props.moveRow(item.id, 0, 0);
      }
    },
  });

  drag(drop(ref));

  const showConfirm = () => {
    setConfirm(true);

    setTimeout(() => setConfirm(false), 5000);
  };

  return (
    <div ref={ref}>
      {isDragging ? (
        <div
          className="collection-item-dragging"
          data-handler-id={handlerId}
        ></div>
      ) : (
        <div className="collection-item">
          <div
            className="wrapper"
            style={{ paddingLeft: props.level * 30 }}
            data-handler-id={handlerId}
          >
            <div className="name">{props.is_default ? (<IsDefault></IsDefault>) : (<></>)}{props.name ?? ''}</div>
            <div className="actions">
              {confirm ? (
                <div>
                  <YesIcon onClick={() => props.actions.deleteHandle(props)} />
                  <NoIcon onClick={() => setConfirm(false)} />
                </div>
              ) : (
                <div>
                  <SharedModal {...props} />
                  <EditIcon
                    onClick={() => props.actions.editHandle(props.id)}
                  />
                  <DeleteIcon onClick={() => showConfirm()} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collection;
