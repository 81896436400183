import React, { useState, useEffect, useRef, useMemo } from "react";
import { useAudienceSample } from "./AudienceSampleProvider";
import { useSelector } from 'react-redux';
import API from '@utils/AdminApi';
import { ReactComponent as Store } from '../../assets/store.svg';

const validateContext = (context) => {
  if ('type' in context && context.type === 'condition') {
    return context.value !== '';
  }
  
  if ('data' in context) {
    let valid = true;

    context.data.forEach(el => {
      valid &= validateContext(el);
    });

    return valid;
  }

  return false;
}

const getColor = (value) => {
  if (value === null) {
    return '#999999';
  }

  if (value < 60) {
    return '#F15B5B';
  }

  if (value < 120) {
    return '#F1BE5B';
  }

  return '#65D98C';
}

function AudienceSample() {

  const loadingContext = useRef();

  const [ sample, setSample ] = useState(null);

  const { available, context } = useAudienceSample();

  const style = useMemo(() => ({
    title: `Sample: ${sample ?? 'None'}`,
    style: {
      fill: getColor(sample),
      cursor: 'pointer',
    }
  }), [sample]);

  const dates = useSelector((state) => state.status.statusObject);

  const doRequest = () => {
    if (!available) {
      return;
    }

    const collection = {
      id: 0,
      name: "Test",
      is_group: false,
      parent: null,
      type: 1,
      content: context
    }

    API
      .getAudienceSample(dates.fact, collection)
      .then((result) => {
        if (context != loadingContext.current) {
          return;
        }

        setSample('sample' in result ? result.sample : null);
      });
  }

  useEffect(() => {
    if (validateContext(context)) {
      loadingContext.current = context;

      doRequest();
    } else {
      setSample(null);
    }
  }, [ context ]);

  if (!available) {
    return <></>
  }

  return (
    <div>
      <Store {...style} />
    </div>
  )
}

export default AudienceSample;