import cn from 'classnames';
import * as React from 'react';

import { useCurrentTableTabData } from '../../../hooks';
import { MINUTE_TO_PX_SCALE } from '../../../models/constants';
import { ScheduleCellEntity } from '../../../models/scheduleCellEntity';
import { ScheduleColumnEntity } from '../../../models/scheduleColumnEntity';

import { ScheduleTableCell } from './ScheduleTableCell';
import s from './ScheduleTableColumn.module.scss';

export type ScheduleTableColumnProps = {
  columnId: string;
  className?: string;
};

/**
 * Компонент колонки расписания
 */
const ScheduleTableColumn: React.FC<ScheduleTableColumnProps> = ({
  columnId,
  className,
}: ScheduleTableColumnProps) => {
  const {
    data: { columnEntities, cellEntities, tableSize },
  } = useCurrentTableTabData();

  const columnEntity: ScheduleColumnEntity | null =
    columnEntities[columnId] || null;

  const relatedCellEntities: ScheduleCellEntity[] = React.useMemo(() => {
    if (!columnEntity) {
      return [];
    }

    return columnEntity.innerCellIds
      .map(
        (cellId: string): ScheduleCellEntity | null =>
          cellEntities[cellId] || null,
      )
      .filter((el: ScheduleCellEntity | null): el is ScheduleCellEntity =>
        Boolean(el),
      );
  }, [columnEntity, cellEntities]);

  const cellsStyle: React.CSSProperties = React.useMemo(
    () => ({
      height: tableSize.yEnd * MINUTE_TO_PX_SCALE,
    }),
    [tableSize.yEnd],
  );

  const innerLayout = React.useMemo(
    () =>
      columnEntity ? (
        <div style={cellsStyle} className={s['schedule-table-column__cells']}>
          {relatedCellEntities.map((cellEntity) => (
            <ScheduleTableCell
              key={cellEntity.id}
              cellEntity={cellEntity}
              className={s['schedule-table-column__cell-item']}
            />
          ))}
        </div>
      ) : null,
    [columnEntity, relatedCellEntities, cellsStyle],
  );

  return (
    <div className={cn(s['schedule-table-column'], className)}>
      {innerLayout}
    </div>
  );
};

export default ScheduleTableColumn;
