import React, { useContext, useEffect, useReducer } from "react"

const OptionsContext = React.createContext();

export const useOptions = () => {
  return useContext(OptionsContext);
}

export const findElement = (data, value, keyField = 'id') => {
  let result;

  if (Array.isArray(data)) {
    data?.forEach(el => {
      if (el[keyField] === value) {
        result = el;

        return result;
      }

      if (el.children) {
        const element = findElement(el.children, value, keyField);

        if (element) {
          result = element;
        }
      }
    });
  }

  return result;
}

export const findElementByPath = (data, path, keyField = 'id') => {
  let result;

  const value = Array.isArray(path) && path.length ? path.shift() : null;

  if (value !== null && Array.isArray(data)) {
    data?.forEach(el => {
      if (el[keyField] === value && path.length === 0) {
        result = el;
       
        return;
      } else if (el[keyField] === value && el.children) {
        const element = findElementByPath(el.children, [ ...path  ], keyField);

        if (element) {
          result = element;
          return;
        }
      }
    });
  }

  return result;
}

export const toList = (options) => {
  return Array.isArray(options) ? options.map(option => {
    if (option.hasOwnProperty('children') && option.children !== null && option.children !== false) {
      let children = [];

      return {
        ...option,
        children
      }
    }

    return {...option}
  }) : options;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, options: action.options }

    case 'options':
      if (action.value === null) {
        state.options = action.children;
      } else {
        let el = findElement(state.options, action.value, state.keyField);

        if (el.children) {
          el.children = action.children;
        }
      }

      return { ...state }

    case 'soptions':
      if (action.value === null) {
        state.searchOptions = action.children;
      } else {
        let el = action.path === null
          ? findElement(state.searchOptions, action.value, state.keyField)
          : findElementByPath(state.searchOptions, [ ...action.path ], state.keyField);

        if (el.children) {
          el.children = action.children;
        }
      }

      return { ...state }
      
    case 'searchOptions':

      return { ...state, searchGroups: [], searchOptions: action.searchOptions }

    case 'searchGroups':
      state.searchGroups = action.value;

      return { ...state }

    case 'groups':
      state.groups = action.value;

      return { ...state }
      
    case 'search':

      return { ...state, searchOptions: action.result, filter: action.filter }

    default:
      return state;
  }
}

export const OptionsProvider = ({ children, options, loader, searchLoader, search, keyField }) => {
  const [state, dispatch] = useReducer(reducer, {
    options,
    searchOptions: [],
    groups: [],
    searchGroups: [],
    keyField,
  });

  useEffect(() => {
    dispatch({ type: 'update', options });
  }, [ options ]);

  const getOptions = (value, getResult, search = false, path = null) => {
    let el = value === null
      ? (search ? state.searchOptions : state.options)
      : (
        path === null
          ? findElement((search ? state.searchOptions : state.options), value, keyField)
          : findElementByPath((search ? state.searchOptions : state.options), [ ...path ], keyField)
      );

    if (value !== null && (!el || !el.children)) {
      return [];
    }

    if ((value === null && !state.options.length) || (el.children !== null && (el.children?.length === 0 || el.children === true))) {
      const _loader = search ? searchLoader : loader;

      if (_loader) {
        _loader(value).then((children) => {
          dispatch({
            type: search ? 'soptions' : 'options',
            value,
            path,
            children: children.map(children => {
              children.path = [];

              if (el.path) {
                children.path = [...el.path];
              }

              if (el.label && (path || el.show)) {
                children.path.push(el.label);
              }

              return children;
            }
          )});

          getResult(toList(children));
        });

        return null;
      }

      return [];
    } else if (value === null) {
      return toList((search ? state.searchOptions : state.options));
    } else {
      return toList(el.children);
    }
  }

  const getSearchOptions = (text) => {
    return new Promise((resolve) => {
      if (search && text.length > 2) {
        search(text).then(result => resolve({ result, text }));
      } else {
        resolve({ result: [], text });
      }
    });
  }

  const setSearchOptions = (searchOptions) => {
    dispatch({ type: 'searchOptions', searchOptions })
  }

  const setGroups = (value) => {
    dispatch({ type: 'groups', value })
  }

  const setSearchGroups = (value) => {
    dispatch({ type: 'searchGroups', value })
  }

  return (
    <OptionsContext.Provider value={{
      groups: state.groups,
      searchGroups: state.searchGroups,
      options: state.options,
      searchOptions: state.searchOptions,
      keyField: state.keyField,
      setGroups,
      setSearchGroups,
      getOptions,
      getSearchOptions,
      setSearchOptions,
    }}>
      { children }
    </OptionsContext.Provider>
  )
}