import { TIME_SLOTS_COLUMN_ID } from './constants';
import { ScheduleCellEntity } from './scheduleCellEntity';
import { ScheduleColumnEntity } from './scheduleColumnEntity';
import { TableTVTimeIntervalEntity } from './timeIntervalValueKind';

/**
 * Данные таблицы
 */
export type ScheduleTableData = {
  /**
   * Список ID колонок, которые должны быть зафиксированы при скролле слева
   */
  fixedColumnIds: string[];

  /**
   * Список ID скроллящихся по горизонтали колонок (справа)
   */
  scrolledColumnIds: string[];

  /**
   * Все ячейки в расписании по ключу, чтобы к ним был доступ за O(1)
   */
  cellEntities: Record<string, ScheduleCellEntity>;

  /**
   * Все колонки в расписании по ключу, чтобы к ним был доступ за O(1)
   */
  columnEntities: Record<string, ScheduleColumnEntity>;

  /**
   * Размер таблицы в минутах и в суммарных величинах с учетом расстояний между таймбендами
   */
  tableSize: TableTVTimeIntervalEntity;
};

export const createInitialScheduleTableData = (): ScheduleTableData => {
  const timeIntervalsColumn: ScheduleColumnEntity = {
    id: TIME_SLOTS_COLUMN_ID,
    header: {
      title: '',
      stats: null,
    },
    innerCellIds: [],
  };

  return {
    fixedColumnIds: [TIME_SLOTS_COLUMN_ID],
    scrolledColumnIds: [],
    cellEntities: {},
    columnEntities: {
      [TIME_SLOTS_COLUMN_ID]: timeIntervalsColumn,
    },
    tableSize: {
      minutesEnd: 0,
      minutesStart: 0,
      yEnd: 0,
      yStart: 0,
    },
  };
};
