import { useEffect, useState } from 'react';

/**
 * Хук для сохранения выбранных опций локально, чтобы сразу их отображать,
 * а отправлять в коллбэке только по onBlur, а не по onChange
 */
export const useFilterSelectedValues = <T>(values: T) => {
  const [selectedValues, setSelectedValues] = useState<T>(values);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  return {
    selectedValues,
    setSelectedValues,
  };
};
