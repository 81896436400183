import * as React from 'react';

import { ScheduleTableActions } from './actions';
import { ScheduleTableContextValue } from './scheduleTableContextValue';

export type ScheduleTableContextProps = {
  value: ScheduleTableContextValue;
  actions: ScheduleTableActions;
  isEmptyFilters: boolean;
};

const ScheduleTableContext =
  React.createContext<ScheduleTableContextProps | null>(null);

export const useScheduleTableContext = (): ScheduleTableContextProps => {
  const value = React.useContext(ScheduleTableContext);

  if (!value) {
    throw 'Hook useScheduleTableContext must be used with the ScheduleTableContextProvider';
  }

  return value;
};

type ScheduleTableContextProviderProps = React.PropsWithChildren<{
  value: ScheduleTableContextProps;
}>;

export const ScheduleTableContextProvider: React.FC<
  ScheduleTableContextProviderProps
> = ({ children, value }) => {
  return (
    <ScheduleTableContext.Provider value={value}>
      {children}
    </ScheduleTableContext.Provider>
  );
};
