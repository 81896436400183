import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment';

const startDate = moment().set('year', 2021).set('month', 0).set('day', 1).toDate();
const endDate = moment().set('year', 2021).set('month', 0).set('day', 2).toDate();


const initialState = {
  datesRange: {'startDate': new Date(new Date()  - 21*24*60*60*1000),
    'endDate': new Date(),
    'key': "selection"
  }
};


export const channels = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    changeSelectedDateRange: (state, action) => {
      state.datesRange = action.payload;
    },
  }
});


export const {
  changeSelectedDateRange
} = channels.actions;

export default channels.reducer;
