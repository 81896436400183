/* eslint-disable */
import cloneDeep from 'lodash/cloneDeep';

// todo KTS typings & comments
/**
 * расчет количества строк для объединения — сколько ячеек с таким значением существует в данной колонке
 * @param {*} cell
 * @param {*} groups
 * @returns
 */
const getRowspan = (cell, groups) => {
  let count = 1;

  // todo KTS заменить проход по циклу на получение по ключу за O(1)
  groups.forEach((group, gIndex) => {
    if (cell.column.id.match(group.name)) {
      group.data.forEach((entry, eIndex) => {
        if (entry.value === cell.value) {
          count = entry.count;
          // todo KTS зачем сбрасывать значение? Видимо, чтобы ячейки ниже с таким же значением не увеличивались. Сделать иммутабельно
          groups[gIndex].data[eIndex].count = 0;
        }
      });
    }
  });

  return count;
};

// todo KTS typings
/**
 * шаблон для таблицы
 * @param {*}
 * @returns
 */
export const TableLayout = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  rowGroups,
  sorting,
}) => {
  const groups = cloneDeep(rowGroups); // объект клонируется что бы не потерялись данные при ререндерах
  return (
    <table {...getTableProps()} className={'table'}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => sorting ? (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{ width: column?.width }}
                className={
                  sorting && column.isSorted
                    ? column.isSortedDesc
                      ? "desc"
                      : "asc"
                    : ""
                }
              >
                {column.render('Header')}
              </th>
            ) : (
              <th
                {...column.getHeaderProps()}
                style={{ width: column?.width }}
                className={
                  sorting && column.isSorted
                    ? column.isSortedDesc
                      ? "desc"
                      : "asc"
                    : ""
                }
              >
                {column.render('Header')}
              </th>
            )
            )}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const rowspan = getRowspan(cell, groups);

                if (rowspan) {
                  return (
                    <td {...cell.getCellProps()} rowSpan={rowspan}>
                      {cell.render('Cell', {})}
                    </td>
                  );
                }

                return null;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
