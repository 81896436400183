import React, { useContext, useReducer, useEffect, useRef } from "react"

const DataContext = React.createContext();

export const useData = () => {
  return useContext(DataContext);
}

const reducer = (state, { type, payload, field }) => {
  switch (type) {
    case 'setSource':
      if (payload !== 'program' && state.filter === 'issued') {
        state.filter = 'date';
      }

      return { ...state, source: payload }

    case 'setFilter':
      return { ...state, filter: payload }

    case 'setValue':
      state[field] = payload;

      return { ...state }

    case 'setData':
      return {
        ...state,
        ...payload,
      }

    default:
      return state;
  }
}

export const DataProvider = ({ defaultValue, onChange, children, program }) => {
  const defaultProgram = useRef(null);

  const [state, dispatch] = useReducer(reducer, {
    source: 'program',
    filter: 'issued',
    program: [],
    channel: null,
    issued: null,
    date: null,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    timeband: null,
    premier: 'all',
    onConfirm: () => {},
  });

  const setSource = (payload) => dispatch({ type: 'setSource', payload });
  const setFilter = (payload) => dispatch({ type: 'setFilter', payload });

  const setValue = (field, payload) => dispatch({ type: 'setValue', payload, field });

  const setData = (payload) => dispatch({ type: 'setData', payload });

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [ state ]);

  const prevValue = useRef();

  useEffect(() => {
    if (defaultValue && !prevValue.current) {
      setData(defaultValue);
    }

    prevValue.current = defaultValue;
  }, [ defaultValue ]);

  useEffect(() => {
    if (defaultProgram.current === null && program) {
      setValue('program', program);
      defaultProgram.current = program;
    }
  }, [ program ]);

  return (
    <DataContext.Provider value={{
      source: state.source,
      filter: state.filter,
      program: state.program,
      channel: state.channel,
      issued: state.issued,
      date: state.date,
      dateRange: state.dateRange,
      timeband: state.timeband,
      premier: state.premier,
      setSource,
      setFilter,
      setValue,
      setData,
    }}>
      { children }
    </DataContext.Provider>
  )
}
