import { ContentLayout } from '@layouts/ContentLayout';

import { Dummy } from './Dummy';
import { ScheduleFilters } from './ScheduleFilters';
import { ScheduleTable } from './ScheduleTable';
import { useLoadFilterCollections } from './hooks';
import { ScheduleTableContextProvider, useCreateScheduleStore } from './stores';

const Schedule = () => {
  const store = useCreateScheduleStore();

  useLoadFilterCollections();

  return (
    <ScheduleTableContextProvider value={store}>
      <ContentLayout title="Сетка каналов">
        <ScheduleFilters key="filters" />
        {store.isEmptyFilters ? (
          <Dummy key="dummy" />
        ) : (
          <ScheduleTable key="table" />
        )}
      </ContentLayout>
    </ScheduleTableContextProvider>
  );
};

export default Schedule;
