import { FormControl, FormLabel } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import MultiSelect from '@components/MultiSelect';
import { getTreeIds } from '@components/Sources/groups/Utils';
import { GlobalStoreEntity } from '@models/globalStoreEntity';
import { getTreeOptions } from '@models/utils';
import { SetTimebandAction } from '@pages/schedule/stores';

type TimebandProps = {
  value: number[];
  onChange: SetTimebandAction;
  label: string;
  placeholder: string;
  className?: string;
  isRequired?: boolean;
  dropDown?: boolean;
  search?: boolean;
  single: boolean;
  oneLine?: boolean;
};

// todo KTS refactor
const Timeband: React.FC<TimebandProps> = ({
  label,
  single,
  placeholder,
  value,
  onChange,
  search,
  className,
  isRequired,
  dropDown,
  oneLine,
}) => {
  const types = useSelector(
    (state: GlobalStoreEntity) => state.collections.collections,
  );

  const collections = useMemo(() => {
    const data = types.filter((entry) => entry.type === 3);

    return data.length ? data[0].children : [];
  }, [types]);

  const options = useMemo(() => {
    return getTreeOptions(collections);
  }, [collections]);

  const handleChange = (values) => {
    if (onChange) {
      const timebands = getTreeIds(values, collections);

      onChange(timebands.length ? timebands[0] : undefined);
    }
  };

  return (
    <FormControl isRequired={isRequired ?? false} className={className ?? ''}>
      {!!label && <FormLabel>{label}</FormLabel>}

      <MultiSelect
        options={options}
        selected={value}
        onChange={handleChange}
        search={search ?? true}
        dropDown={dropDown ?? true}
        placeholder={placeholder ?? ''}
        single={single ?? false}
        line={oneLine ?? false}
      />
    </FormControl>
  );
};

export default Timeband;
