import * as React from 'react';

import { formatDate } from '@utils/formatDate';

const SPAN_STYLE: React.CSSProperties = {
  display: 'inline-block',
  marginRight: '12px',
  fontWeight: 700,
  color: 'inherit',
};

// Возвращает реакт-ноду строчного вида 'ПТ 19 мая'
export const getFormattedDateTab = (date: Date): React.ReactNode => {
  const { weekDayLabel, ddMonthLabel } = formatDate(date);

  return (
    <>
      <span style={SPAN_STYLE}>{weekDayLabel.toUpperCase()}</span>
      {ddMonthLabel}
    </>
  );
};
