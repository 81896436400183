import cn from 'classnames';
import * as React from 'react';

import s from './BaseButton.module.scss';

export type BaseButtonProps = React.PropsWithChildren<{
  className?: string;
  disabled?: boolean;

  /** Добавляет cursor: pointer */
  withCursorPointer?: boolean;

  withStopPropagation?: boolean;

  withPreventDefault?: boolean;

  onClick?: React.MouseEventHandler;
}> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  className,
  withCursorPointer,
  withStopPropagation = true,
  withPreventDefault,
  onClick,
  disabled,
  ...nativeProps
}) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (withStopPropagation) {
        e.stopPropagation();
      }

      if (withPreventDefault) {
        e.preventDefault();
      }

      if (disabled) {
        return;
      }

      onClick?.(e);
    },
    [withPreventDefault, withStopPropagation, disabled, onClick],
  );

  const props = {
    className: cn(
      s.base,
      withCursorPointer && !disabled && s.base_withCursorPointer,
      className,
    ),
    children,
    onClick: handleClick,
    ...nativeProps,
  };

  return <button {...props} />;
};

export default BaseButton;
