/* eslint-disable no-fallthrough */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { startOfDay, format, isSameDay, isAfter, isBefore, endOfDay } from 'date-fns';

class WeekNumber extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hover: false,
      active: false,
    };
  }

  handleMouseEvent = event => {
    const { weekRange, disabled, onPreviewChange, onMouseEnter, onMouseDown, onMouseUp } = this.props;
    const stateChanges = {};

    if (disabled) {
      onPreviewChange();
      return;
    }

    switch (event.type) {
      case 'mouseenter':
        onMouseEnter(weekRange);
        onPreviewChange(weekRange);
        stateChanges.hover = true;
        break;
      case 'blur':
      case 'mouseleave':
        stateChanges.hover = false;
        break;
      case 'mousedown':
        stateChanges.active = true;
        onMouseDown(weekRange);
        break;
      case 'mouseup':
        event.stopPropagation();
        stateChanges.active = false;
        onMouseUp(weekRange);
        break;
      case 'focus':
        onPreviewChange(weekRange);
        break;
    }

    if (Object.keys(stateChanges).length) {
      this.setState(stateChanges);
    }
  }

  render() {
    const {
      disabled,
      styles,
    } = this.props;

    let className = "rdrWeekNumber";

    if (disabled) {
      className += " " + styles.dayDisabled
    }

    return (
      <button
        type="button"
        className={className}
        onMouseEnter={this.handleMouseEvent}
        onMouseLeave={this.handleMouseEvent}
        onFocus={this.handleMouseEvent}
        onMouseDown={this.handleMouseEvent}
        onMouseUp={this.handleMouseEvent}
        onBlur={this.handleMouseEvent}
        onPauseCapture={this.handleMouseEvent}
        {...(this.props.disabled || this.props.isPassive ? { tabIndex: -1 } : {})}
      >
        <span>{ this.props.week }</span>
      </button>
    );
  }
}

export default WeekNumber;