import './Buttons.scss';

import { ReactComponent as SearchSVG } from '../assets/search.svg';

/**
 * types: primary, base, danger
 */
export function Button({ type = 'primary', label, children, onClick, htmlType = 'button', style, disabled }) {
  return (
    <button
      type={htmlType}
      className={'ui-btn ' + type}
      onClick={onClick ? (event) => onClick(event) : undefined}
      style={style}
      disabled={disabled}
    >
      { !!children && <div>{ children }</div> }
      <span>{ label }</span>
    </button>
  )
}

export function SearchButton({ onClick, style, disabled }) {
  return (
    <button
      className="ui-btn primary search"
      onClick={onClick ? (event) => onClick(event) : undefined}
      style={style}
      disabled={disabled}
    >
      <SearchSVG />
    </button>
  )
}