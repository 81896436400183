import { useState } from 'react';
import API from '@utils/AdminApi';
import TreeSelect from '@components/MultiSelect/TreeSelect/TreeSelect';
import { getIcon } from './utils';

function AdvertSearch(props) {
  const [ value, setValue ] = useState([]);
 
  const searchAdverts = (text) => {
    return new Promise(resolve => {
      API.searchAdverts(text).then(
        result =>
          resolve(result.map(entry => ({ ...entry, id: entry.key })))
      );
    });
  }

  const onChange = (value) => {
    if (!value || value.length === 0) {
      return;
    }

    const last = value.pop();

    if (last.path && props.onSelect) {
      props.onSelect(last.path.pop());
    }

    setValue([]);
  }

  return (
    <>
      <TreeSelect
        searchOnly={true}
        hideSelected={true}
        selected={value}
        single={false}
        treeSearch={true}
        onChange={onChange}
        search={searchAdverts}
        searchLoader={API.getAdvertsTreePlus}
        placeholder={""}
        keyField="id"
        getIcon={getIcon}
      />
    </>
  )
}

export default AdvertSearch;