import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect from '@components/MultiSelect';

import {
  FormControl, FormLabel
} from "@chakra-ui/react"

/**
 * @param {
 *  label: string,
 *  single: boolean,
 *  placeholder: string,
 *  setValue: any | Array<any>
 *  onChange: (value) => {}
 *  className: string,
 *  isRequired: boolean,
 *  dropDown: boolean,
 *  source: string,
 *  filter: array,
 * } props
 * @returns
 */
function Statistics(props) {

  const inited = useRef(false);

  const statistics = useSelector((state) => state.collections.statistics);

  const [value, setValue] = useState();

  const options = useMemo(() => {
    const source = props.source ?? 'default';

    return statistics[source] ? statistics[source]
      .filter(({ type }) => typeof props.filter === 'undefined' || props.filter.indexOf(type) !== -1)
      .map(statistic => {
        return {
          value: statistic.type,
          label: statistic.name,
        }
      }) : [];
  }, [ statistics, props.source, props.filter ]);

  const onChange = (value) => {
    setValue(value);

    const source = props.source ?? 'default';

    const val = value.map(id => {
      return statistics[source].filter(entry => entry.type === id)[0]
    })

    if (props.onChange) {
      props.onChange(val);
    }
  }

  useEffect(() => {
    if (!inited.current && options && options.length) {
      if (props.default) {
        onChange([options[0].value]);
      }

      inited.current = false;
    }
  }, [ options, props.default ]);

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  return (
    <div>
      <FormControl
        className={props.className ?? ''}
        isRequired={props.isRequired ?? false}
      >
        { !!props.label && <FormLabel>{ props.label }</FormLabel> }

        <MultiSelect
          options={options}
          onChange={onChange}
          search={props.search ?? true}
          dropDown={props.dropDown ?? true}
          selected={value}
          placeholder={props.placeholder ?? ''}
          single={props.single ?? false}
          line={true}
        />
      </FormControl>
    </div>
  )
}

export default Statistics;
