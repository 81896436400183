import React, { useState } from 'react'
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import { PDAPI } from '@utils/PDApi';
import { toast } from "react-toastify";
import { Modal } from 'antd';
import { ReactComponent as ProfileIcon } from '@UI/assets/settings.svg';

function Profile() {
  const [ isOpen, setIsOpen ] = useState(false);

  const doRequest = (oldPassword, newPassword, confirmPassword) => {
    if (newPassword !== confirmPassword) {
      toast.error('Пароли не совпадают!');
      return;
    }

    PDAPI
      .changePassword(oldPassword, newPassword, confirmPassword)
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast.error('Неверный пароль!');
        } else {
          toast.error('Системная ошибка.');
        }
      })
      .then(result => {
        if (result) {
          toast.success('Пароль успешно изменен.');
          setIsOpen(false);
        }
      });
  }

  return (
    <>
      <div className='user-menu-item-wrapper' onClick={() => setIsOpen(true)}>
        <div><ProfileIcon /></div>
        <div>Настройки</div>
      </div>

      <Modal
        title="Смена пароля"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <ChangePasswordForm handleSubmit={doRequest} />
      </Modal>
    </>
  )
}

export default Profile
