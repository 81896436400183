import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect from '@components/MultiSelect';

import {
  FormControl, FormLabel
} from "@chakra-ui/react"

import { getTreeOptions, getTreeIds } from './Utils';

function Targets(props) {

  const types = useSelector((state) => state.collections.collections);

  const [value, setValue] = useState();

  const collections = useMemo(() => {
    const data = types.filter(entry => entry.type === 1);
    return data.length ? data[0].children : [];
  }, [ types ]);

  const options = useMemo(() => {
    return getTreeOptions(collections)
  }, [ collections ]);

  const onChange = (value) => {
    setValue(value);

    if (props.onChange) {
      const ids = getTreeIds(value, collections);
      props.onChange(ids.length ? ids[0] : undefined);
    }

    if (props.onChangeBase) {
      props.onChangeBase(value);
    }
  }

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  return (
    <div>
      <FormControl isRequired={props.isRequired ?? false} className={props.className ?? "form-control"}>
        { !!props.label && <FormLabel>{ props.label }</FormLabel> }

        <MultiSelect
          single={props.single ?? true}
          selected={value}
          dropDown={props.dropDown ?? true}
          placeholder={props.placeholder ?? "Выберите сохраненный набор"}
          onChange={value => onChange(value)}
          options={options}
          path={true}
          line={true}
        />
      </FormControl>
    </div>
  )
}

export default Targets;
