import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChannelFilterEntity } from '@models/channelFilterEntity';
import { Audience, Collection } from '@models/index';
import { Meta } from '@models/meta';
import { OptionEntity } from '@models/optionEntity';
import { TimebandFilterEntity } from '@models/timebandFilterEntity';
import common from '@utils/common';
import { isSameDay } from '@utils/isSameDay';

import { DEFAULT_TIMEBAND_VALUE } from '../config/defaultFilterValues';
import { createInitialScheduleTableData } from '../models/scheduleTableData';
import { ScheduleTableTabsStructure } from '../models/scheduleTableTabsStructure';

import { TableDataActionPayload } from './actions';
import { ScheduleTableContextValue } from './scheduleTableContextValue';

const initialState = {} as ScheduleTableContextValue;

const scheduleTable = createSlice({
  name: 'scheduleTable',
  initialState,
  reducers: {
    setChannels: (
      state,
      action: PayloadAction<(ChannelFilterEntity | Collection)[]>,
    ) => {
      state.filters.channels = action.payload;
    },
    setTimeband: (state, action: PayloadAction<TimebandFilterEntity>) => {
      state.filters.timeband = action.payload || DEFAULT_TIMEBAND_VALUE;
    },
    setTargetAudiences: (state, action: PayloadAction<Audience[]>) => {
      const targetAudiencesPrevLength = state.filters.targetAudiences.length;

      state.filters.targetAudiences = action.payload;

      /**
       * При выборе первой или оставшейся последней опции
       * в фильтре аудиторий делать таб активным
       * Также если "с нуля" было выбрано сразу несколько опций
       */
      if (
        Array.isArray(action.payload) &&
        (state.filters.targetAudiences.length === 1 ||
          targetAudiencesPrevLength === 0)
      ) {
        state.filters.activeTabOption.targetAudience = action.payload[0];

        return;
      }
    },
    setActiveTargetAudience: (state, action: PayloadAction<OptionEntity>) => {
      state.filters.activeTabOption.targetAudience =
        state.filters.targetAudiences.find(
          (audience) => audience.id === action.payload.key,
        ) || null;
    },
    removeTargetAudience: (state, action: PayloadAction<OptionEntity>) => {
      if (!state.filters.activeTabOption.targetAudience) {
        return;
      }

      /**
       * Список выбранных аудиторий имеет длину 2 и более
       * и удаляемая аудитория является активной (вкладкой в таббаре)
       */
      if (
        state.filters.targetAudiences.length > 1 &&
        state.filters.activeTabOption.targetAudience.id === action.payload.key
      ) {
        const targetAudienceIndex = state.filters.targetAudiences.findIndex(
          (audience) => audience.id === action.payload.key,
        );

        /**
         * Если активная аудитория - первая, то активной сделать следующую,
         * иначе - предыдущую
         */
        state.filters.activeTabOption.targetAudience =
          targetAudienceIndex === 0
            ? state.filters.targetAudiences[1]
            : state.filters.targetAudiences[targetAudienceIndex - 1];
      }

      state.filters.targetAudiences = state.filters.targetAudiences.filter(
        (audience) => audience.id !== action.payload.key,
      );
    },
    addDate: (state, action: PayloadAction<Date>) => {
      if (state.filters.dates.some((date) => isSameDay(date, action.payload))) {
        return;
      }

      state.filters.dates.push(action.payload);

      /**
       * При выборе первой или оставшейся последней опции
       * в фильтре дат делать таб активным
       */
      if (state.filters.dates.length === 1) {
        state.filters.activeTabOption.date = action.payload as Date;
      }
    },
    setActiveDate: (state, action: PayloadAction<OptionEntity>) => {
      state.filters.activeTabOption.date =
        state.filters.dates.find(
          (date) => date.valueOf() === action.payload.key,
        ) || null;
    },
    removeDate: (state, action: PayloadAction<OptionEntity>) => {
      if (!state.filters.activeTabOption.date) {
        return;
      }

      /**
       * Список выбранных дат имеет длину 2 и более
       * и удаляемая дата является активной (вкладкой в таббаре)
       */
      if (
        state.filters.dates.length > 1 &&
        state.filters.activeTabOption.date.valueOf() === action.payload.key
      ) {
        const dateIndex = state.filters.dates.findIndex(
          (date) => date.valueOf() === action.payload.key,
        );

        /**
         * Если активная дата - первая, то активной сделать следующую,
         * иначе - предыдущую
         */
        state.filters.activeTabOption.date =
          dateIndex === 0
            ? state.filters.dates[1]
            : state.filters.dates[dateIndex - 1];
      }

      state.filters.dates = state.filters.dates.filter(
        (date) => date.valueOf() !== action.payload.key,
      );
    },
    setTableData: (state, action: PayloadAction<TableDataActionPayload>) => {
      const { date, audience, meta, data } = action.payload;

      if (!state.tableData[date]) {
        state.tableData[date] = {};
      }

      state.tableData[date][audience] = {
        meta,
        data: data ? data : createInitialScheduleTableData(),
      };
    },
    setAllDataNeedUpdate: (state) => {
      /**
       * Каждую пару "дата-аудитория" в сторе помечаем
       * как требующую обновления (загрузки)
       */
      Object.keys(state.tableData).forEach((date) => {
        Object.keys(state.tableData[date]).forEach((audience) => {
          state.tableData[date][audience].meta = Meta.initial;
        });
      });
    },
    loadAudiencesForDate: (state, action: PayloadAction<string>) => {
      const date = action.payload;

      if (!state.tableData[date]) {
        state.tableData[date] = {};
      }

      const audiencesToUpdate = state.filters.targetAudiences.map(
        (audience) => audience.name,
      );

      audiencesToUpdate.forEach((audience) => {
        state.tableData[date][audience] = {
          meta: Meta.initial,
          data: createInitialScheduleTableData(),
        };
      });
    },
    loadDatesForAudiences: (state, action: PayloadAction<Audience[]>) => {
      const audiences = action.payload;

      const datesToUpdate = state.filters.dates.map((date) =>
        common.toTVDate(date, true),
      );

      datesToUpdate.forEach((date) => {
        if (!state.tableData[date]) {
          state.tableData[date] = {};
        }

        audiences.forEach((audience) => {
          state.tableData[date][audience.name] = {
            meta: Meta.initial,
            data: createInitialScheduleTableData(),
          };
        });
      });
    },
    syncTableDataWithFilters: (state) => {
      const dateKeys = state.filters.dates.map((date) =>
        common.toTVDate(date, true),
      );

      const audienceKeys = state.filters.targetAudiences.map(
        (audience) => audience.name,
      );

      const channelKeys = state.filters.channels.map((channel) =>
        String(channel.id),
      );

      const syncedTableData: ScheduleTableTabsStructure = {};

      dateKeys.forEach((date) => {
        syncedTableData[date] = {};

        audienceKeys.forEach((audience) => {
          if (!state.tableData[date]) {
            return;
          }

          syncedTableData[date][audience] = state.tableData[date][audience];

          if (!syncedTableData[date][audience]) {
            return;
          }

          syncedTableData[date][audience].data.scrolledColumnIds = channelKeys;
        });
      });

      state.tableData = syncedTableData;
    },
  },
});

export const { reducer, actions } = scheduleTable;
