import React from 'react';
import { Form, Input } from 'antd';
import { Button } from '@components/UI/Buttons';

function ChangePasswordForm(props) {
  const onFinish = values => {
    props.handleSubmit(values.oldPassword, values.newPassword, values.confirmPassword);
  }

  return (
    <Form
        onFinish={onFinish}
        layout="vertical"
        className='ant-custom'
    >
      <Form.Item
        label="Старый пароль"
        name="oldPassword"
        rules={[
          { required: true, message: 'Введите старый пароль.' }
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Новый пароль"
        name="newPassword"
        rules={[
          { required: true, message: 'введите новый пароль.' }
        ]}
      >
          <Input.Password />
      </Form.Item>
      <Form.Item
        label="Подтверждение"
        name="confirmPassword"
        rules={[
          { required: true, message: 'введите подтверждение пароля.' }
        ]}
      >
        <Input.Password />
      </Form.Item>
      <div>
        <Button type="primary" htmlType="submit" label="Сменить" style={{ margin: '0 auto' }}></Button>
      </div>
    </Form>
  )
}

export default ChangePasswordForm;