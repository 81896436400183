import { createSlice } from '@reduxjs/toolkit'

export const chart = createSlice({
  isLoading: false,
  name: 'chart',
  initialState: {
    series: [],
  },
  reducers: {
    setSeries: (state, action) => {
      state.series = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    }
  },
})

export const { setSeries, setIsLoading } = chart.actions

export default chart.reducer
