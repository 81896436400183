import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const startDate = moment().set('year', 2021).set('month', 0).set('day', 1).toDate();
const endDate = moment().set('year', 2021).set('month', 0).set('day', 2).toDate();

const initialState = {
  range: {
    startDate,
    endDate,
    key: 'selection',
  },
  selectedRange: {
    startDate,
    endDate,
    key: 'selection',
  },
  interval: 30,
  metrics: [{id: 'trv', name: ' Рейтинг (TVR)'}],
  dimensions: [
    {
      id: 'channel',
      name: 'Телеканал',
    },
  ],
  selectedOnDrillDown: [],
  metricsOnTheChart: 'trv',
  dataFilter: [],
  dataFilterSelected: [],
  sortBy: '-trv',
  presetName: '',
  presetInfo: null,
  loaded: false,
  preset: {
    interval: 30,
    metrics: [{id: 'trv', name: ' Рейтинг (TVR)'}],
    dimensions: [
      {
        id: 'channel',
        name: 'Телеканал',
      },
    ],
    selectedOnDrillDown: [],
    metricsOnTheChart: 'trv',
    dataFilter: [],
    dataFilterSelected: [],
    sortBy: '-trv',
  },
};

export const filter = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    changeDateRange: (state, action) => {
      state.range = action.payload;
    },
    changeSelectedDateRange: (state, action) => {
      state.selectedRange = action.payload;
    },
    changeMetrics: (state, action) => {
      state.metrics = action.payload;
    },
    changeInterval: (state, action) => {
      state.interval = action.payload;
    },
    changeDimensions: (state, action) => {
      state.dimensions = action.payload;
    },
    changeSelectedOnDrillDown: (state, action) => {
      state.selectedOnDrillDown = action.payload;
    },
    changeMetricsOnTheChart: (state, action) => {
      state.metricsOnTheChart = action.payload;
    },
    changeDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
    changeDataFilterSelected: (state, action) => {
      state.dataFilterSelected = action.payload;
    },
    changeSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setPresetName: (state, action) => {
      state.presetName = action.payload;
    },
    setPresetInfo: (state, action) => {
      state.presetInfo = action.payload;
    },
    setFilterLoaded: (state) => {
      state.loaded = true;
    },
    setPreset: (state, action) => {
      state.interval = action.payload.interval;
      state.dimensions = action.payload.dimensions;
      state.selectedOnDrillDown = action.payload.selectedOnDrillDown;
      state.metricsOnTheChart = action.payload.metricsOnTheChart;
      state.metrics = action.payload.metrics;
      state.dataFilter = action.payload.dataFilter ?? [];
      state.dataFilterSelected = action.payload.dataFilterSelected ?? [];
      state.sortBy = action.payload.sortBy ?? '-trv';

      state.preset = action.payload;
    },
  },
});

export const {
  changeDateRange,
  changeMetrics,
  changeInterval,
  changeDimensions,
  changeSelectedOnDrillDown,
  changeMetricsOnTheChart,
  changeDataFilter,
  changeSortBy,
  setPresetName,
  setPresetInfo,
  setPreset,
  changeDataFilterSelected,
  setFilterLoaded,
  changeSelectedDateRange,
} = filter.actions;

export default filter.reducer;
