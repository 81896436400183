import { useState } from 'react';

import Filters from './Filters';
import DataTable from './DataTable';

import '../tables/Tables.scss';

function Adverts() {
  const [ payload, setPayload ] = useState();

  // const data = JSON.parse('{"dates_range":{"start":"2023-06-08 05:00:00","end":"2023-08-22 05:00:00"},"target_base":null,"target_groups":[{"id":28,"name":"18+","is_group":false,"parent":null,"type":1,"content":{"data":[{"type":"condition","field":"age","value":"18","condition":">="}],"type":"block","union":"and"}}],"statistics":[{"name":"Audience (Аудитория)","type":"audience"},{"name":"TVR (Рейтинг)","type":"tvr"}],"columns":["date","channel","position","program","day_type","adiss_id"],"events":{"id":155,"name":"Тест РР 1","is_group":false,"parent":24,"type":6,"content":{"data":[{"type":"condition","field":"advert_id","value":[{"id":"a_3847263","path":[{"id":"a_3847263","name":"ГАЗПРОМ компания (ВНЧ футболисты, после хоккеистов команда баскетболистов) Спонсор показа","type":"advert"}]},{"id":"a_3892291","path":[{"id":"a_3892291","name":"ГАЗПРОМ компания (синий луч, КНЦ тит: Сила Сибири запущен в работу, узкий кадр)","type":"advert"}]}],"condition":"in"},{"type":"condition","field":"content","value":[1],"condition":"in"},{"type":"condition","field":"network_campaign","value":[1],"condition":"=="},{"type":"condition","field":"local_campaign","value":[0],"condition":"=="}],"type":"block","union":"and"},"advert_event":true},"grouped":null,"population_100":null}');

  // const data2 = JSON.parse('{"debug":true,"dates_range":{"start":"2023-07-10 05:00:00","end":"2023-07-10 05:00:00"},"target_base":null,"target_groups":[{"id":28,"name":"18+","is_group":false,"parent":null,"type":1,"content":{"data":[{"type":"condition","field":"age","value":"18","condition":">="}],"type":"block","union":"and"}}],"statistics":[{"name":"TVR (Рейтинг)","type":"tvr","wall":true}],"columns":["date","channel","primetime","day_of_week","brand","position","program","day_type","original_adiss_id","note","first_issued_day","ad_position_type","ad_position","subbrands_list","distribution","advert_id","orig_dur_in_sec","adiss_id","orig_pm_stmom","briss_id","advertisers_list","brands_list"],"events":{"id":173,"name":"федеральные блоки","is_group":false,"parent":null,"type":6,"content":{"data":[{"type":"condition","field":"distribution","value":["network"],"condition":"in"},{"type":"condition","field":"content","value":[1],"condition":"in"}],"type":"block","union":"and"},"advert_event":true},"grouped":null,"population_100":null}');

  return (
    <div className="tables">
      <Filters onFilter={(payload) => setPayload(payload)} />
      <br />
      <DataTable payload={payload} />
    </div>
  );
}

export default Adverts;
