import React from 'react';

// @ts-ignore
import { ReactComponent as Pause } from '../../assets/pause.svg';
// @ts-ignore
import { ReactComponent as Play } from '../../assets/play.svg';

export interface PlayButtonProps {
  play: boolean;
  disabled: boolean;
  onPlay: (play: boolean) => void;
}

export function PlayButton({ play, onPlay, disabled }: PlayButtonProps) {
  const attr = { disabled };

  return (
    <div className="play-button" {...attr}>
      {play ? (
        <Pause onClick={() => !disabled && onPlay(false)} />
      ) : (
        <Play onClick={() => !disabled && onPlay(true)} />
      )}
    </div>
  );
}
