import React from "react";
import { useData } from "../DataProvider";
import { Radio } from 'antd';

function Premier() {

  const { premier, setValue } = useData();

  const options = [{
    label: 'Все',
    value: 'all',
  }, {
    label: 'Премьеры',
    value: 'premier',
  }, {
    label: 'Повторы',
    value: 'repeat',
  }];

  return (
    <div className="premier-wrapper">
      <Radio.Group options={options} onChange={({ target: { value }}) => setValue('premier', value)} value={premier} />
    </div>
  );
}

export default Premier;