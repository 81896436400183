import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment';
import API from '@utils/AdminApi';

const startDate = moment().set('year', 2021).set('month', 0).set('day', 1).toDate();
const endDate = moment().set('year', 2021).set('month', 0).set('day', 2).toDate();

const initialState = {
  datesRange: {
    startDate,
    endDate,
    key: 'selection',
  },
  demographics: {},
};

export const fetchDemographics = createAsyncThunk(
  'collections/demographics',
  async (_, { getState }) => {
    const state = getState();
    if (!state.collections.demographics || state.collections.demographics.length === 0) {
      const data = await API.getDemographics()
      const output =  {};
      for (const v of data) {
        output[v['id']] = v;
      }
      return output ?? {}
    }
    return {};
  }
);

export const panel = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    changeSelectedDateRange: (state, action) => {
      state.datesRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDemographics.fulfilled, (state, action) => {
      state.demographics = action.payload;
    });
  },
});

export const {
  changeSelectedDateRange,
} = panel.actions;

export default panel.reducer;
