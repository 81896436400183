import { useCallback } from 'react';
import List from './List';

function Dropdown({ selected, onSelect, single, filter }) {
  const isSelected = useCallback(
    (value) => selected?.map(el => el.value)?.indexOf(value.value) > -1,
    [selected]
  );

  const select = (value) => {
    return single 
      ? ( onSelect([value]) )
      : (
        isSelected(value)
        ? onSelect(selected.filter(el => el.value !== value.value))
        : onSelect([...selected, value])
      );
  }

  return (
    <div className="multiselect-options">
      <List parent={null} selected={selected} onSelect={select} path={[]} filter={filter} single={single}></List>
    </div>
  )
}

export default Dropdown;