import Tooltip from 'antd/es/tooltip';
import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  RadioButtonBaseColor,
  RadioButtonOption,
} from '@UI/RadioButtonGroup/RadioButton';
import {
  MINUTE_TO_PX_SCALE,
  MIN_INLINE_CONTENT_EVENT_DURATION,
} from '@pages/schedule/models/constants';
import { ScheduleCellTVEvent } from '@pages/schedule/models/scheduleCellPayloadTVEvent';
import createScheduleCellLabel from '@pages/schedule/utils/createScheduleCellLabel';
import { getColorByRange } from '@pages/schedule/utils/getColorByRange';
import {
  addScheduleEventToAudienceCompare,
  setScheduleCellToCompare,
} from '@store/forms/FormStorage';
import { AudienceCompareGroup } from '@store/forms/FormStorage.types';
import { getAllEventsAudienceSelector } from '@store/forms/formStorageSelectors';

import { CellContentInline } from './CellContentInline';
import s from './CellContentTVEvent.module.scss';
import { CellContentTooltip } from './CellContentTooltip';

export type CellContentTVEventProps = {
  cellEntity: ScheduleCellTVEvent;
  cellHeight: number;
  className?: string;
};

const defaultRadioGroupOption = {
  value: AudienceCompareGroup.none,
  baseColor: RadioButtonBaseColor.none,
};

const radioGroupOptions: RadioButtonOption<AudienceCompareGroup>[] = [
  {
    value: AudienceCompareGroup.audienceX,
    baseColor: RadioButtonBaseColor.audienceX,
  },
  {
    value: AudienceCompareGroup.audienceY,
    baseColor: RadioButtonBaseColor.audienceY,
  },
  defaultRadioGroupOption,
];

/**
 * Минимальная высота ячейки (px).
 * Если высота ячейки меньше данной высоты,
 * то содержимое скрывается
 */
const MIN_INLINE_CELL_HEIGHT =
  MINUTE_TO_PX_SCALE * MIN_INLINE_CONTENT_EVENT_DURATION;

/**
 * Контент ячейки события в расписании
 */
const CellContentTVEvent: React.FC<CellContentTVEventProps> = ({
  cellEntity,
  cellHeight,
  className,
}: CellContentTVEventProps) => {
  const dispatch = useDispatch();

  const allAudienceEventsEnumeration = useSelector(
    getAllEventsAudienceSelector,
  );

  const [areControlsVisible, setAreControlsVisible] = React.useState(false);

  const chosenGroup = React.useMemo(() => {
    return allAudienceEventsEnumeration[cellEntity.payload.pissId];
  }, [allAudienceEventsEnumeration, cellEntity.payload.pissId]);

  const cssProps: React.CSSProperties = React.useMemo(() => {
    if (areControlsVisible) {
      return {};
    }

    return {
      backgroundColor: getColorByRange({
        max: cellEntity.payload.share,
        min: cellEntity.payload.share,
      }),
    };
  }, [cellEntity, areControlsVisible]);

  /**
   * хэндлер для добавления события в список сравнения аудиторий
   */
  const handleTableEventToggleCompare = (
    value: RadioButtonOption<AudienceCompareGroup>,
  ) => {
    /**
     * setScheduleCellToCompare - отправка в formData redux store данных из schedule таблицы по ячейкам, для возможности генерации label'ов внутри компонента Issued
     */
    dispatch(
      setScheduleCellToCompare({
        value: cellEntity.payload.pissId,
        label: createScheduleCellLabel(cellEntity),
      }),
    );

    dispatch(
      addScheduleEventToAudienceCompare({
        cellEntity,
        group: value.value,
      }),
    );
  };

  const onTvEventCellEnter = React.useCallback(() => {
    setAreControlsVisible(true);
  }, []);

  const onTvEventCellLeave = React.useCallback(() => {
    setAreControlsVisible(false);
  }, []);

  const chosenRadioOptionValue = React.useMemo(() => {
    return (
      radioGroupOptions.find((option) => option.value === chosenGroup) ||
      defaultRadioGroupOption
    );
  }, [chosenGroup]);

  const cellContentWrapperProps = {
    'data-cell-id': cellEntity.id,
    className: cn(
      s['schedule-table-cell-program'],
      s['schedule-table-cell-program_label'],
      s[`schedule-table-cell-program_label_${chosenGroup}`],
      s['schedule-table-cell-program_dashed'],
      cellEntity.payload.intersectsIntervalStart &&
        s['schedule-table-cell-program_dashed-intersects-interval-start'],
      cellEntity.payload.intersectsIntervalEnd &&
        s['schedule-table-cell-program_dashed-intersects-interval-end'],
      className,
    ),
    style: cssProps,
    onMouseEnter: onTvEventCellEnter,
    onMouseLeave: onTvEventCellLeave,
  };

  const shouldRenderInlineContent = cellHeight >= MIN_INLINE_CELL_HEIGHT;

  if (shouldRenderInlineContent) {
    return (
      <div {...cellContentWrapperProps}>
        <CellContentInline
          cellEntity={cellEntity}
          cellHeight={cellHeight}
          areControlsVisible={areControlsVisible}
          options={radioGroupOptions}
          value={chosenRadioOptionValue}
          onChange={handleTableEventToggleCompare}
        />
      </div>
    );
  }

  return (
    <Tooltip
      title={
        <CellContentTooltip
          cellEntity={cellEntity}
          options={radioGroupOptions}
          value={chosenRadioOptionValue}
          onChange={handleTableEventToggleCompare}
        />
      }
    >
      <div {...cellContentWrapperProps} />
    </Tooltip>
  );
};

export default CellContentTVEvent;
