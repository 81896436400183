/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from '@store/sidebar';
import filterReducer from '@store/filter';
import dictReducer from '@store/dict';
import chartReducer from '@store/chart';
import panelReducer from '@store/panel';
import collectionsReducer from '@store/collections';
import channelsReducer from '@store/channels';
import WallFormReducer from '@store/forms/WallForm';
import AverageFormReducer from '@store/forms/AverageForm';
import FormStorageReducer from '@store/forms/FormStorage';
import AudiencePanelReducer from '@store/audiencePanel';
import statusReducer from '@store/status';

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    filter: filterReducer,
    dict: dictReducer,
    chart: chartReducer,
    panel: panelReducer,
    collections: collectionsReducer,
    channels: channelsReducer,
    wallForm: WallFormReducer,
    averageForm: AverageFormReducer,
    formStorage: FormStorageReducer,
    audiencePanel: AudiencePanelReducer,
    status: statusReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
