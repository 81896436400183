import React, { useState } from 'react'
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import { PDAPI } from '@utils/PDApi';
import { toast } from "react-toastify";
import { Modal } from 'antd';

function ForgotPassword() {
  const [ isOpen, setIsOpen ] = useState(false);

  const doRequest = (email) => {
    PDAPI
      .resetPassword(email)
      .catch(error => {
        if (error.response && error.response.status === 400) {
          toast.error('Пользователь не найден.');
        } else {
          toast.error('Системная ошибка.');
        }
      })
      .then(result => {
        if (result?.data?.token) {
          toast.success('Письмо восстановления пароля отправлено.');

          setIsOpen(false);
        }
      });
  }

  return (
    <div>
      <span onClick={() => setIsOpen(true)}><span>Забыли пароль?</span></span>

      <Modal
        title="Восстановление пароля"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        footer={null}
      >
        <ForgotPasswordForm handleSubmit={doRequest} />
      </Modal>
    </div>
  )
}

export default ForgotPassword
