import { AxiosInstance } from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useCookies } from 'react-cookie';

import API, { admin_instance, api_instance } from '../utils/AdminApi';
import { apiInstance, adminInstance, PDAPI } from '../utils/PDApi';
import { apiVideoInstance } from '../utils/VideoApi';

import { useDispatch } from 'react-redux';
import { resetCache } from './collections';

export interface AuthState {
  isLoggedIn: boolean;
  currentUser: string | null;
  token: string;
  refresh: string;
  population: boolean | null;
}

export interface AuthActions {
  login: (token: string, refresh: string) => void;
  logout: () => void;
  authLoad: (email: string) => void;
  setPopulation: (population: boolean | null) => void;
}

export type AuthStorageContext = AuthActions & AuthState;

export enum AuthActionKind {
  IS_LOGGED_IN = 'isLoggedIn',
  SET_USER = 'setUser',
  SET_POPULATION = 'setPopulation',
}

interface StorageAction {
  type: AuthActionKind;
  payload: any;
}

export const AuthContext = React.createContext<AuthStorageContext>({
  isLoggedIn: false,
  currentUser: null,
  token: '',
  refresh: '',
  population: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (token: string, refresh: string) => {},
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  authLoad: (email: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPopulation: (population: boolean | null) => {},
});

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

const reducer = (state: AuthState, { type, payload }: StorageAction) => {
  switch (type) {
    case AuthActionKind.IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload.token ? true : false,
        token: payload.token,
        refresh: payload.refresh,
      };

    case AuthActionKind.SET_USER:
      return { ...state, currentUser: payload };

    case AuthActionKind.SET_POPULATION:
      return { ...state, population: payload };

    default:
      return { ...state };
  }
};

export interface StorageContextProps {
  children: any;
}

export const AuthProvider = ({ children }: StorageContextProps) => {
  const globalDispatch = useDispatch();

  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'email',
    'refresh',
    'population',
  ]);

  const token = cookies.token;

  const refresh = cookies.refresh;

  const currentUser = cookies.email;
  const population =
    cookies.population && cookies.population !== 'null'
      ? Boolean(cookies.population)
      : null;

  API.population100k = population;
  PDAPI.population100k = population;

  const domain = window.location.hostname.split('.').slice(-2).join('.');

  const path = '/';

  const expires = moment().add(1, 'days').toDate();

  const secure = true;

  const [state, dispatch] = React.useReducer(reducer, {
    isLoggedIn: token ? true : false,
    currentUser: currentUser ? currentUser.toString() : null,
    token: token ? token.toString() : '',
    refresh: refresh ? refresh.toString() : '',
    population: population,
  });

  const login = (token: string, refresh: string) => {
    dispatch({
      type: AuthActionKind.IS_LOGGED_IN,
      payload: { token, refresh },
    });
    setCookie('token', token, { domain, path, expires, secure });
    setCookie('refresh', refresh, { domain, path, expires, secure });
  };

  const logout = () => {
    globalDispatch(
      resetCache()
    );

    dispatch({
      type: AuthActionKind.IS_LOGGED_IN,
      payload: { token: '', refresh: '' },
    });

    removeCookie('token', { domain, path, expires, secure });
    removeCookie('email', { domain, path, expires, secure });
    removeCookie('refresh', { domain, path, expires, secure });
  };

  const authLoad = (email: string) => {
    dispatch({ type: AuthActionKind.SET_USER, payload: email });
    setCookie('email', email, { domain, path, expires, secure });
  };

  const setPopulation = (population: boolean | null) => {
    dispatch({ type: AuthActionKind.SET_POPULATION, payload: population });
    setCookie('population', population, { domain, path, expires, secure });
    API.population100k = population;
    PDAPI.population100k = population;
  };

  const retry = React.useRef(false);

  const retryPromise = React.useRef<Promise<string> | null>(null);

  const refreshToken = React.useCallback(
    async (error: any, instance: AxiosInstance) => {
      const config = error.config;

      if (config.url.indexOf('/user/login') === -1 && error.response) {
        if (error.response.status === 402) {
          logout();

          return Promise.reject();
        }

        if (
          (error.response.status === 401 || error.response.status === 403 || error.response.status === 0) &&
          !retry.current
        ) {
          retry.current = true;

          // eslint-disable-next-line no-async-promise-executor
          retryPromise.current = new Promise(async (resolve, reject) => {
            try {
              const rs = await PDAPI.refresh(state.refresh);

              const { token, refresh_token } = rs.data;

              login(token, refresh_token);

              config.headers.authorization = `Bearer ${token}`;

              apiInstance.defaults.headers.common.authorization = `Bearer ${token}`;
              adminInstance.defaults.headers.common.authorization = `Bearer ${token}`;

              admin_instance.defaults.headers.common.authorization = `Bearer ${token}`;
              api_instance.defaults.headers.common.authorization = `Bearer ${token}`;

              apiVideoInstance.defaults.headers.common.authorization = `Bearer ${token}`;

              retry.current = false;

              resolve(token);

              return instance(config);
            } catch (_error: any) {
              if (_error.response.status === 401 || _error.response.status === 402) {
                logout();
              }

              retry.current = false;

              reject();

              return Promise.reject(_error);
            }
          });
        } else if (retry.current && retryPromise.current !== null) {
          try {
            const token = await retryPromise.current;

            config.headers.authorization = `Bearer ${token}`;

            return instance(config);
          } catch (_error) {
            /* empty */
          }
        }
      }

      return Promise.reject(error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  React.useMemo(() => {
    apiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => refreshToken(error, apiInstance),
    );

    adminInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => refreshToken(error, adminInstance),
    );

    admin_instance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => refreshToken(error, admin_instance),
    );

    api_instance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => refreshToken(error, api_instance),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  React.useMemo(() => {
    if (state.token) {
      apiInstance.defaults.headers.common.authorization = `Bearer ${state.token}`;
      adminInstance.defaults.headers.common.authorization = `Bearer ${state.token}`;

      admin_instance.defaults.headers.common.authorization = `Bearer ${state.token}`;
      api_instance.defaults.headers.common.authorization = `Bearer ${state.token}`;

      apiVideoInstance.defaults.headers.common.authorization = `Bearer ${state.token}`;
    } else {
      delete apiInstance.defaults.headers.common.authorization;
      delete adminInstance.defaults.headers.common.authorization;

      delete admin_instance.defaults.headers.common.authorization;
      delete api_instance.defaults.headers.common.authorization;

      delete apiVideoInstance.defaults.headers.common.authorization;
    }
  }, [state.token]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: state.isLoggedIn,
        currentUser: state.currentUser,
        token: state.token,
        refresh: state.refresh,
        population: state.population,
        login,
        logout,
        authLoad,
        setPopulation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
