import React from 'react';

function TimePicker({
  className,
  disabled,
  name,
  onChange,
  onKeyDown,
  onKeyUp,
  placeholder = '--:--',
  required,
  value,
}) {
  const onChangeHandle = (event) => {
    let value = event.target.value;

    if (value.length >= 3 && (value.indexOf(':') === -1 || value.indexOf(':') > 3)) {
      value = value.replace(/:/, '');

      value = value.substr(0, 2) + ':' + value.substring(2);
    }

    if (onChange) {
      onChange(value);
    }
  }

  const onKeyPressHandle = (event) => {
    const { key, target } = event;
    const { value } = target;

    const isNumberKey = key.length === 1 && /\d/.test(key);

    if (!((key === ':' && value.length === 2) || isNumberKey) || value.length >= 5) {
      event.preventDefault();
    }
  };

  return (
    <input
      key="input"
      className={className}
      data-input="true"
      disabled={disabled}
      name={name}
      onChange={onChangeHandle}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPressHandle}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      required={required}
      value={value !== null ? value : ''}
    />
  );
}

export default TimePicker;
