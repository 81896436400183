import React from 'react'
import { useAuthContext } from '@store/AuthProvider';
import { PDAPI } from '@utils/PDApi';
import { toast } from "react-toastify";

import { ReactComponent as LogoutIcon } from '@UI/assets/exit.svg';

function Logout() {
  const { logout: authLogout } = useAuthContext();

  const logoutRequest = () => {
    PDAPI.logout()
      .catch(error => {
        toast.error('Системная ошибка.');
      })
      .then(result => {
        authLogout();
      });
  }

  return (
    <div className='user-menu-item-wrapper' onClick={logoutRequest}>
      <div><LogoutIcon /></div>
      <div>Выйти</div>
    </div>
  )
}

export default Logout
