import { Audience } from '@models/index';
import { OptionEntity } from '@models/optionEntity';

export const getAudienceOptionEntity = (
  targetAudience: Audience,
): OptionEntity => {
  return {
    key: targetAudience.id,
    children: targetAudience.name,
  };
};
