import React from 'react';
import { useVideosContext } from '../../store/VideosProvider';

// @ts-ignore
import { ReactComponent as Button } from '../../assets/prev.svg';

export function PrevButton() {

  const {
    prevPeriod,
  } = useVideosContext();

  return (
    <div className="prev-button">
      <Button onClick={() => prevPeriod()} />
    </div>
  );
}
