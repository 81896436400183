import { useRef } from 'react';
import { DataProvider } from './DataProvider';
import { OptionsProvider } from './OptionsProvider';
import Component from './Component';

function TreeSelect({
  selected,
  loader,
  searchLoader,
  search,
  options,
  single,
  placeholder,
  line,
  searchOnly,
  onChange,
  treeSearch,
  keyField = 'id',
  labelField = 'name',
  selectFlag = null,
  selectFilter,
  hideSelected = false,
  getIcon,
}) {
  const className = 'multiselect-wrapper ' + (line ? 'one-line' : '');

  const wrapperRef = useRef();

  return (
    <div className="multiselect" ref={wrapperRef}>
      <OptionsProvider
        options={options}
        loader={loader}
        search={search}
        searchLoader={searchLoader}
      >
        <DataProvider
          single={single}
          keyField={keyField}
          labelField={labelField}
          selectFlag={selectFlag}
          selectFilter={selectFilter}
          getIcon={getIcon}
        >
          <Component 
            className={className}
            placeholder={placeholder}
            line={line}
            searchOnly={searchOnly}
            onChange={onChange}
            treeSearch={treeSearch}
            hideSelected={hideSelected}
            selected={selected}
          />
        </DataProvider>
      </OptionsProvider>
    </div>
  )
}

export default TreeSelect;