import {
  getTVTimeIntervalsIntersection,
  TableTVTimeIntervalEntity,
  TVTimeIntervalEntityWithIntersection,
  TVTimeIntervalEntity,
} from './timeIntervalValueKind';

export const getScheduleEventPosition = (
  tvEvent: TVTimeIntervalEntity,
  allTimeBands: TableTVTimeIntervalEntity[],
): null | TVTimeIntervalEntityWithIntersection => {
  const filteredIntervals = allTimeBands.filter((interval) =>
    getTVTimeIntervalsIntersection(interval, tvEvent),
  );

  if (filteredIntervals.length === 0) {
    return null;
  }

  const firstInterval = filteredIntervals[0];

  const lastInterval = filteredIntervals[filteredIntervals.length - 1];

  const intersectsIntervalStart =
    firstInterval.minutesStart > tvEvent.minutesStart;

  const intersectsIntervalEnd = lastInterval.minutesEnd < tvEvent.minutesEnd;

  const eventYStart =
    firstInterval.yStart +
    Math.max(0, tvEvent.minutesStart - firstInterval.minutesStart);

  const eventYEnd =
    lastInterval.yEnd +
    Math.min(0, tvEvent.minutesEnd - lastInterval.minutesEnd);

  const isEventStartedBeforeTimeBand =
    tvEvent.minutesStart < firstInterval.minutesStart;

  const min = isEventStartedBeforeTimeBand ? tvEvent : firstInterval;

  const max = tvEvent === min ? lastInterval : tvEvent;

  if (min.minutesEnd <= max.minutesStart) {
    return null;
  }

  return {
    minutesStart: eventYStart,
    minutesEnd: eventYEnd,
    intersectsIntervalStart,
    intersectsIntervalEnd,
  };
};
