/* eslint-disable */
import React, { useState, useMemo, useCallback, useEffect } from 'react';

import Channels from '@components/Sources/groups/Channels';
import Statistics from '@components/Sources/groups/Statistics';
import Targets from '@components/Sources/groups/Targets';
import moment from 'moment';
import { Button } from '@components/UI/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useFilters } from './FiltersProvider';
import API from '@utils/AdminApi';
import { setChannelsPage } from '../../store/forms/FormStorage';
import  {useAuthContext } from "@store/AuthProvider";
import { useFormTemplatesProvider } from '@components/FormTemplates/FormTemplatesProvider';

export default function ChannelsFilters({ onSubmit, period, onChangeFilters, defaultValue }) {
  const dispatch = useDispatch();

  const tmplProvider = useFormTemplatesProvider();

  const { targetBase, channels, statistics, setFilter, setFilters } = useFilters();

  const [ isLoading, setIsLoading ] = useState(false);

  let channelsPage = useSelector((state) => state.formStorage.channelsPage);

  const { population: population_100 } = useAuthContext();

  const onChange = useCallback(
    (value, field) => {
      setFilter(value, field);

      onChangeFilters && onChangeFilters({ ...channelsPage.filters, [field]: value });

      dispatch(
        setChannelsPage({
          period: channelsPage.period,
          response: channelsPage.response,
          filters: { ...channelsPage.filters, [field]: value },
        }),
      );
    },
    [channelsPage],
  );

  const getChannels = async () => {
    const data = [];

    if (channels) {
      for (let i = 0; i < channels.length; i++) {
        if (Number.isInteger(channels[i])) {
          data.push(channels[i]);
        } else {
          const response = await API.getCollection(channels[i].id);

          if (response && response.data) {
            data.push(response.data);
          }
        }
      }
    }

    return data.length ? data : null;
  };


  const prepareFilters = async () => {
    if (!targetBase || !channels.length || !statistics) {
      return;
    }

    setIsLoading(true);

    const base = await API.getCollection(targetBase.id);

    const payload = {
      debug: false,
      target_base: base.data,
      statistics: statistics,
      events:
        {
          channels: await getChannels(channels),
          aggregated: false,
          premier: null
      },
      dates_range: {
        start: moment(period.startDate).format('YYYY-MM-DD 05:00:00',),
          end: moment(period.endDate).format('YYYY-MM-DD 05:00:00')
        },
      population_100: population_100
    };

    let response = await API.getChannelsStatistics(payload);

    if (onSubmit) {
      onSubmit({
        target: base.data,
        data: response,
      });
    }

    setIsLoading(false);
  };

  const targetBaseValue = useMemo(
    () => (targetBase ? [targetBase.id] : []),
    [targetBase],
  );

  const channelsValue = useMemo(
    () =>
      channels
        ? channels.map((channel) =>
            channel.content ? channel.id : 'ch' + channel,
          )
        : [],
    [channels],
  );

  const statisticsValue = useMemo(
    () => (statistics ? statistics.map(({ type }) => type) : []),
    [statistics],
  );

  useEffect(() => {
    if (tmplProvider.filters !== null) {
      setFilters(tmplProvider.filters);

      dispatch(
        setChannelsPage({
          period: channelsPage.period,
          response: channelsPage.response,
          filters: tmplProvider.filters
        }),
      );

      tmplProvider.reset();
    }
  }, [ tmplProvider.filters ])

  return (
    <>
      <div className="pdcard-filters">
        <Targets
          name="targetBase"
          value={targetBaseValue}
          onChange={(value) => onChange(value, 'targetBase')}
        />

        <div className="two-columns">
          <Channels
            name="channels"
            isRequired={true}
            placeholder="Выберите канал"
            className="form-control"
            value={channelsValue}
            onChange={(value) => onChange(value, 'channels')}
          />

          <Statistics
            name="statistics"
            placeholder="Выберите статистику"
            className="form-control"
            single={true}
            value={statisticsValue}
            source="channels"
            onChange={(value) => onChange(value, 'statistics')}
          />
        </div>
      </div>
      <div className="pdcard-buttons">
        <Button
          onClick={() => prepareFilters()}
          // disabled={isLoading}
          disabled={false}
          type="primary"
          label="Показать"
        />
      </div>
    </>
  );
}
