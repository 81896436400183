/* eslint-disable */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import API from '@utils/AdminApi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import DatePicker from '@components/DatePicker/DatePicker';
import { useNavigate } from 'react-router-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Channels from '@components/Sources/groups/Channels';
import { fetchChannels, fetchStatistics, fetchCollections } from '@store/collections';
import Statistics from '@components/Sources/groups/Statistics';
import Targets from '@components/Sources/groups/Targets';
import moment from 'moment';
import {
  setProgramsSearch,
  setProgramsFilters,
  setProgramsSeries,
} from '../../store/forms/FormStorage';
import { useAuthContext } from '@store/AuthProvider';
import './Program.scss';

moment.locale('ru');

function SearchResult(props) {
  let data = props.data && props.data.data ? props.data.data : [];

  return (
    // todo KTS create general layout component
    <div className="issued-wrapper">
      <div className="issued-content">
        {data.map((entry, index) => (
          <div className="issued" key={index}>
            <Link
              to={{
                pathname: `/programs/${entry.program_id}/channel/${entry.channel_id}`,
              }}
            >
              <span>
                {entry.channel_name} : {entry.program_name}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

function Programs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const programsPage = useSelector((state) => state.formStorage.programsPage);
  const inited = useRef(false);

  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState('');

  const [date, setDate] = useState(moment().subtract(1, 'days').toDate());
  const [target, setTarget] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [channel, setChannel] = useState([]);

  const { population } = useAuthContext();

  const channelValue = useMemo(
    () =>
      channel ? channel.map((ch) => (ch.content ? ch.id : 'ch' + ch)) : [],
    [channel],
  );

  const statisticsValue = useMemo(
    () => (statistics ? statistics.map(({ type }) => type) : []),
    [statistics],
  );

  const targetValue = useMemo(
    () => target ? [target.id] : [],
    [target],
  );

  const [series, setSeries] = useState([]);

  const onClick = (e) => {
    navigate(e.point.id);
  };

  useEffect(() => {
    if (programsPage && !inited.current) {
      inited.current = true;

      setSearchTerm(programsPage.search.term);
      setSearchResults(programsPage.search.results);

      setSeries(programsPage.series);

      if (programsPage.filters.date) {
        setDate(programsPage.filters.date);
      }

      setStatistics(programsPage.filters.statistics);
      setChannel(programsPage.filters.channel);
      setTarget(programsPage.filters.target);

      inited.current = true;
    }
  }, [programsPage]);

  useEffect(() => {
    if (inited.current) {
      dispatch(
        setProgramsSearch({
          term: searchTerm,
          results: searchResults,
        }),
      );
    }
  }, [searchTerm, searchResults]);

  useEffect(() => {
    if (inited.current) {
      dispatch(
        setProgramsFilters({
          statistics,
          date,
          channel,
          target,
        }),
      );
    }
  }, [ date, statistics, channel ]);

  useEffect(() => {
    if (inited.current) {
      dispatch(setProgramsSeries(series));
    }
  }, [series]);

  useEffect(() => {
    if (date && statistics.length > 0 && channel.length > 0 && target && target.id) {
      setLoading(true);

      API.getCollection(target.id).then(
        (targetResponse) => {
          const payload = {
            target_base: targetResponse.data ?? {},
            channels: channel,
            statistics: statistics,
            date: date,
            timeband: null
          }
          payload.date = moment(payload.date).format('YYYY-MM-DD')
          API.getBroadcastData(payload)
            .then((response) => {
              if (response) {
                let data = [];

                response.series[0].data.forEach(record => {
                  const start = record.event_start.split(' ')[1];
                  const end = record.event_end.split(' ')[1];
                  const time = `${start} - ${end}`;
                  const value = record['audience'] || record['tvr'] || record['share'];
                  data.push({name: `${time} ${record.program}`, y: Math.round(value * 100) / 100, events: {click: onClick},
                  id: `/programs/${record.program_id}/channel/${channel[0]}/issued/${record.piss_id}`});
                });

                setSeries(data);
                setLoading(false);
              }
            })
            .catch(() => {
              setSeries([]);
              setLoading(false);
            });
        }
      );
    } else {
      setSeries();
    }
  }, [ date, channel, target, statistics, population ]);

  useEffect(() => {
    dispatch(fetchCollections());
    dispatch(fetchChannels());
    dispatch(fetchStatistics('programs'));
  }, []);

  const options = useMemo(
    () => ({
      chart: {
        type: 'bar',
        height: 40 + (series ? series.length * 20 : 0),
      },
      title: {
        text: null,
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        },
        visible: false,
      },
      plotOptions: {
        bar: {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            color: '#716F6F',
            format: '{point.y}',
            align: 'left',
            style: {
              fontSize: '12px',
              fontFamily: 'Roboto',
              fontWeight: 600,
            },
          },
          colorByPoint: true,
          colors: ['#EBEFF6', '#D9E0ED'],
        },
        series: {
          pointPadding: 0,
          groupPadding: 0,
        },
      },
      series: [
        {
          data: series,
        },
      ],
    }),
    [series],
  );

  useEffect(() => {
    let timer = null;

    if (searchTerm && searchTerm.length > 3) {
      timer = setTimeout(() => {
        API.getSearchPrograms(searchTerm)
          .then((response) => setSearchResults(response ?? []))
          .catch(() => setSearchResults([]));

        timer = null;
      }, 500);

      return () => {
        if (timer !== null) {
          clearTimeout(timer);
        }
      };
    }
  }, [searchTerm]);

  return (
    <div>
      <div className="card-wrapper">
        <div className="card-title-full">
          <div className="search-wrapper">
            <div className="form-control">
              <label>Поиск программы</label>
              <Input
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        {!!searchResults &&
          !!searchResults.data &&
          !!searchResults.data.length && <SearchResult data={searchResults} />}
      </div>

      <br />

      <div className="card-wrapper">
        <div className="card-title">
          <div className="title">Программа передач</div>
        </div>
        <div className="card-content">
          <div className="filters-white-wrapper">
            <Targets
              name="targetBase"
              className="form-control"
              onChange={setTarget}
              placeholder={'Выберите целевая аудитория/группа'}
              label="Целевая аудитория/группа"
              value={targetValue}
              single={true}
            />

            <Channels
              name="channels"
              isRequired={true}
              placeholder="Выберите канал"
              className="form-control"
              value={channelValue}
              onChange={setChannel}
              label="Канал"
              single={true}
              channelsOnly={true}
            />

            <Statistics
              name="statistics"
              className="form-control"
              onChange={setStatistics}
              placeholder={'Выберите статистику'}
              label="Статистика"
              value={statisticsValue}
              single={true}
              source="programs"
            />

            <DatePicker
              onChange={(value) => setDate(value ?? null)}
              placeholder="Выберите дату"
              value={date}
              format="DD.MM.YYYY"
              label="Дата"
            />


          </div>
        </div>
        {(loading || !!series) && (
          <div className="chart-wrapper">
            <div className="title">Эфир</div>
            <HighchartsReact highcharts={Highcharts} options={options} />
            {loading && (
              <div className="is-loading">
                <div>Загрузка...</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Programs;
