import { ColorRangeConfigEntity } from '../models/colorRangeConfigEntity';

export const FALLBACK_COLOR = 'transparent';

export const colorRangeConfig: ColorRangeConfigEntity[] = [
  {
    title: '< 0,5%',
    value: {
      min: -Infinity,
      max: 0.5,
    },
    color: '#99ffaf',
  },
  {
    title: '0,5 — 1%',
    value: {
      min: 0.5,
      max: 1,
    },
    color: '#99ff99',
  },
  {
    title: '1 — 1,5%',
    value: {
      min: 1,
      max: 1.5,
    },
    color: '#b0ff98',
  },
  {
    title: '1,5 — 2%',
    value: {
      min: 1.5,
      max: 2,
    },
    color: '#c6ff98',
  },
  {
    title: '2 — 2,5%',
    value: {
      min: 2,
      max: 2.5,
    },
    color: '#ddff97',
  },
  {
    title: '2,5 — 3%',
    value: {
      min: 2.5,
      max: 3,
    },
    color: '#f4ff97',
  },
  {
    title: '3 — 5%',
    value: {
      min: 3,
      max: 5,
    },
    color: '#fff397',
  },
  {
    title: '5 — 10%',
    value: {
      min: 5,
      max: 10,
    },
    color: '#ffdc96',
  },
  {
    title: '10 — 15%',
    value: {
      min: 10,
      max: 15,
    },
    color: '#ffc496',
  },
  {
    title: '15 — 20%',
    value: {
      min: 15,
      max: 20,
    },
    color: '#ffad95',
  },
  {
    title: '> 20%',
    value: {
      min: 20,
      max: Infinity,
    },
    color: '#ff9595',
  },
];
