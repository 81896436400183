import { FormControl, FormLabel } from '@chakra-ui/react';
import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import MultiSelect from '@components/MultiSelect';
import { ChannelFilterEntity } from '@models/channelFilterEntity';
import { GlobalStoreEntity } from '@models/globalStoreEntity';
import { CollectionOptions, Collection } from '@models/index';
import { getTreeOptions } from '@models/utils/getTreeOptions';
import {
  CHANNELS_PRESET_VALUE,
  CHANNEL_PREFIX,
} from '@pages/schedule/models/constants';

import { prepareSelectedChannelValues } from '../../models/utils/prepareSelectedChannelValues';

type ChannelOptions = {
  value: string;
  label: string;
  children?: CollectionOptions[];
};

type ChannelValue = (string | number)[];

type ChannelsProps = {
  className?: string;
  value: ChannelValue;
  onChange?: (value: (ChannelFilterEntity | Collection)[]) => void;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  search?: boolean;
  dropDown?: boolean;
  single?: boolean;
  oneLine?: boolean;
};

// todo KTS refactor
const Channels: React.FC<ChannelsProps> = ({
  className,
  onChange,
  label,
  placeholder,
  isRequired,
  search,
  dropDown,
  single,
  oneLine,
  value,
}) => {
  const [selectedValues, setSelectedValues] = React.useState<ChannelValue>([]);

  const channelsCollection = useSelector(
    (state: GlobalStoreEntity) => state.collections.channelsCollections,
  );

  const channels = useSelector(
    (state: GlobalStoreEntity) => state.collections.channels,
  );

  /**
   * Массив наборов каналов (находится в единственном элементе массива коллекций каналов в поле children)
   */
  const collections = React.useMemo(() => {
    return channelsCollection.length ? channelsCollection[0].children : [];
  }, [channelsCollection]);

  /**
   * CHANNELS_PRESET_VALUE и CHANNEL_PREFIX необходимы
   * для поддержки существующего поведения фильтра каналов
   * (для различия в одном массиве каналов и пресета каналов)
   */
  const options: ChannelOptions[] = React.useMemo(() => {
    return [
      {
        value: CHANNELS_PRESET_VALUE,
        label: 'Наборы каналов',
        children: getTreeOptions(collections),
      },
      ...channels.map((channel) => {
        return {
          value: `${CHANNEL_PREFIX}${channel.id}`,
          label: channel.name,
        };
      }),
    ];
  }, [collections, channels]);

  const handleBlur = React.useCallback(
    (selectedValues: ChannelValue) => {
      if (onChange) {
        /**
         * Список id каналов и целых наборов каналов
         */
        const selectedChannels = prepareSelectedChannelValues(
          selectedValues,
          collections,
        );

        /** Смешанный список объектов каналов и наборов каналов */
        onChange(
          selectedChannels
            .map((selectedChannel) => {
              return typeof selectedChannel === 'object'
                ? selectedChannel
                : channels.find((channel) => channel.id === selectedChannel);
            })
            .filter(Boolean) as ChannelFilterEntity[],
        );
      }
    },
    [onChange, collections, channels],
  );

  React.useEffect(() => {
    setSelectedValues(value);
  }, [ value ]);

  return (
    <FormControl className={cn(className)} isRequired={isRequired ?? false}>
      {!!label && <FormLabel>{label}</FormLabel>}

      <MultiSelect
        options={options}
        onChange={setSelectedValues}
        onBlur={handleBlur}
        selected={selectedValues}
        search={search ?? true}
        dropDown={dropDown ?? true}
        single={single ?? false}
        placeholder={placeholder ?? ''}
        path={true}
        line={oneLine ?? false}
      />
    </FormControl>
  );
};

export default Channels;
