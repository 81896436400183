import React, { useEffect, useState, useMemo, useRef } from 'react';
import EventsAudience from '@components/EventsAudience/EventsAudience';
import { useDispatch, useSelector } from 'react-redux';
import {fetchChannels, fetchStatistics, fetchCollections, fetchChannelsCollections} from '@store/collections';
import Targets from '../../components/Sources/groups/Targets';
import { Button } from '@components/UI/Buttons';
import API from '@utils/AdminApi';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from "react-router-dom";
import { setEventsAudience } from '../../store/forms/FormStorage';
import moment from 'moment-timezone';

import { ReactComponent as Right } from '../collections/crud/UI/images/right.svg';
import { ReactComponent as Down } from '../collections/crud/UI/images/down.svg';

import './CompareAudience.scss';
import {useAuthContext} from "@store/AuthProvider";
import population from "@components/Population/Population";
import Common from "@utils/common";


function CompareAudience() {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const highchartsRef = useRef();

  const [ target, setTarget ] = useState();

  const [ payloadX, setPayloadX ] = useState();
  const [ payloadY, setPayloadY ] = useState();

  const targetValue = useMemo(() => target ? [ target.id ] : null, [ target ]);
  const [qualReach, setQualReach] = useState({g0: "50", g1: "75", g2: "90"});

  const [ isOpen, setIsOpen ] = useState(true);

  const [ isLoading, setIsLoading ] = useState(false);

  const [ chartData, setChartData ] = useState({});

  let eventsAudience = useSelector((state) => state.formStorage.eventsAudience);
  const { population: population_100 } = useAuthContext();

  const toggle = () => {
    setIsOpen(value => !value);
  }

  useEffect(() => {
    dispatch(
      fetchChannels()
    );

    // dispatch(
    //   fetchStatistics('compare')
    // );

    dispatch(
      fetchCollections()
    );

    // dispatch(
    //   fetchChannelsCollections()
    // );
  }, [ ]);

  const onChange = (num, payload) => {
    switch (num) {
      case 1:
        setPayloadX(payload);
        dispatch(setEventsAudience({ ...eventsAudience, payloadX: payload }));
        break;

      case 2:
        setPayloadY(payload);
        dispatch(setEventsAudience({ ...eventsAudience, payloadY: payload }));
        break;

      default:
        break;
    }
  }

  const onGroupValueChange = (payload) => {
    setQualReach(payload);
    dispatch(setEventsAudience({ ...eventsAudience, groups: payload}));
  }

  const onTargetChange = (target) => {
    setTarget(target);
    dispatch(setEventsAudience({ ...eventsAudience, target }));
  }

  const onChartData = (chartData) => {
    setChartData(chartData);
    dispatch(setEventsAudience({ ...eventsAudience, chartData }));
  }

  useEffect(() => {
    setQualReach(eventsAudience.groups ?? {g0: "50", g1: "75", g2: "90"})
    setTarget(eventsAudience.target ?? null);
    setPayloadX(eventsAudience.payloadX ?? null);
    setPayloadY(eventsAudience.payloadY ?? null);
    setChartData(eventsAudience.chartData ?? {});
  }, [ ]);

  const isValid = useMemo(() => {
    let valid = true;

    for (let i in payloadX) {
      valid = valid && !!payloadX[i];
    }

    for (let i in payloadY) {
      valid = valid && !!payloadY[i];
    }

    return valid;
  }, [ payloadX, payloadY ]);

  const chartOptions = useMemo(() => ({chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    xAxis: chartData.xAxis,
    legend: {
      align: 'left',
      x: 70,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
      title: {
        text: 'Qual.Reach аудитории'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Аудитория (тыс) - Qual.Reach'
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray',
          textOutline: 'none'
        }
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
        ,
        events: {
          click: (e) => {
              navigate('/panel/audience', {
                state: {
                  members: e.point.custom.members,
                  source: e.point.custom.source
                }
              })
          }
        }}
    },
    series: chartData.series ?? {},
  }), [ chartData ]);

  const compare = async () => {
    let base = [];

    setIsLoading(true)

    if (target) {
      base = await API.getCollection(target.id);
      base = base.data;
    }

    // INFO: Get collections body

    let payload = {
      qual_reach: qualReach,
      target_base: base,
      method: 'intersection',
      population_100: population_100,
      events: [Common.copy(payloadX), Common.copy(payloadY)]
    };

    for (let event of payload.events) {
      delete event.source;
      delete event.filter;

      if (event.timeband) {
        event.timeband = await API.getCollection(event.timeband.id);
        event.timeband = event.timeband.data;
      }

      if (event.date) {
        event.date = moment(event.date).format('YYYY-MM-DD') + ' 05:00:00';
        event.dates_range = {"start": event.date, "end": event.date}
        delete event.date
      }

      if (event.premier) {
        event.premier = {'all': null, 'premier': true, 'repeat' : false}[event.premier]
      }

      if (event.channel) {
        event.channels = event.channel;
        delete event.channel;
      }

      if (event.program) {
        event.programs = event.program;
        delete event.program;
      }

      if (event.issued) {
        event.issued_ids = event.issued;
        delete event.issued;
      }
    }

    API
      .postCompare(payload)
      .then((data) => {
        for (let chartName in data.data.charts) {
          onChartData({ chartName, ...data.data.charts[chartName] });
          break;
        }
      })
      .finally(() => setIsLoading(false))
  }


  return (
    <div className="compare-audience">
      <div className="compare-audience-wrapper">
        <div className="compare-audience-title">
          <div className="list" onClick={() => toggle()}>{ isOpen ? <Down /> : <Right /> }</div>
          <div className="title" onClick={() => toggle()}>Сравнение аудиторий</div>
        </div>
        <div className={`compare-audience-filters${isOpen ? "" : " hidden"}`}>
          <Targets
            name="targetBase"
            value={targetValue}
            onChange={onTargetChange}
            label="Целевая аудитория"
            className="target-control"
            placeholder="Целевая аудитория"
          />
          <div className="form-control">
            <label>Qual.Reach%</label>
            <div className="qual-reach">
              <span>G0&gt;</span>
              <input type="text" id="g0" value={qualReach.g0} onChange={(e) => onGroupValueChange({...qualReach, g0: e.target.value})}/>

              <span>G1&gt;</span>
              <input type="text" id="g1" value={qualReach.g1} onChange={(e) => onGroupValueChange({...qualReach, g1: e.target.value})}/>

              <span>G2&gt;</span>
              <input type="text" id="g2" value={qualReach.g2} onChange={(e) => onGroupValueChange({...qualReach, g2: e.target.value})}/>
            </div>
          </div>
          <div className="compare-audience-events">
            <div>
              <EventsAudience onChange={payload => onChange(1, payload)} defaultValue={payloadX} />
            </div>
            <div>
              <EventsAudience onChange={payload => onChange(2, payload)} defaultValue={payloadY} />
            </div>
          </div>
          <div className="buttons">
            <Button
              onClick={() => compare()}
              // disabled={isLoading}
              disabled={false}
              type="primary"
              label="Сравнить"
            />
          </div>
        </div>
      </div>
      { !isLoading && !!chartData &&
        <div className="compare-audience-chart">
          <div className="compare-audience-title">{chartData.chartName}</div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={highchartsRef} allowChartUpdate={true}/>
        </div>
      }
    </div>
  );
}

export default CompareAudience;
