import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import { MoreStats } from '../../components/Video/MoreStats';
import { Video } from '../../components/Video/Video';
import { Filters } from '../../components/VideoUI/Filters';
import { useAuthContext } from '../../store/AuthProvider';
import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useVideosContext } from '../../store/VideosProvider';
import { stringToTime } from '../../utils/index';

import { Results } from './Results';

import './Dashboard.scss';
import './DashboardScreen.scss';

export function Dashboard() {
  const { isLoggedIn } = useAuthContext();

  const {
    channels: dictChannels,
    loadStatistics,
    loadChannels,
    loadDatesWithVideo,
    loadAudiences,
  } = useDictionaryContext();

  const {
    duration,
    statistics,
    audience,
    date,
    interval,
    channels,
    setChannel,
  } = useVideosContext();

  const showResults = useMemo(() => {
    return statistics && audience && date && interval && channels;
  }, [statistics, audience, date, interval, channels]);

  useEffect(() => {
    loadStatistics();
    loadDatesWithVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAudiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (date && interval) {
      const current = moment(`${date}`, 'YYYY-MM-DD').add(
        stringToTime(interval),
        'minutes',
      );

      loadChannels(current.format('YYYY-MM-DDTHH:mm:ss'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, interval]);

  useEffect(() => {
    if (channels) {
      channels.forEach((channel, index) => {
        const hasChannel = dictChannels?.filter(({ id }) => id === channel);

        if (!hasChannel || hasChannel.length === 0) {
          setChannel(0, index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictChannels]);

  return (
    <div className="dashboard">
      <div className="dashboard-wrapper">
        <div className="dashboard-title">Телеканалы</div>
        <Filters />
        <div className="videos">
          <div>
            <Video index={0} />
          </div>
          <div>
            <Video index={1} />
          </div>
          <div>
            <Video index={2} />
          </div>
          <div>
            <Video index={3} />
          </div>
        </div>

        {showResults && <MoreStats />}
      </div>

      {showResults && !!duration && <Results />}
    </div>
  );
}
