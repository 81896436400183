import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDemographics, fetchChannels } from '@store/collections';
import MultiSelect from '@components/MultiSelect';

import { DeleteIcon } from './UI/Buttons';

import FieldSelect from './UI/FieldSelect';
import ConditionSelect from './UI/ConditionSelect';

const getValue = (initedValue, value, condition) => {
  if (['in', 'not in'].indexOf(condition) !== -1) {
    return value ?? '';
  }

  if (initedValue.type === 'list' && (value === '' || value === null)) {
    return initedValue.options[0].value;
  } else if (initedValue.type === 'list' && Array.isArray(value) && value[0] === '') {
    return [
      initedValue.options[0].value,
      initedValue.options[0].value
    ]
  }

  return ['datetime', 'time'].indexOf(initedValue.type) !== -1
      ? condition === 'between' ? (
        (value ? [new Date(value[0]), new Date(value[1])] : [new Date(), new Date()])
      ) : (value ? new Date(value) : new Date())
      : (value ?? '');
}

function Condition(props) {
  const demographics = useSelector((state) => state.collections.demographics);
  const channels = useSelector((state) => state.collections.channels);
  const conditionOptions6 = useSelector((state) => state.collections.conditionOptions);

  // каркас объекта с полями для каждого типа, дополняется из 
  const fieldOptions = [{
    type: 1,
    options: [],
  }, {
    type: 2,
    options: [{
      key: 'channel_id',
      name: 'Канал',
      type: 'list',
    }],
  }];

  // сосояния полей
  const [field, setField] = useState();
  const [condition, setCondition] = useState();
  const [value, setValue] = useState();
  
  const [valueType, setValueType] = useState();

  const conditionOptions = useMemo(() => {
    return field === 'channel_id'
      ? ['in',]
      : ['==', '!=', '>=', '<=', '>', '<', 'in', 'not in', 'between',];
  }, [field]);

  // костыль для Cascader, ключи первого уровня не могут быть числовыми
  const multipleValue = useMemo(() => {
    if (!value) {
      return [];
    }

    return `${value}`.split(',').map(id => parseInt(id));
  }, [ value ]);

  const isBetween = useMemo(() => condition === 'between', [ condition ]);

  const fields = useMemo(() => {
    const getDemographics = () => {
      return demographics.map(item => {
        return {
          key: item.id,
          name: item.name,
          type: (item.hasOwnProperty('values') && item.values) || (item.hasOwnProperty('options') && item.options) ? 'list' : 'text',
        }
      });
    }

    const options = props.type === 1
      ? getDemographics()
      : (
        props.type ===  6 ? conditionOptions6 : fieldOptions.filter(field => field.type === props.type)?.pop()?.options
      );

    return options ?? [];
  }, [ props.type, demographics, conditionOptions6 ]);

  // подготовка значений для поля
  const initValue = (field, fields) => {
    const fieldData = fields.filter(entry => entry.key === field)?.pop();

    if (fieldData && fieldData.type) {
      let options = [];

      if (fieldData.type === 'list') {
        switch (props.type) {
          case 1:
            const data = demographics.filter(entry => entry.id === field)?.pop().values;
            
            if (data && data.length) {
              options = data.map(entry => {
                return {
                  value: entry.id,
                  label: entry.name,
                }
              });
            }

            break;

          case 2:
            options = channels.map(channel => {
              return {
                value: channel.id,
                label: channel.name,
              }
            });

            break;

          default:
            if (fieldData.options) {
              options = fieldData.options.map(option => {
                return {
                  value: option.value,
                  label: option.label,
                }
              });
            }
        }
      }

      setValueType({
        type: fieldData.type,
        options: options,
      });

      return {
        type: fieldData.type,
        options: options,
      };
    }
  
    setValueType({
      type: 'text',
      options: [],
    });

    return {
      type: 'text',
      options: [],
    }
  }

  // обработчики изменения формы

  const onDelete = () => {
    if (props.onDeleteHandle) {
      props.onDeleteHandle();
    }
  }

  const onChangeField = (field) => {
    setField(field);

    const initedValue = initValue(field, fields);
    const emptyValue = getValue(initedValue, null, condition);

    if (props.onChangeHandle) {
      props.onChangeHandle(field, condition, emptyValue);
    }
  }

  const onChangeCondition = (condition) => {
    setCondition(condition);

    const initedValue = initValue(field, fields);
    const emptyValue = getValue(initedValue, props.value, condition);

    if (props.onChangeHandle) {
      props.onChangeHandle(field, condition, emptyValue);
    }
  }

  const onChangeValue = (event, num = 1) => {
    if (isBetween) {
      const newValue = [...value];
      newValue[num] = event.target.value;

      setValue(newValue);

      if (props.onChangeHandle) {
        props.onChangeHandle(field, condition, newValue);
      }
    } else {
      setValue(event.target.value);

      if (props.onChangeHandle) {
        props.onChangeHandle(field, condition, event.target.value);
      }
    }
  }

  const onChangeSelectValue = (val, num = 1) => {
    if (isBetween) {
      const newValue = [...value];
      newValue[num] = val;
      setValue(newValue);

      if (props.onChangeHandle) {
        props.onChangeHandle(field, condition, newValue);
      }
    } else {
      const newValue = val;
      setValue(newValue);

      if (props.onChangeHandle) {
        props.onChangeHandle(field, condition, newValue);
      }
    }
  }

  const onChangeMultipleValue = (value) => {
    const newValue = value.join(',');
    setValue(newValue);

    if (props.onChangeHandle) {
      props.onChangeHandle(field, condition, newValue);
    }
  }

  const dipatch = useDispatch();

  // инициализация постого блока
  useEffect(() => {
    if (!demographics || demographics.length === 0) {
      dipatch(fetchDemographics());
      return;
    }

    if (!channels || channels.length === 0) {
      dipatch(fetchChannels());
      return;
    }

    const _field = props.field ? props.field : (fields ? fields[0]?.key : '');
    const initedValue = initValue(_field, fields);

    const _condition = props.condition ? props.condition : (
      _field === 'channel_id' ? 'in' : conditionOptions[0]
    );

    const _value = getValue(initedValue, props.value, _condition);

    if (props.new && props.onChangeHandle) {
      props.onChangeHandle(_field, _condition, _value);
    } else {
      setField(_field);
      setCondition(_condition);
      setValue(_value);
    }
  }, [ dipatch, props, demographics, channels ]);

  const range = isBetween ? [0, 1] : [0];

  return fields && valueType ? (
    <div className="condition">
      <div className="condition-wrapper">
        <div className="field">
          <FieldSelect
            value={field}
            placeholder="Поле"
            onChange={event => onChangeField(event?.value)}
            options={fields.map(({ key, name }) => ({ value: key, label: name }))}
          />
        </div>
        <div className="field">
          <ConditionSelect
            value={condition}
            onChange={event => onChangeCondition(event?.value)}
            options={conditionOptions.map(value => ({ value, label: value }))}
          />
        </div>
        { range.map(num => (
          <>
            { valueType.type === 'list' && valueType.options.length ? (
              ['in', 'not in'].indexOf(condition) !== -1 ? (
                <div className="field full-width" key={'c_' + num}>
                  <MultiSelect
                    options={valueType.options}
                    onChange={(values) => onChangeMultipleValue(values)}
                    selected={multipleValue}
                    search={true}
                    dropDown={true}
                  />
                </div>
              ) : (
                <div className="field" key={'s_' + num}>
                  <FieldSelect
                    value={(parseInt(Array.isArray(value) ? value[num] : value))}
                    onChange={(event) => onChangeSelectValue(event?.value, num)}
                    options={valueType.options.map(({ value, label }) => ({ value, label }))}
                  />
                </div>
              )
            ) : (
              <div className="field" key={'n_' + num}>
                <input
                  className="number"
                  value={Array.isArray(value) ? value[num] : value}
                  onChange={(event) => onChangeValue(event, num)}
                />
              </div>
            )}
          </>
        )) }
      </div>
      <div className="condition-actions">
        <DeleteIcon
          onClick={onDelete}
        />
      </div>
    </div>
  ) : '';
}

export default Condition;