import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import API from '@utils/AdminApi';
import { ShareIcon, SharedIcon } from './Buttons';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './SharedModel.scss';

const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

function SharedModal(props) {

  const wrapperRef = useRef();

  const [visible, setVisible] = useState(false);

  const [shared, setShared] = useState(null);

  const [loading, setLoading] = useState(true);

  const filtered = useMemo(() => shared && visible ? shared : [], [shared, visible]);

  const load = () => {
    if (shared === null) {
      API.getCollectionShared(props.id)
        .then(
          (result) => {
            if (result.data) {
              setShared(result.data);
            }
          }
        )
        .finally(
          () => setLoading(false)
        );
    }
  }

  const update = (index) => {
    const newShared = [ ...shared ];
    newShared[index].shared = !newShared[index].shared;

    API.updateCollectionShared(props.id, newShared);

    setShared(newShared);
  }

  useEffect(() => {
    if (visible) {
      load();
    }
  }, [visible]);

  const handleClickOutside = (event) => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', (event) => handleClickOutside(event));

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event));
    }
  }, []);

  return (
    <div className="shared-wrapper" ref={wrapperRef}>
      <SharedIcon onClick={() => setVisible(!visible)}></SharedIcon>
      { visible ? <div className="shared-content">
        <div className="list-wrapper">
          { loading ? <Spin className="shared-spin" indicator={loadingIcon} /> : <></> }
          { filtered.map((item, index) => (
            <div className="list-item" key={item.id}>
              <div><input type="checkbox" checked={item.shared} onChange={event => update(index)} /></div>
              <div>{item.name}</div>

            </div>
          )) }
        </div>
      </div> : <></> }
    </div>
  );
}

export default SharedModal;