import React, { useEffect, useState, useMemo, useCallback } from 'react';

import moment from 'moment';
import 'moment/locale/ru';
import * as locales from '@components/DateRange/locale';
import { useSelector } from 'react-redux';
import { dateColorCallback } from '@utils/helpers';

import { FormControl, FormLabel, Text, useOutsideClick} from "@chakra-ui/react"

import { Calendar } from '@components/DateRange';
import { ReactComponent as CalendarIcon } from '@components/UI/assets/calendar.svg';

function DatePicker(props) {

    const dates = useSelector((state) => state.status.statusObject);

    const calendarWrapperRef = React.useRef();
    const [showCalendar, setShowCalendar] = useState(false);
  
    useOutsideClick({
      ref: calendarWrapperRef,
      handler: () => setShowCalendar(false)
    });
  
    const [focusedRange, setFocusedRange] = useState([0, 0]);
    const [selectionRange, setSelectionRange] = useState(moment().toDate());
  
    const onChange = (dateRange) => {
      if (props.onChange) {
          props.onChange(dateRange);
      }
    }
  
    const onRangeChange = (range) => {
      setSelectionRange(range);
      onChange(range);
      setTimeout(() => setShowCalendar(false), 200);
    };
  
    useEffect(() => {
      if (props.value) {
        setSelectionRange(props.value);
      }
    }, [ props.value ]);
  
    const minDate = useMemo(
      () => moment().set('year', props.start ?? 2018).set('month', 0).set('day', 1).toDate(),
      [ props.start ]
    );
  
    const maxDate = useMemo(
      () => props.maxDate ?? moment().toDate(),
      [ props.maxDate, props.end ]
    );
  
    const dateColor = useMemo(() => {
      return dateColorCallback(dates);
    }, [ dates, maxDate ]);
  
    const rangeColors = ['#F15B5B'];

    return (
      <div>
        <FormControl isRequired={props.isRequired ?? false} className='form-control'>
          { !!props.label && <FormLabel htmlFor="range">{ props.label }</FormLabel> }
            <div
              className='date-range-text'
              onClick={() => setShowCalendar(true)}
            >
            <Text ml={1} mr={1}>
                {moment(selectionRange).format('DD.MM.YY')}
            </Text>
            <div className='date-range-text-icon'>
                <CalendarIcon />
            </div>
            </div>

            <div
              className={`date-range--calendar-wrapper ${showCalendar ? 'show' : ''}`}
              ref={calendarWrapperRef}
            >
            <Calendar
              showDateDisplay={false}
              showSelectionPreview={false}
              editableDateInputs={false}
              onChange={onRangeChange}
              onRangeFocusChange={setFocusedRange}
              moveRangeOnFirstSelection={false}
              date={selectionRange}
              locale={locales['ru']}
              minDate={minDate}
              maxDate={maxDate}
              rangeColors={rangeColors}
              dayContentRenderer={dateColor}
              color='#F15B5B'
              weekdayDisplayFormat='EEEEEE'
              disabledDay={props.disabledDay}
            />
            </div>
        </FormControl>
      </div>
    );
}

export default DatePicker;