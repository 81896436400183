import { createSlice } from '@reduxjs/toolkit';

export interface WallFormInterface {
  filters: {
    channels: number[];
    videos: string[];
    stats: number[];
    statistics: string[];
    audience: number | null;
    date: string | null;
    interval: string | null;
    moreChannels: number[];
    sound: number;
  };
}

const initialState: WallFormInterface = {
  filters: {
    channels: [],
    videos: [],
    stats: [],
    statistics: [],
    audience: null,
    date: null,
    interval: null,
    moreChannels: [],
    sound: -1,
  },
};

export const WallForm = createSlice({
  name: 'WallForm',
  initialState,
  reducers: {
    setfilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const { setfilters } = WallForm.actions;

export default WallForm.reducer;
