// todo KTS добавлять все роуты в единый конфиг
export const ROUTES = {
  programs: {
    detail: {
      mask: '/programs/:programId',
      createRoute: (programId) => `/programs/${programId}`,

      channel: {
        detail: {
          mask: '/programs/:programId/channel/:channelId',
          createRoute: (programId, channelId) =>
            `/programs/${programId}/channel/${channelId}`,

          issued: {
            detail: {
              mask: '/programs/:programId/channel/:channelId/issued/:issuedId',
              createRoute: (programId, channelId, issuedId) =>
                `/programs/${programId}/channel/${channelId}/issued/${issuedId}`,
            },
          },
        },
      },
    },
  },
};
