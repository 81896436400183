import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getChosenCellOptionsFromScheduleSelector } from '@store/forms/formStorageSelectors';
import API from '@utils/AdminApi';

import MultiSelect from '../../MultiSelect';
import { useData } from '../DataProvider';

function Issued() {
  const { filter, program, issued, setValue } = useData();

  /**
   * список опшинов из модуля Schedule, для мультиселекта
   * содержатся выбранные события (pissId) и их скомпонованные названия
   */
  const cellOptionsFromSchedule = useSelector(
    getChosenCellOptionsFromScheduleSelector,
  );

  const [options, setOptions] = useState();

  useEffect(() => {
    let cellEntitiesValues = [];

    try {
      for (const key in cellOptionsFromSchedule) {
        // TODO убрать спред объекта (причина - утилита getFullPath, добавляет/изменяет поле option'а)
        cellEntitiesValues.push({ ...cellOptionsFromSchedule[key] });
      }
    } catch (error) {
      cellEntitiesValues = [];
    }

    /**
     * для каждого селектора отдельно запрашивается список option'ов из АПИ
     * в случае если выбрана программа (по сути канал, e.g. Первый канал, Россия 24),
     * и фильтр выбран в статусе 'issued', запрашиваются опшины для мультиселекта
     */
    if (program && program.length && filter === 'issued') {
      let update = true;

      const promises = [];

      /**
       * program.value - это скомпонованный в виде строки список id'шников:
       * программы, события, подтипа события, и pissId
       */

      program.forEach(({ value }) => promises.push(API.getProgramIssuedMultiselect(value)));
      
      Promise.all(promises).then(value => {
        const data = [];

        program.forEach((entry, index) => {
          data.push({
            value: entry.value,
            label: entry.label,
            children: value[index],
          });
        });

        update && setOptions([...data]);
      });

      return () => (update = false);
    }
  }, [program, filter, cellOptionsFromSchedule]);

  const issuedValue = useMemo(() => issued ?? [], [issued]);

  return (
    <div className="form-control">
      <MultiSelect
        search={true}
        options={options}
        selected={issuedValue}
        placeholder="Выберите передачу"
        single={false}
        line={false}
        onChange={(value) => setValue('issued', value)}
      />
    </div>
  );
}

export default Issued;
