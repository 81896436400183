import { useMemo } from 'react';

import { DEFAULT_TIMEBAND_VALUE } from '../config/defaultFilterValues';
import { CHANNEL_PREFIX } from '../models/constants';
import { ChannelFilterEntity } from '@models/channelFilterEntity';
import {
  useScheduleTableContext,
  AddDateAction,
  SetChannelsAction,
  SetTargetAudiencesAction,
  SetTimebandAction,
} from '../stores';

type FilterData<Value, Action> = {
  value: Value;
  onChange: Action;
};

type FilterParams = {
  channelsFilterParams: FilterData<(string | number)[], SetChannelsAction>;
  timebandFilterParams: FilterData<number[], SetTimebandAction>;
  targetAudienceFilterParams: FilterData<number[], SetTargetAudiencesAction>;
  dateFilterParams: FilterData<Date[], AddDateAction>;
};

/**
 * Хук мемоизирует и нормализует данные из стора к виду,
 * который принимают фильтры в виде своих опций
 */
export const useFiltersData = (): FilterParams => {
  const {
    value: { filters },
    actions,
  } = useScheduleTableContext();

  const { setChannels, setTimeband, setTargetAudiences, addDate } = actions;

  const { channels, timeband, targetAudiences, dates } = filters;

  const channelsIds = useMemo(() => {
    return channels ? channels.map((el) => (el as ChannelFilterEntity).net_name ? `${CHANNEL_PREFIX}${el.id}` : el.id) : [];
  }, [channels]);

  const timebandIds = useMemo<number[]>(() => {
    if (!timeband || timeband === DEFAULT_TIMEBAND_VALUE) {
      return [];
    }

    return [timeband.id];
  }, [timeband]);

  const targetAudienceIds = useMemo(() => {
    return targetAudiences ? targetAudiences.map((el) => el.id) : [];
  }, [targetAudiences]);

  const channelsFilterParams = useMemo(() => {
    return {
      value: channelsIds,
      onChange: setChannels,
    };
  }, [channelsIds, setChannels]);

  const timebandFilterParams = useMemo(() => {
    return {
      value: timebandIds,
      onChange: setTimeband,
    };
  }, [timebandIds, setTimeband]);

  const targetAudienceFilterParams = useMemo(() => {
    return {
      value: targetAudienceIds,
      onChange: setTargetAudiences,
    };
  }, [targetAudienceIds, setTargetAudiences]);

  const dateFilterParams = useMemo(() => {
    return {
      value: dates,
      onChange: addDate,
    };
  }, [dates, addDate]);

  return {
    channelsFilterParams,
    timebandFilterParams,
    targetAudienceFilterParams,
    dateFilterParams,
  };
};
