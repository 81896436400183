import React, { useContext, useReducer } from 'react';

import {
  VideoData,
  StatsData,
  StatsRequest,
  VideoEntry,
  VideoRequest,
  ProgramsData,
} from '../models';
import { PDAPI } from '../utils/PDApi';
import { VideoApi } from '../utils/VideoApi';

export interface StatsState {
  stats: { [key: number]: StatsData };
  programs: { [key: number]:  ProgramsData };
  videoData: { [key: number]: VideoEntry | null };
}

export interface StatsActions {
  loadStats: (request: StatsRequest) => void;
  loadVideos: (request: VideoRequest) => void;
}

export type StatsContext = StatsActions & StatsState;

export enum StatsActionKind {
  LOAD_STATS = 'statistics',
  SET_VIDEO_DATA = 'video',
}

interface StorageAction {
  type: StatsActionKind;
  payload: any;
  index?: number;
}

// eslint-disable-next-line import/no-named-as-default-member
export const AtdlContext = React.createContext<StatsContext>({
  stats: {},
  programs: {},
  videoData: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadStats: (request: StatsRequest) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadVideos: (request: VideoRequest) => {},
});

export const useStatsContext = () => {
  return useContext(AtdlContext);
};

const reducer = (
  state: StatsState,
  { type, payload, index }: StorageAction,
) => {
  switch (type) {
    case StatsActionKind.LOAD_STATS:
      // eslint-disable-next-line no-case-declarations
      const stats = { ...state.stats };
      const programs = { ...state.programs };

      if (typeof index !== 'undefined') {
        stats[index] = payload.stats;
        programs[index] = payload.programs;
      }

      return { ...state, stats, programs };

    case StatsActionKind.SET_VIDEO_DATA:
      // eslint-disable-next-line no-case-declarations
      const videoData = { ...state.videoData };

      if (typeof index !== 'undefined') {
        videoData[index] = payload;
      }

      return { ...state, videoData };

    default:
      return state;
  }
};

export interface StorageContextProps {
  count?: number;
  children: any;
}

export const StatsProvider = ({ children }: StorageContextProps) => {
  const [state, dispatch] = useReducer(reducer, {
    stats: {},
    programs: {},
    videoData: {},
  });

  const loadStats = (request: StatsRequest) => {
    PDAPI.getStats(request)
      .then((payload) => {
        dispatch({
          type: StatsActionKind.LOAD_STATS,
          payload: payload,
          index: request.channel,
        });
      })
      .catch(() => {});
  };

  const loadVideos = (request: VideoRequest) => {
    VideoApi.getMetrics(request)
      .then((data: VideoData) => {
        if (data.videos && data.videos.length) {
          const videos = [
            ...data.videos.filter(
              (video) => video.video_date > request.date_from,
            ),
          ];

          dispatch({
            type: StatsActionKind.SET_VIDEO_DATA,
            payload: videos.length ? videos[0] : null,
            index: request.channel_id,
          });
        }
      })
      .catch(() => {});
  };

  return (
    <AtdlContext.Provider
      value={{
        stats: state.stats,
        programs: state.programs,
        videoData: state.videoData,
        loadStats,
        loadVideos,
      }}
    >
      {children}
    </AtdlContext.Provider>
  );
};
