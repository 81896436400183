import { StatsRequest, ProgramsData, StatsData, Audience } from '../models';

// todo KTS comment and move somewhere
type TimeIntervalEntity = {
  /**
   * Текстовое обзоначение временного интервала
   */
  label: string;
  /**
   * Значение временного интервала
   */
  value: string;
};

export const getIntervals = (): TimeIntervalEntity[] => {
  const intervals: TimeIntervalEntity[] = [];

  for (let hour = 5; hour <= 28; hour++) {
    const value = (hour > 9 ? hour : '0' + hour) + ':00';

    intervals.push({
      label: value,
      value,
    });
  }

  return intervals;
};

// todo KTS add comment
export const timeToString = (time: number): string => {
  const hour = Math.floor(time / 60);

  const minute = Math.floor(time % 60);

  return (
    (hour < 10 ? `0${hour}` : hour) +
    ':' +
    (minute < 10 ? `0${minute}` : minute)
  );
};

export const stringToTime = (value: string) => {
  const [hour, minute] = value.split(':');

  return parseInt(hour) * 60 + parseInt(minute);
};

export const prevPeriod = (period: string) => {
  return timeToString(stringToTime(period) - 1);
};

export const nextInterval = (period: string, d = 0) => {
  return timeToString(stringToTime(period) + 60 + d);
};

// todo KTS add comment
export const getAllIntervals = (
  timeString: string,
  duration: number,
): string[] => {
  const time = stringToTime(timeString);

  const end = time + Math.floor(duration / 60);

  let itime = time;

  const intervals: string[] = [];

  while (itime <= end) {
    intervals.push(timeToString(itime));
    itime++;
  }

  return intervals;
};

export const isLight = (color: string) => {
  const hexColor = color.substring(0, 1) === '#' ? color.substring(1) : color;

  const r = parseInt(hexColor.substring(0, 2), 16);

  const g = parseInt(hexColor.substring(2, 4), 16);

  const b = parseInt(hexColor.substring(4, 6), 16);

  return ((r * 0.8 + g + b * 0.2) / 510) * 100 < 50;
};

export const roundStat = (stat: number) => Math.round(stat * 100) / 100;

export const getStatsPayload = (request: StatsRequest, population100k: boolean | null) => {
  const stats = request.statistics.map((type) => ({
    name: type,
    type,
  }));

  return {
    debug: true,
    target_base: request.target,
    dates_range: {
      start: `${request.date} 05:00:00`,
      end: `${request.date} 05:00:00`,
    },
    channels: [ request.channel ],
    statistics: stats,
    events: {
      channels: [ request.channel ],
      split_by: 1,
      aggregated: false,
      timeband: {
        name: request.time,
        type: 3,
        parent: null,
        content: {
            data: [
                {
                    type: "condition",
                    field: "time",
                    value: [
                      request.time,
                      nextInterval(request.time, 1)
                    ],
                    condition: "between"
                }
            ],
            type: "block",
            union: "and"
        }
      },
      premier: null,
      join_events: false,
      full_overlap_only: false
    },
    population_100: population100k,
  };
};

export const getStatsData = (data: any, time: string) => {
  const stats: StatsData = {};
  const programs: ProgramsData = {};

  const hour = time.substring(0, 2);
  const next = nextInterval(time)

  if (typeof data === 'object' && data && data.datasets) {
    data.datasets.forEach((row: { data : [ number ], label: string }) => {

      row.data.forEach((value, index) => {
        if (index == 60) {
          if (!stats[next]) {
            stats[next] = {}
          }

          stats[next][row.label] = roundStat(value);
        } else {
          const interval = `0${index}`.slice(-2);

          if (!stats[`${hour}:${interval}`]) {
            stats[`${hour}:${interval}`] = {}
          }

          stats[`${hour}:${interval}`][row.label] = roundStat(value);
        }
      });
    });

    data.labels.forEach((row: string[], index: number) => {
      if (index == 60) {
        programs[next] = {
          programId: row[0],
          pissId: row[1],
          label: row[2],
        }
      } else {
        const interval = `0${index}`.slice(-2);

        programs[`${hour}:${interval}`] = {
          programId: row[0],
          pissId: row[1],
          label: row[2],
        }
      }
    });
  }

  return {
    stats,
    programs
  };
};

export const findElement = (
  data: Audience[] | null,
  id: number,
): Audience | null => {
  let result: Audience | null = null;

  if (data && Array.isArray(data)) {
    data?.forEach((el) => {
      if (el.id === id) {
        result = el;
      }

      if (el.children) {
        const element = findElement(el.children, id);

        if (element) {
          result = element;
        }
      }
    });
  }

  return result;
};
