import moment from 'moment';
import React, { useMemo, useEffect, useState } from 'react';

import { MinuteMetrics } from '@models/index';
import { collectionToArray } from '@models/utils';

// @ts-ignore
import { ReactComponent as VolumeHigh } from '../../assets/volumehigh.svg';
// @ts-ignore
import { ReactComponent as VolumeOff } from '../../assets/volumeoff.svg';
import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useStatsContext } from '../../store/StatsProvider';
import { useVideosContext } from '../../store/VideosProvider';
import {
  nextInterval,
  stringToTime,
  isLight,
  prevPeriod,
  findElement,
} from '../../utils';
import { ChannelsModal } from '../VideoUI/ChannelsModal';
import { Metrics } from '../VideoUI/Metrics';

export interface StatsProps {
  index: number;
}

export function Stats({ index }: StatsProps) {
  const { channels, statistics, audiences } = useDictionaryContext();

  const {
    channels: selectedChannels,
    audience,
    date,
    interval,
    statistics: selectedStats,
    time,
    sound,
    setSound,
    setChannel,
  } = useVideosContext();

  const { stats, loadStats, loadVideos } = useStatsContext();

  const [ isModalVisible, setIsModalVisible ] = useState<boolean>(false);

  const channel = useMemo(() => {
    if (selectedChannels[index]) {
      const current = channels?.filter(
        ({ id }) => selectedChannels[index] === id,
      );

      return current && current.length ? current[0] : null;
    }

    return '';
  }, [channels, selectedChannels, index]);

  const statsValues = useMemo(() => {
    const result: MinuteMetrics = {};

    if (selectedStats && time && channel) {
      const prev = prevPeriod(time);

      selectedStats.forEach((type: string) => {
        result[type] = {
          current:
            channel &&
            stats &&
            stats[channel.channel_id] &&
            stats[channel.channel_id][time]
              ? stats[channel.channel_id][time][type]
              : 0,
          prev:
            channel &&
            stats &&
            stats[channel.channel_id] &&
            stats[channel.channel_id][prev]
              ? stats[channel.channel_id][prev][type]
              : 0,
        };
      });
    }

    return result;
  }, [stats, selectedStats, time, channel]);

  const statsList = useMemo(() => {
    return statistics
      ? statistics.filter(
          ({ type }) => selectedStats && selectedStats.includes(type),
        )
      : [];
  }, [statistics, selectedStats]);

  const selectChannel = (value: number) => {
    setIsModalVisible(false);
    setChannel(value, index);
  };

  useEffect(() => {
    if (channel && date && interval) {
      const current = moment(`${date}`, 'YYYY-MM-DD').add(
        stringToTime(interval) - 1,
        'minutes',
      );

      const next = moment(`${date}`, 'YYYY-MM-DD').add(
        stringToTime(nextInterval(interval)) - 1,
        'minutes',
      );

      loadVideos({
        channel_id: channel.id,
        date_from: current.format('YYYY-MM-DDTHH:mm:ss'),
        date_to: next.format('YYYY-MM-DDTHH:mm:ss'),
        statistics: '10',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, date, interval]);

  useEffect(() => {
    if (channel && date && selectedStats && audience && audiences && interval) {
      const currentAudience = findElement(audiences, Number(audience));

      loadStats({
        channel: channel.channel_id,
        date,
        time: interval,
        target: currentAudience,
        statistics: selectedStats,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, date, audience, audiences, selectedStats, interval]);

  const className =
    'video-stats ' +
    (index % 2 === 1 ? 'video-stats-left' : 'video-stats-right');

  const volumeStyle = index % 2 === 1 ? { left: 0 } : { right: 0 };

  const titleStyle = useMemo(
    () =>
      channel
        ? {
            background: channel.hex_color,
            color: isLight(channel.hex_color)
              ? '#FFFFFF'
              : 'rgba(0, 0, 0, 0.85)',
          }
        : {},
    [channel],
  );

  return (
    <div className={className}>
      <div
        className="video-stats-channel"
        style={titleStyle}
        onClick={() => setIsModalVisible(true)}
      >
        {channel ? channel.title : ''}
      </div>
      <div className="video-stats-list">
        {time &&
          statsList.map((stat) => (
            <Metrics
              key={stat.type}
              name={stat.name}
              type={stat.type}
              metric={statsValues[stat.type]}
            />
          ))}
      </div>
      <div
        className="video-stats-volume"
        style={volumeStyle}
        onClick={() => setSound(sound === index ? -1 : index)}
      >
        {sound === index ? <VolumeHigh /> : <VolumeOff />}
      </div>
      <ChannelsModal
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        handleSelect={selectChannel}
      />
    </div>
  );
}
