import cn from 'classnames';
import * as React from 'react';

import { OptionEntity } from '@models/optionEntity';

import { TabProps } from './Tab';
import s from './TabBar.module.scss';
import { TabView } from './types';

export type TabBarProps<Value extends OptionEntity> = {
  // Выбранная опция
  value: Value | null;
  options: Value[];
  tabView: TabView;
  TabComponent: React.ComponentType<TabProps<OptionEntity>>;
  onChange: (option: Value) => void;
  onClose: (option: Value) => void;
  onMouseEnter?: (option: Value) => void;
  onMouseLeave?: (option: Value) => void;
};

const TabBar: React.FC<TabBarProps<OptionEntity>> = ({
  value,
  options,
  onChange,
  onClose,
  onMouseEnter,
  onMouseLeave,
  tabView,
  TabComponent,
}) => {
  return (
    <div className={cn(s['tab-bar'], s[`tab-bar_view-${tabView}`])}>
      {options.map((option) => {
        const isActiveTab = option.key === value?.key;

        return (
          <TabComponent
            key={option.key}
            value={option}
            onClick={onChange}
            onClose={onClose}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            chosen={isActiveTab}
          />
        );
      })}
    </div>
  );
};

export default TabBar;
