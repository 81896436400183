import React, { useState, useEffect } from 'react'
import API from '@utils/AdminApi';
import { Button } from '@chakra-ui/react'
import { Link } from "react-router-dom";
import Table from '@components/Table/table'
import { useParams } from 'react-router-dom'


function Types(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.columns;
  let channel_id = Object.keys(props.data).length === 0 ? '' : props.data.channel_id;
  let channel_name = Object.keys(props.data).length === 0 ? '' : props.data.channel_name;

  if (data && data.length !== 0) {
    for (const row of data) {
      row['type_name'] = <Link  to={{pathname: `/programs/channel/${channel_id}/type/${row['type_id']}/categories`}}> <Button>{row['type_name']}</Button></Link>
      delete row['type_id'];
    }
    delete columns[0];
  }

  return (
    <div>
      <div>Типы программ</div>
      <div>Канал: {channel_name}</div>
      <br/>
      <Table columns={columns} data={data} rowStyle={{"borderWidth":"2px"}} tableStyle={{"borderWidth":"2px"}} showHeader={false}/>
    </div>
  );
}


function ProgramsTypesByChannel() {
  const channel_id = useParams()['channel_id']
  const [data, setData] = useState([]);

  const loadData = () => {
    API.getProgramsTypesByChannel(channel_id)
      .then(response => {
        if (response) {
          setData(response);
        }
      });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Types data={data}/>
    </div>
  )
}



export default ProgramsTypesByChannel

