import React, { useState, useEffect } from 'react'
import API from '@utils/AdminApi';
import DateRangeFilter from '@components/DateRangeFilter/DateRangeFilter'
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedDateRange, fetchDemographics } from '@store/panel';
import { useParams } from 'react-router-dom'
import { useTable } from 'react-table'
import { Button } from '@chakra-ui/react'
import { Link } from "react-router-dom";


function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row)
        return (<tr>
          {row.cells.map(cell => {
            return <td {...cell.getCellProps()} style={{"borderWidth":"1px", 'borderColor':"#aaaaaa", 'borderStyle':'solid'}}>{cell.render('Cell')}</td>
          })}
        </tr>)
      })}
      </tbody>
    </table>
  )
}


function HouseholdDetails(props) {
  let data = Object.keys(props.data).length === 0 ? [] : props.data.members.data;
  let columns = Object.keys(props.data).length === 0 ? [] : props.data.members.columns;
  let city100_plus = {'id': '', 'name': ''}
  let region_federation = {'id': '', 'name': ''}


  if (data && data.length !== 0) {
    city100_plus = props.data['city100_plus']
    region_federation = props.data['region_federation']
    for (const record of data ) {
      let member_id = record['member'];
      record['member'] =
        <Link  to={{pathname: `/panel/member/${member_id}`}}>
          <Button>{member_id}</Button>
        </Link>
    }
  }
  return (
    <div><br/>
      <div>
        <DateRangeFilter/>
      </div>
      <div> Домовладение: {props.data['household_id']}
        <div>

          <Link  to={{pathname: `/panel/city/${city100_plus['id']}`}}>
            <Button>{city100_plus['name']}</Button>
          </Link>
        </div>
        <br/>
        <div>
          <Link  to={{pathname: `/panel/region/${region_federation['id']}`}}>
            <Button>{region_federation['name']} регион</Button>
          </Link>
        </div>
        <br/>
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
}

function PanelHousehold() {
  const household_id = useParams()['id']
  const datesRange = useSelector((state) => state.panel.datesRange);
  const demographics = useSelector((state) => state.panel.demographics);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const loadData = (city_id, datesRange) => {
    API.postHouseholdInfo({ household_id, datesRange })
      .then(response => {
        if (response) {
          setData(response.data);
        }
      });
  };

  useEffect(() => {
    if (Object.keys(demographics).length === 0) {
      dispatch(fetchDemographics());
    }
    dispatch(changeSelectedDateRange(datesRange));
    loadData(household_id, datesRange);
  }, [datesRange]);

  useEffect(() => {
    if (!data || data.length === 0) return;
  }, [data]);


  return (
    <div>
      <HouseholdDetails household_id={household_id} data={data}/>
    </div>
  )
}


export default PanelHousehold
