import {
  DEFAULT_INTERVAL_SPLIT_STEP,
  TIME_BITE_STUB_HEIGHT,
  TIME_SLOTS_COLUMN_ID,
  Y_SPACE_BETWEEN_INTERVALS,
} from '../models/constants';
import { ScheduleCellTVEvent } from '../models/scheduleCellPayloadTVEvent';
import { ScheduleCellTimeSlot } from '../models/scheduleCellPayloadTimeSlot';
import { ScheduleTableData } from '../models/scheduleTableData';
import { createTimeBites } from '../utils/createTimeBites';

export const collapseCellsHeight = (tableData: ScheduleTableData) => {
  const timeIntervalIds =
    tableData.columnEntities[TIME_SLOTS_COLUMN_ID].innerCellIds;

  const mappedTimeBites: {
    [timeBiteId: string]: {
      relatedTimeSlot: ScheduleCellTimeSlot;
      relatedEvents: ScheduleCellTVEvent[];
    };
  } = {};

  // Инициализация матрицы тайм-байтов
  timeIntervalIds.forEach((timeIntervalId) => {
    const timeInterval = tableData.cellEntities[
      timeIntervalId
    ] as ScheduleCellTimeSlot;

    timeInterval.payload.timeBiteIds.forEach((timeBiteId) => {
      mappedTimeBites[timeBiteId] = {
        relatedTimeSlot: timeInterval,
        relatedEvents: [],
      };
    });
  });

  // цикл по каналам
  tableData.scrolledColumnIds.forEach((channelColumnId) => {
    // цикл по эвентам
    tableData.columnEntities[channelColumnId].innerCellIds.forEach(
      (eventId) => {
        const event = tableData.cellEntities[eventId] as ScheduleCellTVEvent;

        if (!event) {
          return;
        }

        const { timeBiteIds } = createTimeBites(
          event.tableTVTimeInterval.minutesStart,
          event.tableTVTimeInterval.minutesEnd,
        );

        timeBiteIds.forEach((timeBiteId) => {
          // событие вышло за пределы тайм-бенда - игнорируем
          if (!mappedTimeBites[timeBiteId]) {
            return;
          }

          mappedTimeBites[timeBiteId].relatedEvents.push(event);
        });
      },
    );
  });

  // Удаленные тайм-байты перед эвентом
  const removedCountBeforeEvent: Record<string, number> = {};

  // Вставленные тайм-стабы перед эвентом
  const stubsCountBeforeEvent: Record<string, number> = {};

  // Сколько тайм-байтов надо убрать для событий и тайм-интервалов
  const removedCountForEvent: Record<string, number> = {};

  // Сколько тайм-стабов надо убрать для событий и тайм-интервалов
  const stubsCountForEvent: Record<string, number> = {};

  let isPrevHidden = false;

  let prevTimeBiteSlot: ScheduleCellTimeSlot | null = null;

  Object.entries(mappedTimeBites).forEach(
    ([timeBiteId, { relatedTimeSlot, relatedEvents }]) => {
      let isCurrentHidden = true;

      relatedEvents.forEach((relatedEvent) => {
        // Эвент начинается или заканчивается в данном тайм-байте
        if (
          relatedEvent.tableTVTimeInterval.minutesStart >=
            relatedTimeSlot.payload.timeBites[timeBiteId].minutesStart ||
          relatedEvent.tableTVTimeInterval.minutesEnd <=
            relatedTimeSlot.payload.timeBites[timeBiteId].minutesEnd
        ) {
          isPrevHidden = false;
          isCurrentHidden = false;
          prevTimeBiteSlot = relatedTimeSlot;
        }

        // эвент встречается впервые => добавить текущее количество схлопнутых тайм-байтов до него
        if (!removedCountForEvent[relatedEvent.id]) {
          removedCountBeforeEvent[relatedEvent.id] =
            removedCountForEvent[relatedTimeSlot.id] || 0;
          stubsCountBeforeEvent[relatedEvent.id] =
            stubsCountForEvent[relatedTimeSlot.id] || 0;
        }
      });

      if (!isCurrentHidden) {
        return;
      }

      relatedTimeSlot.payload.timeBites[timeBiteId].hidden = true;

      // Инкремент количества схлопнутых тайм-байтов для событий
      relatedEvents.forEach((e) => {
        removedCountForEvent[e.id] =
          Number(removedCountForEvent[e.id] || 0) + 1;
      });

      // Инкремент количества схлопнутых тайм-байтов для тайм-интервала
      removedCountForEvent[relatedTimeSlot.id] =
        Number(removedCountForEvent[relatedTimeSlot.id] || 0) + 1;

      // расчет количества стабов
      // втыкаем стаб на этом месте, если предыдущий тайм-байт принадлежит тому же интервалу и он не был схлопнут
      const shouldAddStub =
        prevTimeBiteSlot === relatedTimeSlot && !isPrevHidden;

      isPrevHidden = true;
      prevTimeBiteSlot = relatedTimeSlot;

      if (!shouldAddStub) {
        return;
      }

      relatedEvents.forEach((e) => {
        stubsCountForEvent[e.id] = Number(stubsCountForEvent[e.id] || 0) + 1;
      });

      stubsCountForEvent[relatedTimeSlot.id] =
        Number(stubsCountForEvent[relatedTimeSlot.id] || 0) + 1;
    },
  );

  /**
   * Сдвиг координат эвентов
   */
  // цикл по каналам
  tableData.scrolledColumnIds.forEach((channelColumnId) => {
    // цикл по эвентам
    tableData.columnEntities[channelColumnId].innerCellIds.forEach(
      (eventId) => {
        const event = tableData.cellEntities[eventId] as ScheduleCellTVEvent;

        if (!event) {
          return;
        }

        const hiddenTimeBitesBeforeEventCount =
          removedCountBeforeEvent[event.id] || 0;

        const stubsBeforeEventCount = stubsCountBeforeEvent[event.id] || 0;

        const currentShift =
          hiddenTimeBitesBeforeEventCount * DEFAULT_INTERVAL_SPLIT_STEP -
          stubsBeforeEventCount * TIME_BITE_STUB_HEIGHT;

        const hiddenTimeBitesCount = removedCountForEvent[event.id] || 0;

        const stubsCount = stubsCountForEvent[event.id] || 0;

        const additionalShift =
          hiddenTimeBitesCount * DEFAULT_INTERVAL_SPLIT_STEP -
          stubsCount * TIME_BITE_STUB_HEIGHT;

        event.tableTVTimeInterval.yStart -= currentShift;
        event.tableTVTimeInterval.yEnd -= currentShift + additionalShift;
      },
    );
  });

  let yTableShift = 0;

  let spaceBetweenIntervalsShift = 0;

  /**
   * Смещение координат тайм-интервалов согласно скрытым внутри них
   * тайм-байтам и количеству тайм-стабов
   */
  timeIntervalIds.forEach((timeIntervalId) => {
    const hiddenTimeBitesCount = removedCountForEvent[timeIntervalId] || 0;

    const stubsCount = stubsCountForEvent[timeIntervalId] || 0;

    const yTimeIntervalShift =
      hiddenTimeBitesCount * DEFAULT_INTERVAL_SPLIT_STEP -
      stubsCount * TIME_BITE_STUB_HEIGHT -
      spaceBetweenIntervalsShift;

    tableData.cellEntities[timeIntervalId].tableTVTimeInterval.yEnd -=
      yTimeIntervalShift;

    spaceBetweenIntervalsShift += Y_SPACE_BETWEEN_INTERVALS;
    yTableShift += yTimeIntervalShift;
  });

  tableData.tableSize.yEnd -= yTableShift;
};
