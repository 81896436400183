import cn from 'classnames';
import * as React from 'react';

import { useScheduleTableColumnsContext } from '@pages/schedule/stores/ScheduleTableColumnsContext';

import s from './ScheduleTableHeader.module.scss';

/**
 * Позиция в px, при которой header становится фиксированным.
 * Вычисляется как сумма высоты шапки страницы и шапки таблицы (2 таббара)
 */
const COLUMN_HEADER_FIXED_Y_POSITION = 150;

export type HeaderProps = {
  setScrollHandler: (scrollHandler: (scrollXPosition: number) => void) => void;
  children: (
    scrolledHeaderColumnsRef: React.ForwardedRef<HTMLDivElement | null>,
  ) => React.ReactNode;
};

/**
 * Компонент шапки таблицы (рендер отдельно от колонок
 * для реализации прилипания при скроле)
 */
const ScheduleTableHeader: React.FC<HeaderProps> = ({
  setScrollHandler,
  children,
}: HeaderProps) => {
  const [isFixedHeader, setIsFixedHeader] = React.useState(false);

  const { columnsContainerRef, subscribeOnColumnsContainerResize } =
    useScheduleTableColumnsContext();

  const headerContainerRef = React.useRef<HTMLDivElement | null>(null);

  const scrolledHeaderColumnsRef = React.useRef<HTMLDivElement | null>(null);

  const headerStubRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * Установить фиксированный header после скрола по вертикали
   * до определенной позиции (COLUMN_HEADER_FIXED_Y_POSITION)
   */
  React.useEffect(() => {
    let lastCallId;

    const handleColumnYScroll = () => {
      lastCallId = requestAnimationFrame(() => {
        if (!headerStubRef.current) {
          return;
        }

        const { y } = headerStubRef.current.getBoundingClientRect();

        setIsFixedHeader(y < COLUMN_HEADER_FIXED_Y_POSITION);
      });
    };

    document.addEventListener('scroll', handleColumnYScroll);

    return () => {
      document.removeEventListener('scroll', handleColumnYScroll);
      cancelAnimationFrame(lastCallId);
    };
  }, []);

  React.useEffect(() => {
    const unsubscribe = subscribeOnColumnsContainerResize((containerWidth) => {
      if (!headerContainerRef.current || !columnsContainerRef.current) {
        return;
      }

      const { left } = columnsContainerRef.current.getBoundingClientRect();

      headerContainerRef.current.style.top = `${COLUMN_HEADER_FIXED_Y_POSITION}px`;
      headerContainerRef.current.style.left = `${left}px`;
      headerContainerRef.current.style.width = `${containerWidth}px`;
    });

    return () => {
      unsubscribe();
    };
  }, [columnsContainerRef, subscribeOnColumnsContainerResize]);

  /**
   * При горизонтальном скроле колонок таблицы
   * обновить положение фиксированного хедера
   */
  React.useEffect(() => {
    let lastCallId;

    setScrollHandler(() => (scrollXPosition) => {
      lastCallId = requestAnimationFrame(() => {
        if (!scrolledHeaderColumnsRef.current) {
          return;
        }

        scrolledHeaderColumnsRef.current.style.transform = `translateX(-${scrollXPosition}px)`;
      });
    });

    return () => {
      cancelAnimationFrame(lastCallId);
    };
  }, [setScrollHandler, isFixedHeader]);

  const headerStubStyle: React.CSSProperties = React.useMemo(
    () => ({
      height: isFixedHeader
        ? headerContainerRef.current?.getBoundingClientRect().height || 0
        : 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFixedHeader, headerContainerRef.current],
  );

  return (
    <>
      <div ref={headerStubRef} style={headerStubStyle} />
      <div
        ref={headerContainerRef}
        className={cn(
          s['schedule-table-header'],
          isFixedHeader
            ? s['schedule-table-header_fixed']
            : s['schedule-table-header_static'],
        )}
      >
        {children(scrolledHeaderColumnsRef)}
      </div>
    </>
  );
};

export default ScheduleTableHeader;
