import React, { useContext, useEffect, useReducer } from "react"
import { intPaths } from '../Helpers';

const OptionsContext = React.createContext();

export const useOptions = () => {
  return useContext(OptionsContext);
}

export const findElement = (data, value) => {
  let result;

  if (Array.isArray(data)) {
    data?.forEach(el => {
      if (el.value === value) {
        result = el;

        return result;
      }

      if (el.children) {
        const element = findElement(el.children, value);

        if (element) {
          result = element;
        }
      }
    });
  }

  return result;
}

export const toList = (options) => {
  return Array.isArray(options) ? options.map(option => {
    if (option.hasOwnProperty('children') && option.children !== null) {
      let children = [];

      return {
        ...option,
        children
      }
    }

    return {...option}
  }) : options;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, options: action.options }

    case 'options':
      if (action.value === null) {
        state.options = action.children;
      } else {
        let el = findElement(state.options, action.value);

        if (el.children) {
          el.children = action.children;
        }
      }

      return { ...state }

    case 'groups':
      state.groups = action.value;


      return { ...state }

    case 'search':

      return { ...state, searchOptions: action.result, filter: action.filter }

    default:
      return state;
  }
}

export const OptionsProvider = ({ children, options, loader, search, lazily, path}) => {
  const [state, dispatch] = useReducer(reducer, {
    options,
    searchOptions: [],
    filter: '',
    groups: [],
  });

  useEffect(() => {
    if (!lazily) {
      dispatch({ type: 'update', options });
    }
  }, [ options, lazily ]);

  const getOptions = (value, filter, getResult) => {
    if (typeof search !== 'function' || !filter || filter.length < 3) {
      return optionsList(value, filter, getResult);
    }

    return searchList(value, filter, getResult);
  }

  const doFilter = (filter, options) => {
    const results = [];

    if (Array.isArray(options)) {
      options.forEach(option => {
        if (option.label.toLowerCase().search(filter) > -1) {
          results.push(option);
        } else if (option.children) {
          const _options = doFilter(filter, option.children);

          if (_options.length) {
            results.push({
              ...option,
              children: _options,
            });
          }
        }
      });
    }

    return results;
  }

  const filterOptions = (options, filter) => {
    if (search === true) {
      return filter ? doFilter(filter.toLowerCase(), options) : options;
    }

    return options;
  }

  const searchList = (value, filter, getResult) => {
    if (filter !== state.filter) {
      search(filter).then((result) => {
        dispatch({ type: 'search', filter, result: intPaths(result, [], !!path)});

        let el = findElement(result, value);

        if (el) {
          getResult(toList(el.children));
        } else {
          getResult(toList(result));
        }
      }, () => {
        getResult([]);
      });

      return null;
    }

    if (value === null) {
      return toList(state.searchOptions);
    } else {
      let el = findElement(state.searchOptions, value);

      if (el) {
        return toList(el.children);
      }
    }

    return false;
  }

  const optionsList = (value, filter, getResult) => {
    let el = value === null ? state.options : findElement(state.options, value);

    if (value !== null && (!el || !el.children)) {
      return [];
    }

    if ((value === null && !state.options.length) || (el.children !== null && el.children?.length === 0)) {
      if (loader) {
        loader(value).then((children) => {
          dispatch({
            type: 'options',
            value,
            children: children.map(children => {
              children.path = [];

              if (el.path) {
                children.path = [...el.path];
              }

              if (el.label && (path || el.show)) {
                children.path.push(el.label);
              }

              return children;
            }
          )});

          getResult(toList(children));
        });

        return null;
      }

      return [];
    } else if (value === null) {
      return toList(filterOptions(state.options, filter));
    } else {
      return toList(filterOptions(el.children, filter));
    }
  }

  const setGroups = (value) => {
    dispatch({ type: 'groups', value })
  }

  return (
    <OptionsContext.Provider value={{
      groups: state.groups,
      options: state.options,
      setGroups,
      getOptions,
    }}>
      { children }
    </OptionsContext.Provider>
  )
}