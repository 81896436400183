import * as React from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { GrChannel } from 'react-icons/gr';

import s from './Dummy.module.scss';

const Dummy: React.FC = () => {
  return (
    <section className={s['dummy-content']}>
      <div className={s['dummy-content__icons']}>
        <div className={s['dummy-content__icons-item']}>
          <GrChannel />
        </div>
        <div className={s['dummy-content__icons-item']}>
          <AiOutlineUsergroupAdd />
        </div>
      </div>

      <h5 className={s['dummy-content__title']}>Выберите канал и аудиторию</h5>
      <p className={s['dummy-content__text']}>
        Также можете настроить таймслот и даты.
      </p>
    </section>
  );
};

export default React.memo(Dummy);
