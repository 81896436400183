import { ConditionArrayLikeEntity } from '@models/conditionArrayLikeEntity';
import { Condition, ConditionBlock, ConditionBlockType } from '@models/index';

export const collectionToArray = (
  collection: ConditionBlock,
): ConditionArrayLikeEntity => {
  return [
    collection.union,
    ...collection.data.map((entry: ConditionBlock | Condition) => {
      if (entry.type === ConditionBlockType.condition) {
        const condition = entry as Condition;

        return [condition.field, condition.condition, condition.value];
      } else {
        return collectionToArray(entry as ConditionBlock);
      }
    }),
  ];
};
