import React from 'react';
import { useVideosContext } from '../../store/VideosProvider';

// @ts-ignore
import { ReactComponent as Button } from '../../assets/forward.svg';

export function NextButton() {

    const {
        nextPeriod,
      } = useVideosContext();
    
      return (
        <div className="prev-button">
          <Button onClick={() => nextPeriod()} />
        </div>
      );
    }
