import { useCallback, useRef, useEffect, useState } from 'react';
import { useDataContext } from './DataProvider';
import { useOptions } from './OptionsProvider';
import TreeView from './TreeView';
import Items from './Items';
import Placeholder from '../Placeholder';
import Search from './Search';
import { ReactComponent as SearchIcon } from '../images/search.svg';
import { useCallbackRef } from '@chakra-ui/react';
import { getId } from './utils';

function Component({ className, placeholder, line, searchOnly, onChange, treeSearch, hideSelected, selected, getIcon }) {

  const [ paths, setPaths ] = useState([]);

  const { isTree, isSearch, showTree, showSearch, hideAll, single, selectFilter, keyField } = useDataContext();

  const { setSearchOptions } = useOptions();

  const wrapperRef = useRef();

  const prevSelected = useRef([]);

  const handleClickOutside = useCallbackRef((event) => {
    if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
      if (isTree || isSearch) {
        hideAll();
      }
    }
  });

  const showAndresetSearch = () => {
    setSearchOptions([]);
    showSearch();
  }

  const toggleTree = useCallback(() => {
    if (isTree || isSearch) {
      hideAll();
    } else {
      !!searchOnly ? showAndresetSearch() : showTree();
    }
  }, [ isTree, isSearch  ]);

  const toggleSearch = useCallback(() => {
    if (isTree || isSearch) {
      hideAll();
    } else {
      showAndresetSearch();
    }
  }, [ isTree, isSearch  ]);

  const addItem = (payload) => {
    if (selectFilter) {
      const el = payload.length ? payload[payload.length - 1] : null;

      if (el === null || selectFilter(el) === false) {
        return
      }
    }

    const id = getId(payload, keyField);

    let newPaths = [];

    if (single) {
      newPaths = [{ id, path: payload }];

      hideAll();
    } else {
      const exists = paths && paths.filter(entry => entry.id == id);

      newPaths = exists.length ? paths : [...paths, { id, path: payload }];
    }

    setPaths(newPaths);
    onChange && onChange(newPaths)
  }

  const deleteItem = (payload) => {
    const newPaths = [...paths].filter(({ id }) => id != payload);

    setPaths(newPaths);
    onChange && onChange(newPaths)
  }

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      handleClickOutside(event)
    });
  
    return () => {
      document.removeEventListener('mousedown', (event) => {
        handleClickOutside(event)
      });
    }
  }, []);

  useEffect(() => {
    if (selected != prevSelected.current) {
      setPaths(selected);
      prevSelected.current = selected;
    }
  }, [ selected ]);

  return (
    <div className="multiselect" ref={wrapperRef}>
      <div className="multiselect-search-icon" onClick={toggleSearch}><SearchIcon /></div>
      { !hideSelected ? <div
        className={className}
        onClick={toggleTree}
      >
        {paths.length ? (
          <Items paths={paths} deleteItem={deleteItem} getIcon={getIcon} />
        ) : (
          <Placeholder
            placeholder={placeholder ?? 'Выбрать...'}
            oneLine={line}
          />
        )}
      </div> : <></> }
      {!!isSearch && (
        <div className="multiselect-dropdown">
          <Search treeSearch={treeSearch} addItem={addItem} />
        </div>
      )}
      {!!isTree && (
        <div className="multiselect-dropdown">
          <div className="multiselect-options">
            <TreeView parent={null} path={[]} addItem={addItem} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Component;