import { useState, useEffect, useRef } from 'react';
import { useOptions } from './OptionsProvider';
import { useDataContext } from './DataProvider';
import SearchLabel from './SearchLabel';
import SearchTreeView from './SearchTreeView';
import { getId } from './utils';

function Search({ treeSearch, addItem }) {
  const lastSearch = useRef(null);

  const { getSearchOptions, searchOptions, setSearchOptions } = useOptions();

  const { keyField, labelField } = useDataContext();

  const [ filter, setFilter ] = useState("");

  useEffect(() => {
    const interval = setTimeout(() => {
      if (filter && filter.length > 2) {
        lastSearch.current = filter;

        getSearchOptions(filter).then(value => {
          if (lastSearch.current === null || lastSearch.current === value.text) {
            setSearchOptions(value.result);
          }
        });
      } else {
        setSearchOptions();
      }
    }, 1000);

    return () => {
      clearTimeout(interval);
    }
  }, [ filter ]);

  return (
    <>
      <div className="multiselect-search">
        <div className="multiselect-search-field">
          <input name="search" placeholder='Введите название' value={filter} onChange={(event) => setFilter(event.target.value)} />
        </div>
      </div>
      <div className="multiselect-options">
        { treeSearch
          ? ( searchOptions && searchOptions.length ? <SearchTreeView parent={null} path={[]} addItem={addItem} /> : <></> )
          : (
            searchOptions && searchOptions.length ? searchOptions.map((option) => (
              <SearchLabel
                key={getId(option.path ? option.path : [option], keyField)}
                path={option.path ? option.path : [option]}
                keyField={keyField}
                labelField={labelField}
                addItem={addItem}
              />
            )) : <></> 
          )}
      </div>
    </>
  );
}

export default Search;