import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { Collection } from '@models/';
import { Meta } from '@models/meta';
import { loadConditions } from '@store/collections';
import API from '@utils/AdminApi';
import common from '@utils/common';

import { DEFAULT_TIMEBAND_VALUE } from '../config/defaultFilterValues';
import { ApiTVTimeIntervalEntity } from '../models/apiScheduleEntities';
import { ScheduleTableData } from '../models/scheduleTableData';
import {
  BroadcastRequest,
  createBroadcastRequestPayload,
} from '../utils/createBroadcastRequestPayload';
import { getAudienceCondition } from '../utils/getAudienceCondition';
import { getTimebandIntervals } from '../utils/getTimebandIntervals';
import { prepareChannelsPayload } from '../utils/prepareChannelsPayload';

import { SetTableDataAction } from './actions';
import { normalizeScheduleTableData } from './normalizeScheduleTableData';
import { ScheduleTableContextValue } from './scheduleTableContextValue';

type ScheduleTableRequest = {
  request: BroadcastRequest;
  timeIntervals: ApiTVTimeIntervalEntity[];
};

type RequestPayload = {
  dateKey: string;
  audienceKey: string;
  data: ScheduleTableRequest;
};

const createRequestPayload = async (
  value: ScheduleTableContextValue,
  dispatch: Dispatch<AnyAction>,
): Promise<RequestPayload[]> => {
  const { timeband, activeTabOption, targetAudiences, channels } =
    value.filters;

  // Дата, выбранная на табе
  const date = activeTabOption.date
    ? common.toTVDate(activeTabOption.date, true)
    : '';

  if (!date || !value.tableData[date]) {
    return [];
  }

  if (!channels.length) {
    return [];
  }

  const payloadChannels = prepareChannelsPayload(channels);

  const audienceIds = targetAudiences.map((audience) => audience.id);

  if (!audienceIds) {
    return [];
  }

  const timebandId =
    (timeband !== DEFAULT_TIMEBAND_VALUE && timeband?.id) || '';

  const conditionIds = [...audienceIds];

  if (timebandId) {
    conditionIds.push(timebandId);
  }

  const conditions = (await dispatch(
    loadConditions(conditionIds) as unknown as AnyAction,
  )) as Record<number, Collection>;

  const timebandCondition = conditions[timebandId] || null;

  const timebandIntervals = getTimebandIntervals(timebandCondition);

  return Object.entries(value.tableData[date])
    .map(([audience, tabData]) => {
      if (tabData.meta !== Meta.initial) {
        return null;
      }

      const audienceCondition = getAudienceCondition(
        targetAudiences,
        audience,
        conditions,
      );

      if (!audienceCondition) {
        return null;
      }

      const payload = createBroadcastRequestPayload({
        audience: audienceCondition,
        channels: payloadChannels,
        date,
        timeband: timebandCondition,
      });

      return {
        dateKey: date,
        audienceKey: audience,
        data: {
          request: payload,
          timeIntervals: timebandIntervals,
        },
      };
    })
    .filter(Boolean) as RequestPayload[];
};

const requestBroadcastData = async (
  payload: ScheduleTableRequest,
): Promise<ScheduleTableData | null> => {
  return API.getBroadcastData(payload.request)
    .then((response) => {
      if (!response) {
        return null;
      }

      try {
        const data = normalizeScheduleTableData({
          apiTVTimeIntervals: payload.timeIntervals,
          apiChannelsBroadcast: response,
        });

        return data;
      } catch {
        return null;
      }
    })
    .catch(() => null);
};

const onStartLoading = (
  payload: RequestPayload,
  setTableData: SetTableDataAction,
): void => {
  setTableData({
    meta: Meta.loading,
    date: payload.dateKey,
    audience: payload.audienceKey,
  });
};

const onFinishLoading = (
  payload: RequestPayload,
  response: ScheduleTableData | null,
  setTableData: SetTableDataAction,
): void => {
  if (!response) {
    setTableData({
      meta: Meta.error,
      date: payload.dateKey,
      audience: payload.audienceKey,
    });

    return;
  }

  setTableData({
    meta: Meta.success,
    date: payload.dateKey,
    audience: payload.audienceKey,
    data: response,
  });
};

export const loadBroadcastData = async (
  value: ScheduleTableContextValue,
  setTableData: SetTableDataAction,
  dispatch: Dispatch<AnyAction>,
): Promise<void> => {
  const requestPayloads = await createRequestPayload(value, dispatch);

  requestPayloads.forEach((payload) => {
    onStartLoading(payload, setTableData);

    requestBroadcastData(payload.data).then((response) => {
      onFinishLoading(payload, response, setTableData);
    });
  });
};
