import React, { useState, useEffect, useRef} from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, SimpleGrid } from '@chakra-ui/react';
import API from '@utils/AdminApi';
import DateRangeFilter from '@components/DateRangeFilter/DateRangeFilter'
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedDateRange } from '@store/panel'
import _ from "lodash-es"
import { useNavigate } from "react-router-dom";


function Panel() {
  const [data, setData] = useState([]);
  const highchartsRef = useRef();
  const datesRange = useSelector((state) => state.panel.datesRange);
  const dispatch = useDispatch()
  let history = useNavigate();

  const PageGrid = ({
                      columns,
                      spacing,
                      data,
                      highchartsRef,
                    }) => {
    let i = 0;

    return <SimpleGrid columns={columns} spacing={spacing}>
      {data.map((record) => {
        const options = {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: record.title
          },
          tooltip: {
            pointFormat: '<b>{point.z}</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.y:.1f} %'
              },
              events: {
                click: function(event) {
                  history.push(`/panel/region/${event.point.id}`);
                }
              }
            }
          },
          series: [{name: record.name, data: record.series}]
        };
        i += 1;
        return <Box key={i} maxW={700} maxH={700}> <HighchartsReact highcharts={Highcharts} options={options} ref={highchartsRef}/> </Box>;
      })
      }

    </SimpleGrid>;
  };

  const loadData = (datesRange) => {
    API.postPanel({datesRange: datesRange})
      .then(response => {
        if (response) {
          setData(response.data);
        }
      });
    dispatch(changeSelectedDateRange(datesRange));
  }

  useEffect(() => {
    loadData(datesRange);
  }, [ datesRange, ]);

  return (
    <div>
      <div>
        <DateRangeFilter state={datesRange} store={'panel'}/>
      </div>
      <PageGrid columns={1} spacing={0} data={data} highchartsRef={highchartsRef}> </PageGrid>
    </div>
  )
}



export default Panel
