import React, { useMemo } from "react";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import moment from 'moment';

import {Tabs} from 'antd';
import heatmap from 'highcharts/modules/heatmap';

heatmap(Highcharts);
import Common from "@utils/common";

function CalendarHeatMap({ year, data, channel_id, program_id }) {

  const groupedData = useMemo(() => {
    if (data) {
      // Group by date
      let groupedData = _.groupBy(data, 'date');

      groupedData = _.map(groupedData, (v, k) => [k, {custom: {issued: v, size: v.length}}]);
      
      return _.fromPairs(groupedData, (v) => v[0]);
    }

    return {};
  }, [ data ]);

  const chartData = useMemo(() => {

    let firstDay = moment(`${year}-01-01`, 'YYYY-MM-DD');
    let lastDay = moment(`${year}-12-31`, 'YYYY-MM-DD');

    let allDays = [];

    for (let shift = firstDay.weekday(); shift > 0; shift--) {
      allDays.push(moment(firstDay).subtract(shift, 'day'));
    }

    for (let shift = 0; shift < lastDay.dayOfYear() + (6 - lastDay.weekday()); shift++) {
      allDays.push(moment(firstDay).add(shift, 'day'));
    }

    const data = [];

    for (const day of allDays) {
      data.push({
        x: day.week(),
        y: day.day() === 0 ? 6 : day.day() - 1,
        value: null,
        custom: {issued: [], 'date': day.format('YYYY-MM-DD')}
      });
    }

    let mergedData = [];

    for (let record of data) {

      const gData = groupedData[record.custom.date];

      if (gData) {
        record.value = gData.custom.size;
        record.custom.issued = Common.copy(gData.custom.issued);
      } else {
        record.value = null;
        record.custom.issued = []
      }

      mergedData.push(record)
    }

    const xAxis = {
      title: {
        text: 'Недели'
      },
      categories: _.sortBy(_.uniq(_.map(data, (v) => {
        return v[0]
      })))
    }

    const series = [
      {
        name: 'Heatmap',
        borderWidth: 1,
        data: mergedData,
        dataLabels: {
          enabled: true,
          color: '#000000',
        },
      }
    ];
  
    return {
      chart: {
        type: 'heatmap',
      },
      title: {
        text: '',
        enabled: false
      },
      xAxis,
      yAxis: {
        categories: [
          'Понедельник', // 0
          'Вторник', // 1
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
        reversed: true,
        title: null,
      },
      colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: Highcharts.getOptions().colors[0],
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        hideDelay: 2000,
        style: {
          pointerEvents: 'auto'
        },
        formatter: function () {
          if (this.point.custom.issued.length) {
            return '<div class="">' +
              this.point.custom.issued.map((v) => {
                return `<a href="/programs/${program_id}/channel/${channel_id}/issued/${v.piss_id}">${v.label}</a>`;
              }).join("<br>") + '</div>';
          }

          return this.point.custom.date;

        },
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          events: {
            click: function (event) {
              // Create and display the popup
              let popup = document.createElement('div');
              popup.className = 'popup';
              popup.innerHTML = '<b>Popup Content</b>';
              document.body.appendChild(popup);

              // Position the popup near the click event coordinates
              popup.style.left = event.pageX + 'px';
              popup.style.top = event.pageY + 'px';
            }
          },
        }
      },
      series
    }
  }, [ year, groupedData ]);

  return <HighchartsReact highcharts={Highcharts} options={chartData}/>
}

import './CalendarHeatmap.scss';


function YearHeatmapContent({year, data, channel_id, program_id}) {
  return (
    <Tabs className="top-tabs">
      <Tabs.TabPane tab={'Events'} key={'events'}>
        <br />
        <div>
          <CalendarHeatMap year={year} data={data} channel_id={channel_id} program_id={program_id}/>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab={'TVR'} key={'tvr'} disabled={true}>
        <div>В разработке</div>
      </Tabs.TabPane>
      <Tabs.TabPane tab={'Share'} key={'share'} disabled={true}>
        <div>В разработке</div>
      </Tabs.TabPane>
    </Tabs>
  );
}

function CalendarHeatmap({data = []}) {

  const yearsData = useMemo(() => _.groupBy(data.data, (v) => v.date.split('-')[0]), [data]);

  if (data.length === 0) {
    return <div></div>;
  }

  return (
    <div className="calendar-heatmap-wrapper">
      <Tabs className="top-tabs">
        {
          _.keys(yearsData).map((v) => (
            <Tabs.TabPane tab={v} key={v}>
              <br />
              <YearHeatmapContent year={v} data={yearsData[v]} channel_id={data.channel_id} program_id={data.program_id}/>
            </Tabs.TabPane>
          ))
        }
      </Tabs>
    </div>
  );
}

export default CalendarHeatmap;
