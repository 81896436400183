export type RadioButtonOption<Value extends string | number> = {
  value: Value;
  label?: string;
  baseColor?: RadioButtonBaseColor;
};

export type RadioButtonProps<Value extends string | number> = {
  value: RadioButtonOption<Value>;
  onChange: (value: RadioButtonOption<Value>) => void;
  checked: boolean;
  baseColor?: RadioButtonBaseColor;
  className?: string;
};

export enum RadioButtonBaseColor {
  none = 'none',
  audienceX = 'audienceX',
  audienceY = 'audienceY',
}
