import React, { useEffect, useState } from 'react';
import MultiSelect from '@components/MultiSelect';

import {
  FormControl, FormLabel
} from "@chakra-ui/react"

import './groups.scss';

function Interval(props) {

  const [interval, setInterval] = useState();

  const intervalOptions = [{
    value: 1,
    label: '1 минута',
  }, {
    value: 5,
    label: '5 минут',
  }, {
    value: 10,
    label: '10 минут',
  }, {
    value: 30,
    label: '30 минут',
  }, {
    value: 60,
    label: '60 минут',
  }, {
    value: 120,
    label: '120 минут',
  }];

  const onChange = (interval) => {
    if (props.onChange) {
      props.onChange(interval ? interval[0] : '');
    }
  }

  const onIntervalChange = (value) => {
    setInterval(value);
    onChange(value);
  }

  useEffect(() => {
    if (props.value) {
      setInterval(props.value);
    }
  }, [ props.value ]);

  return (
    <div className='interval-control'>
      <FormControl isRequired={props.isRequired ?? false} className='form-control'>
        { !!props.label && <FormLabel htmlFor="interval">{ props.label }</FormLabel> }

        <MultiSelect
          placeholder="Интервал"
          selected={interval}
          onChange={(value) => onIntervalChange(value)}
          name="interval"
          search={false}
          options={intervalOptions}
          single={true}
        />
      </FormControl>
    </div>
  )
}

export default Interval;
