import { Collection } from '@models/';
import { ChannelFilterEntity } from '@models/channelFilterEntity';

/** Функция формирует массив из id каналов и целых наборов каналов */
export const prepareChannelsPayload = (
  /** Смешанный массив каналов и наборов каналов */
  channels: (ChannelFilterEntity | Collection)[],
): (number | Collection)[] => {
  return channels.map((channel) =>
    'cctgid' in channel ? channel.id : channel,
  );
};
