import * as React from 'react';

import { SESSION_STORAGE_KEYS } from '@config/sessionStorageKeys';
import { ChannelFilterEntity } from '@models/channelFilterEntity';
import { Audience, Collection } from '@models/index';
import { OptionEntity } from '@models/optionEntity';
import { TimebandFilterEntity } from '@models/timebandFilterEntity';
import { useReducerSessionStorage } from '@utils/hooks/useReducerSessionStorage';

import { ScheduleTableContextProps } from './ScheduleTableContext';
import { TableDataActionPayload } from './actions';
import { reducer, actions } from './reducer';
import { createInitialScheduleTableContextValue } from './scheduleTableContextValue';

export const useCreateScheduleStore = (): ScheduleTableContextProps => {
  const [store, dispatch] = useReducerSessionStorage(
    reducer,
    createInitialScheduleTableContextValue(),
    SESSION_STORAGE_KEYS.scheduleModuleState,
  );

  const memoizedActions = React.useMemo(() => {
    return {
      setChannels: (channels: (ChannelFilterEntity | Collection)[]) =>
        dispatch(actions.setChannels(channels)),
      setTimeband: (timeband: TimebandFilterEntity) =>
        dispatch(actions.setTimeband(timeband)),
      setTargetAudiences: (targetAudiences: Audience[]) =>
        dispatch(actions.setTargetAudiences(targetAudiences)),
      setActiveTargetAudience: (targetAudience: OptionEntity) =>
        dispatch(actions.setActiveTargetAudience(targetAudience)),
      removeTargetAudience: (targetAudience: OptionEntity) =>
        dispatch(actions.removeTargetAudience(targetAudience)),
      addDate: (date: Date) => dispatch(actions.addDate(date)),
      setActiveDate: (date: OptionEntity) =>
        dispatch(actions.setActiveDate(date)),
      removeDate: (date: OptionEntity) => dispatch(actions.removeDate(date)),
      setTableData: (tableDataActionPayload: TableDataActionPayload) =>
        dispatch(actions.setTableData(tableDataActionPayload)),
      setAllDataNeedUpdate: () => dispatch(actions.setAllDataNeedUpdate()),
      loadAudiencesForDate: (date: string) =>
        dispatch(actions.loadAudiencesForDate(date)),
      loadDatesForAudiences: (audiences: Audience[]) =>
        dispatch(actions.loadDatesForAudiences(audiences)),
      syncTableDataWithFilters: () =>
        dispatch(actions.syncTableDataWithFilters()),
    };
  }, [dispatch]);

  const isEmptyFilters =
    store.filters.targetAudiences.length === 0 ||
    store.filters.channels.length === 0;

  return {
    value: store,
    actions: memoizedActions,
    isEmptyFilters,
  };
};
