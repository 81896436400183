export const hasNewEntitiesWithId = <
  PrevEl extends { id: any },
  NextEl extends { id: any },
>(
  prevEntities: PrevEl[],
  nextEntities: NextEl[],
) => {
  const newEntities = nextEntities.filter(
    (nextChannel) =>
      !prevEntities.find((prevChannel) => prevChannel.id === nextChannel.id),
  );

  return newEntities.length > 0;
};

export const hasNewDates = (prevDates: Date[], nextDates: Date[]) => {
  const prevDatesValues = prevDates.map((date) => date.getDate());

  const nextDatesValues = nextDates.map((date) => date.getDate());

  const newDates = nextDatesValues.filter(
    (nextDate) => !prevDatesValues.find((prevDate) => prevDate === nextDate),
  );

  return newDates.length > 0;
};
