import React, { useMemo } from 'react';

import { stringToTime, timeToString } from '../../utils';

export interface IntervalsProps {
  from: string;
  duration: number;
}

export function Intervals({ from, duration }: IntervalsProps) {
  // todo KTS check if the same logic already exists
  const intervals = useMemo<string[]>(() => {
    if (!from) {
      return [];
    }

    const start = stringToTime(from);

    const end = start + Math.round(duration / 60);

    const step = Math.round(duration / 60) / 12;

    const intervals: string[] = [];

    for (let time = start; time < end; time += step) {
      intervals.push(timeToString(time));
    }

    intervals.push(timeToString(end));

    return intervals;
  }, [from, duration]);

  return (
    <div className="intervals-wrapper">
      {intervals.map((interval) => (
        <div key={interval}>{interval}</div>
      ))}
    </div>
  );
}
