import axios from 'axios';
import { toast } from 'react-toastify';

import { StatsRequest } from '../models';
import { getStatsPayload, getStatsData } from '../utils';

import { ADMIN_HOST, API_HOST } from './Env';

export const adminInstance = axios.create({
  baseURL: ADMIN_HOST,
});

export const apiInstance = axios.create({
  baseURL: API_HOST,
});

export const PDAPI = {
  population100k: null as boolean | null,
  getUrl(url: string) {
    return apiInstance
      .get(url)
      .then((res) => res.data)
      .catch((error) => toast.error(error));
  },
  getAudiences() {
    return apiInstance
      .get('/api/v3/glossary/wall/target_audiences')
      .then((response) => response.data)
      .catch(() => {
        toast.error('Сервис не доступен.');
      });
  },
  getStatistics() {
    return apiInstance
      .get('/api/v3/glossary/statistics?source=wall')
      .then((response) => response.data)
      .catch(() => {
        toast.error('Сервис не доступен.');
      });
  },
  getStats(request: StatsRequest) {
    return apiInstance
      .post('/api/v3/channel/wall', getStatsPayload(request, this.population100k))
      .then((response) =>
        getStatsData(response.data, request.time),
      )
      .catch(() => {
        toast.error('Сервис не доступен.');
      });
  },
  getTargets() {
    return adminInstance.get('/site/collections/type/1/').then((response) => {
      if (response.data && response.data.length) {
        return response.data[0].children;
      }
    });
  },
  /** AUTH */
  login(username: string, password: string) {
    return adminInstance.post('/user/login', { username, password });
  },
  refresh(token: string) {
    return axios
      .create({
        baseURL: ADMIN_HOST,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .post('/user/refresh', { refresh_token: token });
  },
  logout() {
    return adminInstance.get('/user/logout');
  },
  userInfo() {
    return adminInstance.get('/user/info');
  },
  resetPassword(email: string) {
    return adminInstance.post('/user/reset', { email });
  },
  changePassword(oldPassword: string, newPassword: string) {
    return adminInstance.patch('/user/password', { password: newPassword });
  },
};
