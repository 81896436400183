import { Collection, CollectionOptions } from '@models/index';

// todo KTS add typings
export const getTreeOptions = (
  collections: Collection[],
  prefix = null,
): CollectionOptions[] => {
  return collections
    .filter((collection) => !collection.is_group || collection.children?.length)
    .map((collection) => ({
      value: prefix ? prefix + collection.id : collection.id,
      label: collection.name,
      children:
        collection.is_group && collection.children
          ? getTreeOptions(collection.children)
          : null,
    }));
};
