import * as React from 'react';
import { useTable, useSortBy } from 'react-table';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { Button } from '@components/UI/Buttons';
import { toast } from 'react-toastify';
import Csv from '@utils/Csv.js';

import API from '@utils/AdminApi';

import { TableLayout } from '../tables/layouts';

import '../tables/Tables.scss';

// todo KTS make predictable component directories structur

// todo KTS fix typings
type DataTableProps = {
  /**
   * Данные, с которыми будет выполнен запрос за отчетом
   */
  payload: any;
};

// todo KTS Виртуализация таблицы
/**
 * Компонент отображает таблицу на странице «Мои отчеты»
 */
function DataTable(props: DataTableProps) {
  const [isLoading, setIsLoading] = React.useState(false);

  // todo KTS add table typings
  const [response, setResponse] = React.useState({ columns: [], data: [], csv: [], file: ''});

  const doanloadFile = (url) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = API.getDownloadLink(url);
    a.target = "_blank";
    a.download = url
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  const file2download = React.useMemo(() => {
    return response.file ? `Файл ${response.file} загружается...` : null;
  }, [ response ]);


  // todo KTS remove useMemo
  const data = React.useMemo(() => {
    return response.data ?? [];
  }, [response]);

  const columns = React.useMemo(() => {
    return response.columns ?? [];
  }, [response]);

  const chartToExcelHandler = React.useCallback(() => {
    if (response && response.csv) {
      Csv(response.csv, true);
      toast.success(`Данные скопированы в буфер обмена`);
    }
  }, [ response ]);

  const tableInstance = useTable<Record<string, any>>({
    columns,
    data,
    // @ts-ignore todo KTS remove ts-ignore.
    //   autoResetExpanded doesn't work in react-table v7, but passed here. Check if it really works
    autoResetExpanded: false,
  }, useSortBy);

  /** загрузка данных */
  React.useEffect(() => {
    if (props.payload) {
      setIsLoading(true);
      API.postAdverts(props.payload)
        .then((response) => {
          setResponse(response);

          if (response.file) {
            doanloadFile(response.file);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [props.payload]);

  return (
    <div className="data-table">
      {isLoading ? (
        <div className="data-table-info">Загрузка таблицы ...</div>
      ) : data && data.length ? (
        <>
          <div className="data-table-title-wrapper">
            <div className="data-table-info">Результат</div>
            <div className="data-table-buttons">
              <Button
                onClick={() => chartToExcelHandler()}
                type="base"
                label="CSV"
                disabled={false}
                style={{}}
              >
                <AiOutlineFileExcel />
              </Button>
            </div>
          </div>
          <TableLayout {...tableInstance} sorting={true} rowGroups={[]} />
        </>
      ) : file2download ? (
          <div className="data-table-info">{file2download}</div>
        ) :
          (
        <div className="data-table-info">Нет данных</div>
      )}
    </div>
  );
}

export default DataTable;
