import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Card } from './card';
import { useDrop } from 'react-dnd';

function AxisCard({ axes, dropToAxis, addToAxis, moveCard, dropCard }) {
  const refX = useRef(null);
  const refY = useRef(null);

  const renderCard = (card) => {
    return card.option.value === -1 ? (
      <div className="selector-drop" key={card.option.value}></div>
      ) : (
      <Card
        key={card.option.value}
        index={card.dropId}
        id={card.value}
        text={card.option.label}
        moveCard={moveCard}
        dropCard={dropCard}
      />
    );
  };

  const [{ handlerId: handlerXId }, dropX] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropToAxis(item.index, 'x');
    },
    hover(item) {
      addToAxis(item.index, 'x');
    },
  });

  const [{ handlerId: handlerYId }, dropY] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      dropToAxis(item.index, 'y');
    },
    hover(item) {
      addToAxis(item.index, 'y');
    },
  });

  dropX(refX);
  dropY(refY);

  return (
    <>
      <div className='axes-axis'>
        <div className='axes-axis-label'>Строки</div>
        <div
          className='axes-axis-options'
          ref={refY}
          data-handler-id={handlerYId}
        >
          { axes.y.map((card, index) => renderCard(card, index)) }
        </div>
      </div>
      <div className='axes-axis'>
        <div className='axes-axis-label'>Колонки</div>
        <div
          className='axes-axis-options'
          ref={refX}
          data-handler-id={handlerXId}
        >
          { axes.x.map((card, index) => renderCard(card, index)) }
        </div>
      </div>
    </>
  )
}

export default AxisCard;
