
import { ReactComponent as SearchIcon } from './images/search.svg';

function Search({ filter, onFilter }) {

  return (
    <div className="multiselect-search">
      <div><SearchIcon /></div>
      <div className="multiselect-search-field">
        <input name="search" placeholder='Введите название' value={filter} onChange={(event) => onFilter(event.target.value)} />
      </div>
    </div>
  );
}

export default Search;