import {
  DEFAULT_FILTER_DATE,
  DEFAULT_TIMEBAND_VALUE,
} from '../config/defaultFilterValues';
import { ScheduleTableFilters } from '../models/scheduleTableFilters';
import { ScheduleTableTabsStructure } from '../models/scheduleTableTabsStructure';

/**
 * Структура данных, доступная через контекст страницы ScheduleTable
 */
export type ScheduleTableContextValue = {
  /**
   * Данные таблицы по датам
   */
  tableData: ScheduleTableTabsStructure;

  /**
   * Фильтры таблицы
   */
  filters: ScheduleTableFilters;
};

export const createInitialScheduleTableContextValue =
  (): ScheduleTableContextValue => {
    return {
      tableData: {},
      filters: {
        channels: [],
        timeband: DEFAULT_TIMEBAND_VALUE,
        targetAudiences: [],
        dates: [DEFAULT_FILTER_DATE],
        activeTabOption: {
          targetAudience: null,
          date: DEFAULT_FILTER_DATE,
        },
      },
    };
  };
