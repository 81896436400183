/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Meta } from '@models/meta';
import common from '@utils/common';

import { DEFAULT_TIMEBAND_VALUE } from '../config/defaultFilterValues';
import { loadBroadcastData, useScheduleTableContext } from '../stores';

/**
 * Хук загружает данные для тех пар "дата-аудитория",
 * которые помечены флагом meta = initial (только в пределах
 * текущей выбранной даты в таббаре)
 */
export const useLoadSchedule = () => {
  const dispatch = useDispatch();

  const { value, actions } = useScheduleTableContext();

  const activeDate = value.filters.activeTabOption.date;

  const { targetAudiences, timeband, channels } = value.filters;

  // Дата, выбранная на табе
  const activeDateString = activeDate ? common.toTVDate(activeDate, true) : '';

  /**
   * Хуки формируют строку — триггер, при изменении которого useEffect загружает данные
   * Триггеры: строка из названий аудиторий, id таймбенда, строка из id каналов
   * Триггер формируется, когда изменилась активная вкладка таба даты, изменились аудитории,
   * таймбенд или каналы
   */
  const audienceToLoadNames = useMemo(() => {
    if (!activeDateString || !value.tableData[activeDateString]) {
      return '';
    }

    return Object.entries(value.tableData[activeDateString])
      .filter(([, tabData]) => tabData.meta === Meta.initial)
      .map(([audience]) => audience)
      .join('-');
  }, [activeDateString, targetAudiences, value.tableData]);

  const timeBandTrigger = useMemo(() => {
    return timeband === DEFAULT_TIMEBAND_VALUE ? timeband : timeband?.id;
  }, [timeband]);

  const channelsTrigger = useMemo(() => {
    return channels.map((channel) => channel.id).join('-');
  }, [channels]);

  useEffect(() => {
    if (!audienceToLoadNames) {
      return;
    }

    loadBroadcastData(value, actions.setTableData, dispatch);
  }, [audienceToLoadNames, timeBandTrigger, channelsTrigger]);
};
