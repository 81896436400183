import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

export const status = createSlice({
  name: 'status',
  initialState: {
    status: [],
    statusObject: {},
    maxDate: null,
  },
  reducers: {
    setGlobalStatus: (state, action) => {
      state.status = [...action.payload];

      state.statusObject = {};

      let maxDate = '';

      for (let key in state.status) {
				const el = state.status[key];
			
        state.statusObject[el.table.toLowerCase()] = el.date;

        if (maxDate === '' || maxDate < el.date) {
            maxDate = el.date;
        }
      }

      state.maxDate = moment(maxDate, 'YYYY-MM-DD').toDate();
    },
  },
});

export const { setGlobalStatus } = status.actions;

export default status.reducer;
