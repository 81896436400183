import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import API from '@utils/AdminApi';
import { fetchChannels, fetchChannelsCollections, fetchCollections, fetchDemographicsBreakdown } from '@store/collections';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Demographics from './UI/Demographics';
import { useDispatch, useSelector } from 'react-redux';
import { setBarData, setQueryData, setDemographicsData, setPieCharts } from '@store/audiencePanel';
import MultiSelect from '../../components/MultiSelect';
import PieCharts from './UI/PieCharts';

import './Audience.scss';

function Audience() {
  const location = useLocation();
  const dispatch = useDispatch();

  const audBarStatus = useRef({
    loaded: 0,
    demographics: []
  });

  // const [ audDemTable, setAudDemTable ] = useState({ columns: [], data: [] });

  const { barData, queryData, demographicsData } = useSelector((state) => state.audiencePanel);

  const { demographicsBreakdown: demographics } = useSelector((state) => state.collections);

  const highchartsRef = useRef();

  const audBar = useMemo(() => ({
    chart: {
      type: 'bar',
      height: (barData.xAxis.categories ? barData.xAxis.categories.length * 50 : 0) + 100,
    },
    title: {
      text: null// 'Аудитория по полу и возрастной группе',
    },
    subtitle: {
      text: '',
    },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.',
      },
    },
    colors: ['#585771', '#D9E0ED'],
    xAxis: barData.xAxis,
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: function() {
          return this.value;
        },
      },
      accessibility: {
        description: 'Аудитория в тысячах',
        rangeDescription: 'тысяч',
      },
      opposite: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        },
      },
      series: {
        pointPadding: 0.05,
        groupPadding: 0.1,
      }
    },
    tooltip: {
      formatter: function() {
        return '<b>' + this.series.name + ', возраст ' + this.point.category + '</b><br/>' +
          'Аудитория: ' + Highcharts.numberFormat(this.point.y < 0 ? this.point.y * -1.0 : this.point.y, 1) + ' тысяч';
      },
    },

    series: barData.series,
  }), [ barData ]);

  const members = useMemo(() => {
    return location.state ? location.state.members : JSON.parse('["1beaac7","1bef959","1befb4f","1befd2a","1bf103c","1bf19ca","1bf1efc","1bf2769","1bf2c77","1bf2c78","1bf3280","1bf3a01","1bf3a02"]');
  }, [location]);

  const demographicOptions = useMemo(
    () => demographics
      ? demographics.map(({ id, name }) => ({ label: name, value: id }))
      : [],
    [ demographics ]
  );

  const demographicsValue = useMemo(
    () => demographics && demographicsData 
      ? demographics.filter(({ id }) => demographicsData.indexOf(id) !== -1).map(({ id , name}) => ({ id, name }))
      : [],
    [ demographicsData, demographics ]
  );

  const onChange = (value) => {
    dispatch(setDemographicsData(value));
  }

  const isChanged = useCallback(() => {
    if (audBarStatus.current.demographics.length === 0 && demographicsData.length === 0) {
      return false;
    }

    if (audBarStatus.current.demographics.length !== demographicsData.length) {
      return true;
    }

    return !!audBarStatus.current.demographics.filter(({ id }) => demographicsData.indexOf(id) === -1).length

  }, [ demographicsData ]);

  useEffect(() => {
/*
    location.state = {
      source: JSON.parse('{"group":"G90","base":[],"name":"Аудитория Х","SelectedX":[{"value":"1862;1;1;195350","label":"Лесник","path":["НТВ"]}],"issuedSelectedX":["f7152fce"]}'),
      members: JSON.parse('["1beaac7","1bef959","1befb4f","1befd2a","1bf103c","1bf19ca","1bf1efc","1bf2769","1bf2c77","1bf2c78","1bf3280","1bf3a01","1bf3a02"]'),
    };
*/
    if (audBarStatus.current.loaded === 0 || isChanged()) {
      audBarStatus.current.loaded = 1;
      audBarStatus.current.demographics = demographicsValue;

      API.postAudienceDemographics(location.state.source, location.state.members, demographicsValue).then((response) => {
        let newQueryData = { ...queryData };
        
        newQueryData.fromDate = moment(response.minDate).toDate();
        newQueryData.toDate = moment(response.maxDate).toDate();

        dispatch(setQueryData(newQueryData));

        dispatch(setBarData({
          xAxis: response.bar.xAxis[0],
          series: response.bar.series.map(row => ({
            ...row,
            data: row.data.map(value => Math.abs(value)),
          })),
        }));

        dispatch(setPieCharts(response.pie_charts ?? []));

        // setAudDemTable(response.table);
      }, (error) => {
        audBarStatus.current.loaded = 0;
        dispatch(setPieCharts([]));
        // setAudDemTable({ columns: [], data: [] });
      });

      dispatch(
        fetchChannelsCollections(),
      );

      dispatch(
        fetchCollections(),
      );

      dispatch(
        fetchDemographicsBreakdown()
      );

      dispatch(
        fetchChannels(),
      );
    }
  }, [ location, demographicsValue, queryData ]);

  return (
    <>
      <div className="audience-wrapper">
        <div className="audience-title">
          <div className="title">
            <div>Демография аудитории</div>
            <div className="subtitle">Аудитория по полу и возрастной группы</div>
          </div>
          <div>
            <div className="form-control">
              <label>Категории</label>

              <MultiSelect
                options={demographicOptions}
                onChange={onChange}
                search={false}
                selected={demographicsData}
                single={false}
                line={true}
              />
            </div>
          </div>
        </div>
        <div className="audience-content">
          <div className="bars">
            <HighchartsReact highcharts={Highcharts} options={audBar} ref={highchartsRef} allowChartUpdate={true} />
          </div>
          <div className="pie-charts">
            <PieCharts />
          </div>
        </div>
      </div>
      <br />
      <Demographics members={members} />
    </>
  );
}

export default Audience;