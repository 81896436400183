/* eslint-disable */
import React, { useEffect, useState } from 'react';
import MultiSelect from '../MultiSelect';

import FieldSelect from './UI/FieldSelect';
import { DeleteIcon } from './UI/Buttons';
import TimePicker from './UI/TimePicker';
import MonthPicker from './UI/MonthPicker';

import { Flex } from '@chakra-ui/react';

import moment from 'moment-timezone';

/**
 * условие для таймбендов
 * @param {*} props
 * @returns
 */
function Timeband(props) {
  const [field, setField] = useState();

  const [value, setValue] = useState([]);

  /** поля для условий */
  const fields = [
    {
      key: 'time',
      name: 'Время',
      condition: 'between',
    },
    {
      key: 'weekday',
      name: 'День недели',
      condition: 'in',
    },
    {
      key: 'month_day',
      name: 'Месяц, день',
      condition: 'between',
    },
  ];

  /** дни недели */
  const days = [
    {
      value: 1,
      label: 'Понедельник',
    },
    {
      value: 2,
      label: 'Вторник',
    },
    {
      value: 3,
      label: 'Среда',
    },
    {
      value: 4,
      label: 'Четверг',
    },
    {
      value: 5,
      label: 'Пятница',
    },
    {
      value: 6,
      label: 'Суббота',
    },
    {
      value: 7,
      label: 'Воскресенье',
    },
    {
      value: 10,
      label: 'Выходные',
    },
    {
      value: 11,
      label: 'Праздники',
    },
  ];

  /** удаление условия */
  const onDelete = () => {
    if (props.onDeleteHandle) {
      props.onDeleteHandle();
    }
  };

  /**
   * получение значений по умолчанию
   * @param {*} field
   * @returns
   */
  const getDefaultValue = (field) => {
    switch (field) {
      case 'time':
        return ['05:00', '29:00'];

      case 'datetime':
        return [
          moment().format('yyyy-MM-DD') + ' 05:00',
          moment().format('yyyy-MM-DD') + ' 29:00',
        ];

      case 'daytime':
        return [moment().isoWeekday(), '05:00', '29:00'];

      default:
        return [];
    }
  };

  /**
   * форматирование дат в строки
   *
   * @param {*} field
   * @param {*} value
   * @returns
   */
  const getValue = (field, value) => {
    switch (field) {
      case 'datetime':
        return [
          moment(value[0]).format('yyyy-MM-DD') + ' ' + value[1],
          moment(value[2]).format('yyyy-MM-DD') + ' ' + value[3],
        ];

      default:
        return value;
    }
  };

  /**
   * преобразование строк в даты
   * @param {*} field
   * @param {*} value
   * @returns
   */
  const getPropsValue = (field, value) => {
    switch (field) {
      case 'datetime':
        const from = value[0].toString().split(' ');
        const to = value[1].toString().split(' ');

        return [
          moment(from[0], 'yyyy-MM-DD').toDate(),
          from[1],
          moment(to[0], 'yyyy-MM-DD').toDate(),
          to[1],
        ];

      default:
        return value;
    }
  };

  /**
   * обработчик изменения поля
   * @param {*} event
   */
  const onChangeField = (field) => {
    if (props.onChangeHandle) {
      const emptyValue = getDefaultValue(field);
      const currentField = fields.filter((f) => f.key === field)[0];

      props.onChangeHandle(field, currentField.condition, emptyValue);
    }
  };

  /**
   * обработчик изменения значний для условий
   * @param {*} val
   * @param {*} num
   * @returns
   */
  const onChangeValue = (val, num) => {
    if (val === null) {
      // пропускаем если время и дата не введены до конца
      return;
    }

    const newValue =
      field === 'daytime'
        ? [value[0] ?? 1, value[1], value[2]]
        : field === 'datetime'
        ? [value[0] ?? new Date(), value[1], value[2] ?? new Date(), value[3]]
        : value;

    newValue[num] = val;

    setValue(newValue);

    if (props.onChangeHandle) {
      const currentField = fields.filter((f) => f.key === field)[0];

      props.onChangeHandle(
        field,
        currentField.condition,
        getValue(field, newValue),
      );
    }
  };

  /** инициализация значений по пропсам */
  useEffect(() => {
    const _field = props.field ? props.field : 'weekday';
    const _value = props.value
      ? getPropsValue(_field, props.value)
      : getPropsValue(_field, getDefaultValue(_field));

    setField(_field);
    setValue(_value);
  }, [props]);

  return (
    <div className="timeband">
      <div className="condition-wrapper">
        <div className="field">
          <FieldSelect
            value={field}
            placeholder="Поле"
            onChange={(event) => onChangeField(event?.value)}
            options={fields.map(({ key, name }) => ({
              value: key,
              label: name,
            }))}
          />
        </div>
        {field === 'time' ? (
          <Flex>
            <TimePicker
              className="time-picker"
              value={value && value[0] ? value[0] : ''}
              onChange={(value) => onChangeValue(value, 0)}
            />
            &nbsp;
            <TimePicker
              className="time-picker"
              value={value && value[1] ? value[1] : ''}
              onChange={(value) => onChangeValue(value, 1)}
            />
          </Flex>
        ) : field === 'month_day' ? (
          <Flex>
            <MonthPicker
              value={value && value[0] ? value[0] : ''}
              onChange={(value) => onChangeValue(value, 0)}
            />
            &nbsp;-&nbsp;
            <MonthPicker
              value={value && value[1] ? value[1] : ''}
              onChange={(value) => onChangeValue(value, 1)}
            />
          </Flex>
        ) : (
          <div className="field full-width">
            <MultiSelect
              options={days}
              onChange={(values) => onChangeValue(values, 0)}
              selected={value[0]}
              dropDown={true}
            />
          </div>
        )}
      </div>
      <div className="condition-actions">
        <DeleteIcon onClick={onDelete} />
      </div>
    </div>
  );
}

export default Timeband;
