import React from 'react';
import { Progress } from "@chakra-ui/react"

import './LoaderTop.scss';

const LoaderTop = () => {
    return (
        <div className="loaderTop">
          <Progress size="xs" isIndeterminate />
        </div>
    );
}

export default LoaderTop;
