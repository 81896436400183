import { createSlice } from '@reduxjs/toolkit';

import { AudienceCompareGroup } from '@store/forms/FormStorage.types';
import { getAllEventsAudienceSelector } from '@store/forms/formStorageSelectors';

const initialState = {
  eventsAudience: {
    target: null,
    payloadX: {
      source: 'program',
      filter: 'issued',
      program: null,
      channel: null,
      issued: null,
      date: null,
      dateRange: null,
      timeband: null,
      premier: 'all',
    },
    payloadY: {
      source: 'program',
      filter: 'issued',
      program: null,
      channel: null,
      issued: null,
      date: null,
      dateRange: null,
      timeband: null,
      premier: 'all',
    },
  },
  channelsPage: {
    period: null,
    response: null,
    filters: {
      dateRange: '',
      targetBase: '',
      channels: '',
      statistics: '',
    },
  },
  programsPage: {
    search: {
      term: '',
      results: [],
    },
    filters: {
      channel: [],
      date: null,
      statistics: [],
      target: null,
    },
    series: [],
  },
  scheduleCellsToCompare: {},
  templates: {
    channels: null
  }
};

export const FormStorage = createSlice({
  name: 'FormStorage',
  initialState,
  reducers: {
    setEventsAudience: (state, action) => {
      state.eventsAudience = action.payload;
    },
    setChannelsPage: (state, action) => {
      state.channelsPage = action.payload;
    },
    setProgramsSearch: (state, action) => {
      state.programsPage.search = action.payload;
    },
    setProgramsFilters: (state, action) => {
      state.programsPage.filters = action.payload;
    },
    setProgramsSeries: (state, action) => {
      state.programsPage.series = action.payload ? [ ...action.payload ] : [];
    },
    setScheduleCellToCompare: (state, action) => {
      state.scheduleCellsToCompare[action.payload.value] = action.payload;
    },
    setTemplate: (state, action) => {
      state.templates[action.payload.key] = action.payload.value;
    },
    addScheduleEventToAudienceCompare: (state, action) => {

      const { cellEntity, group } = action.payload;

      const {
        // audienceX - список событий к сравнению, в аудитории X
        payloadX: { issued: audienceX, program: _programX },
        // audienceY - список событий к сравнению, в аудитории Y
        payloadY: { issued: audienceY, program: _programY },
      } = state.eventsAudience;

      const { payload } = cellEntity;

      const allAudienceEventsEnumeration = getAllEventsAudienceSelector({
        formStorage: state,
      });

      const chosenGroup = allAudienceEventsEnumeration[payload.pissId];

      let issuedX = audienceX || [];

      let issuedY = audienceY || [];

      let programX = _programX || [];
      let programY = _programY || [];

      let isAuditoryXChanged,
        isAuditoryYChanged = false;

      const isEventAlreadyInAudienceX =
        chosenGroup === AudienceCompareGroup.audienceX;

      const isEventAlreadyInAudienceY =
        chosenGroup === AudienceCompareGroup.audienceY;

      /**
       * удаляем передачу из списка событий в аудитории X
       */
      if (isEventAlreadyInAudienceX) {
        issuedX = issuedX.filter((event) => event !== payload.pissId);
        programX = programX.filter((event) => event.value !== payload.path);
        isAuditoryXChanged = true;
      }

      /**
       * удаляем передачу из списка событий в аудитории Y
       */
      if (isEventAlreadyInAudienceY) {
        issuedY = issuedY.filter((event) => event !== payload.pissId);
        programY = programY.filter((event) => event.value !== payload.path);
        isAuditoryYChanged = true;
      }

      /**
       * добавляем передачу в соответствующий список событий аудитории X или Y
       */
      switch (group) {
        case AudienceCompareGroup.audienceX:
          issuedX.push(payload.pissId);
          programX.push({
            value: payload.path,
            path: [payload.channel],
            label: payload.program
          });
          isAuditoryXChanged = true;
          break;

        case AudienceCompareGroup.audienceY:
          issuedY.push(payload.pissId);
          programY.push({
            value: payload.path,
            path: [payload.channel],
            label: payload.program
          });
          isAuditoryYChanged = true;
          break;

        default:
          break;
      }

      if (isAuditoryXChanged) {
        state.eventsAudience.payloadX.issued = issuedX;
        state.eventsAudience.payloadX.program = programX;
        state.eventsAudience.payloadX.source = "program";
      }

      if (isAuditoryYChanged) {
        state.eventsAudience.payloadY.issued = issuedY;
        state.eventsAudience.payloadY.program = programY;
        state.eventsAudience.payloadY.source = "program";
      }
    },
  },
});

export const {
  setEventsAudience,
  setChannelsPage,
  setProgramsSearch,
  setProgramsFilters,
  setProgramsSeries,
  setScheduleCellToCompare,
  addScheduleEventToAudienceCompare,
  setTemplate,
} = FormStorage.actions;

export default FormStorage.reducer;
