
function Csv(data, isText = false) {
  const processRow = function (row) {
    const finalVal = [];

    row.forEach(entry => {
      let innerValue = entry === null ? '' : entry.toString();



      if (entry instanceof Date) {
        innerValue = row[j].toLocaleString();
      }

      let result = innerValue.replace(/"/g, '""');

      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"';
      }

      finalVal.push(result);
    })
    return finalVal.join('\t') + '\n';
  };

  let csvFile = "\ufeff" + '';

  if (isText) {
    csvFile += data;
  } else {
    data.forEach(row => {
      csvFile += processRow(row);
    });
  }

  let input = document.createElement("INPUT");
  input.setAttribute("type", "hidden");
  input.setAttribute("id", "myHiddenCsvData");

  document.body.appendChild(input);
  const copyText = document.getElementById("myHiddenCsvData");

  copyText.value = csvFile;
  copyText.select();

  navigator.clipboard.writeText(copyText.value);

  copyText.remove();
}

export default Csv;
