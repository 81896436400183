import { useState, useEffect, useCallback } from 'react';
import { ReactComponent as Right } from '../images/right.svg';
import { ReactComponent as Down } from '../images/down.svg';
import { useOptions, toList } from './OptionsProvider';
import { Spin } from 'antd';
import { getId } from './utils';

import { LoadingOutlined } from '@ant-design/icons';
import { useDataContext } from './DataProvider';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

function SearchTreeView({ parent, path, addItem }) {
  const [ options, setOptions ] = useState(null);

  const { searchGroups, setSearchGroups, getOptions, searchOptions: globalOptions } = useOptions();

  const { keyField, labelField, selectFilter, getIcon } = useDataContext();

  const openGroup = (path) => {
    const id = getId(path, keyField);

    setSearchGroups([...searchGroups, id]);
  }

  const closeGroup = (path) => {
    const id = getId(path, keyField);

    setSearchGroups(searchGroups.filter(value => value !== id));
  }

  const isOpen = useCallback((path) => {
    return searchGroups.indexOf(getId(path, keyField)) !== -1;
  }, [ searchGroups, keyField ]);

  useEffect(() => {
    if (options) {
      return;
    }

    if (parent === null) {
      setOptions(toList(globalOptions));
      return;
    }

    const getResult = result => {
      setOptions(result);
    }

    let _options = getOptions(parent, getResult, true, [ ...path.map(entry => entry[keyField]) ]);

    if (_options !== null) {
      setOptions(_options);
    }
  }, [ globalOptions, path ]);

  const isDisabled = (option) => {
    return !selectFilter || selectFilter(option) ? {} : {
      className: 'disabled'
    }
  }

  return (
    <>
      { options === null ? (
        <div className="multiselect-options-option">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        !!options && options.length ? options.map(option => 
          !!option.children
            ? (
              <div key={`option-${option[keyField]}`}>
                <div
                  className="multiselect-options-option"
                >
                  <div className="__icon" style={{opacity: option.children !== null ? 1 : 0.3}}>
                    { isOpen([...path, option]) ? (
                      <Down onClick={() => closeGroup([...path, option])} />
                    ) : (
                      <Right onClick={() => option.children !== null ? openGroup([...path, option]) : false} />
                    ) }
                  </div>
                  { getIcon ? getIcon(option.type) : <></> }
                  <div onClick={() => addItem([...path, option])} {...isDisabled(option)}>{ option[labelField] }</div>
                </div>
                { isOpen([...path, option]) && (
                  <div
                    className="multiselect-options-group"
                  >
                    <SearchTreeView parent={option[keyField]} path={[...path, option]} addItem={addItem} />
                  </div>
                ) }
              </div>
            ) : (
              <div
                key={`option-${option[keyField]}`}
                className="multiselect-options-option"
              >
                { getIcon ? getIcon(option.type) : <></> }
                <div onClick={() => addItem([...path, option])} {...isDisabled(option)}>{ option[labelField] }</div>
              </div>
            )
        ) : (
          <div className="multiselect-options-option">
            <div className="small">пусто</div>
          </div>
        )
      ) }
    </>
  )
}

export default SearchTreeView;