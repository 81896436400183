import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from '@utils/AdminApi'

export const fetchFilterDict = createAsyncThunk('dict/fetchData', async () => {
  const response = await API.filter()
  return response?.results
})


export const dict = createSlice({
  name: 'dict',
  initialState: {
    metrics: [],
    dimensions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFilterDict.fulfilled, (state, action) => {
      state.metrics = action.payload?.metrics || []
      state.dimensions = action.payload?.dimensions || []
    });
  },
})

export const selectFilterDict = createSelector(
  (state) => ({
    metrics: state.metrics,
    dimensions: state.dimensions,
  }), (state) => state,
)

export default dict.reducer
