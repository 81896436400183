import { Tabs } from 'antd';
import Slider from 'rc-slider';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Chart } from '../../components/Video/Chart';
import { Intervals } from '../../components/VideoUI/Intervals';
import { PlayButton } from '../../components/VideoUI/PlayButton';
import { NextButton } from '../../components/VideoUI/NextButton';
import { PrevButton } from '../../components/VideoUI/PrevButton';
import { SpeedButton } from '../../components/VideoUI/SpeedButton';
import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useVideosContext } from '../../store/VideosProvider';
import { timeToString, stringToTime } from '../../utils/index';

import 'rc-slider/assets/index.css';

const { TabPane } = Tabs;

export function Results() {
  const { statistics: dictStatistics } = useDictionaryContext();

  const {
    statistics,
    interval,
    duration,
    position,
    play,
    canPlayVideo,
    setPosition,
    startPlay,
    stop,
    seek,
  } = useVideosContext();

  const [value, setValue] = useState<number | number[]>(0);

  const [dragging, setDragging] = useState<boolean>(false);

  const onAfterChange = (value: number | number[]) => {
    if (value as number) {
      const prc = duration ? (value as number) / duration : 0;

      setPosition(prc);
      seek(prc);
    }

    setDragging(false);
  };

  const onChange = (value: number | number[]) => {
    setValue(value);
  };

  const onBeforeChange = () => {
    setDragging(true);
  };

  const charts = useMemo(() => {
    return statistics
      ? statistics.map((type) => {
          const current = dictStatistics?.filter(
            (statistic) => statistic.type === type,
          );

          const title = current && current.length ? current[0].name : '';

          return {
            type,
            title,
          };
        })
      : [];
  }, [statistics, dictStatistics]);

  useEffect(() => {
    if (!dragging) {
      setValue(Math.round(position * duration));
    }
  }, [position, duration, dragging]);

  const ariaValueTextFormatterForHandle = useCallback(
    (value: number) => {
      if (interval) {
        const seconds = Math.round(value % 60);

        const time = stringToTime(interval) + Math.floor(value / 60);

        return (
          timeToString(time) + ':' + (seconds < 10 ? `0${seconds}` : seconds)
        );
      }

      return '';
    },
    [interval],
  );

  const onPlay = (value: boolean) => {
    value ? startPlay() : stop();
  };

  return (
    <div className="dashboard-results">
      <div className="dashboard-results-wrapper">
        <div className="dashboard-title">
          <div>Результаты</div>
          <div className="player-bottons">
            <PrevButton />
            <SpeedButton />
            <NextButton />
          </div>
        </div>
        <div className="seek-bar">
          <PlayButton play={play} onPlay={onPlay} disabled={!canPlayVideo} />
          <div className="seek-bar-wrapper">
            <div className="seek-bar-line">
              <Slider
                value={value}
                min={0}
                max={duration}
                onBeforeChange={onBeforeChange}
                onChange={onChange}
                onAfterChange={onAfterChange}
                ariaValueTextFormatterForHandle={
                  ariaValueTextFormatterForHandle
                }
              />
            </div>
            <Intervals from={interval ? interval : ''} duration={duration} />
          </div>
        </div>
        <Tabs>
          {!!statistics &&
            charts.map(({ type, title }) => (
              <TabPane tab={title} key={type}>
                <Chart type={type} key={type} />
              </TabPane>
            ))}
        </Tabs>
      </div>
    </div>
  );
}
