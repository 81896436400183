import cn from 'classnames';
import * as React from 'react';

import { OptionEntity } from '@models/optionEntity';

import { Tab, TabProps } from '../Tab';

import s from './TabFilled.module.scss';

type TabFilledProps = TabProps<OptionEntity>;

const TabFilled: React.FC<TabFilledProps> = (props) => {
  return (
    <Tab
      {...props}
      className={cn(s['tab-filled'], props.chosen && s['tab-filled_chosen'])}
      value={props.value}
    />
  );
};

export default TabFilled;
