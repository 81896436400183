import React, { useContext, useReducer } from "react"

const FormTemplatesContext = React.createContext();

export const useFormTemplatesProvider = () => {
  return useContext(FormTemplatesContext);
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, filters: action.filters, savedFilters: null }

    case 'reset':
      return { ...state, filters: null, savedFilters: state.filters }
  
    default:
      return state;
  }
}

export const FormTemplatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    filters: null,
    savedFilters: null,
    load: (filters) => {},
    reset: () => {},
  });

  const load = (filters) => dispatch({ type: 'set', filters });
  const reset = () => dispatch({ type: 'reset'});

  return (
    <FormTemplatesContext.Provider value={{
      filters: state.filters,
      savedFilters: state.savedFilters,
      load,
      reset
    }}>
      { children }
    </FormTemplatesContext.Provider>
  )
}