import React, { useContext, useReducer, useEffect, useRef } from "react"

const FiltersContext = React.createContext();

export const useFilters = () => {
  return useContext(FiltersContext);
}

const reducer = (state, { type, payload, field }) => {
  switch (type) {
    case 'set':
      return { ...state, ...payload }

    case 'setField':
        state[field] = payload;

        return { ...state, }

    default:
      return state;
  }
}

export const FiltersProvider = ({ defaultValue, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    dateRange: '',
    targetBase: '',
    channels: '',
    statistics: '',
    onConfirm: () => {},
  });

  const setFilters = (filters) => dispatch({ type: 'set', payload: filters });
  const setFilter = (value, name) => dispatch({ type: 'setField', payload: value, field: name });

  const prevValue = useRef();

  useEffect(() => {
    if (defaultValue && !prevValue.current) {
      setFilters(defaultValue);
    }

    prevValue.current = defaultValue;
  }, [ defaultValue ]);

  return (
    <FiltersContext.Provider value={{
      dateRange: state.dateRange,
      targetBase: state.targetBase,
      channels: state.channels,
      statistics: state.statistics,
      setFilters,
      setFilter,
    }}>
      { children }
    </FiltersContext.Provider>
  )
}