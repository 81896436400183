import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MultiSelect from '@components/MultiSelect';

import { findElement, getTreeOptions } from './Utils';

import {
  FormControl, FormLabel
} from "@chakra-ui/react"

function Channels(props) {

  const channelsCollection = useSelector((state) => state.collections.channelsCollections);
  const channels = useSelector((state) => state.collections.channels);

  const collections = useMemo(() => {
    return channelsCollection.length ? channelsCollection[0].children : [];
  }, [channelsCollection]);

  const [value, setValue] = useState();

  const options = useMemo(() => {
    const _collections = props.channelsOnly
      ? []
      : [{
          value: `c`,
          label: 'Наборы каналов',
          children: getTreeOptions(collections)
        }];

    return [
      ..._collections,
      ...channels.map(channel => {
        return {
          value: 'ch' + channel.id,
          label: channel.name,
        }
      })
    ];
  }, [ collections, channels, props.channelsOnly ]);

  const onChange = (value) => {
    setValue(value);

    if (props.onChange) {
      const result = [];

      value.forEach(val => {
        if (typeof val === 'string' && val.substr(0, 2) === 'ch') {
          result.push(
            parseInt(val.substr(2))
          );
        } else {
          const el = findElement(collections, parseInt(val));

          result.push({
            type: 'collection',
            id: el.id,
            content: el.content,
          });
        }
      });

      props.onChange(result);
    }
  }

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  return (
    <div>
      <FormControl
        className={props.className ?? ''}
        isRequired={props.isRequired ?? false}
      >
        { !!props.label && <FormLabel>{ props.label }</FormLabel> }

        <MultiSelect
          options={options}
          onChange={onChange}
          selected={value}
          search={props.search ?? true}
          dropDown={props.dropDown ?? true}
          single={props.single ?? false}
          placeholder={props.placeholder ?? ''}
          path={true}
          line={true}
        />
      </FormControl>
    </div>
  )
}

export default Channels;
