import React from "react";
import { useConfirm } from "./ConfirmProvider";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import "./Confirm.scss";

function Confirm() {
  const { visible, message, hide, onConfirm } = useConfirm();

  const confirmHandle = () => {
    if (onConfirm) {
      onConfirm();
    }

    hide();
  }

  return (
    <>
      <Modal isOpen={visible} onClose={hide}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className="confirm-message">{ message }</div>
          </ModalBody>
          <ModalFooter>
            <button className="confirm-no" onClick={hide}>Нет, оставить</button>
            <button className="confirm-yes" onClick={confirmHandle}>Да, удалить</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Confirm;