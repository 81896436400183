import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collection from './Collection';
import { useConfirm } from '@components/Confirm/ConfirmProvider';
import { useDrop } from 'react-dnd';

import { AddIcon, AddGroupIcon, EditIcon, DeleteIcon } from './Buttons';

import { ReactComponent as Right } from './images/right.svg';
import { ReactComponent as Down } from './images/down.svg';

import { openGroup, closeGroup, setEditGroup } from '@store/collections';

function Group(props) {
  const dispatch = useDispatch();

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'collection',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      if (props.dropRow) {
        props.dropRow(item.id, props.id, item.last);
      }
    },
    hover(item) {
      if (!ref.current) {
          return;
      }

      const dragId = item.index;
      const hoverId = props.id;

      if (dragId === hoverId) {
          return;
      }

      if (props.moveRow) {
        props.moveRow(dragId, hoverId, props.children?.length);
      }
    },
  });

  drop(ref);

  const input = useRef();

  const openGroups = useSelector((state) => state.collections.openGroups);
  const editGroup = useSelector((state) => state.collections.editGroup);

  const [editName, setEditName] = useState('');

  const confirm = useConfirm();

  const isEdit = useMemo(() => {
    return editGroup === props.id;
  }, [editGroup])

  const setEdit = () => {
    dispatch(setEditGroup(props.id));
  }

  const isOpen = useMemo(() => {
    return openGroups.indexOf(props.id) !== -1;
  }, [openGroups]);

  const toggle = () => {
    if (props.children?.length) {
      isOpen ? dispatch(closeGroup(props.id)) : dispatch(openGroup(props.id));
    }
  }

  const save = (name) => {
    if (props.actions.editGroupHandle) {
      props.actions.editGroupHandle(props.id, props.parent, name, props.type);
    }

    dispatch(setEditGroup(0));
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      save(event.target.value);
    }
  }

  const deleteHandle = (event) => {
    confirm.show(
      'Удалить группу со всеми наборами?',
      () => props.actions.deleteHandle(props, true),
    );

    event.preventDefault();
  }

  useEffect(() => {
    if (isEdit) {
      setEditName(props.name);
      input.current.focus();
    }
  }, [isEdit]);

  return (
    <div className='collection-group'>
      <div
        className='wrapper'
        style={{paddingLeft: props.level * 30}}
        ref={ref}
        data-handler-id={handlerId}
      >
        <div className='list' onClick={() => toggle()}>{ isOpen ? <Down /> : <Right className={props.children?.length ? '' : 'disabled'} /> }</div>
        <div className='name' onClick={() => toggle()}>
          { isEdit ? (
            <input
              ref={input}
              value={editName}
              onChange={(event) => setEditName(event.target.value)}
              onBlur={(event) => save(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          ) : (props.name ?? '')
          }
        </div>
        <div className='actions'>
          <div>
            <AddIcon onClick={() => props.actions.createHandle(props.id, props.type)} />
            <AddGroupIcon onClick={() => props.actions.createGroupHandle(props.id, props.type)} />
            <EditIcon onClick={() => setEdit()} />
            <DeleteIcon onClick={deleteHandle} />
          </div>
        </div>
      </div>
      { !!props.flag && (
        <div style={{marginLeft: (props.level + 1) * 30 - 6}} className='drop-flag' />
      )}
      { isOpen ? (
        <div className="children">
          { props.children?.filter(element => element.is_group === true).map(element => (
              <Group
                {...element}
                type={props.type}
                actions={props.actions}
                level={props.level + 1}
                id={element.id}
                key={'g_' + element.id}
                moveRow={props.moveRow}
                dropRow={props.dropRow}
              ></Group>
            )
          )}
          { props.children?.filter(element => element.is_group !== true).map(element => (
              <Collection
                {...element}
                type={props.type}
                actions={props.actions}
                level={props.level + 1}
                id={element.id}
                key={'c_' + element.id}
                moveRow={props.moveRow}
                dropRow={props.dropRow}
                last={props.children?.length > 1 ? 0 : props.id}
              ></Collection>
            )
          )}
        </div>
      ) : '' }
    </div>
  );
}

export default Group;