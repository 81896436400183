import * as React from 'react';

import { useAuthContext } from '@store/AuthProvider';

type ReducerSessionStorageOptions = {
  clearOnLogout?: boolean;
};

/**
 * Каждый JSON-токен при десиреализации проходит через этот метод
 */
const parseJSONToken = (_, value) => {
  const dateLikeObj = new Date(value);

  /**
   * Изначально дата сериализуется в JSON с помощью метода toISOString,
   * поэтому сравнимаем, является ли value валидной строкой формата iso 8601
   */
  const isDateString =
    String(dateLikeObj) !== 'Invalid Date' &&
    dateLikeObj.toISOString() === value;

  if (isDateString) {
    return dateLikeObj;
  }

  return value;
};

export const useReducerSessionStorage = <R extends React.Reducer<any, any>>(
  reducer: R,
  initialArg: React.ReducerState<R>,
  stateKey: string,
  { clearOnLogout }: ReducerSessionStorageOptions = {
    clearOnLogout: false,
  },
): [React.ReducerState<R>, React.Dispatch<React.ReducerAction<R>>] => {
  const { isLoggedIn } = useAuthContext();

  const syncReducerAndWriteToStorage: R = ((
    prevState,
    action,
  ): ReturnType<R> => {
    const nextState = reducer(prevState, action);

    const nextStateKey = stateKey;

    window.sessionStorage.setItem(nextStateKey, JSON.stringify(nextState));

    return nextState;
  }) as R;

  const syncInitialStateFromStorage = (
    initialState: React.ReducerState<R>,
  ): React.ReducerState<R> => {
    const savedInitialValue = sessionStorage.getItem(stateKey);

    if (!savedInitialValue) {
      return initialState;
    }

    try {
      const result = JSON.parse(savedInitialValue, parseJSONToken);

      return result;
    } catch (err) {
      return initialState;
    }
  };

  const result = React.useReducer<R, React.ReducerState<R>>(
    syncReducerAndWriteToStorage,
    initialArg,
    syncInitialStateFromStorage,
  );

  // Очищаем состояние, если пользователь разлогинился
  React.useEffect(() => {
    if (!isLoggedIn && clearOnLogout) {
      sessionStorage.removeItem(stateKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, clearOnLogout]);

  return result;
};
