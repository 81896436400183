import { MdAccountTree, MdResetTv, MdVideoLibrary, MdCopyright, MdModelTraining } from 'react-icons/md';

import API from '@utils/AdminApi';

export const searchAdvertsAdvert = (text) => {
  return new Promise(resolve => {
    API.searchAdvertsAdvert(text).then(
      result =>
        resolve(result.map(({ advert_id, name }) => ({id: advert_id, name, type: 'advert'})))
    );
  });
};

export const searchAdvertsAdvertiser = (text) => {
  return new Promise(resolve => {
    API.searchAdvertsAdvertiser(text).then(
      result =>
        resolve(result.map(({ advertiser_id, name }) => ({id: advertiser_id, name, type: 'advertiser' })))
    );
  });
};

export const searchAdvertsBrands = (text) => {
  return new Promise(resolve => {
    API.searchAdvertsBrands(text).then(
      result =>
        resolve(result.map((entry) => ({...entry, pk_id: entry.id})))
    );
  });
};

export const searchAdvertsSubbrands = (text) => {
  return new Promise(resolve => {
    API.searchAdvertsSubbrands(text).then(
      result =>
        resolve(result.map((entry) => ({...entry, pk_id: entry.id})))
    );
  });
};

const filter = (type) => {
  return (entry) => entry.type === type;
};

export const getIcon = (type) => {
  switch (type) {
    case 'articles':
      return (
        <div className="advert-tree-type">
          <MdAccountTree />
        </div>
      );

    case 'advertiser':
      return (
        <div className="advert-tree-type">
          <MdResetTv />
        </div>
      );

    case 'advert':
      return (
        <div className="advert-tree-type">
          <MdVideoLibrary />
        </div>
      );

    case 'brand':
      return (
        <div className="advert-tree-type">
          <MdCopyright />
        </div>
      );

    case 'subbrand':
      return (
        <div className="advert-tree-type" style={{fillOpacity: 0.7}}>
          <MdCopyright />
        </div>
      );

    case 'model':
      return (
        <div className="advert-tree-type">
          <MdModelTraining />
        </div>
      );
  }

  return <></>;
};

export const ADVERT_FIELDS = [
  {
    id: 'advert_id',
    name: 'Реклама',
    type: 'search',
    conditions: ['in', 'not in'],
    params: {
      keyField: 'id',
      single: false,
      search: searchAdvertsAdvert,
    }
  },
  {
    id: 'brand_id',
    name: 'Брэнд',
    type: 'treeView',
    conditions: ['in', 'not in'],
    params: {
      single: false,
      search: API.searchAdvertsBrands,
      selectFilter: filter('brand'),
      loader: API.getAdvertsTree,
    }
  },
  {
    id: 'subbrand_id',
    name: 'Суббрэнд',
    type: 'treeView',
    conditions: ['in', 'not in'],
    params: {
      single: false,
      search: API.searchAdvertsSubbrands,
      selectFilter: filter('subbrand'),
      loader: API.getAdvertsTree,
    }
  },
  {
    id: 'model_id',
    name: 'Модель',
    type: 'search',
    conditions: ['in', 'not in'],
    params: {
      single: false,
      keyField: 'id',
      search: API.searchAdvertsModel,
    }
  },
  {
    id: 'article_id',
    name: 'Категория',
    type: 'treeView',
    conditions: ['in', 'not in'],
    params: {
      single: false,
      search: API.searchAdvertsArticles,
      selectFilter: filter('article'),
      loader: API.getAdvertsTree,
    }
  },
  {
    id: 'advertiser_id',
    name: 'Рекламодатель',
    type: 'search',
    conditions: ['in', 'not in'],
    params: {
      keyField: 'id',
      search: searchAdvertsAdvertiser,
    }
  },
  {
    id: 'primetime',
    name: 'Праймтайм',
    type: 'checkboxGroup',
    conditions: ['=='],
    options: [
      {value: 1, label: 'Прайм-тайм'},
      {value: 2, label: 'Вне прайм-тайм'}
    ],
  },
  {
    id: 'network_campaign',
    name: 'Сетевая кампания',
    type: 'checkboxGroup',
    conditions: ['=='],
    options: [
      {value: 1, label: 'Да'},
      {value: 0, label: 'Нет'}
  ],
  },
  {
    id: 'local_campaign',
    name: 'Локальная кампания',
    type: 'checkboxGroup',
    conditions: ['=='],
    options: [
      {value: 1, label: 'Да'},
      {value: 0, label: 'Нет'}
  ],
  },
  {
    id: 'both_campaigns',
    name: 'Локальная + Сетевая кампания',
    type: 'checkboxGroup',
    conditions: ['=='],
    options: [
        {value: 1, label: 'Да'},
        {value: 0, label: 'Нет'}
    ],
  },
  {
    id: 'content',
    name: 'Контент',
    type: 'multiselect',
    conditions: ['in', 'not in'],
  },
  {
    id: 'channels',
    name: 'Каналы',
    type: 'multiselect',
    conditions: ['in', 'not in'],
  },
  {
    id: 'distribution',
    name: 'Распространение блока',
    type: 'multiselect',
    conditions: ['in', 'not in'],
  },
  {
    id: 'ClipId',
    name: 'Clip ID',
    type: 'text',
    conditions: ['in', 'not in'],
  },
  {
    id: 'BrandId',
    name: 'Brand ID',
    type: 'text',
    conditions: ['in', 'not in'],
  },
  {
    id: 'AdvertiserId',
    name: 'Advertiser ID',
    type: 'text',
    conditions: ['in', 'not in'],
  },
  {
    id: 'SubbrandId',
    name: 'Subbrand ID',
    type: 'text',
    conditions: ['in', 'not in'],
  },
  {
    id: 'ArticleId',
    name: 'Article ID',
    type: 'text',
    conditions: ['in', 'not in'],
  },
  {
    id: 'ModelId',
    name: 'Model ID',
    type: 'text',
    conditions: ['in', 'not in'],
  },
];
