import { ScheduleCellTVEvent } from '@pages/schedule/models/scheduleCellPayloadTVEvent';
import { intervalMinutesToTVTimeString } from '@pages/schedule/models/timeIntervalValueKind';

function createScheduleCellLabel(
  cell: ScheduleCellTVEvent,
  defaultValue = '',
): string {
  let result = '';

  try {
    result = `${cell.payload.program} ${cell.payload.category} ${
      cell.payload.pissId
    } ${intervalMinutesToTVTimeString(cell.tableTVTimeInterval.minutesStart)}`;
  } catch (e) {
    result = defaultValue;
  }

  return result;
}

export default createScheduleCellLabel;
