import React, { useState, useEffect } from "react";
import { Radio } from 'antd';

function Premier(props) {

  const [value, setValue] = useState();

  const onChange = (value) => {
    setValue(value);

    if (props.onChange) {
      props.onChange(value);
    }
  }

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  const options = [{
    label: 'Все',
    value: 'all',
  }, {
    label: 'Премьеры',
    value: 'premier',
  }, {
    label: 'Повторы',
    value: 'repeat',
  }];

  return (
    <div className="premier-wrapper">
      <Radio.Group options={options} onChange={({ target: { value }}) => onChange(value)} value={value} />
    </div>
  );
}

export default Premier;