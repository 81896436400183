import React, { useMemo, useEffect, useState } from 'react';

// @ts-ignore
import { ReactComponent as Trash } from '../../assets/trash.svg';
import { MinuteMetrics, Channel } from '../../models';
import { collectionToArray } from '../../models/utils';
import { useDictionaryContext } from '../../store/DictionaryProvider';
import { useStatsContext } from '../../store/StatsProvider';
import { useVideosContext } from '../../store/VideosProvider';
import { isLight, prevPeriod, findElement } from '../../utils';
import { ChannelsModal } from '../VideoUI/ChannelsModal';
import { Metrics } from '../VideoUI/Metrics';

export function MoreStats() {
  const { channels, statistics, audiences } = useDictionaryContext();

  const {
    moreChannels: selectedChannels,
    audience,
    date,
    statistics: selectedStats,
    time,
    interval,
    selectMoreChannel,
    deleteMoreChannel,
  } = useVideosContext();

  const { stats, loadStats } = useStatsContext();

  const [isModalVisible, setIsModalVisible] = useState<number>(0);

  const channelsData = useMemo(() => {
    const result: Channel[] = [];

    if (channels && selectedChannels && selectedChannels.length) {
      selectedChannels.forEach((channelId) => {
        const channel = channels?.filter(({ id }) => channelId === id);

        if (channel && channel.length) {
          result.push(channel[0]);
        }
      });
    }

    return result;
  }, [channels, selectedChannels]);

  const statsValues = useMemo(() => {
    if (selectedStats && time && channelsData) {
      return channelsData.map((channel) => {
        const values: MinuteMetrics = {};

        const prev = prevPeriod(time);

        selectedStats.forEach((type: string) => {
          values[type] = {
            current:
              channel &&
              stats &&
              stats[channel.channel_id] &&
              stats[channel.channel_id][time]
                ? stats[channel.channel_id][time][type]
                : 0,
            prev:
              channel &&
              stats &&
              stats[channel.channel_id] &&
              stats[channel.channel_id][prev]
                ? stats[channel.channel_id][prev][type]
                : 0,
          };
        });

        return {
          id: channel.id,
          title: channel.title,
          style: {
            color: isLight(channel.hex_color) ? '#FFFFFF' : '#000000',
            background: channel.hex_color,
          },
          values,
        };
      });
    }

    return [];
  }, [channelsData, stats, selectedStats, time]);

  const statsList = useMemo(() => {
    return statistics
      ? statistics.filter(
          ({ type }) => selectedStats && selectedStats.includes(type),
        )
      : [];
  }, [statistics, selectedStats]);

  const selectChannel = (value: number) => {
    setIsModalVisible(0);
    selectMoreChannel(value, isModalVisible);
  };

  useEffect(() => {
    if (channelsData && date && selectedStats && audience && audiences && interval) {
      const currentAudience = findElement(audiences, Number(audience));

      channelsData.forEach((channel) => {
        loadStats({
          channel: channel.channel_id,
          date,
          time: interval,
          target: currentAudience,
          statistics: selectedStats,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelsData, date, audience, audiences, selectedStats && interval]);

  return (
    <div className="more-stats">
      {!!statsValues &&
        statsValues.map((item) => (
          <div key={item.id}>
            <div className="more-stats-channel" style={item.style}>
              <div onClick={() => setIsModalVisible(item.id)}>{item.title}</div>
              <div className="trash" onClick={() => deleteMoreChannel(item.id)}>
                <Trash />
              </div>
            </div>
            <div className="more-stats-list">
              {time &&
                statsList.map((stat) => (
                  <Metrics
                    key={stat.type}
                    name={stat.name}
                    type={stat.type}
                    metric={item.values[stat.type]}
                  />
                ))}
            </div>
          </div>
        ))}
      {(!channelsData || channelsData.length < 4) && (
        <div
          className="more-stats-channel-select"
          onClick={() => setIsModalVisible(-1)}
        >
          ВЫБРАТЬ КАНАЛ
        </div>
      )}
      <ChannelsModal
        isModalVisible={!!isModalVisible}
        handleCancel={() => setIsModalVisible(0)}
        handleSelect={selectChannel}
      />
    </div>
  );
}
