/* eslint-disable react-hooks/exhaustive-deps */
import { usePrevious } from '@chakra-ui/react';
import { useEffect } from 'react';

import common from '@utils/common';

import { useScheduleTableContext } from '../stores';
import { hasNewDates, hasNewEntitiesWithId } from '../utils/hasNewElements';

/**
 * Хук, который следит за обновлениями в фильтрах (каналы, таймбенд,
 * аудитории, даты) и выставляет флаг meta = initial в сторе
 * для каждой пары "дата-аудитория"
 */
export const useObserveFilters = () => {
  const { value, actions } = useScheduleTableContext();

  const { channels, timeband, targetAudiences, dates } = value.filters;

  const {
    setAllDataNeedUpdate,
    loadAudiencesForDate,
    loadDatesForAudiences,
    syncTableDataWithFilters,
  } = actions;

  /**
   * При обновлении каналов или таймбенда пометить
   * все данные как требующие обновления (загрузки)
   */
  const channelsPrev = usePrevious(channels);

  const timebandPrev = usePrevious(timeband);

  useEffect(() => {
    if (!channels || !channelsPrev) {
      return;
    }

    if (hasNewEntitiesWithId(channelsPrev, channels)) {
      setAllDataNeedUpdate();

      return;
    }

    syncTableDataWithFilters();
  }, [channels, channelsPrev, setAllDataNeedUpdate]);

  useEffect(() => {
    if (!timebandPrev) {
      return;
    }

    setAllDataNeedUpdate();
  }, [timeband, timebandPrev, setAllDataNeedUpdate]);

  const datesPrev = usePrevious(dates);

  useEffect(() => {
    if (!dates || !datesPrev) {
      return;
    }

    /**
     * Если добавилась дата, то загрузить для нее данные
     * по всем выбранным аудиториям
     */
    if (dates.length > datesPrev.length || hasNewDates(datesPrev, dates)) {
      const addedDate = common.toTVDate(
        dates.filter((date) => !datesPrev.includes(date))[0],
        true,
      );

      loadAudiencesForDate(addedDate);

      return;
    }

    syncTableDataWithFilters();
  }, [dates, datesPrev, loadAudiencesForDate]);

  const targetAudiencesPrev = usePrevious(targetAudiences);

  useEffect(() => {
    if (!targetAudiences || !targetAudiencesPrev) {
      return;
    }

    /**
     * Если добавилась целевая аудитория, то загрузить для нее данные
     * по всем выбранным датам
     */
    if (
      targetAudiences.length > targetAudiencesPrev.length ||
      hasNewEntitiesWithId(targetAudiencesPrev, targetAudiences)
    ) {
      const addedTargetAudiences = targetAudiences.filter(
        (audience) => !targetAudiencesPrev.includes(audience),
      );

      loadDatesForAudiences(addedTargetAudiences);

      return;
    }

    syncTableDataWithFilters();
  }, [targetAudiences, targetAudiencesPrev, loadDatesForAudiences]);
};
