import React, { useState, useEffect, useMemo } from 'react'
import API from '@utils/AdminApi';
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchCollections } from '@store/collections';
import moment from 'moment';

import CalendarHeatmap from "@components/calendarheatmap/CalendarHeatmap";

import { ReactComponent as Right } from '../collections/crud/UI/images/right.svg';
import { ReactComponent as Down } from '../collections/crud/UI/images/down.svg';

import './Program.scss';

function Program() {
  const dispatch = useDispatch();

  const { program_id, channel_id } = useParams();

  const [ isLoading, setIsLoading ] = useState(false);

  const [ filtersData, setFiltersData ] = useState();

  let history = useNavigate();

  const [ programInfo, setProgramInfo ] = useState({name: '', columns: [], data: []});
  const [ programIssued, setProgramIssued ] = useState({columns: [], data: [], channel_id: channel_id, program_id: program_id});

  const [ isOpen, setIsOpen ] = useState(false);

  const toggle = () => {
    setIsOpen(value => !value);
  }

  if (!history.querydata) {
    history.querydata = {
      fromDate: new Date(),
      fromTime: "05:00",
      toDate: new Date(),
      toTime: "28:59"
    };
  }

  useEffect( () => {
    // INFO: Load data
    API.getProgramInfo(channel_id, program_id).then(response => {
      let name = response.data.filter(entry => entry.name === 'Название');
      let data = response.data.filter(entry => entry.name !== 'Название');

      setProgramInfo({
        name: name.length ? name[0].value : '',
        ...response,
        data
      });
    });

    dispatch(
      fetchCollections()
    );

    setIsLoading(true);

    API
      .getProgramIssuedByPeriod(
        program_id,
        channel_id,
        '2020-01-01',
        moment(_.now()).format('YYYY-MM-DD'),
        // timeband,
        // filtersData.premier
      )
      .then((result) => {
        setProgramIssued({...result, channel_id:channel_id, program_id: program_id})
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, []);

  const loadIssued = async () => {
    let startDate = null;
    let endDate = null;

    if (filtersData.filter === 'issued' && filtersData.issued && filtersData.issued.length) {
      API.getProgramIssuedByIds(program_id, filtersData.issued).then(
        result => setProgramIssued(result)
      )
      .finally(() => {
        setIsLoading(false);
      });
    } else if (filtersData.filter === 'date') {
      startDate = filtersData.date.format('YYYY-MM-DD');
      endDate = startDate;
    } else if (filtersData.filter === 'period' && filtersData.period) {
      startDate = moment(filtersData.period.startDate).format('YYYY-MM-DD');
      endDate = moment(filtersData.period.endDate).format('YYYY-MM-DD');
    }

    if (startDate === null) {
      return;
    }

    let timeband = null;

    if (filtersData.timeband) {
      let value = await API.getCollection(filtersData.timeband.id);
      timeband = value.data.content;
    }

    setIsLoading(true);

    API
      .getProgramIssuedByPeriod(
        program_id,
        channel_id,
        '2020-01-01',
        moment(_.now()).format('YYYY-MM-DD'),
        timeband,
        filtersData.premier
      )
      .then(result => setProgramIssued(result, channel_id, program_id))
      .finally(() => {
        setIsLoading(false);
      });
  }

  const filteProgram = useMemo(() => ({
    value: `${program_id}`
  }), [ program_id ]);

  return (
    <>
      <div className="card-wrapper">
        <div className="card-title">
          <div className="list" onClick={() => toggle()}>{ isOpen ? <Down /> : <Right /> }</div>
          <div className="title">Карточка программы</div>
        </div>
        <div className="card-content">
          <div className="channels-info">
            <div className="title">{ programInfo.name }</div>
            <div className="wrapper">
              { !!programInfo && !!programInfo.data ? programInfo.data.map(entry =>
                <div className={isOpen || ['Канал', 'Категория'].indexOf(entry.name) !== -1 ? "box" : "hidden"} key={entry.name}>
                  <div>{ entry.name }</div>
                  <div>{ entry.value }</div>
                </div>
              ) : <></> }
            </div>
          </div>
        </div>
      </div>
      <br />

      <div><CalendarHeatmap data={programIssued}/></div>
    </>
  )
}

export default Program
