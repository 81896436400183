import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DictionaryProvider } from '../../store/DictionaryProvider';
import { StatsProvider } from '../../store/StatsProvider';
import { VideosProvider } from '../../store/VideosProvider';
import { WallFormInterface } from '../../store/forms/WallForm';

import { Dashboard } from './Dashboard';

import './Dashboard.scss';
import './DashboardScreen.scss';

export function Wall() {
  const defaultValues = useSelector((state: RootState) => state.wallForm);

  const [filters, setFilters] = useState<WallFormInterface>();

  useEffect(() => {
    setFilters(defaultValues as WallFormInterface);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DictionaryProvider>
      <VideosProvider defaultValues={filters}>
        <StatsProvider>
          <Dashboard />
        </StatsProvider>
      </VideosProvider>
    </DictionaryProvider>
  );
}
