import React from 'react';
import { Form, Input } from 'antd';
import { Button } from '@components/UI/Buttons';

function ForgotPasswordForm(props) {
  const onFinish = values => {
      props.handleSubmit(values.email);
  }

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      className='ant-custom'
    >
      <Form.Item
        label="E-mail"
        rules={[
            { required: true, message: 'Введите e-mail.' }
        ]}
      >
          <Input />
      </Form.Item>
      <div>
        <Button type="primary" htmlType="submit" label="Отправить письмо" style={{ margin: '0 auto' }}></Button>
      </div>
    </Form>
  )
}

export default ForgotPasswordForm;