import { useState, useEffect, useCallback } from 'react';
import { ReactComponent as Right } from './images/right.svg';
import { ReactComponent as Down } from './images/down.svg';
import { useOptions } from './Storage/OptionsProvider';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

function List({ selected, onSelect, parent, path, filter, single }) {
  const [options, setOptions] = useState(null);

  const {groups, setGroups, getOptions} = useOptions();

  const isSelected = useCallback(
    (value) => selected?.map(el => el.value)?.indexOf(value) > -1,
    [selected]
  );

  const openGroup = (id) => {
    setGroups([...groups, id]);
  }

  const closeGroup = (id) => {
    setGroups(groups.filter(value => value !== id));
  }

  const toogleGroup = (value) => isOpen(value) ? closeGroup(value) : openGroup(value);

  const isOpen = useCallback((id) => {
    return groups.indexOf(id) !== -1;
  }, [groups]);

  useEffect(() => {
    const getResult = result => {
      setOptions(result);
    }

    let _options = getOptions(parent, filter, getResult);

    if (_options !== null) {
      setOptions(_options);
    }
  }, [filter])

  return (
    <>
      { options === null ? (
        <div className="multiselect-options-option">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        !!options && options.length ? options.map(option => 
          !!option.children
            ? (
              <div key={`option-${option.value}`}>
                <div
                  className="multiselect-options-option"
                >
                  <div className="__icon" style={{opacity: option.children !== null ? 1 : 0.3}}>
                    { isOpen(option.value, options) ? (
                      <Down onClick={() => closeGroup(option.value)} />
                    ) : (
                      <Right onClick={() => option.children !== null ? openGroup(option.value) : false} />
                    ) }
                  </div>
                  <div onClick={() => toogleGroup(option.value)}>{ option.label }</div>
                </div>
                { isOpen(option.value, options) && (
                  <div
                    className="multiselect-options-group"
                  >
                    <List parent={option.value} onSelect={onSelect} filter={filter} selected={selected} single={single} path={[...path, option.value]}/>
                  </div>
                ) }
              </div>
            ) : (
              <div
                key={`option-${option.value}`}
                className="multiselect-options-option"
              >
                {!single && <div><input type="checkbox" checked={isSelected(option.value)} onChange={event => onSelect(option)}/></div> }
                <div onClick={() => onSelect(option)}>{ option.label }</div>
              </div>
            )
        ) : (
          <div className="multiselect-options-option">
            <div className="small">пусто</div>
          </div>
        )
      ) }
    </>
  )
}

export default List;