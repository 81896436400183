import cn from 'classnames';
import React from 'react';

import { useFiltersData, useLoadSchedule, useObserveFilters } from '../hooks';

import { Channels } from './Channels';
import { DatePicker } from './DatePicker';
import s from './ScheduleFilters.module.scss';
import { Targets } from './Targets';
import { Timeband } from './Timeband';

const ScheduleFilters: React.FC = () => {
  const {
    channelsFilterParams,
    timebandFilterParams,
    targetAudienceFilterParams,
    dateFilterParams,
  } = useFiltersData();

  useObserveFilters();

  useLoadSchedule();

  return (
    <div className={s['schedule-table__filters']}>
      <div
        className={cn(
          s['schedule-table__filter'],
          s['schedule-table__filter_full-width'],
        )}
      >
        <Channels
          className={s['schedule-table__filter-content']}
          label="Каналы"
          placeholder="Выберите каналы"
          {...channelsFilterParams}
          oneLine
        />
      </div>
      <div
        className={cn(
          s['schedule-table__filter'],
          s['schedule-table__filter_full-width'],
        )}
      >
        <Targets
          className={s['schedule-table__filter-content']}
          label="Аудитории"
          single={false}
          isRequired={false}
          {...targetAudienceFilterParams}
          oneLine
        />
      </div>
      <div className={s['schedule-table__filter']}>
        <Timeband
          className={s['schedule-table__filter-content']}
          label="Таймслот"
          placeholder="Выберите таймслот"
          single={true}
          {...timebandFilterParams}
          oneLine
        />
      </div>
      <div className={s['schedule-table__filter']}>
        <DatePicker
          className={s['schedule-table__filter-content']}
          label="Даты"
          placeholder="Добавьте дату"
          {...dateFilterParams}
        />
      </div>
    </div>
  );
};

export default ScheduleFilters;
