import { Audience, Collection } from '@models/index';

/**
 * "Условие" - формализованное представление ЦА в виде массива типа ConditionArrayLikeEntity
 * @param targetAudiences массив всех объектов ЦА
 * @param audience индекс (имя) аудитории, для которой запрашивается "условие"
 * @param conditions объект с "условиями"
 * @returns объект ЦА для передачи в запрос
 */
export const getAudienceCondition = (
  targetAudiences: Audience[],
  audience: string,
  conditions: Record<number, Collection>,
): Collection | null => {
  const audienceId = targetAudiences.find(
    (element) => element.name === audience,
  )?.id;

  if (!audienceId || !conditions[audienceId]) {
    return null;
  }

  return conditions[audienceId];
};
